<script>
  import {
    fetchDeletePrices,
    fetchCreatePrices,
    fetchAndStorePrices,
    fetchCSV,
  } from "../api";
  import { prices, spaces } from "../stores";

  export let property = null;

  let file = null;

  let items = [];
  let itemPrices = {};
  let submitting = false;
  let submittable = false;

  let edited = {};
  // $: pricesBySubject = ($prices || []).reduce((map, item) => {
  //     // const prices = map[item.subject.id || item.subject] = (map[item.subject.id || item.subject] || []);
  //     // prices.push(item);
  //     map[item.subject.id || item.subject] = item;
  //     return map;
  // }, {});

  // $: console.log("pricesBySubject=", pricesBySubject);

  //spacePrices = list;
  //items = items;

  $: if ($prices && $spaces) {
    itemPrices = $prices.reduce((map, item) => {
      // const prices = map[item.subject.id || item.subject] = (map[item.subject.id || item.subject] || []);
      // prices.push(item);
      for (const id of Object.keys(item.subjects)) {
        map[id] = item;
      }
      //map[item.subject.id || item.subject] = item;
      return map;
    }, {});

    items = $spaces;
  }

  $: if (file && $spaces) processCsvFile($spaces, file);

  function grouped() {}

  function edit(space, price) {
    edited[space.id || space] = price;
  }

  function checkSubmittable() {
    submittable = Object.keys(edited).length > 0;
    console.log("edited=", edited);
  }

  $: if (edited) checkSubmittable();

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // delete
    const deleteData = new FormData();

    const groups = {};

    for (const [subject, amount] of Object.entries(edited)) {
      var prev = itemPrices[subject];
      if (prev) deleteData.append("price", prev.id);

      // if(!amount) continue;
      // if(amount && parseFloat(amount) > 0) {

      //     const group = groups[amount] = (groups[amount] || []);
      //     group.push(id);
      // }
    }

    // prices to create

    await Promise.all([
      fetchDeletePrices(property, deleteData),
      ...Object.entries(edited)
        .filter(([subject, amount]) => !!amount)
        .map(function ([subject, amount]) {
          const createData = new FormData(form);
          createData.append("amount.dollars", amount);

          createData.append("subject", subject);

          return fetchCreatePrices(property, createData);
        }),
    ]);

    // first close out the prices
    //await fetchDeletePrices(property, deleteData);

    // for(const [ amount, ids ] of Object.entries(groups)) {
    //     const createData = new FormData(form);
    //     createData.append("amount.dollars", amount);

    //     for(const id of ids) {
    //         createData.append("subject", id);
    //     }

    //     await fetchCreatePrices(property, createData);

    // }

    await fetchAndStorePrices(property);

    submitting = false;

    // do the updating
    edited = {};
  }

  function normalize(str) {
    return !str || (str + "").replace(/[^a-zA-Z0-9]/gi, "").toUpperCase();
  }

  async function processCsvFile(spaces, file) {
    if (!file) return;

    let input = file;

    console.log("process=", file);

    const spacesByKey = spaces.reduce((result, space) => {
      result[space.key] = space;
      return result;
    }, {});

    //console.log("spacesByKey=", spacesByKey);

    if (file.type !== "text/csv" && file.name.indexOf(".csv") < 0)
      input = await fetchCSV(file);

    const numeric = /[$.]/gi;

    Papa.parse(input, {
      header: false,
      complete: function (results) {
        console.log(results);

        for (const item of results.data) {
          let spaceFieldNameOrKey = null;
          let space = null;

          //check for explicit space field
          if (!!item.space) {
            spaceFieldNameOrKey = "space";
            space = spacesByKey[normalize(item.space)];
          }

          // find the first field that matches a unit
          for (const [key, value] of Object.entries(item)) {
            if (!!space || null != spaceFieldNameOrKey) break;

            if (numeric.test(key + "")) continue; // money or numeric

            const normalized = normalize(value);

            space =
              spacesByKey[normalized] ||
              spacesByKey["0" + normalized] ||
              (normalized &&
                normalized[0] === "0" &&
                spacesByKey[normalized.substring(1)]);

            if (!!space) spaceFieldNameOrKey = key;

            //console.log("identified unit field?", unitFieldNameOrKey, tenant);
          }

          if (!space) continue;

          let price = null;

          for (const [key, value] of Object.entries(item)) {
            if (key === spaceFieldNameOrKey) continue; // skip the one we already matched
            if (!value) continue;

            price = value;
            if (price && price.replace) price = price.replace(/[^0-9.]/gi, "");
            break;
          }

          if (space && price) edit(space, price);
        }

        //console.log(results);
      },
    });

    edited = edited;
  }

  function files(e) {
    //console.log(e);

    var items =
      (e.target && e.target.files) ||
      (e.dataTransfer && e.dataTransfer.files) ||
      [];
    //console.log("files=", items);
    if (!items.length) return;

    for (let item of items) {
      if (
        item.type !== "text/csv" &&
        item.name.indexOf(".csv") < 0 &&
        item.name.indexOf(".xlsx") < 0 &&
        item.name.indexOf(".xls") < 0
      )
        continue;
      file = item;
    }

    if (e.target) e.target.value = "";

    e.preventDefault();
  }
</script>

<form
  on:input={checkSubmittable}
  on:change={checkSubmittable}
  on:submit={submit}
>
  <fieldset>
    <ul>
      <li>
        <label for="new-price-concurrent">Type</label><select
          id="new-price-concurrent"
          name="period"
        >
          <option value="P1M">monthly</option>
        </select>
      </li>
      <li>
        <input
          id="prices-file"
          type="file"
          on:change={files}
          accept="text/csv,.csv,.xlsx,.xls"
        />
        <label for="prices-file">Import from CSV</label>
      </li>
      {#each items as space}
        <li>
          <!-- <label> -->
          <data class="space id" value={space.id}>{space.display}</data>
          <!-- </label> -->
          <input
            on:input={(e) => edit(space.id, e.target.value)}
            on:change={(e) => edit(space.id, e.target.value)}
            type="number"
            min="0"
            value={edited[space.id] ||
              (itemPrices[space.id]
                ? (itemPrices[space.id].total.value / 100).toFixed(2)
                : "")}
            step="0.01"
            placeholder="none"
          />
        </li>
      {/each}
    </ul>
  </fieldset>
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Saving" : "Save Prices"}</button
    >
  </footer>
</form>
