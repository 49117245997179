<script lang="ts">
  import { LabelledSelect, ShadedBox } from "@communityboss/components";
  import debug from "debug";
  import { propertyId } from "../../stores";
  import UploadDropzone from "./UploadDropzone.svelte";
  import type { UploadFormViewModel } from "./stores";

  const log = debug("boss:components:import-permits:UploadForm");

  export let view_model: UploadFormViewModel;

  let dragging = false;
  let file_input: HTMLInputElement | null;

  function handle_drop(event: CustomEvent<{ files: FileList }>) {
    if (!file_input) return alert("no file input found");

    const dropped_files = event.detail.files;

    log("upload:", dropped_files);

    const data_transfer = new DataTransfer();
    for (const file of dropped_files) {
      data_transfer.items.add(file);
    }

    file_input.files = data_transfer.files;
    const file = file_input.files[0];

    log("file:", file);

    view_model.upload_csv(file);
  }

  function update_csv(e: Event) {
    const textarea = e.target as HTMLTextAreaElement;
    view_model.update_csv(textarea.value);
  }

  function upload_csv() {
    const file = file_input?.files?.[0];
    view_model.upload_csv(file);
  }

  function create_permits() {
    view_model.create_permits();
  }
</script>

<UploadDropzone bind:dragging on:drop={handle_drop} />

{#if $view_model.importing}
  <progress
    class="progress progress-success w-full h-4 mt-8"
    value={$view_model.progress}
    max="100"
  />
{/if}

<form on:submit|preventDefault={create_permits}>
  <input type="hidden" name="location" value={$propertyId} />
  <fieldset>
    <ul>
      <li>
        <textarea
          name="csv"
          cols="30"
          rows="10"
          placeholder="Paste or upload CSV file..."
          value={$view_model.csv}
          on:input={update_csv}
          disabled={$view_model.importing}
        />
      </li>
    </ul>
  </fieldset>
  {#if $view_model.policies?.length}
    <ShadedBox>
      <LabelledSelect
        label="Policy"
        bind:value={$view_model.selected_policy}
        required
      >
        <option value="" selected disabled hidden>Select a policy</option>
        {#each $view_model.policies as policy}
          <option value={policy.id}>{policy.title}</option>
        {/each}
      </LabelledSelect>
    </ShadedBox>
  {/if}
  <footer>
    <input
      type="file"
      name="csv_file"
      accept=".csv,.xlsx,.xls"
      style:display="none"
      bind:this={file_input}
      on:change={upload_csv}
    />
    <button
      type="button"
      class="btn-submit"
      on:click={() => file_input?.click()}
      disabled={$view_model.importing}
    >
      Choose CSV File
    </button>
    <button type="submit" disabled={!$view_model.csv || $view_model.importing}
      >Create Permits</button
    >
  </footer>
</form>

<!--
	OLD FORM:
	<iframe
    title="import"
    src="https://boss.parkingboss.com/locations/{$propertyId}/permits/media/import#access_token={$token}"
    style:width="100%"
    style:height="800px"
  /> 
-->
<style lang="scss">
  form {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    > fieldset {
      margin: 0;
    }

    footer {
      display: flex;
      align-items: center;
      column-gap: 1rem;

      button {
        flex: 1;
      }
    }
  }
</style>
