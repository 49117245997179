<script>
  import Valid from "./Valid.svelte";
  // import { permitId } from "./stores";
  import { get, capitalize } from "lodash-es";
  import { format } from "date-fns";

  export let permit = null;
  export let status = "";
  let selected = false;
  //$: selected = !!permit && permit.id === $permitId;
  $: valid = permit && permit.valid.interval; //.interval.split('/').map(deviceTimeAsIfLocal).map(d => format(d, "yyyy-MM-dd'T'HH:mm:ssxxx")).join("/");
</script>

<data class="{get(permit, 'type', 'permit')} {status}" class:selected>
  {#if permit}
    <h1>{permit.title.toLowerCase()}</h1>
    <Valid interval={valid} bind:status />
    <h2>
      {permit.name ||
        "Any Resident" ||
        `Entire ${capitalize(get(permit, "tenant.format", "Unit"))}`}
    </h2>
    {#if !!permit.vehicle}
      <data
        class="{permit.vehicle.type} id {[
          permit.vehicle.format,
          permit.vehicle.size,
        ]
          .filter((i) => !!i)
          .join(' ')}"
        value={permit.vehicle.id}>{permit.vehicle.display}</data
      >
    {/if}
    {#each permit.spaces as space}
      <data
        class="{space.type} id {[space.format, space.size]
          .filter((i) => !!i)
          .join(' ')}"
        value={space.id}>{space.display}</data
      >
    {/each}
    {#if !!permit.tenant}
      <data
        class="{permit.tenant.type} id {[
          permit.tenant.format,
          permit.tenant.size,
        ]
          .filter((i) => !!i)
          .join(' ')}"
        value={permit.tenant.id}>{permit.tenant.display}</data
      >
    {/if}
  {/if}
</data>
