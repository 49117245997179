<script>
  import { onMount } from "svelte";
  import { createNewProperty } from "../api";
  import { toasts } from "../stores";

  let scheduleType;
  let isSubmitting;

  async function handleSubmit(e) {
    e.preventDefault();
    isSubmitting = true;

    const form = document.querySelector("form");
    console.log(form);
    let result;

    try {
      result = await createNewProperty(new FormData(form));
    } catch (err) {
      toasts.add(`${err}`, { type: "error" });
    } finally {
      console.log(result);

      if (result.ok === false) {
        // failed, result
        toasts.add("Failed to create property.", { type: "error" });
      } else {
        // json
        window.location.href = "/properties/" + result.properties.item;
      }

      isSubmitting = false;
    }
  }
</script>

<main>
  <form id="new-property-form" method="POST" on:submit={handleSubmit}>
    <!-- <header>
        <h1>New Property</h1>
    </header> -->

    <fieldset>
      <p>Name:</p>
      <ul>
        <li>
          <label for="new-property-name">Name</label><input
            id="new-property-name"
            name="name"
            maxlength="50"
            type="text"
            placeholder="required"
            required
          />
        </li>
        <li>
          <label for="new-property-alias">Alias</label><input
            id="new-property-alias"
            name="alias"
            type="text"
            placeholder="optional"
          />
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <p>Address:</p>
      <ul>
        <li>
          <label for="new-property-street">Street</label><input
            id="new-property-street"
            name="street"
            type="text"
            maxlength="150"
            placeholder="required"
            required
          />
        </li>
        <li>
          <label for="new-property-city">City</label><input
            id="new-property-city"
            name="city"
            type="text"
            maxlength="50"
            placeholder="required"
            required
          />
        </li>
        <li>
          <label for="new-property-state">State</label><input
            id="new-property-state"
            name="state"
            maxlength="2"
            type="text"
            placeholder="required"
            required
          />
        </li>
        <li>
          <label for="new-property-postal">Zip</label><input
            id="new-property-postal"
            name="postal"
            type="text"
            maxlength="5"
            placeholder="required"
            required
          />
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <p>Management:</p>
      <ul>
        <li>
          <label for="new-property-units">Units</label><input
            id="new-property-units"
            name="units"
            type="number"
            min="0"
            placeholder="required"
            required
          />
        </li>
        <li>
          <label for="new-property-management">Management</label><input
            id="new-property-management"
            name="management"
            type="text"
            placeholder="required"
            required
          />
        </li>
        <li>
          <label for="new-property-type">Type</label>
          <select
            id="new-property-type"
            name="type"
            placeholder="required"
            required
          >
            <option value="hoa">HOA</option>
            <option value="multifamily">multifamily</option>
            <option value="commercial">commercial</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <p>Payment:</p>
      <ul>
        <li>
          <label for="new-property-payment-schedule">Schedule</label>
          <select
            id="new-property-payment-schedule"
            bind:value={scheduleType}
            placeholder="required"
            required
          >
            <option value="annual">annual</option>
            <option value="monthly">monthly</option>
            <option value="permit">permit fee</option>
          </select>
        </li>
        <li>
          <label for="new-property-amount">Amount</label><input
            id="new-property-amount"
            min="0"
            name={scheduleType}
            type="number"
            step="any"
            placeholder="required"
            required
          />
        </li>
      </ul>
    </fieldset>
    <footer>
      <button disabled={isSubmitting} type="submit"
        >{isSubmitting ? "Creating..." : "Create Property"}</button
      >
    </footer>
  </form>
</main>
