<script>
  import {
    fetchCreateSpaces,
    fetchCreateSpaces as update,
    fetchCSV,
  } from "../api";
  import { get, invokeMap, split, uniq } from "lodash-es";

  export let property = null;

  let submittable = false;
  let submitting = false;

  let items = [];

  function change(e) {
    submittable = items?.length || e?.target?.form.checkValidity();
  }

  $: change(items); // trigger change on items

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    const form = e.target;

    submitting = true;

    const attrs = [
      "format",
      "size",
      "capacity",
      "level",
      "indoor",
      "outdoor",
      "covered",
      "disabled",
      "capacity:charging",
      "controlled",
      "capacity:disabled",
      "capacity:lift",
      "parking_space",
    ].reduce(
      (result, k) =>
        ((result[k] = form.elements[k]?.value) && result) || result,
      {}
    );

    // update with local if not set from file
    if (!items?.length)
      items = uniq(
        invokeMap(
          split(form.elements["space"].value, /\r?\n/),
          String.prototype.trim
        )
      ).map((item) => ({
        space: item,
        ...attrs,
      }));

    // TODO: diff items from previous spaces?
    await update(property, items);

    submitting = submittable = false;

    //property = null;
    items = [];

    form.reset();
  }

  async function processCsvFile(file) {
    if (!file) return;

    let input = file;

    console.log("process=", file);

    if (file.type !== "text/csv" && file.name.indexOf(".csv") < 0)
      input = await fetchCSV(file);

    // const numeric = /[$.]/gi;

    return new Promise(function (resolve) {
      Papa.parse(input, {
        header: true,
        complete: async function (results) {
          console.log(results);

          // straight update?
          //await fetchCreateSpaces(property, results.data);
          items = results.data;
          return resolve(results.data);
        },
      });
    });
  }
  async function files(e) {
    //console.log(e);

    e.preventDefault();

    var items =
      (e.target && e.target.files) ||
      (e.dataTransfer && e.dataTransfer.files) ||
      [];
    //console.log("files=", items);

    if (e.target) e.target.disabled = true;

    if (!items.length) return;

    for (let item of items) {
      if (
        item.type !== "text/csv" &&
        item.name.indexOf(".csv") < 0 &&
        item.name.indexOf(".xlsx") < 0 &&
        item.name.indexOf(".xls") < 0
      )
        continue;
      await processCsvFile(item);
    }

    if (e.target) {
      e.target.value = "";
      e.target.disabled = false;
    }
  }
</script>

{#if property && property.spaces && property.spaces.enabled}
  <form on:change={change} on:input={change} on:submit={submit}>
    <fieldset>
      <!-- <p>One {get(property, "spaces.title", "").toLowerCase()} per line, once created duplicates will be skipped:</p> -->

      <ul>
        <li>
          <input
            id="prices-file"
            type="file"
            on:change={files}
            accept="text/csv,.csv,.xlsx,.xls"
          />
          <label for="prices-file">Import from CSV</label>
        </li>
        {#if !items?.length}
          <li>
            <label for="new-spaces-format">Type</label>
            <select name="format" id="new-spaces-format">
              <option value="parking">parking</option>
              <option value="storage">storage</option>
              <option value="amenity">amenity</option>
              <option value="leisure">leisure</option>
              <option value="office">office</option>
            </select>
          </li>
          <li>
            <label for="new-spaces-parking_space">Parking</label>
            <select name="parking_space" id="new-spaces-parking_space">
              <option value="normal">normal</option>
              <option value="motorcycle">motorcycle</option>
              <option value="bicycle">bicycle</option>
              <option value="disabled">disabled</option>
              <option value="charging">charging</option>
              <option value="van">van</option>
              <!-- <option value="tandem">tandem</option> -->
              <option value="multiple">multiple</option>
            </select>
          </li>
          <li>
            <label for="new-spaces-size">Size</label>
            <select name="size" id="new-spaces-size">
              <option value="standard">standard</option>
              <option value="compact">compact</option>
              <option value="oversize">oversize</option>
              <!-- <option value="motorcycle">motorcycle</option> -->
              <!-- <option value="tandem">tandem</option> -->
              <option value="multiple">multiple</option>
            </select>
          </li>
          <li>
            <label for="new-spaces-capacity">Capacity</label>
            <input
              type="number"
              min="1"
              name="capacity"
              id="new-spaces-capacity"
              value="1"
            />
          </li>
          <li>
            <label for="new-spaces-charging">EV charging</label>
            <select name="capacity:charging" id="new-spaces-charging">
              <option value="false">no</option>
              <option value="true">yes</option>
            </select>
          </li>
          <li>
            <label for="new-spaces-controlled">Secure</label>
            <select name="controlled" id="new-spaces-controlled">
              <option value="false">no</option>
              <option value="true">controlled access</option>
            </select>
          </li>
          <li>
            <label for="new-spaces-disabled">Accessibilty</label>
            <select name="disabled" id="new-spaces-disabled">
              <option value="false">standard space</option>
              <option value="true">disabled access only</option>
            </select>
          </li>
          <li>
            <label for="new-spaces-lift">Lift</label>
            <select name="capacity:lift" id="new-spaces-lift">
              <option value="">no</option>
              <option value="yes">yes</option>
            </select>
          </li>
          <!-- <li>
                <label for="new-spaces-options">Options</label>
                <ul>
                    <li>
                        <input
                          id="new-spaces-charging"
                          type="checkbox"
                          name="capacity:charging"
                          value="true"
                           /><label for="new-spaces-charging">EV charging</label>
                      </li><li>
                        <input
                          id="new-spaces-controlled"
                          type="checkbox"
                          name="controlled"
                          value="true"
                           /><label for="new-spaces-controlled">controlled access</label>
                      </li>
                      <li>
                        <input
                          id="new-spaces-accessible"
                          type="checkbox"
                          name="disabled"
                          value="true"
                           /><label for="new-spaces-accessible">accessible</label>
                      </li>
                </ul>
            </li> -->
          <li>
            <label for="new-spaces-level">Level</label>
            <input
              placeholder="optional"
              type="text"
              name="level"
              id="new-spaces-level"
            />
          </li>
          <li>
            <label for="new-spaces-indoor">Indoor</label>
            <select name="indoor" id="new-spaces-indoor">
              <option value="">--</option>
              <option value="yes">parking garage</option>
              <option value="garage">single garage</option>
            </select>
          </li>
          <li>
            <label for="new-spaces-outdoor">Outdoor</label>
            <select name="outdoor" id="new-spaces-outdoor">
              <option value="">--</option>
              <option value="yes">yes</option>
            </select>
          </li>
          <li>
            <label for="new-spaces-covered">Covered</label>
            <select name="covered" id="new-spaces-covered">
              <option value="">--</option>
              <option value="yes">yes</option>
              <option value="carport">carport</option>
            </select>
          </li>
          <li>
            <label for="new-spaces">Spaces</label>
            <textarea id="new-spaces" name="space" required />
          </li>
        {/if}
      </ul>
    </fieldset>
    <p>
      Please note: one {get(property, "spaces.title", "").toLowerCase()} per line,
      {get(property, "spaces.title", "").toLowerCase()}s will be created once
      and duplicates will be skipped.
    </p>
    <!-- <label for="new-spaces">Important: enter one {get(property, "spaces.title", "").toLowerCase()} per line:</label>
    <textarea id="new-spaces" name="space" required />
    <p>Please note: {get(property, "spaces.title", "").toLowerCase()}s will be created once and duplicates will be skipped.</p> -->
    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Add/Update Spaces"}</button
      >
      <!-- <button type="reset" disabled={submitting}>Cancel</button> -->
    </footer>
  </form>
{/if}
