<script>
  import SearchMedia from "./SearchMedia.svelte";
  import SearchPermit from "./SearchPermit.svelte";
  import SearchViolation from "./SearchViolation.svelte";
  import SearchUser from "./SearchUser.svelte";
</script>

<main>
  <SearchPermit />
  <SearchViolation />
  <SearchMedia />
  <SearchUser />
</main>
