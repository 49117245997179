<script>
  import { fetchDeletePolicyMeters } from "./api";
  import { get, filter, entries } from "lodash-es";
  import { format, utcToZonedTime } from "date-fns-tz";
  import { metersFromPolicy } from "./util/policy";
  import { sortBy } from "./util/sort";
  import { parseISO } from "date-fns";
  import DayTimesWeekChart from "$components/daytimes/DayTimesWeekChart.svelte";

  export let policy = null;
  export let groups = [];
  export let readonly = false;

  let submittable = false;
  let submitting = false;

  let remove = {};
  $: if (filter(remove).length > 0) submittable = true;

  $: meters = metersFromPolicy(policy).filter(
    (meter) =>
      ((!meter.group || meter.group == "principal") &&
        !Object.values(meter.principals || {}).length &&
        !groups?.length &&
        meter.concurrent != 0) ||
      groups.includes(meter.group) ||
      Object.values(meter.principals || {}).filter((item) =>
        groups.includes(item.type)
      ).length
  );

  function change(e) {
    submittable = filter(remove).length > 0;
  }

  async function submit(e) {
    e.preventDefault();

    if (!policy || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    await fetchDeletePolicyMeters(policy, formData);

    submitting = submittable = false;
    remove = {};
  }
</script>

{#if policy}
  <form on:submit={submit} on:change={change}>
    <fieldset disabled={readonly && "disabled"}>
      <p>Limit</p>
      <ul
        class="remove"
        data-count={get(
          meters.filter((i) => null != i.maximum),
          "length",
          ""
        )}
      >
        {#each meters.filter((i) => null != i.maximum) as item}
          <li>
            <input
              type="checkbox"
              name="meter"
              value={item.id}
              checked={!!remove[item.id]}
              on:change={(e) => (remove[e.target.value] = e.target.checked)}
            />
            <!-- <h1>
              
            </h1> -->
            <dl>
              <dt>Meter</dt>
              <dd>
                {item.display}

                {[
                  item.match,
                  //(item.group && `per ${item.group}`) || "for entire property",
                  // item.principals &&
                  //   `for ${Object.values(item.principals)
                  //     .map((p) => p.display || p)
                  //     .join(",")}`,
                ]
                  .filter((i) => !!i)
                  .join(" ")}
              </dd>
              <!-- <dd>
                <DayTimesWeekChart values={item.valid.daytime} />
              </dd> -->
              <dt>Applies To</dt>
              {#each sortBy(Object.values(item.principals || {}), "display") as item}
                <dd>
                  <data
                    class="id {[item.format, item.type].join(' ')}"
                    value={item.id}>{item.display}</data
                  >
                </dd>
              {:else}
                {#if item.group}
                  <dd>each {item.group.replace("tenant", "unit")}</dd>
                {:else}
                  <dd>entire property</dd>
                {/if}
              {/each}
              <dt>Policies</dt>
              {#each Object.entries(item.subjects).filter(([id, title]) => true || id != policy.policy) as [id, policy]}
                <dd><data value={id}>{policy.title}</data></dd>
              {/each}
              <dt>Primary</dt>
              <dd>{item.subject ? item.subject.title : "--"}</dd>
              <dt>Valid</dt>
              <dd>
                {#if !item.valid.interval.endsWith("/")}
                  {item.valid.interval
                    .split("/")
                    .map((str) =>
                      format(
                        utcToZonedTime(str, policy.timezone),
                        " EE MMM d yyyy h:mm a"
                      )
                    )
                    .join(" - ")}
                {:else if parseISO(item.valid.interval.split("/")[0]).getTime() > Date.now()}
                  starting {format(
                    utcToZonedTime(
                      item.valid.interval.split("/")[0],
                      policy.timezone
                    ),
                    " EE MMM d yyyy h:mm a"
                  )}
                {:else}
                  until removed
                {/if}
              </dd>
              <dt>Days & Times</dt>
              <dd>
                {#if item.valid.display}
                  {item.valid.display}
                {:else}
                  all/any
                {/if}
              </dd>
              <dd>
                <DayTimesWeekChart
                  values={item.valid.daytime.length
                    ? item.valid.daytime
                    : ["7T00:00/7T00:00"]}
                />
              </dd>
              {#if item.concurrent}
                <dt>Spacing</dt>
                <dd>{item.spacing || "none"}</dd>
              {/if}
            </dl>
          </li>
        {/each}
      </ul>
    </fieldset>
    {#if meters.filter((i) => i.minimum).length}
      <fieldset disabled={readonly && "disabled"}>
        <p>Report</p>
        <ul
          class="remove"
          data-count={get(
            meters.filter((i) => i.minimum),
            "length",
            ""
          )}
        >
          {#each meters.filter((i) => i.minimum) as item}
            <li>
              <input
                type="checkbox"
                name="meter"
                value={item.id}
                checked={!!remove[item.id]}
                on:change={(e) => (remove[e.target.value] = e.target.checked)}
              />
              <h1>
                {item.display}
                {#if !item.valid.interval.endsWith("/")}
                  - {item.valid.interval
                    .split("/")
                    .map((str) =>
                      format(
                        utcToZonedTime(str, policy.timezone),
                        " EE MMM d yyyy h:mm a"
                      )
                    )
                    .join(" - ")}
                {/if}
                {[
                  item.match,
                  item.group && `per ${item.group}`,
                  item.principals &&
                    `for ${Object.values(item.principals)
                      .map((p) => p.display || p)
                      .join(",")}`,
                ]
                  .filter((i) => !!i)
                  .join(" ")}
              </h1>
              {#each Object.entries(item.subjects).filter(([id, title]) => id != policy.policy) as [id, title]}
                <data value={id}>{title}</data>
              {/each}
            </li>
          {/each}
        </ul>
      </fieldset>
    {/if}
    {#if !!get(meters, "length") && (submitting || submittable)}
      <footer>
        <button type="submit" disabled={!submittable || submitting}
          >{submitting ? "Saving" : "Remove Meters"}</button
        >
      </footer>
    {/if}
  </form>
{/if}
