<script>
  import { utcToZonedTime, format as formatTZ } from "date-fns-tz";
  import {
    format,
    isWithinInterval,
    areIntervalsOverlapping,
    eachDayOfInterval,
    startOfDay,
    endOfDay,
    addDays,
    min,
  } from "date-fns";
  import { percentOf, getPercentInterval } from "../util/interval";
  import { toZoneISOString, timeLocal } from "../util/datetime";
  import { minuteNow } from "../stores";

  //export let timezone = "America/New_York";
  export let x = "datetime";
  export let policy = {
    generated: "2020-12-23T17:13:55.594Z",
    type: "policy",
    format: "amenity",
    result: "permit",
    id: "5p2j7d88md74ndzvfk2j2tcqz4",
    uuid: "2d8523b5-08a3-4e4a-b7fb-7cc5216997f9",
    version: "5p2j7d88md74ndzvfk2j2tcqz4",
    policy: "sqxvfbxkas2c19ey2dan752xr4",
    timezone: "America/New_York",
    title: "Fitness Center",
    photo: {
      generated: "2020-12-23T17:13:55.594Z",
      id: "x89a690xj96v5dg60p2gg6ak44",
      uuid: "ea12a324-1d92-4db2-b606-058508195321",
      title: "fitnesscenter.jpg",
      name: "fitnesscenter.jpg",
      format: "image/jpeg",
      type: "file",
      image: true,
      length: 135366,
      created: "2020-08-26T15:58:59.970Z",
      updated: "2020-08-26T15:58:59.970Z",
      url: "https://upload.parkingboss.com/files/ea12a324-1d92-4db2-b606-058508195321/fitnesscenter.jpg",
    },
    enabled: true,
    scope: "y1g3mebta503n79nhabtg09r74",
    subject: "sqxvfbxkas2c19ey2dan752xr4",
    location: "y1g3mebta503n79nhabtg09r74",
    amenity: "amenity",
    documents: {
      count: 1,
      items: {
        nx24wjj4rh4yn4s7s5zwh58sd0: {
          generated: "2020-12-23T17:13:55.594Z",
          id: "nx24wjj4rh4yn4s7s5zwh58sd0",
          uuid: "af444e4a-44c4-49ea-9327-c97fc8951968",
          title: "amenityspacerules05.19.20cm1002002.pdf",
          name: "amenityspacerules05.19.20cm1002002.pdf",
          format: "application/pdf",
          type: "file",
          image: false,
          length: 129406,
          created: "2020-05-28T20:52:29.503Z",
          updated: "2020-05-28T20:52:29.503Z",
          url: "https://upload.parkingboss.com/files/af444e4a-44c4-49ea-9327-c97fc8951968/amenityspacerules05.19.20cm1002002.pdf",
        },
      },
    },
    audience: {
      title: "Parking Attendant",
      public: true,
      admin: false,
    },
    issue: {
      enabled: true,
      disabled: false,
      title: "get pass",
    },
    permit: {
      format: "temporary",
      title: "Temporary",
      continuous: false,
      temporary: true,
    },
    permits: {
      title: "Fitness Center",
      create: true,
      transient: true,
      reclaimable: true,
      selfservice: {
        title: "get pass",
        enabled: true,
      },
    },
    send: {
      enabled: true,
      email: true,
      sms: true,
      optout: true,
    },
    schedule: {
      daytimes: {
        "7T00:00:00/7T12:00:00": "closed",
        "7T12:00:00/7T17:00:00": "open",
        "7T17:00:00/1T00:00:00": "closed",
        "1T00:00:00/1T10:00:00": "closed",
        "1T10:00:00/1T18:00:00": "open",
        "1T18:00:00/2T00:00:00": "closed",
        "2T00:00:00/2T10:00:00": "closed",
        "2T10:00:00/2T18:00:00": "open",
        "2T18:00:00/3T00:00:00": "closed",
        "3T00:00:00/3T10:00:00": "closed",
        "3T10:00:00/3T18:00:00": "open",
        "3T18:00:00/4T00:00:00": "closed",
        "4T00:00:00/4T10:00:00": "closed",
        "4T10:00:00/4T18:00:00": "open",
        "4T18:00:00/5T00:00:00": "closed",
        "5T00:00:00/5T10:00:00": "closed",
        "5T10:00:00/5T17:00:00": "open",
        "5T17:00:00/6T00:00:00": "closed",
        "6T00:00:00/6T10:00:00": "closed",
        "6T10:00:00/6T17:00:00": "open",
        "6T17:00:00/7T00:00:00": "closed",
      },
      intervals: {
        "6T17:00:00/7T12:00:00": "closed",
        "7T12:00:00/7T17:00:00": "open",
        "7T17:00:00/1T10:00:00": "closed",
        "1T10:00:00/1T18:00:00": "open",
        "1T18:00:00/2T10:00:00": "closed",
        "2T10:00:00/2T18:00:00": "open",
        "2T18:00:00/3T10:00:00": "closed",
        "3T10:00:00/3T18:00:00": "open",
        "3T18:00:00/4T10:00:00": "closed",
        "4T10:00:00/4T18:00:00": "open",
        "4T18:00:00/5T10:00:00": "closed",
        "5T10:00:00/5T17:00:00": "open",
        "5T17:00:00/6T10:00:00": "closed",
        "6T10:00:00/6T17:00:00": "open",
      },
      friendly: {
        "Sat 5 PM - Sun 12 PM": "closed",
        "Sun 12 PM - 5 PM": "open",
        "Sun 5 PM - Mon 10 AM": "closed",
        "Mon 10 AM - 6 PM": "open",
        "Mon 6 PM - Tue 10 AM": "closed",
        "Tue 10 AM - 6 PM": "open",
        "Tue 6 PM - Wed 10 AM": "closed",
        "Wed 10 AM - 6 PM": "open",
        "Wed 6 PM - Thu 10 AM": "closed",
        "Thu 10 AM - 6 PM": "open",
        "Thu 6 PM - Fri 10 AM": "closed",
        "Fri 10 AM - 5 PM": "open",
        "Fri 5 PM - Sat 10 AM": "closed",
        "Sat 10 AM - 5 PM": "open",
      },
      week: {
        interval: "2020-12-23T00:00:00.000-05:00/2020-12-30T00:00:00.000-05:00",
        intervals: {
          "2020-12-23T00:00:00.000-05:00/2020-12-23T10:00:00.000-05:00":
            "closed",
          "2020-12-23T10:00:00.000-05:00/2020-12-23T18:00:00.000-05:00": "open",
          "2020-12-23T18:00:00.000-05:00/2020-12-24T10:00:00.000-05:00":
            "closed",
          "2020-12-24T10:00:00.000-05:00/2020-12-24T18:00:00.000-05:00": "open",
          "2020-12-24T18:00:00.000-05:00/2020-12-25T10:00:00.000-05:00":
            "closed",
          "2020-12-25T10:00:00.000-05:00/2020-12-25T17:00:00.000-05:00": "open",
          "2020-12-25T17:00:00.000-05:00/2020-12-26T10:00:00.000-05:00":
            "closed",
          "2020-12-26T10:00:00.000-05:00/2020-12-26T17:00:00.000-05:00": "open",
          "2020-12-26T17:00:00.000-05:00/2020-12-27T00:00:00.000-05:00":
            "closed",
          "2020-12-27T00:00:00.000-05:00/2020-12-27T12:00:00.000-05:00":
            "closed",
          "2020-12-27T12:00:00.000-05:00/2020-12-27T17:00:00.000-05:00": "open",
          "2020-12-27T17:00:00.000-05:00/2020-12-28T10:00:00.000-05:00":
            "closed",
          "2020-12-28T10:00:00.000-05:00/2020-12-28T18:00:00.000-05:00": "open",
          "2020-12-28T18:00:00.000-05:00/2020-12-29T10:00:00.000-05:00":
            "closed",
          "2020-12-29T10:00:00.000-05:00/2020-12-29T18:00:00.000-05:00": "open",
          "2020-12-29T18:00:00.000-05:00/2020-12-30T00:00:00.000-05:00":
            "closed",
        },
        days: {
          "2020-12-23T00:00:00.000-05:00/2020-12-23T10:00:00.000-05:00":
            "closed",
          "2020-12-23T10:00:00.000-05:00/2020-12-23T18:00:00.000-05:00": "open",
          "2020-12-23T18:00:00.000-05:00/2020-12-24T00:00:00.000-05:00":
            "closed",
          "2020-12-24T00:00:00.000-05:00/2020-12-24T10:00:00.000-05:00":
            "closed",
          "2020-12-24T10:00:00.000-05:00/2020-12-24T18:00:00.000-05:00": "open",
          "2020-12-24T18:00:00.000-05:00/2020-12-25T00:00:00.000-05:00":
            "closed",
          "2020-12-25T00:00:00.000-05:00/2020-12-25T10:00:00.000-05:00":
            "closed",
          "2020-12-25T10:00:00.000-05:00/2020-12-25T17:00:00.000-05:00": "open",
          "2020-12-25T17:00:00.000-05:00/2020-12-26T00:00:00.000-05:00":
            "closed",
          "2020-12-26T00:00:00.000-05:00/2020-12-26T10:00:00.000-05:00":
            "closed",
          "2020-12-26T10:00:00.000-05:00/2020-12-26T17:00:00.000-05:00": "open",
          "2020-12-26T17:00:00.000-05:00/2020-12-27T00:00:00.000-05:00":
            "closed",
          "2020-12-27T00:00:00.000-05:00/2020-12-27T12:00:00.000-05:00":
            "closed",
          "2020-12-27T12:00:00.000-05:00/2020-12-27T17:00:00.000-05:00": "open",
          "2020-12-27T17:00:00.000-05:00/2020-12-28T00:00:00.000-05:00":
            "closed",
          "2020-12-28T00:00:00.000-05:00/2020-12-28T10:00:00.000-05:00":
            "closed",
          "2020-12-28T10:00:00.000-05:00/2020-12-28T18:00:00.000-05:00": "open",
          "2020-12-28T18:00:00.000-05:00/2020-12-29T00:00:00.000-05:00":
            "closed",
          "2020-12-29T00:00:00.000-05:00/2020-12-29T10:00:00.000-05:00":
            "closed",
          "2020-12-29T10:00:00.000-05:00/2020-12-29T18:00:00.000-05:00": "open",
          "2020-12-29T18:00:00.000-05:00/2020-12-30T00:00:00.000-05:00":
            "closed",
        },
      },
      upcoming: {
        interval: "2020-12-23T00:00:00.000-05:00/2021-01-06T00:00:00.000-05:00",
        intervals: {
          "2020-12-23T00:00:00.000-05:00/2020-12-23T10:00:00.000-05:00":
            "closed",
          "2020-12-23T10:00:00.000-05:00/2020-12-23T18:00:00.000-05:00": "open",
          "2020-12-23T18:00:00.000-05:00/2020-12-24T10:00:00.000-05:00":
            "closed",
          "2020-12-24T10:00:00.000-05:00/2020-12-24T18:00:00.000-05:00": "open",
          "2020-12-24T18:00:00.000-05:00/2020-12-25T10:00:00.000-05:00":
            "closed",
          "2020-12-25T10:00:00.000-05:00/2020-12-25T17:00:00.000-05:00": "open",
          "2020-12-25T17:00:00.000-05:00/2020-12-26T10:00:00.000-05:00":
            "closed",
          "2020-12-26T10:00:00.000-05:00/2020-12-26T17:00:00.000-05:00": "open",
          "2020-12-26T17:00:00.000-05:00/2020-12-27T00:00:00.000-05:00":
            "closed",
          "2020-12-27T00:00:00.000-05:00/2020-12-27T12:00:00.000-05:00":
            "closed",
          "2020-12-27T12:00:00.000-05:00/2020-12-27T17:00:00.000-05:00": "open",
          "2020-12-27T17:00:00.000-05:00/2020-12-28T10:00:00.000-05:00":
            "closed",
          "2020-12-28T10:00:00.000-05:00/2020-12-28T18:00:00.000-05:00": "open",
          "2020-12-28T18:00:00.000-05:00/2020-12-29T10:00:00.000-05:00":
            "closed",
          "2020-12-29T10:00:00.000-05:00/2020-12-29T18:00:00.000-05:00": "open",
          "2020-12-29T18:00:00.000-05:00/2020-12-30T10:00:00.000-05:00":
            "closed",
          "2020-12-30T10:00:00.000-05:00/2020-12-30T18:00:00.000-05:00": "open",
          "2020-12-30T18:00:00.000-05:00/2020-12-31T10:00:00.000-05:00":
            "closed",
          "2020-12-31T10:00:00.000-05:00/2020-12-31T18:00:00.000-05:00": "open",
          "2020-12-31T18:00:00.000-05:00/2021-01-01T10:00:00.000-05:00":
            "closed",
          "2021-01-01T10:00:00.000-05:00/2021-01-01T17:00:00.000-05:00": "open",
          "2021-01-01T17:00:00.000-05:00/2021-01-02T10:00:00.000-05:00":
            "closed",
          "2021-01-02T10:00:00.000-05:00/2021-01-02T17:00:00.000-05:00": "open",
          "2021-01-02T17:00:00.000-05:00/2021-01-03T00:00:00.000-05:00":
            "closed",
          "2021-01-03T00:00:00.000-05:00/2021-01-03T12:00:00.000-05:00":
            "closed",
          "2021-01-03T12:00:00.000-05:00/2021-01-03T17:00:00.000-05:00": "open",
          "2021-01-03T17:00:00.000-05:00/2021-01-04T10:00:00.000-05:00":
            "closed",
          "2021-01-04T10:00:00.000-05:00/2021-01-04T18:00:00.000-05:00": "open",
          "2021-01-04T18:00:00.000-05:00/2021-01-05T10:00:00.000-05:00":
            "closed",
          "2021-01-05T10:00:00.000-05:00/2021-01-05T18:00:00.000-05:00": "open",
          "2021-01-05T18:00:00.000-05:00/2021-01-06T00:00:00.000-05:00":
            "closed",
        },
      },
    },
    url: "https://amenitypass.app/properties/y1g3mebta503n79nhabtg09r74/amenities/sqxvfbxkas2c19ey2dan752xr4",
    qr: "http://api.qrserver.com/v1/create-qr-code/?data=http%3A//qr.parkingboss.com/l/y1g3mebta503n79nhabtg09r74%3Fqr&ecc=L&format=svg",
    authentication: true,
    preauthentication: {
      enabled: true,
    },
    valid: {
      min: {
        future: {
          min: "PT0S",
          max: "P14D",
        },
      },
    },
    start: {
      retroactive: "PT1H",
      future: "P14D",
      night: false,
      days: {
        "1": "Monday",
        "2": "Tuesday",
        "3": "Wednesday",
        "4": "Thursday",
        "5": "Friday",
        "6": "Saturday",
        "7": "Sunday",
      },
    },
    end: {},
    overflow: {
      cumulative: "PT0S",
      permit: "PT1H",
    },
    duration: "PT1H",
    limits: {
      count: 11,
      items: {
        xs1ckvz3bx6k37cc2z4jjwc8gg: {
          generated: "2020-12-23T17:13:55.605Z",
          type: "meter",
          format: "concurrent",
          id: "xs1ckvz3bx6k37cc2z4jjwc8gg",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "ee42c9ef-e35f-4d31-9d8c-17c929718884",
          rank: 0,
          display: "closed (Fri 5 PM - Sat 10 AM)",
          concurrent: 0,
          valid: {
            interval: "2020-06-18T16:25:14.170Z/",
            daytime: ["5T17:00:00/6T10:00:00"],
          },
        },
        wnv0v02nn9231fk59jjfn58jpr: {
          generated: "2020-12-23T17:13:55.624Z",
          type: "meter",
          format: "concurrent",
          id: "wnv0v02nn9231fk59jjfn58jpr",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "e5760d80-55aa-4430-be65-4ca4fa9512b6",
          rank: 0,
          display: "closed (Sun 12 AM - 12 PM)",
          concurrent: 0,
          valid: {
            interval: "2020-06-18T16:25:14.143Z/",
            daytime: ["7T00:00:00/7T12:00:00"],
          },
        },
        ngy9y721ds70qdsr9xnv2fmvc8: {
          generated: "2020-12-23T17:13:55.624Z",
          type: "meter",
          format: "concurrent",
          id: "ngy9y721ds70qdsr9xnv2fmvc8",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "ac3c9f1c-416e-4e0b-b738-4f6bb13e9b62",
          rank: 0,
          display: "closed (Tue 6 PM - Wed 10 AM)",
          concurrent: 0,
          valid: {
            interval: "2020-06-18T16:25:14.160Z/",
            daytime: ["2T18:00:00/3T10:00:00"],
          },
        },
        "3y9bxnkj5d5mb324dpqe7246hm": {
          generated: "2020-12-23T17:13:55.624Z",
          type: "meter",
          format: "concurrent",
          id: "3y9bxnkj5d5mb324dpqe7246hm",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "1f92bed6-722b-4b45-8c44-6daee388868d",
          rank: 0,
          display: "closed (Wed 6 PM - Thu 10 AM)",
          concurrent: 0,
          valid: {
            interval: "2020-06-18T16:25:14.163Z/",
            daytime: ["3T18:00:00/4T10:00:00"],
          },
        },
        "0tfxx88nn145xf86ftvg6309km": {
          generated: "2020-12-23T17:13:55.624Z",
          type: "meter",
          format: "concurrent",
          id: "0tfxx88nn145xf86ftvg6309km",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "069fdea1-15a8-485e-bd06-7eb7030c099d",
          rank: 0,
          display: "closed (Sun 5 PM - Mon 10 AM)",
          concurrent: 0,
          valid: {
            interval: "2020-06-18T16:25:14.147Z/",
            daytime: ["7T17:00:00/1T10:00:00"],
          },
        },
        "14yczr2efn5qq323r3dt0pwd6r": {
          generated: "2020-12-23T17:13:55.624Z",
          type: "meter",
          format: "concurrent",
          id: "14yczr2efn5qq323r3dt0pwd6r",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "093ccfe0-4e7d-4b7b-8c43-c0dba05b8d36",
          rank: 0,
          display: "closed (Sat 5 PM - Sun 12 AM)",
          concurrent: 0,
          valid: {
            interval: "2020-06-18T16:25:14.177Z/",
            daytime: ["6T17:00:00/7T00:00:00"],
          },
        },
        dtarqhq8ah4jh03zst33e8gsr4: {
          generated: "2020-12-23T17:13:55.624Z",
          type: "meter",
          format: "concurrent",
          id: "dtarqhq8ah4jh03zst33e8gsr4",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "6e958bc6-e854-4928-807f-ce86372219c1",
          rank: 0,
          display: "closed (Mon 6 PM - Tue 10 AM)",
          concurrent: 0,
          valid: {
            interval: "2020-06-18T16:25:14.153Z/",
            daytime: ["1T18:00:00/2T10:00:00"],
          },
        },
        "423d4br4ns2g170mxabf1t00k8": {
          generated: "2020-12-23T17:13:55.624Z",
          type: "meter",
          format: "concurrent",
          id: "423d4br4ns2g170mxabf1t00k8",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "2086d22f-04ae-4500-9c14-ea96f0e8009a",
          rank: 10,
          display: "10 at a time",
          concurrent: 10,
          valid: {
            interval: "2020-06-18T16:26:26.130Z/",
            daytime: [],
          },
        },
        d7e952j3ed1kv1qrxwvjg4sdar: {
          generated: "2020-12-23T17:13:55.624Z",
          type: "meter",
          format: "concurrent",
          id: "d7e952j3ed1kv1qrxwvjg4sdar",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "69dc928a-4373-433d-86f8-ef3728132d56",
          rank: 0,
          display: "closed (Thu 6 PM - Fri 10 AM)",
          concurrent: 0,
          valid: {
            interval: "2020-06-18T16:25:14.167Z/",
            daytime: ["4T18:00:00/5T10:00:00"],
          },
        },
        e03qs3mred54h7y2r8s8yshy0c: {
          generated: "2020-12-23T17:13:55.624Z",
          type: "meter",
          format: "concurrent",
          id: "e03qs3mred54h7y2r8s8yshy0c",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "70077c8e-9873-4a48-9fc2-c2328f663e03",
          group: "tenant",
          rank: 2,
          display: "2 at a time",
          concurrent: 2,
          valid: {
            interval: "2020-06-18T16:24:06.790Z/",
            daytime: [],
          },
        },
        vf3cbq5prh1eb2grv6n55tn6w8: {
          generated: "2020-12-23T17:13:55.625Z",
          type: "meter",
          format: "time",
          id: "vf3cbq5prh1eb2grv6n55tn6w8",
          subject: "sqxvfbxkas2c19ey2dan752xr4",
          uuid: "dbc6c5dc-b6c4-42e5-8a18-d9aa52eaa6e2",
          group: "tenant",
          rank: 36000000000,
          display: "1 per day",
          unlimited: false,
          calendar: "P1D",
          period: "P1D",
          time: "PT1H",
          allowed: "PT1H",
          interval:
            "2020-12-23T00:00:00.000-05:00/2020-12-24T00:00:00.000-05:00",
          divisor: "PT1H",
          valid: {
            interval: "2020-06-18T16:24:06.793Z/",
            daytime: [],
          },
        },
      },
    },
    meters: {
      count: 11,
      items: {
        xs1ckvz3bx6k37cc2z4jjwc8gg: "xs1ckvz3bx6k37cc2z4jjwc8gg",
        wnv0v02nn9231fk59jjfn58jpr: "wnv0v02nn9231fk59jjfn58jpr",
        ngy9y721ds70qdsr9xnv2fmvc8: "ngy9y721ds70qdsr9xnv2fmvc8",
        "3y9bxnkj5d5mb324dpqe7246hm": "3y9bxnkj5d5mb324dpqe7246hm",
        "0tfxx88nn145xf86ftvg6309km": "0tfxx88nn145xf86ftvg6309km",
        "14yczr2efn5qq323r3dt0pwd6r": "14yczr2efn5qq323r3dt0pwd6r",
        dtarqhq8ah4jh03zst33e8gsr4: "dtarqhq8ah4jh03zst33e8gsr4",
        "423d4br4ns2g170mxabf1t00k8": "423d4br4ns2g170mxabf1t00k8",
        d7e952j3ed1kv1qrxwvjg4sdar: "d7e952j3ed1kv1qrxwvjg4sdar",
        e03qs3mred54h7y2r8s8yshy0c: "e03qs3mred54h7y2r8s8yshy0c",
        vf3cbq5prh1eb2grv6n55tn6w8: "vf3cbq5prh1eb2grv6n55tn6w8",
      },
    },
    tenant: {
      request: true,
      required: true,
      type: "apartment",
      format: "apartment",
      predefined: true,
      resident: true,
      title: "Apartment",
      simplified: true,
      limits: {
        count: 2,
        items: {
          e03qs3mred54h7y2r8s8yshy0c: {
            generated: "2020-12-23T17:13:55.633Z",
            type: "meter",
            format: "concurrent",
            id: "e03qs3mred54h7y2r8s8yshy0c",
            subject: "sqxvfbxkas2c19ey2dan752xr4",
            uuid: "70077c8e-9873-4a48-9fc2-c2328f663e03",
            group: "tenant",
            rank: 2,
            display: "2 at a time",
            concurrent: 2,
            valid: {
              interval: "2020-06-18T16:24:06.790Z/",
              daytime: [],
            },
          },
          vf3cbq5prh1eb2grv6n55tn6w8: {
            generated: "2020-12-23T17:13:55.633Z",
            type: "meter",
            format: "time",
            id: "vf3cbq5prh1eb2grv6n55tn6w8",
            subject: "sqxvfbxkas2c19ey2dan752xr4",
            uuid: "dbc6c5dc-b6c4-42e5-8a18-d9aa52eaa6e2",
            group: "tenant",
            rank: 36000000000,
            display: "1 per day",
            unlimited: false,
            calendar: "P1D",
            period: "P1D",
            time: "PT1H",
            allowed: "PT1H",
            interval:
              "2020-12-23T00:00:00.000-05:00/2020-12-24T00:00:00.000-05:00",
            divisor: "PT1H",
            valid: {
              interval: "2020-06-18T16:24:06.793Z/",
              daytime: [],
            },
          },
        },
      },
      list: {
        all: true,
      },
    },
    media: {
      request: false,
      required: false,
      predefined: true,
      repermit: false,
    },
    reason: {
      request: false,
      required: false,
    },
    files: {
      request: 0,
      required: 0,
      items: [],
    },
    name: {
      request: true,
      required: true,
    },
    email: {
      request: true,
      required: true,
      send: true,
    },
    phone: {
      request: true,
      required: true,
    },
    tel: {
      request: true,
      required: true,
      send: true,
    },
    contact: {
      description:
        "Questions? Contact us at shadygrovemetro@bainbridgere.com or 301-330-2979.",
      custom: "",
      name: "",
      email: "shadygrovemetro@bainbridgere.com",
      phone: "301-330-2979",
    },
    passes: {
      vehicle: false,
      always: false,
    },
    rules: {
      summary:
        "Pass required, limited to 10 at a time. Each apartment limited to 2 at a time, 1 per day. ",
      description:
        "Pass required. Limited to 10 at a time. Each apartment limited to 2 at a time, 1 per day. Registrations that exceed this are automatically blocked. ",
      custom: "",
      assigneduse: false,
      tenants: false,
      soleuse: false,
    },
    alert: {
      text: "Block is actually only 45 minutes with staff cleaning area the last 15 minutes.",
    },
    agreement: {
      request: true,
      required: true,
      text: "I acknowledge and agree to all rules, instructions, limits, and conditions of use",
    },
    payments: {
      enabled: false,
      always: false,
      source: "",
      title: "Bainbridge Shady Grove Metro",
      stripe: {
        enabled: false,
        key: "pk_live_ERdgZ1FtE6lFvBhFnem3IT7Q",
      },
      prices: {
        count: 0,
        items: {},
      },
    },
    updated: "2020-08-26T11:59:00.070-04:00",
  };
  let days = [];

  let values = null;
  let chart = null;
  let nowLocal = null;
  let timezone = null;

  $: if (policy) timezone = policy.timezone;
  $: if (timezone) nowLocal = timeLocal(minuteNow, timezone);

  $: values = [].concat(scheduleValues(policy.schedule.upcoming.intervals));

  $: y = x === "datetime" ? "value" : "datetime";

  // $: if(minLocal && maxLocal) days = eachDayOfInterval({ start: minLocal, end: maxLocal }).filter(day => day < maxLocal).map(day => ({
  //     start:startOfDay(day),
  //     end:endOfDay(day)
  // }));
  //$: if(minLocal && maxLocal) chart.x = { start: minLocal, end: maxLocal, type: "datetime" };

  $: if (values && values.length)
    chart = {
      datetime: {
        start: values.reduce(
          (result, item) =>
            null != result
              ? Math.min(result, item.datetime.start)
              : item.datetime.start,
          null
        ),
        end: values.reduce(
          (result, item) =>
            null != result
              ? Math.max(result, item.datetime.end)
              : item.datetime.end,
          null
        ),
        timezone: timezone,
        type: "datetime",
      },
      value: {
        start: values.reduce(
          (result, item) => Math.min(result, item.value?.start || 0),
          0
        ),
        end:
          values.reduce(
            (result, item) => Math.max(result, item.value?.end || 0),
            0
          ) + 1,
        timezone: timezone,
        type: "value",
      },
    };

  $: if (chart) {
    let interval = Object.values(chart).find(
      (item) => item.type === "datetime"
    );
    console.log("days interval=", chart, interval);
    days = eachDayOfInterval(interval)
      .filter((day) => day < interval.end)
      .map((day) => ({
        start: startOfDay(day),
        end: endOfDay(day),
        timezone: timezone,
      }));
  }

  function scheduleValues(intervals) {
    if (!intervals) return null;

    return Object.entries(intervals).reduce((result, [interval, value]) => {
      const [minLocal, maxLocal] =
        interval && interval.split("/").map((i) => utcToZonedTime(i, timezone));
      result.push({
        type: "schedule",
        datetime: {
          start: minLocal,
          end: maxLocal,
          timezone: timezone,
          type: "datetime",
        },
        value: value,
      });
      return result;
    }, []);
  }

  function getInterval(item, tz) {
    if (item.getTime && timezone) return toZoneISOString(item, tz);

    if (!!item && !!item.datetime) item = item.datetime;

    if (!!item) {
      return `${toZoneISOString(item.start, item.timezone)}/${toZoneISOString(
        item.end,
        item.timezone
      )}`;
    }

    return "";
  }

  // returns an interval that's a percent from the start and end of containing interval

  function getTopLeftBottomRightHeightWidth(item) {
    console.log("getTLBRHW=", item, x, y);

    if (item.getTime)
      item = {
        start: item,
        end: item,
      };

    if (item.start && item.end)
      item = {
        datetime: item,
      };

    const tb = getPercentInterval(chart[y], item[y]) || {
      start: null,
      end: null,
    };
    const lr = getPercentInterval(chart[x], item[x]) || {
      start: null,
      end: null,
    };

    const w = percentOf(chart[x], item[x]);
    const h = percentOf(chart[y], item[y]);

    if (x === "value") return [tb.start, lr.end, tb.end, lr.start, h, w];

    return [tb.end, lr.end, tb.start, lr.start, h, w];
  }

  function getStyle(item) {
    const tlbr = getTopLeftBottomRightHeightWidth(item);
    //.map(i => typeof(i) === "number" ? `${i}%` : "auto");
    const top = tlbr[0] ? tlbr[0] + "%" : "auto";
    const left = tlbr[3] ? tlbr[3] + "%" : "auto";
    const right = tlbr[1] ? tlbr[1] + "%" : "auto";
    const bottom = tlbr[2] ? tlbr[2] + "%" : "auto";
    const height = tlbr[4] ? tlbr[4] + "%" : "auto";
    const width = tlbr[5] ? tlbr[5] + "%" : "auto";
    return `top:${top};left:${left};bottom:${bottom};right:${right};width:${width};height:${height};`;
  }

  //console.log("parsed = ", parseDayTimeInterval("7T01:00/1T00:00:00").join(","));
</script>

{#if chart}
  <time class="schedule" datetime={getInterval(chart)} data-x={x}>
    {#if nowLocal && $nowLocal && isWithinInterval($nowLocal, chart.datetime)}
      <time
        datetime={toZoneISOString($nowLocal, timezone)}
        class="now"
        style={getStyle($nowLocal)}
        title={format($nowLocal, "h:mm a")}
      />
    {/if}
    {#each days as item}
      <!-- <time datetime="{getInterval(item, timezone)}" class="marker" style="{getStyle(item)}"></time> -->
      <time
        datetime={getInterval(item)}
        class="day"
        title={format(item.start, "E MMM d")}
      />
    {/each}
    {#each values || [] as item}
      <time
        datetime={getInterval(item)}
        class={item.type}
        data-value={item.value}
        style={getStyle(item)}
      />
    {/each}
  </time>
{/if}
