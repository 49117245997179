<script>
  import PolicyDisplayPreview from "./PolicyDisplayPreview.svelte";
  import PolicyDisplayCast from "./PolicyDisplayCast.svelte";
  import PolicyDisplayUrl from "./PolicyDisplayUrl.svelte";
  import { query } from "../util/router";

  export let policies;
  export let policy;

  let id;

  $: if (!policy && !id && policies && policies[0]) id = policies[0].id;

  $: if (!id && policy && policy.id) id = policy.id;

  $: query("policy", id);

  $: url =
    policy &&
    `https://display.amenitypass.app/properties/${
      policy.scope.id || policy.scope
    }/amenities/${policy.policy}`;
</script>

<section class="display">
  <PolicyDisplayPreview {url}>
    <form>
      <label for="policy-display">Amenity</label><select
        id="policy-display"
        bind:value={id}
      >
        {#if policies}
          {#each policies as policy}
            <option value={policy.id}>{policy.title}</option>
          {/each}
        {/if}
      </select>
    </form>
    <!-- <data class="policy">{(policy && policy.title) || "Loading…"}
            </data> -->
    <PolicyDisplayUrl {policy} />
    <PolicyDisplayCast {policy} />
  </PolicyDisplayPreview>
</section>
