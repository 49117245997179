<script>
  import { find, filter, debounce } from "lodash-es";
  import { minuteNow } from "../stores";
  import { subMonths, subWeeks, startOfDay, endOfDay } from "date-fns";
  import { param } from "../util/params";
  import { query } from "../util/router";
  import { mapbox, boundsFromGeoJSON, updateStyle } from "./maps/mapbox";
  import { onMount } from "svelte";
  import Dropdown from "../components/maps/Dropdown.svelte";
  import {
    style as mapstyle,
    propertyGeoJsonLeveled as geojson,
    styles,
  } from "./maps/stores";
  import { fetchPropertyViolations } from "../api";
  import { toZoneISOString } from "../util/datetime";
  import Timeline from "./d3/TimelineChart.svelte";

  export let property;

  let cssLoaded;
  let styleLoaded;
  let map;
  let container;
  let markers = [];
  let startDate = subMonths(startOfDay(new Date()), 1)
    .toISOString()
    .split("T")[0];
  let endDate = endOfDay(new Date()).toISOString().split("T")[0];
  let timelineWidth;
  let getViolations = setViolations();

  const paramStyle = param("style");
  const defaultStyle = $paramStyle || "esrisatellite";
  const style = mapstyle(defaultStyle);
  const styleOptions = Object.entries($styles).map((entry) => ({
    name: entry[1].name,
    value: entry[0],
  }));

  const paramLayers = param("layers");
  $: selectedLayers = $paramLayers || [];

  function handleStyleChange(style, name) {
    query(name, style);
    styleLoaded = false;
  }

  function handleClickViolation(id) {
    query("violation", id);
  }

  function handleDateChange(e) {
    e.preventDefault();

    if (e.target.name === "startDate" && e.target.value <= endDate) {
      startDate = e.target.value;
    } else if (e.target.name === "endDate" && e.target.value >= startDate) {
      endDate = e.target.value;
    }

    getViolations = setViolations();
  }

  function addDataLayers() {
    // Add properties geojson
    map.addSource("properties", {
      type: "geojson",
      data: $geojson,
    });
  }

  $: if (property && cssLoaded && container) {
    if (!map) initMap();
  }

  $: if (map && $geojson) {
    boundsFromGeoJSON(map, $geojson, 40);
  }

  $: if (map) {
    map.on("style.load", function () {
      styleLoaded = true;
      addDataLayers();
    });
  }

  $: if (map && $style) {
    console.log("STYLE=", $style);
    updateStyle(map, $style);
  }

  $: if (container) timelineWidth = container.clientWidth;

  function createVioMarker(map, violation) {
    const { coords, id } = violation;

    const markerElem = document.createElement("div");
    markerElem.className = "violation-marker mapboxgl-marker";
    markerElem.onclick = () => handleClickViolation(id);

    const marker = new mapbox.Marker(markerElem)
      .setLngLat(coords)
      // .setPopup(popup)
      .addTo(map);

    markers.push(marker);
  }

  function initMap() {
    map = new mapbox.Map({
      container,
      style: defaultStyle,
    });
  }

  function addVioMarkers(violations) {
    violations.forEach((violation) => createVioMarker(map, violation));
  }

  async function setViolations() {
    markers.forEach((marker) => marker.remove());
    markers = [];

    const propertyViolations = await fetchPropertyViolations(
      property.id,
      `${startDate}/${endDate}`
    );
    const violations = Object.values(
      filter(
        propertyViolations.items,
        (item) => item.type === "violation" && item.coords
      )
    ).map(({ id, coords, issued }) => ({
      name: id,
      id,
      coords: { lng: coords.longitude, lat: coords.latitude },
      date: issued.datetime || issued,
    }));
    console.log("VIOS", violations);
    addVioMarkers(violations);

    return violations;
  }

  onMount(async () => {
    const styleUrl = `https://api.mapbox.com/mapbox-gl-js/v${mapbox.version}/mapbox-gl.css`;
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = styleUrl;
    link.onload = async () => (cssLoaded = true);

    document.head.appendChild(link);
    getViolations = setViolations();
  });
</script>

<div class="enforcement-map-container">
  <figure class="map" bind:this={container}>
    <div class="properties-map-styles">
      <h2>Styles</h2>
      <div class="properties-map-style-dropdown">
        <Dropdown
          name="style"
          defaultValue={defaultStyle}
          options={styleOptions}
          onChange={handleStyleChange}
        />
      </div>
    </div>
    <div class="timeline-controls">
      <div class="timeline-date-picker">
        <form>
          <label for="startDate">From:</label>
          <input
            type="date"
            id="start"
            name="startDate"
            max={endDate}
            on:change={handleDateChange}
            bind:value={startDate}
          />
          <label for="endDate">To:</label>
          <input
            type="date"
            id="start"
            name="endDate"
            min={startDate}
            on:change={handleDateChange}
            bind:value={endDate}
          />
        </form>
      </div>
      {#await getViolations then violations}
        {#if timelineWidth}
          <Timeline
            width={timelineWidth}
            height={80}
            handleClick={handleClickViolation}
            data={violations}
            startDate={new Date(startDate)}
            endDate={new Date(endDate)}
          />
        {:else}
          <h1>Loading&hellip;</h1>
        {/if}
      {/await}
    </div>
  </figure>
</div>
