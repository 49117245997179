<script>
  export let id;
  export let value;
  export let items;
  export let empty = "all";
</script>

<!-- {#if policies} -->
<select {id} value={value || ""} on:change={(e) => (value = e.target.value)}>
  <option value="">{empty}</option>
  {#each items || [] as item}
    <option value={item.id}>{item.display}</option>
  {/each}
</select>
<!-- {/if} -->
