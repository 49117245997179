<script lang="ts">
  import { onMount } from "svelte";

  import Modal from "./Modal.svelte";
  import { state } from "./state";
  import { BOUNDARY, POLYGON, RADIUS } from "./constants";

  let container: HTMLElement;

  onMount(() => {
    state.send("CREATE_AREA_MAP", { container });
  });
</script>

<Modal
  open
  on:close={() => state.send("STOP_EDITING_AREA")}
  title="Edit Service Area"
  width="5xl"
>
  <section class="flex flex-col gap-6 mb-4">
    <div class="flex items-center gap-4">
      <div class="btn-group [&>*]:btn-sm">
        <button
          class="btn"
          on:click={() => state.send("CHANGE_AREA_TYPE", { area_type: RADIUS })}
          class:btn-active={$state.context.active_area?.area_type === RADIUS}
          >Radius</button
        >
        <button
          class="btn"
          on:click={() =>
            state.send("CHANGE_AREA_TYPE", { area_type: BOUNDARY })}
          class:btn-active={$state.context.active_area.area_type === BOUNDARY}
          >Boundary</button
        >
        <button
          class="btn"
          on:click={() =>
            state.send("CHANGE_AREA_TYPE", { area_type: POLYGON })}
          class:btn-active={$state.context.active_area?.area_type === POLYGON}
          >Custom Area</button
        >
      </div>
      {#if $state.context.active_area?.area_type === RADIUS}
        <input
          type="number"
          max="1000"
          step="1.0"
          class="input input-sm input-bordered w-24"
          value={$state.context.active_area.radius?.radius / 1000}
          on:input={(e) =>
            state.send("CHANGE_RADIUS", {
              radius: Number(e.currentTarget.value) * 1000,
            })}
        />
        KM
      {/if}
      {#if $state.context.active_area?.area_type === BOUNDARY && $state.context.active_area?.boundary?.boundaries?.length}
        <select
          value={$state.context.active_area.boundary?.selected_boundary}
          on:change={(e) =>
            state.send("CHANGE_BOUNDARY", {
              boundary: e.currentTarget.value,
            })}
          class="select select-sm select-bordered w-full flex-1"
        >
          <option>Choose boundary...</option>
          {#each $state.context.active_area.boundary?.boundaries as boundary}
            {@const addr = boundary.address}
            <option value={boundary.dataSources?.geometry?.id}>
              {addr?.freeformAddress}
              ({boundary.type})
            </option>
          {/each}
        </select>
      {/if}
      <!-- {#if $map.features}
        <div class="tooltip ml-auto" data-tip="Zoom to fit">
          <button class="btn btn-sm" on:click={() => map.fit_to_features()}>
            <Fa icon={faMagnifyingGlassLocation} fw />
          </button>
        </div>
      {/if} -->
    </div>
  </section>
  <section bind:this={container} class="w-full h-[600px]" />

  <section class="my-4 flex items-center">
    <button class="btn" on:click={() => state.send("STOP_EDITING_AREA")}
      >Cancel</button
    >
    <button class="btn btn-primary ml-auto">Save Changes</button>
  </section>

  <pre
    class="p-4 text-xs overflow-auto bg-base-200 rounded-lg my-6">{JSON.stringify(
      $state.value,
      null,
      2
    )}</pre>
  <pre
    class="p-4 text-xs overflow-auto bg-base-200 rounded-lg my-6">{JSON.stringify(
      $state.context?.active_area,
      null,
      2
    )}</pre>
  <!-- <ServiceAreaForm area={edit_area} /> -->
</Modal>
