<script>
  export let url;
</script>

<figure class="tv">
  <figcaption>
    <slot />
  </figcaption>
  <iframe src={url} />
</figure>
