<script>
  import { fetchCreateMedia as update } from "../api";
  import { get } from "lodash-es";

  export let property = null;

  let submittable = false;
  let submitting = false;

  function change(e) {
    submittable = e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    const form = e.target;

    submitting = true;

    await update(property, new FormData(form));

    submitting = submittable = false;

    //property = null;

    form.reset();
  }
</script>

{#if property}
  <form on:change={change} on:input={change} on:submit={submit}>
    <fieldset>
      <input type="hidden" name="format" value="smart-decal" />
      <ul>
        <li>
          <label for="new-media">Items</label>
          <textarea id="new-media" name="media" required />
        </li>
      </ul>
    </fieldset>
    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Add Own Permits"}</button
      >
    </footer>
  </form>
{/if}
