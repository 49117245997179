<script>
  import { onMount, onDestroy } from "svelte";
  import { toasts } from "../stores";

  export let idx;
  export let message;
  export let type = "";
  let timer;
  let ticker;
  let classStr = type;

  function handleClose() {
    toasts.remove(idx);
  }

  function tick() {
    if (timer < 1) {
      handleClose();
      return;
    }

    timer--;
    ticker = setTimeout(tick, 1000);
  }

  onMount(() => {
    timer = 3;
    tick();
  });

  onDestroy(() => {
    clearTimeout(ticker);
  });
</script>

<div class={`toast ${classStr}`}>
  <div class="toast-close" on:click={handleClose} />
  {message}
</div>
