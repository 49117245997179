<script lang="ts">
  import type { ExpandedProvider, ExpandedService } from "@communityboss/api"
  import {
    faPencil,
    faPlus,
    faTrashAlt,
  } from "@fortawesome/free-solid-svg-icons"
  import Fa from "svelte-fa"
  import CreateAreaForm from "./CreateAreaForm.svelte"
  import { state } from "./state"

  export let service: ExpandedService
  export let provider: ExpandedProvider

  let show_create_service_form = false
  let hover = false
</script>

<article
  on:mouseenter={() => (hover = true)}
  on:mouseleave={() => (hover = false)}
>
  <header class="mb-4 flex items-center gap-4">
    <h1 class="text-lg font-bold">
      <strong>{service.name}</strong>
    </h1>
    <h2 class="italic text-sm text-base-content/80">{service.category}</h2>
    <button
      class="btn btn-sm btn-error btn-ghost gap-3 ml-auto"
      on:click={() =>
        state.send("REMOVE_SERVICE", {
          provider_id: provider.id,
          service_id: service.id,
        })}
    >
      <Fa icon={faTrashAlt} />
      Remove Service
    </button>
  </header>

  {#if service.areas?.length}
    <table class="table table-compact">
      {#each service.areas as area (area.id)}
        <tr>
          <th class="pl-0 pr-4 py-4">
            {#if area?.street}
              {area?.street} {area?.city}, {area?.state} {area?.zip}
            {:else if area?.latitude && area?.longitude}
              lat: {area?.latitude} long: {area?.longitude}
            {:else}
              {area?.id}
            {/if}
          </th>
          <td>
            <div class="btn-group">
              <button
                class="btn btn-xs gap-3"
                on:click={() =>
                  state.send("EDIT_AREA", {
                    service_id: service.id,
                    provider_id: provider.id,
                    area,
                  })}
              >
                <Fa icon={faPencil} />
                Edit
              </button>
              <button
                class="btn btn-xs btn-error gap-3"
                on:click={() => state.send("REMOVE_AREA", { area_id: area.id })}
              >
                <Fa icon={faTrashAlt} />
                Remove
              </button>
            </div>
          </td>
        </tr>
      {/each}
    </table>
  {:else}
    <button
      class="btn btn-sm btn-outline gap-3 transition-all"
      on:click={() =>
        state.send("EDIT_AREA", {
          service_id: service.id,
          provider_id: provider.id,
        })}
    >
      <Fa icon={faPlus} />
      Area
    </button>
  {/if}
</article>

<CreateAreaForm
  bind:open={show_create_service_form}
  service_id={service.id}
  provider_id={provider.id}
/>
