<script>
  import { fetchUpdateProperty, fetchViolations } from "./api";
  import { formatISO, addDays, startOfDay } from "date-fns";
  import { get, clone } from "lodash-es";
  import EditUserSettings from "./EditUserSettings.svelte";
  import EditViolationSettings from "./EditViolationSettings.svelte";
  import EditViolationThresholds from "./EditViolationThresholds.svelte";
  import Violations from "./components/Violations.svelte";

  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing)) editing = { ...property };
</script>

{#if editing}
  <EditUserSettings {property} />
  <EditViolationSettings {property} />
  {#if get(editing, "violations.policies.issued.enabled", false)}
    <EditViolationThresholds {property} />
  {/if}
{/if}
<!-- {#if property}
<Violations valid="{formatISO(addDays(new Date(), -60))}/{formatISO(addDays(new Date(), 3))}" property={property} violations={fetchViolations(property, `${formatISO(addDays(new Date(), -60))}/${formatISO(addDays(new Date(), 3))}`)} />
{/if} -->
