<script>
  import { fetchResetTenantsCodes as update } from "../api";

  export let property = null;
  export let tenants = null;

  let submittable = true;
  let submitting = false;
  let valid = false;
  let all = true;

  let omit = {};

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    if (!window.confirm(`This is permanent and cannot be undone. Continue?`))
      return;

    const form = e.target;
    const formData = new FormData(form);

    submitting = true;

    const res = await update(property, formData);

    //console.log("res=", res);
    submitting = false;

    if (res && res.message) return alert(res.message);

    form.reset();

    submittable = true;
    valid = false;
    all = true;
    omit = {};
  }

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }
</script>

{#if tenants}
  <form on:change={change} on:input={change} on:submit={submit}>
    {#if all}
      {#each tenants as item}
        <input type="hidden" name={item.id} value="" />
      {/each}
    {/if}
    <fieldset>
      <ul>
        <li>
          <label for="update-tenants-passcodes-valid">Effective</label>
          <select bind:value={valid}>
            <option value={false}>immediately</option>
            <option value={true}>select…</option>
          </select>
        </li>
        {#if valid}
          <li>
            <!-- <label for="update-tenants-passcodes-valid"></label> -->
            <input
              id="update-tenants-passcodes-valid"
              type="datetime-local"
              name="valid"
            />
          </li>
        {/if}
        <li>
          <label for="update-tenants-passcodes-units">Units</label>
          <select bind:value={all}>
            <option value={true}>all</option>
            <option value={false}>select…</option>
          </select>
        </li>
        {#if !all}
          <li>
            <!-- <label></label> -->
            <ul on:change={(e) => (omit[e.target.value] = !e.target.checked)}>
              {#each tenants as item}
                <li>
                  <input
                    id="update-tenant-passcode-{item.id}"
                    type="checkbox"
                    name={item.id}
                    value=""
                    checked={!omit[item.id]}
                  />
                  <label for="update-tenant-passcode-{item.id}"
                    ><data class="id {[item.format, item.type].join(' ')}"
                      >{item.display}</data
                    ></label
                  >
                </li>
              {/each}
            </ul>
          </li>
        {/if}
      </ul>
    </fieldset>
    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Reset All Passcodes"}</button
      >
    </footer>
  </form>
{/if}
