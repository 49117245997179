<script>
  import {
    onMount,
    onDestroy,
    setContext,
    createEventDispatcher,
  } from "svelte";
  import {
    mapbox,
    key,
    updateData,
    updateStyle,
    boundsFromGeoJSON,
  } from "./mapbox";
  import { rotateAlignLabelsToMap } from "./MapUtil";
  // assuming all MAPVIEW gl js/access/css is running

  const dispatch = createEventDispatcher();

  export let style;
  let bounded = false;

  export let geojson = {
    type: "FeatureCollection",
    features: [],
  };

  // ability to toggle layer visibility
  export let layers = {};

  export let minzoom = 0;
  export let maxzoom = 22;

  //export let highlight = [];
  export let pitch = 0;
  // export let heading;
  // export let zoom;
  // export let center;

  let extrudePitchThreshold = 0.01;

  // update store on param change

  $: current = geojson; /*Object.assign({}, geojson, {
        features: geojson.features
        // .filter(feature => {
        //     return get(feature, "properties.level", level) == level;
        // })
        .map(feature => {
            //console.log("feature.properties.name=", get(feature, "properties.name"), highlight.includes(get(feature, "properties.name")), ...highlight);
            if(feature.properties && feature.properties.name && highlight && highlight.includes(feature.properties.name)) {
                //console.log("highlight=", feature);
                return Object.assign({}, feature, {
                    properties: Object.assign({}, feature.properties, {
                        highlight:true
                    })
                });
            }
            return feature;
        })
    });*/
  //$: console.log(JSON.stringify(current));

  //$: loadedStyle = style(style || "parkingboss/ckfwye90625cz1ap6881ds7lx");

  // map gets rendered with no style
  let MAPVIEW = null;
  let container = null;
  let padding = 10;

  // $: if(MAPVIEW && (pitch >= 0 || heading >= 0)) {
  //     console.log("updating map=", heading, pitch);
  //     MAPVIEW.easeTo({
  //         pitch,
  //         bearing: heading
  //     }, {
  //         duration:400
  //     });
  // }

  //$: if(MAPVIEW) console.log("map=", MAPVIEW, MAPVIEW.getStyle());

  const resizeObserver = new ResizeObserver(() => {
    if (MAPVIEW) MAPVIEW.resize();
  });

  $: if (container) resizeObserver.observe(container);

  setContext(key, {
    getMap: () => MAPVIEW,
  });

  $: if (
    MAPVIEW &&
    geojson &&
    geojson.features &&
    geojson.features.length &&
    !bounded
  ) {
    //console.log("bound!");
    //if(geojson.bbox) boundsFromBbox(MAPVIEW, geojson.bbox, padding)
    boundsFromGeoJSON(MAPVIEW, geojson, padding);
    bounded = true;
    //if(!center)
  }

  let styleLoaded = false;

  let extrusionLayers;
  function updateMapExtrusionLayers(MAPVIEW) {
    if (extrusionLayers) return;
    extrusionLayers = MAPVIEW.getStyle().layers.filter((layer) => {
      return (
        layer.type === "fill-extrusion" &&
        MAPVIEW.getLayoutProperty(layer.id, "visibility") !== "none"
      );
    });
    console.log("extrusion layers=", extrusionLayers);
    updateExtrusionLayersOnPitch(MAPVIEW);
  }
  function updateExtrusionLayersOnPitch(MAPVIEW) {
    console.log("update extrusion", pitch, extrudePitchThreshold);
    (extrusionLayers || []).forEach((layer) =>
      MAPVIEW.setLayoutProperty(
        layer.id,
        "visibility",
        pitch > extrudePitchThreshold ? "visible" : "none"
      )
    );
  }

  $: if (MAPVIEW) {
    //styleLoaded = MAPVIEW.isStyleLoaded();
    MAPVIEW.on("styledata", () => {
      //if(MAPVIEW.getStyle())
      styleLoaded = true;
      //if(!styleLoaded) styleLoaded = MAPVIEW.isStyleLoaded();
      // updateData(MAPVIEW, {
      //     "property": current,
      // });
    });
    // MAPVIEW.on("load", () => {
    //     if(!styleLoaded) styleLoaded = MAPVIEW.isStyleLoaded();
    // });
    // update state
    MAPVIEW.on("pitchend", function (e) {
      //console.log("pitchend=", e);
      //pitch = MAPVIEW.getPitch();
      if (styleLoaded) updateExtrusionLayersOnPitch(MAPVIEW);
    });
    // MAPVIEW.on("rotate", "parking space label normal", function(e) {
    //     MAPVIEW.setFeatureState({
    //         source: "property",
    //     }, {
    //         rotating: true
    //     });
    // })

    // MAPVIEW.on("rotateend", "parking space label normal", function(e) {
    //     MAPVIEW.setFeatureState({
    //         source: "property",
    //     }, {
    //         rotating: false
    //     });
    // })
    // MAPVIEW.on("zoomend", function(e) {
    //     //console.log("pitchend=", e);
    //     zoom = MAPVIEW.getZoom();
    // });
    MAPVIEW.on("rotateend", function (e) {
      // Updating current triggers map update
      console.log("ROTATE END");
      current = rotateAlignLabelsToMap(current, MAPVIEW.getBearing());
    });
    // MAPVIEW.on("moveend", function(e) {
    //     //console.log("moveend=", e);
    //     center = MAPVIEW.getCenter().toArray();
    // });
    //MAPVIEW.on("render", updateMapState);
    // MAPVIEW.on("render", function() {
    //     bearing = MAPVIEW.getBearing();
    //     center = MAPVIEW.getCenter().toArray();
    //     zoom = MAPVIEW.getZoom();
    //     pitch = MAPVIEW.getPitch();
    // });
  }

  $: if (style) console.log("map style changed=", style);
  $: if (MAPVIEW) console.log("map changed=", MAPVIEW);

  $: if (MAPVIEW && style) {
    styleLoaded = false;
    extrusionLayers = null;
    console.log("setting style=", style);
    updateStyle(MAPVIEW, style);
    //
  }

  $: console.log("styleLoaded", styleLoaded);

  $: if (MAPVIEW && styleLoaded) {
    if (styleLoaded) updateMapExtrusionLayers(MAPVIEW);
  }

  $: if (MAPVIEW && current && styleLoaded) {
    updateData(MAPVIEW, {
      property: current,
    });

    MAPVIEW.once("idle", function () {
      dispatch("ready", MAPVIEW);
    });
  } // update map to current geojson
  //$: if(MAPVIEW && current && style) console.log(MAPVIEW);

  async function initMap(container) {
    const created = new mapbox.Map({
      container: container, // container id
      attributionControl: false,
      style,
      //logoPosition:"bottom-right",
      // heading,
      // pitch,
      // center,
      // zoom, // starting zoom
      maxZoom: 22,
      minZoom: 10,
      //dragRotate:false,
      //pitchWithRotate: false
    });
    //created.touchZoomRotate.disableRotation();

    return created;

    return new Promise(function (resolve) {
      created.on("load", async function () {
        resolve(created);
      });
    });
  }

  onMount(async () => {
    const styleUrl = `https://api.mapbox.com/mapbox-gl-js/v${mapbox.version}/mapbox-gl.css`;

    if (document.head.querySelector(`link[href='${styleUrl}']`)) {
      if (!MAPVIEW) MAPVIEW = await initMap(container);
    } else {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      //link.href = 'https://unpkg.com/mapbox-gl/dist/mapbox-gl.css';
      link.href = styleUrl;

      link.onload = async () => {
        if (!MAPVIEW) MAPVIEW = await initMap(container);
      };

      document.head.appendChild(link);
    }

    //styleLoaded = false;

    return () => {
      if (MAPVIEW) MAPVIEW.remove();
      MAPVIEW = null;
      //link.remove();
    };
  });

  onDestroy(() => {
    if (MAPVIEW) MAPVIEW.remove();
    MAPVIEW = null;
  });
</script>

<figure class="map" bind:this={container}>
  {#if MAPVIEW}
    <slot />
  {/if}
</figure>
