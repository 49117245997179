<script>
  import { minuteNow } from "../stores";
  import { smscreen } from "../util/envstores";
  import { get, find } from "lodash-es";
  import { addHours, startOfHour, differenceInMinutes } from "date-fns";
  import PolicyUsage from "./PolicyUsage.svelte";
  import DateTimeSchedule from "./DateTimeSchedule.svelte";
  import Policy from "./Policy.svelte";
  import Permits from "./Permits.svelte";
  import {
    utcToZonedDayInterval,
    toZoneISOString,
    utcToZoned,
  } from "../util/datetime";
  import { fetchAmenityMetrics } from "../api";
  export let property = null;
  export let policies = null;
  export let permits = null;
  export let valid = null;

  let propertyId = null;
  let timezone = null;
  let fetchTime = null;

  $: if (property && property.id != propertyId) propertyId = property.id;
  $: if (property && property.timezone != timezone)
    timezone = property.timezone;

  $: if (!fetchTime || differenceInMinutes($minuteNow, fetchTime) > 5)
    fetchTime = new Date();

  $: metrics =
    propertyId &&
    fetchTime &&
    fetchAmenityMetrics(
      `${addHours(startOfHour(fetchTime), -6).toISOString()}/${addHours(
        startOfHour(fetchTime),
        3
      ).toISOString()}`,
      {
        property: propertyId,
        datetimes: "PT15M",
        capacity: true,
      }
    );

  async function policyUsageMetrics(metrics, policy) {
    if (!metrics) return;

    return find((await metrics).metrics.items, {
      metric: "count",
      count: "maximum",
      source: "permits",
      intervals: "datetimes",
      datetimes: "PT15M",
      group: "policy",
      policy: policy.policy,
    });
  }
  async function policyCapacityMetrics(policy) {
    if (!metrics) return;

    return find((await metrics).metrics.items, {
      metric: "count",
      count: "maximum",
      source: "capacity",
      intervals: "datetimes",
      datetimes: "actual",
      group: "policy",
      policy: policy.policy,
    });
  }

  // function navigate(e) {

  //     if(!policy || e.target.value !== policy.subject)

  // }
</script>

{#if !!policies && !!policies.length}
  <ul class="policies" data-count={get(policies, "length", "")}>
    {#each policies as item}
      <li>
        <header>
          <!-- <a href="/properties/{item.scope}/amenities/{item.subject}"> -->
          <Policy policy={item} />
          <!-- </a> -->
          <PolicyUsage {valid} policy={item} capacity={true} used={true} />
        </header>
        <DateTimeSchedule
          now={true}
          timezone={item.timezone}
          usage={policyUsageMetrics(metrics, item)}
          capacity={policyCapacityMetrics(metrics, item)}
        />
        <Permits {permits} {valid} policy={item} />
      </li>
    {/each}
  </ul>
{/if}
