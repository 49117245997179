<script>
  import * as d3 from "d3";
  import ChartAxis from "./ChartAxis.svelte";
  import ChartLine from "./ChartLine.svelte";
  import { days } from "../../util/metrics";

  const margin = {
    top: 20,
    bottom: 20,
    left: 32,
    right: 0,
  };

  export let data;

  let width;
  let height;

  $: insetSize = {
    width: width - (margin.left + margin.right),
    height: height - (margin.top + margin.bottom),
  };
  $: xScale = d3
    .scaleBand()
    .rangeRound([0, insetSize.width])
    .domain(days)
    .padding(0.1);
  $: xSubScale = d3
    .scaleBand()
    .rangeRound([0, xScale.bandwidth()])
    .domain(["00:00:00", "06:00:00", "12:00:00", "18:00:00"])
    .padding(0.05);

  $: yMax =
    (data &&
      d3.max(
        Object.values(data)
          .map((m) => Object.values(m))
          .flat()
      )) ||
    1;
  $: yScale = d3.scaleLinear().range([insetSize.height, 0]).domain([0, yMax]);

  const tickFormat = (value) => {
    return value[0].toLowerCase();
  };
</script>

<div
  class="chart-bar"
  on:click
  bind:clientWidth={width}
  bind:clientHeight={height}
>
  {#if width && height}
    <svg {width} {height}>
      <ChartLine text={yMax} x1={margin.left} x2={width} {margin} />
      <ChartAxis
        scale={xScale}
        position="bottom"
        {height}
        {margin}
        {tickFormat}
      />
      {#if data}
        {#each Object.entries(data) as [day, hourMap]}
          <g
            transform={`translate(${xScale(day) + margin.left}, ${margin.top})`}
            data={`${day}::${hourMap}`}
          >
            {#each Object.entries(hourMap) as [hour, value]}
              <rect
                x={xSubScale(hour)}
                y={yScale(value)}
                width={4}
                height={value > 0 ? insetSize.height - yScale(value) : 0}
                rx={2}
              />
            {/each}
          </g>
        {/each}
      {/if}
    </svg>
  {/if}
</div>
