<script>
  import { format, utcToZonedTime } from "date-fns-tz";
  import { capitalize, groupBy } from "lodash-es";

  export let attachments;
  export let timezone;
  export let permitUuid;
  export let filter = [
    "name",
    "description",
    "text",
    "issued",
    "valid",
    "total",
  ];

  let groupedAttachments;
  $: if (attachments) groupedAttachments = groupBy(attachments, "type");
  $: if (groupedAttachments) console.log(groupedAttachments);
</script>

{#each Object.keys(groupedAttachments) as type}
  <tr>
    <th>{capitalize(type)}</th>
    <td class="asNested">
      {#each groupedAttachments[type] as field, i}
        <tr>
          <th>
            {#if type === "sent"}
              <b>{field.email[0] || field.tel[0]}</b>
              <ul>
                {#if field.sent}
                  <li>
                    Sent on <time datetime={field.sent.utc}
                      >{format(
                        utcToZonedTime(field.sent.utc, timezone),
                        "h:mm a EE MMM d yyyy zzz",
                        { timezone }
                      )}</time
                    >
                  </li>
                {/if}
                {#if field.delivered}
                  {#if typeof Date.parse(field.delivered) === "number"}
                    <li>
                      Delivered on <time datetime={field.sent.utc}
                        >{format(
                          utcToZonedTime(field.sent.utc, timezone),
                          "h:mm a EE MMM d yyyy zzz",
                          { timezone }
                        )}</time
                      >
                    </li>
                  {:else}
                    <li>{capitalize(field.delivered)}</li>
                  {/if}
                {/if}
                {#if field.viewed}
                  {#if typeof Date.parse(field.viewed) === "number"}
                    <li>
                      Viewed on <time datetime={field.sent.utc}
                        >{format(
                          utcToZonedTime(field.sent.utc, timezone),
                          "h:mm a EE MMM d yyyy zzz",
                          { timezone }
                        )}</time
                      >
                    </li>
                  {:else}
                    <li>{capitalize(field.viewed)}</li>
                  {/if}
                {/if}
              </ul>
            {:else if type === "note"}
              {#if field.lines.length}
                {field.lines[0]}
              {/if}
            {:else if type === "file"}
              <img src={field.url} alt={`attachment-${i}`} loading="lazy" />
            {:else if type === "fee"}
              <a
                href={`https://dashboard.stripe.com/search?query=${permitUuid}`}
                target="_blank"
                rel="noreferrer"
                >{field.name}, {field.description} = {field.total.display}</a
              >
            {/if}
          </th>
        </tr>
      {/each}
    </td>
  </tr>
{/each}
