<script lang="ts">
  import FormActions from "./FormActions.svelte";
  import Modal from "./Modal.svelte";
  import ServiceAreaForm from "./ServiceAreaForm.svelte";
  import { actions } from "./stores";

  export let open = false;
  export let service_id;
  export let provider_id;

  let loading = false;

  let street = "";
  let postal = "";
  let country = "";
  let latitude: number;
  let longitude: number;
  let radius: number;
  let json = "";
  let wkt = "";
  let timezone = "";

  async function handle_submit() {
    loading = true;
    await actions.areas.create({
      service_id,
      provider_id,
      street,
      postal,
      country,
      latitude,
      longitude,
      radius,
      json,
      wkt,
      timezone,
    });
    street = "";
    postal = "";
    country = "";
    latitude = undefined;
    longitude = undefined;
    radius = undefined;
    json = "";
    wkt = "";
    timezone = "";
    loading = false;
  }
</script>

<Modal bind:open title="Create Service Area">
  <form on:submit|preventDefault={handle_submit}>
    <ServiceAreaForm />
    <!--
    <fieldset>
      <ul>
        <li>
          <label for="provider-name">Street</label>
          <input
            autofocus
            type="text"
            name="name"
            id="provider-name"
            bind:value={street}
            disabled={loading}
          />
        </li>
        <li>
          <label for="provider-postal">Postal code</label>
          <input
            type="text"
            name="postal"
            id="provider-postal"
            bind:value={postal}
            disabled={loading}
          />
        </li>
        <li>
          <label for="provider-country">Country</label>
          <input
            type="text"
            name="country"
            id="provider-country"
            bind:value={country}
            disabled={loading}
          />
        </li>
        <li>
          <label for="provider-latitude">Latitude</label>
          <input
            type="number"
            name="latitude"
            id="provider-latitude"
            bind:value={latitude}
            disabled={loading}
          />
        </li>
        <li>
          <label for="provider-longitude">Longitude</label>
          <input
            type="number"
            name="longitude"
            id="provider-longitude"
            bind:value={longitude}
            disabled={loading}
          />
        </li>
        <li>
          <label for="provider-radius">Radius</label>
          <input
            type="number"
            name="radius"
            id="provider-radius"
            bind:value={radius}
            disabled={loading}
          />
        </li>
        <li>
          <label for="provider-geojson">GeoJSON</label>
          <input
            type="text"
            name="geojson"
            id="provider-geojson"
            bind:value={json}
            disabled={loading}
          />
        </li>
        <li>
          <label for="provider-wkt">Well-Known Text</label>
          <input
            type="text"
            name="wkt"
            id="provider-wkt"
            bind:value={wkt}
            disabled={loading}
          />
        </li>
        <li>
          <label for="provider-timezone">Timezone</label>
          <input
            type="text"
            name="timezone"
            id="provider-timezone"
            bind:value={timezone}
            disabled={loading}
          />
        </li>
      </ul>
    </fieldset>
-->
    <FormActions bind:loading on:cancel={() => (open = false)} />
  </form>
</Modal>
