<script>
  import { parse, startOfDay, endOfDay } from "date-fns";
  import { format, utcToZonedTime } from "date-fns-tz";

  import page from "page";

  export let value = null;
  export let timezone = null;

  let min,
    max = null;

  $: if (value)
    [min, max] = value.split("/").map((i) => utcToZonedTime(i, timezone));

  function update(e) {
    e.preventDefault();
    let form = e.target;

    if (!e.target.checkValidity()) return;

    let d = parse(form.elements["min"].value, "yyyy-MM-dd", new Date());

    if (!d) return;

    min = startOfDay(d);

    d = parse(form.elements["max"].value, "yyyy-MM-dd", new Date());

    if (!d) return;

    max = endOfDay(d);

    const q = new URLSearchParams(location.search);
    q.set(
      "valid",
      `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", {
        timeZone: timezone,
      })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: timezone })}`
    );
    page(`${location.pathname}?${q.toString()}`); // navigate
  }
</script>

<form on:submit|preventDefault={update}>
  <fieldset>
    <ul>
      <li>
        <label>From</label><input
          type="date"
          min="2000-01-01"
          max="2100-01-01"
          value={format(min, "yyyy-MM-dd")}
          name="min"
          required
        />
      </li>
      <li>
        <label>To</label><input
          type="date"
          min="2000-01-01"
          max="2100-01-01"
          value={format(max, "yyyy-MM-dd")}
          name="max"
          required
        />
      </li>
    </ul>
  </fieldset>
  <footer>
    <button type="submit">Update</button>
  </footer>

  <!-- <input type="time" value="{timeMin}" min="00:00" max="23:59" required on:input={onTimeMin} on:change={onTimeMin} /> -->
</form>
