<script>
  import { fetchUpdateProperty } from "./api";
  import { thumbnail } from "$utils/filepreview";

  export let property = null;

  let editingProperty = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editingProperty))
    editingProperty = { ...property };

  $: newPhotoUrl = null;
  $: photoUrl =
    newPhotoUrl ||
    (editingProperty &&
      editingProperty.photo &&
      thumbnail(editingProperty.photo.url, 500));

  $: newLogoUrl = null;
  $: logoUrl = newLogoUrl || editingProperty?.logo?.url; /*.replace(
        "https://upload.parkingboss.com/files/",
        "https://amenitypass.imgix.net/"
      ) + "?auto=compress,format&w=500");*/

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  function photo(e) {
    const input = e.target;
    if (!input.files && !input.files[0]) return;
    const oldPhotoUrl = newPhotoUrl;
    newPhotoUrl = URL.createObjectURL(input.files[0]);
    if (oldPhotoUrl) URL.revokeObjectURL(oldPhotoUrl);
  }

  function logo(e) {
    const input = e.target;
    if (!input.files && !input.files[0]) return;
    const oldLogoUrl = newLogoUrl;
    newLogoUrl = URL.createObjectURL(input.files[0]);
    if (oldLogoUrl) URL.revokeObjectURL(oldLogoUrl);
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editingProperty) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    const newPolicy = await fetchUpdateProperty(editingProperty, formData);

    submitting = submittable = false;
    editingProperty = null;

    if (newPhotoUrl) {
      const oldPhotoUrl = newPhotoUrl;
      newPhotoUrl = null;
      URL.revokeObjectURL(oldPhotoUrl);
    }

    if (newLogoUrl) {
      const oldLogoUrl = newLogoUrl;
      newLogoUrl = null;
      URL.revokeObjectURL(oldLogoUrl);
    }
  }
</script>

{#if editingProperty}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    class="edit property"
  >
    <header>
      <h1>Photo & Logo</h1>
    </header>

    <fieldset>
      <ul>
        <li>
          <figure>
            <img class="property photo" alt="property" src={photoUrl} />
          </figure>
        </li>
        <li>
          <input
            id="property-photo-new"
            type="file"
            accept="image/*"
            name="photo"
            on:change={photo}
            disabled={submitting}
            title="Select photo"
          />
          <label for="property-photo-new">Select photo</label>
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <ul>
        <li>
          <figure>
            <!-- <a
              href={newLogoUrl || editingProperty?.logo?.url}
              download={editingProperty?.logo?.name}
              target="_blank" rel="noreferrer"
            > -->
            <img class="property logo" alt="logo" src={logoUrl} />
            <!-- </a> -->
          </figure>
        </li>
        <li>
          <input
            id="property-logo-new"
            type="file"
            accept="image/png,.png,image/svg+xml,.svg"
            name="logo"
            on:change={logo}
            disabled={submitting}
            title="Select logo"
          />
          <label for="property-logo-new">Select logo</label>
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
