<script lang="ts">
  import { propertyId } from "$stores";
  import { auth } from "$utils/auth";
  import {
    InfoTagAssociationWizard,
    type InfoTagImportEvent,
  } from "@communityboss/components";
  import debug from "debug";
  import { get } from "lodash-es";
  import { onMount } from "svelte";
  import AddMedia from "./AddMedia.svelte";
  import EditPropertyMedia from "./EditPropertyMedia.svelte";
  import AddCustomMedia from "./components/AddCustomMedia.svelte";
  import { downloadHandler } from "./util/exportTable";

  const log = debug("boss:boss-web:Media");

  export let property = null;
  export let items = null;
  let table = null;

  async function infotags_associated_successfully({
    detail: value,
  }: CustomEvent<InfoTagImportEvent>) {
    log("infotags imported:", value);
  }

  function infotags_association_failed({ detail: value }: CustomEvent<Error>) {
    alert(value.message);
  }

  onMount(() => {
    table && table.scrollIntoView();
  });
</script>

<!-- <EditTenants property={property} /> -->
<section>
  <InfoTagAssociationWizard
    token={$auth.token}
    property_id={$propertyId}
    on:success={infotags_associated_successfully}
    on:error={infotags_association_failed}
  />
</section>

<section>
  <EditPropertyMedia {property} />
  <a
    href="#"
    type="text/csv"
    download="{get(property, 'name', '')} {get(
      property,
      'media.title',
      ''
    )}s.csv"
    on:click={(e) => downloadHandler(e, table)}>Download Spreadsheet</a
  >
  <AddMedia {property} />
  <AddCustomMedia {property} />
</section>
{#if items}
  <table class="media" data-count={get(items, "length", "")} bind:this={table}>
    <thead>
      <tr>
        <th scope="col row">{get(property, "media.title", "Media")}</th>
        <th scope="col">Type</th>
        <th scope="col">Color</th>
        <th scope="col">Label</th>
        <th scope="col">Description</th>
      </tr>
    </thead>
    {#if !!items && !!items.length}
      <tbody>
        {#each items as item}
          {#if item}
            <tr>
              <th scope="row"
                ><data
                  class="{item.type} id {[item.format, item.size]
                    .filter((i) => !!i)
                    .join(' ')}"
                  value={item.id}>{item.display}</data
                ></th
              >
              <td>{item.title}</td>
              <td>{item.color || ""}</td>
              <td>{item.label || ""}</td>
              <td>{item.description || ""}</td>
            </tr>
          {/if}
        {/each}
      </tbody>
    {/if}
  </table>
{/if}
