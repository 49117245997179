<script>
  export let policy;

  const applicationID = "5CEDECAF";
  const namespace = "urn:x-cast:app.amenitypass.chromecast";
  let session = null;

  if (!chrome.cast || !chrome.cast.isAvailable) {
    setTimeout(initializeCastApi, 1000);
  } else {
    initializeCastApi();
  }

  function initializeCastApi() {
    var sessionRequest = new chrome.cast.SessionRequest(applicationID);
    var apiConfig = new chrome.cast.ApiConfig(
      sessionRequest,
      sessionListener,
      receiverListener
    );

    chrome.cast.initialize(apiConfig, onInitSuccess, onError);
  }

  function onInitSuccess() {
    console.log("onInitSuccess");
  }

  function onError(message) {
    console.log("onError: " + JSON.stringify(message));
  }

  function onSuccess(message) {
    console.log("onSuccess: " + JSON.stringify(message));
  }

  function onStopAppSuccess() {
    console.log("onStopAppSuccess");
  }

  function sessionListener(e) {
    console.log("New session ID: " + e.sessionId);
    session = e;
    session.addUpdateListener(sessionUpdateListener);
  }

  function sessionUpdateListener(isAlive) {
    console.log(
      (isAlive ? "Session Updated" : "Session Removed") +
        ": " +
        session.sessionId
    );
    if (!isAlive) {
      session = null;
    }
  }

  function receiverListener(e) {
    // Due to API changes just ignore this.
  }

  function sendMessage(message) {
    console.log("sendMessage=", message);
    if (session != null) {
      session.sendMessage(
        namespace,
        message,
        onSuccess.bind(this, message),
        onError
      );
    } else {
      chrome.cast.requestSession(function (e) {
        session = e;
        sessionListener(e);
        session.sendMessage(
          namespace,
          message,
          onSuccess.bind(this, message),
          onError
        );
      }, onError);
    }
  }

  function startSession() {
    chrome.cast.requestSession(function (e) {
      session = e;
      sessionListener(e);
      //session.sendMessage(namespace, message, onSuccess.bind(this, message), onError);
    }, onError);
  }

  function stopApp() {
    session.stop(onStopAppSuccess, onError);
  }
</script>

<button
  type="button"
  classs="cast"
  class:success={null != session}
  on:click={sendMessage({
    policy: policy.policy,
    property: policy.scope,
  })}>Cast</button
>
