<script>
  import { get, range, filter } from "lodash-es";
  import EditPolicySettings from "./EditPolicySettings.svelte";
  import EditPolicyLimits from "./EditPolicyLimits.svelte";
  import EditPolicyClosedSchedule from "./components/EditPolicyClosedPattern.svelte";
  import EditPolicyClosedEventMetersNew from "./components/EditPolicyClosedEventNew.svelte";
  import EditPolicyClosedEventMeters from "./components/EditPolicyClosedEventMeters.svelte";
  import EditPolicyUnits from "./EditPolicyUnits.svelte";
  import EditPolicySpaces from "./EditPolicySpaces.svelte";
  import EditPolicyMedia from "./EditPolicyMedia.svelte";
  import EditPolicyVehicles from "./EditPolicyVehicles.svelte";
  import EditPolicyPasses from "./EditPolicyPasses.svelte";
  import DeletePolicy from "./DeletePolicy.svelte";
  import NewPolicyMeter from "./components/NewPolicyMeter.svelte";
  import NewPrice from "./NewPrice.svelte";
  import EditPolicyMeters from "./EditPolicyMeters.svelte";
  import EditPolicyPrices from "./EditPolicyPrices.svelte";
  import EditPolicyTimes from "./EditPolicyTimes.svelte";
  import EditPolicyAuthCodes from "./components/EditPolicyAuthCodes.svelte";

  export let policy = null;
  export let issue = true;
  export let disabled = false;
  export let readonly = false;

  //$: issue = !!get(policy, "issue.enabled", true);
  //$: disabled = !!get(policy, "disabled");
</script>

{#if policy}
  <EditPolicySettings {policy} bind:issue bind:disabled {readonly} />
  <EditPolicyLimits {policy} {readonly} />
  <!-- {#if !disabled} -->
  {#if issue || disabled}
    <section>
      <header>
        <h1>Fields</h1>
      </header>
      <EditPolicyPasses {policy} {readonly} />
      <EditPolicyAuthCodes {policy} {readonly} />
    </section>
    {#if !get(policy, "permit.continuous", false)}
      <EditPolicyTimes {policy} {readonly} />
    {/if}
    <!-- {#if get(policy, "vehicle.request", false)}
      
    {/if} -->
  {/if}

  <!-- {#if get(policy, "space.request", false)} -->
  <EditPolicyVehicles {policy} {readonly} />
  <EditPolicySpaces {policy} {readonly} />
  <EditPolicyMedia {policy} {readonly} />
  <EditPolicyUnits {policy} {readonly} />
  <!-- {/if} -->
  {#if issue || disabled}
    <!-- {#if get(policy, "tenant.request", false)}
      
    {/if} -->
    <!-- {#if get(policy, "media.request", false)} -->

    <!-- {/if} -->
    {#if !readonly}
      <section>
        <header>
          <h1>Capacity</h1>
        </header>
        <NewPolicyMeter {policy} />
        <EditPolicyMeters {policy} {readonly} />
      </section>
    {/if}
    <EditPolicyClosedSchedule {policy} {readonly} />
    {#if !readonly}
      <section>
        <header><h1>Special Closures</h1></header>
        <EditPolicyClosedEventMetersNew {policy} {readonly} />
        <EditPolicyClosedEventMeters {policy} />
      </section>
      {#if !get(policy, "audience.admin", false)}
        <section>
          <header>
            <h1>Prices</h1>
          </header>
          <NewPrice {policy} />
          <EditPolicyPrices {policy} {readonly} />
        </section>
      {/if}
    {/if}
  {/if}

  <!-- {#if issue || disabled}
            <EditPolicyClosed policy={policy} />
        {/if} -->
  <!-- {/if} -->
  {#if !readonly}<DeletePolicy {policy} />{/if}
{/if}
