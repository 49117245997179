<script>
  import {
    parseISO,
    startOfWeek,
    addWeeks,
    startOfMonth,
    addMonths,
    startOfDay,
    addDays,
    addHours,
    startOfHour,
  } from "date-fns";
  import {
    toDate as toZonedDate,
    utcToZonedTime,
    zonedTimeToUtc,
    format,
  } from "date-fns-tz";
  import { onDestroy } from "svelte";

  export let interval;
  export let timezone;
  export let now; // store
  export let nowInView = false;
  export let refs = ["P1M"];
  export let direction = "horizontal asc";
  export let label = false;
  let pxPerMs = 1 / 1000 / 60 / 60;
  let offsetDirection = "left";
  let scaleDirection = "width";
  let vertical = false;
  let desc = false;

  $: calendars = refs.reduce((result, iso) => {
    let adder;
    let startOf;
    let labeler;

    switch (iso) {
      case "P1M":
        adder = addMonths;
        startOf = startOfMonth;
        labeler = (interval) =>
          format(
            utcToZonedTime(parseISO(interval.split("/")[0]), timezone),
            "MMM yyyy"
          );
        break;
      case "P1W":
        adder = addWeeks;
        startOf = startOfWeek;
        labeler = (interval) =>
          format(
            utcToZonedTime(parseISO(interval.split("/")[0]), timezone),
            "d"
          );
        break;
      case "P1D":
        adder = addDays;
        startOf = startOfDay;
        pxPerMs = 3 / 1000 / 60 / 60;
        labeler = (interval) =>
          format(
            utcToZonedTime(parseISO(interval.split("/")[0]), timezone),
            "d"
          );
        break;
      case "PT1H":
        adder = addHours;
        startOf = startOfHour;
        pxPerMs = 1 / 1000 / 60;
        labeler = (interval) => {
          console.log("interval=", interval);
          console.log(
            utcToZonedTime(parseISO(interval.split("/")[0]), timezone)
          );
          return format(
            utcToZonedTime(parseISO(interval.split("/")[0]), timezone),
            "h:mm a"
          );
        };
        break;
    }

    if (adder && startOf)
      result[iso] = {
        adder,
        startOf,
        labeler,
      };

    return result;
  }, {});

  $: switch (direction) {
    case "vertical desc":
    case "horizontal desc":
      desc = true;
    case "vertical":
    case "vertical desc":
    case "vertical asc":
      vertical = true;
      offsetDirection = "top";
      scaleDirection = "height";
      break;
    default:
      offsetDirection = "left";
      scaleDirection = "width";
  }

  let nowElem;

  $: if (nowInView && nowElem) nowElem.scrollIntoView({ inline: "center" });

  $: utcInterval = interval && interval.split("/").map((s) => parseISO(s)); // [ a, b ]
  $: [utcIntervalMin, utcIntervalMax] = utcInterval;
  // $: zonedInterval = interval && (timezone ? utcInterval.map(d => utcToZonedTime(d, timezone)) : interval.split('/').map(s => toZonedDate(s, { timeZone: timezone }))); // [ a, b ], dates that represent zoned by either timezone or incoming offset
  // $: [ zonedIntervalMin, zonedIntervalMax ] = zonedInterval;
  $: intervalScale = scale(interval);

  function calculateLocalIntervals(
    interval,
    timezone,
    startOf = startOfMonth,
    adder = addMonths
  ) {
    var results = [];
    if (!interval) return results;

    var minUtc = parseISO(interval.split("/")[0]).getTime();
    var maxUtc = parseISO(interval.split("/")[1]).getTime();

    var zoned = startOf(utcToZonedTime(minUtc, timezone));
    //if(zonedTimeToUtc(local, timezone).getTime() < minUtc) local = utcToZonedTime(minUtc, timezone);
    while (zonedTimeToUtc(zoned, timezone) < maxUtc) {
      let next = startOf(adder(zoned, 1));
      //if(zonedTimeToUtc(next, timezone) > maxUtc) next = utcToZonedTime(maxUtc, timezone);
      results.push(
        `${format(zoned, "yyyy-MM-dd'T'HH:mm:ssxxx", {
          timeZone: timezone,
        })}/${format(next, "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: timezone })}`
      );
      zoned = next;
    }
    if (desc) return results.reverse();
    return results;
  }

  function scale(relative, frame, scale = pxPerMs) {
    if (!relative.includes("/")) return 0; // has to be a zero frame
    if (relative && frame) {
      let [a1, a2] = relative.split("/").map((s) => parseISO(s).getTime());
      if (a2 <= a1) return 0;
      const [b1, b2] = frame.split("/").map((s) => parseISO(s).getTime());
      if (!a2) a2 = b2;
      return Math.abs(Math.min(a2, b2) - Math.max(a1, b1)) * scale;
    }
    return (
      relative &&
      relative
        .split("/")
        .reduce((duration, item) => parseISO(item).getTime() - duration, 0) *
        scale
    );
  }

  function offsetStart(relative, frame) {
    if (desc)
      return scale(
        `${relative.split("/").pop()}/${frame.split("/").pop()}`,
        frame
      );

    return scale(
      `${frame.split("/").shift()}/${relative.split("/").shift()}`,
      frame
    );
  }
  // function offsetEnd(relative, frame) {
  //     return scale(`${frame.split('/').pop()}/${relative.split('/').pop()}`);
  // }
</script>

<time
  class="refs"
  datetime={interval}
  style="{scaleDirection}:{intervalScale}px;"
>
  {#each Object.entries(calendars) as [cal, options]}
    <time
      class="refs {cal}"
      datetime={interval}
      style="{scaleDirection}:{intervalScale}px;"
    >
      {#each calculateLocalIntervals(interval, timezone, options.startOf, options.adder) as item}
        <time
          class="ref {cal}"
          style="{scaleDirection}:{scale(item, interval)}px;"
          datetime={item}
          >{#if label}<span>{options.labeler(item)}</span>{/if}</time
        >
      {/each}
    </time>
  {/each}

  {#if now}
    <time
      bind:this={nowElem}
      class="ref now"
      style="{offsetDirection}:{offsetStart(
        format(utcToZonedTime($now, timezone), "yyyy-MM-dd'T'HH:mm:ssxxx", {
          timeZone: timezone,
        }),
        interval
      )}px;"
      datetime={format(
        utcToZonedTime($now, timezone),
        "yyyy-MM-dd'T'HH:mm:ssxxx",
        { timeZone: timezone }
      )}
    >
      {#if label}
        {format(utcToZonedTime($now, timezone), "h:mm a")}
      {/if}
    </time>
  {/if}
</time>
