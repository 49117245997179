<script>
  import { fetchCreateTenants } from "./api";
  import { get, invokeMap, split, uniq } from "lodash-es";
  import { units } from "./stores";

  export let property = null;

  let items = [];

  let submittable = false;
  let submitting = false;

  function change(e) {
    submittable = items?.length || e?.target?.form.checkValidity();
  }

  $: change(items); // trigger change on items

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    const form = e.target;

    submitting = true;

    const attrs = [
      "format",
      "bedrooms",
      "bathrooms",
      "squarefeet",
      "level",
    ].reduce(
      (result, k) =>
        ((result[k] = form.elements[k]?.value) && result) || result,
      {}
    );

    // update with local if not set from file
    if (!items?.length)
      items = uniq(
        invokeMap(
          split(form.elements["tenant"].value, /\r?\n/),
          String.prototype.trim
        )
      ).map((item) => ({
        tenant: item,
        ...attrs,
      }));

    await fetchCreateTenants(property, items);

    submitting = submittable = false;

    //property = null;

    items = [];

    form.reset();
  }
  async function processCsvFile(file) {
    if (!file) return;

    let input = file;

    console.log("process=", file);

    if (file.type !== "text/csv" && file.name.indexOf(".csv") < 0)
      input = await fetchCSV(file);

    // const numeric = /[$.]/gi;

    return new Promise(function (resolve) {
      Papa.parse(input, {
        header: true,
        complete: async function (results) {
          console.log(results);

          // straight update?
          //await fetchCreateSpaces(property, results.data);
          items = results.data;
          return resolve(results.data);
        },
      });
    });
  }
  async function files(e) {
    //console.log(e);

    e.preventDefault();

    var items =
      (e.target && e.target.files) ||
      (e.dataTransfer && e.dataTransfer.files) ||
      [];
    //console.log("files=", items);

    if (e.target) e.target.disabled = true;

    if (!items.length) return;

    for (let item of items) {
      if (
        item.type !== "text/csv" &&
        item.name.indexOf(".csv") < 0 &&
        item.name.indexOf(".xlsx") < 0 &&
        item.name.indexOf(".xls") < 0
      )
        continue;
      await processCsvFile(item);
    }

    if (e.target) {
      e.target.value = "";
      e.target.disabled = false;
    }
  }
</script>

{#if property && property.units && property.units.enabled}
  <form on:change={change} on:input={change} on:submit={submit} class="tenants">
    <fieldset>
      <ul>
        <li>
          <input
            id="prices-file"
            type="file"
            on:change={files}
            accept="text/csv,.csv,.xlsx,.xls"
          />
          <label for="prices-file">Import from CSV</label>
        </li>
        {#if !items?.length}
          <li>
            <label for="new-units-format">Type</label>
            <select
              name="format"
              id="new-units-format"
              value={property.units.format}
            >
              <option value="unit">unit</option>
              <option value="apartment">apartment</option>
              <option value="townhome">townhome</option>
              <option value="condo">condo</option>
              <option value="home">home</option>
              <option value="office">office</option>
              <option value="suite">suite</option>
              <option value="retail">retail</option>
              <option value="commercial">commercial</option>
            </select>
          </li>
          <li>
            <label for="new-units-bedrooms">Bedrooms</label>
            <select name="bedrooms" id="new-units-bedrooms">
              <option value="">--</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </li>
          <li>
            <label for="new-units-bathrooms">Bathrooms</label>
            <select name="bathrooms" id="new-units-bathrooms">
              <option value="">--</option>
              <option value="0">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </select>
          </li>
          <li>
            <label for="new-spaces-squarefeet">Sq Ft</label>
            <input
              placeholder="optional"
              type="number"
              min="1"
              name="squarefeet"
              id="new-spaces-squarefeet"
            />
          </li>
          <li>
            <label for="new-units-level">Level</label>
            <input
              placeholder="optional"
              type="text"
              name="level"
              id="new-units-level"
            />
          </li>
          <li>
            <label for="new-tenants">Units</label>
            <textarea id="new-tenants" name="tenant" required />
          </li>
        {/if}
      </ul>
    </fieldset>
    <p>
      Please note: one unit per line, units will be created once and duplicates
      will be skipped.
    </p>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Add/Update Units"}</button
      >
      <!-- <button type="reset" disabled={submitting}>Cancel</button> -->
    </footer>
  </form>
{/if}
