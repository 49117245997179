<script>
  import { minuteNow, permit } from "../stores";
  import { get, uniq, map } from "lodash-es";
  import {
    startOfDay,
    format,
    addHours,
    subHours,
    isWithinInterval,
  } from "date-fns";
  import PolicyUsage from "./PolicyUsage.svelte";
  import Policy from "./Policy.svelte";
  export let property = null;
  export let policies = null;
  export let permits = null;
  export let valid = null;
  let permitItems = [];
  let permitIds = [];
  let expandedItems = {};
  const max = 3;

  let timeRows = [];
  let interval = startOfDay(new Date());
  for (let i = 0; i < 24; i++) {
    interval = addHours(interval, 1);
    timeRows.push(interval);
  }

  function doesOverlapWithTimeslot(timeStart, valid) {
    const max = valid.max
      ? new Date(valid.max.datetime)
      : addHours(timeStart, 1);
    if (timeStart >= max) return false;
    if (addHours(timeStart, 1) <= new Date(valid.min.datetime)) return false;
    return true;
  }

  function handleToggleItems(key) {
    if (expandedItems[key]) {
      expandedItems[key] = !expandedItems[key];
    } else {
      expandedItems[key] = true;
    }
  }

  function getActivePolicies(permits) {
    const permitIds = uniq(
      permits.items.map((permit) => (permit.policy ? permit.policy.id : ""))
    );
    return (
      policies.filter(
        (policy) =>
          policy.amenity !== "parking" && permitIds.includes(policy.id)
      ) || []
    );
  }

  function getAgenda(permits) {
    const activePolicies = getActivePolicies(permits);

    return timeRows.map((row, i) => {
      return {
        timeslot: row,
        policies: activePolicies.map((policy) => {
          const permitsForTimeslot = permits.items.filter(
            (permit) =>
              permit.policy &&
              policy.id === permit.policy.id &&
              doesOverlapWithTimeslot(row, permit.valid)
          );

          return {
            permits: permitsForTimeslot,
            permitsTotal: permitsForTimeslot.length,
          };
        }),
      };
    });
  }

  $: if (permits) permits.then((data) => console.log(getAgenda(data)));
</script>

{#await permits}
  <h1>Loading&hellip;</h1>
{:then permits}
  <table class="permits-agenda" data-count={get(policies, "length", "")}>
    <tr>
      <th>
        {property.timezone.split("/")[0]}/<br />
        {property.timezone.split("/")[1]}
      </th>
      {#each getActivePolicies(permits) as item}
        <th>
          <header>
            <!-- <a href="/properties/{item.scope}/amenities/{item.permit.tenant}"> -->
            <Policy policy={item} />
            <!-- </a> -->
            <PolicyUsage {valid} policy={item} capacity={true} used={true} />
          </header>
        </th>
      {/each}
    </tr>
    {#each getAgenda(permits) as item, i}
      <tr
        class:asActive={isWithinInterval($minuteNow, {
          start: subHours(item.timeslot, 1),
          end: item.timeslot,
        })}
      >
        <td>
          <div class="timeslot-header">
            {format(item.timeslot, "h a")}
          </div>
        </td>
        {#if $minuteNow}
          {#each item.policies as policy}
            <td class="timeslot">
              {#each expandedItems[`${policy.id}-${i}`] ? policy.permits : policy.permits.slice(0, max) as permit}
                <div class="timeslot-permit">
                  {#if permit.amenity === "parking"}
                    <data
                      class="{permit.vehicle.type} id {[
                        permit.vehicle.format,
                        permit.vehicle.size,
                      ]
                        .filter((i) => !!i)
                        .join(' ')}"
                      value={permit.vehicle.id}>{permit.vehicle.display}</data
                    >
                  {:else}
                    <data
                      class="{permit.tenant.type} id {[
                        permit.tenant.format,
                        permit.tenant.size,
                      ]
                        .filter((i) => !!i)
                        .join(' ')}"
                      value={permit.tenant.id}>{permit.tenant.display}</data
                    >
                  {/if}
                </div>
              {/each}
              {#if policy.permitsTotal > max}
                <div
                  class="more-permits-btn"
                  on:click={() => handleToggleItems(`${policy.id}-${i}`)}
                >
                  {expandedItems[`${policy.id}-${i}`]
                    ? "[-]"
                    : `[+${policy.permitsTotal - max}]`}
                </div>
              {/if}
            </td>
          {/each}
        {/if}
      </tr>
    {/each}
  </table>
{/await}
