<script>
  import { parseISO } from "date-fns";
  import { format, utcToZonedTime } from "date-fns-tz";

  export let data;
  export let property;

  $: items = process(data);

  async function process(data) {
    if (!data) return [];

    var json = await data;

    var items = Object.keys(json.detections.items)
      .map((id) => {
        var item = json.items[id];
        item.observation = json.items[item.observation] || item.observation;
        if (item.observation && item.observation.id) return null;
        item.files = Object.entries(json.attachments.items[id] || {})
          .filter(([id, type]) => type === "file")
          .map(([id, type]) => json.items[id]);

        item.created.by =
          item.created.by && (json.items[item.created.by] || item.created.by);

        return item;
      })
      .filter((i) => i);

    items.sort((a, b) => parseISO(b.created.utc) - parseISO(a.created.utc));

    return items;
  }
</script>

{#await items}
  <figure>Loading&hellip;</figure>
{:then items}
  <ul class="detections" data-count={items.length}>
    {#each items as item}
      <li>
        {#each item.files as file}
          <figure>
            <a href={file.url} target="_blank" rel="noreferrer"
              ><img src={thumbnail(file.url, 500)} alt="scan" /></a
            >
          </figure>
        {/each}
        <data class="vehicle id" value={item.vehicle}>{item.vehicle}</data>
        {#if item.created.by}
          <a href="mailto:{item.created.by.email}"
            >scanned by {item.created.by.name}</a
          >
        {/if}
        <time datetime={item.created.utc}
          >Scanned {format(
            utcToZonedTime(item.created.utc, property.timezone),
            "h:mm a EE MMM d yyyy zzz",
            { timeZone: property.timezone }
          )}</time
        >
      </li>
    {/each}
  </ul>
{/await}
