<script>
  import {
    parseISO,
    format,
    startOfWeek,
    addWeeks,
    startOfMonth,
    addMonths,
    startOfDay,
    addDays,
    addHours,
    startOfHour,
  } from "date-fns";
  import { onDestroy } from "svelte";

  export let interval;
  //export let timezone;
  export let scales = ["P1M"];
  export let direction = "horizontal asc";
  let pxPerMs = 1 / 1000 / 60 / 60;
  let offsetDirection = "left";
  let scaleDirection = "width";
  let vertical = false;
  let desc = false;
  export let mutate = false;

  $: calendars = scales.reduce((result, iso) => {
    let adder;
    let startOf;
    let labeler;

    switch (iso) {
      case "P1M":
        adder = addMonths;
        startOf = startOfMonth;
        labeler = (interval) =>
          format(toZonedDate(interval.split("/")[0]), "MMM yyyy");
        break;
      case "P1W":
        adder = addWeeks;
        startOf = startOfWeek;
        labeler = (interval) =>
          format(toZonedDate(interval.split("/")[0]), "d");
        break;
      case "P1D":
        adder = addDays;
        startOf = startOfDay;
        pxPerMs = 3 / 1000 / 60 / 60;
        labeler = (interval) =>
          format(toZonedDate(interval.split("/")[0]), "d");
        break;
      case "PT1H":
        adder = addHours;
        startOf = startOfHour;
        pxPerMs = 1 / 1000 / 60;
        labeler = (interval) =>
          format(toZonedDate(interval.split("/")[0]), "h a");
        break;
    }

    if (adder && startOf)
      result[iso] = {
        adder,
        startOf,
        labeler,
      };

    return result;
  }, {});

  // let adder = addMonths;
  // let startOf = startOfMonth;

  // $:switch(calendar) {
  //     case "P1M":
  //         adder = addMonths;
  //         startOf = startOfMonth;
  //         break;
  //     case "P1W":
  //         adder = addWeeks;
  //         startOf = startOfWeek;
  //         break;
  //     case "P1D":
  //         adder = addDays;
  //         startOf = startOfDay;
  //         perHour = 3 / 1000 / 60 / 60;
  //         break;
  // }

  $: switch (direction) {
    case "vertical desc":
    case "horizontal desc":
      desc = true;
    case "vertical":
    case "vertical desc":
    case "vertical asc":
      vertical = true;
      offsetDirection = "top";
      scaleDirection = "height";
      break;
    default:
      offsetDirection = "left";
      scaleDirection = "width";
  }

  let dataElem;
  let nowElem;

  // Options for the observer (which mutations to observe)
  const config = { attributes: false, childList: true, subtree: true };

  function updateDataElems() {
    for (const time of dataElem.querySelectorAll("time[datetime]")) {
      //console.log("time", time, time.getAttribute("datetime"), interval);
      time.style[scaleDirection] =
        scale(time.getAttribute("datetime"), interval) + "px";
      time.style[offsetDirection] =
        offsetStart(time.getAttribute("datetime"), interval, direction) + "px";
    }
  }

  // Callback function to execute when mutations are observed
  const callback = function (mutationsList, observer) {
    // update the style of times
    updateDataElems();

    // Use traditional 'for loops' for IE 11
    for (const mutation of mutationsList) {
      if (mutation.type === "childList") {
        console.log("A child node has been added or removed.");
      } else if (mutation.type === "attributes") {
        console.log(
          "The " + mutation.attributeName + " attribute was modified."
        );
      }
    }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);

  $: if (!mutate) observer.disconnect();

  $: if (dataElem && mutate) {
    updateDataElems();
    observer.disconnect();
    observer.observe(dataElem, config);
  }

  onDestroy(function () {
    if (observer) observer.disconnect();
  });

  $: if (nowElem) nowElem.scrollIntoView({ inline: "center" });

  $: utcInterval = interval && interval.split("/").map((s) => parseISO(s)); // [ a, b ]
  $: [utcIntervalMin, utcIntervalMax] = utcInterval;
  //$: zonedInterval = interval && (timezone ? utcInterval.map(d => utcToZonedTime(d, timezone)) : interval.split('/').map(s => toZonedDate(s, { timeZone: timezone }))); // [ a, b ], dates that represent zoned by either timezone or incoming offset
  //$: [ zonedIntervalMin, zonedIntervalMax ] = zonedInterval;
  $: intervalScale = scale(interval);

  function scale(relative, frame, scale = pxPerMs) {
    if (!relative.includes("/")) return 0; // has to be a zero frame
    if (relative && frame) {
      let [a1, a2] = relative.split("/").map((s) => parseISO(s).getTime());
      if (a2 <= a1) return 0;
      const [b1, b2] = frame.split("/").map((s) => parseISO(s).getTime());
      if (a1 && a2 && b1 && b2 && (a2 < b1 || a1 > b2)) return 0; // completely outside
      if (!a2) a2 = b2;
      return Math.abs(Math.min(a2, b2) - Math.max(a1, b1)) * scale;
    }
    return (
      relative &&
      relative
        .split("/")
        .reduce((duration, item) => parseISO(item).getTime() - duration, 0) *
        scale
    );
  }

  function offsetStart(relative, frame) {
    if (desc)
      return scale(
        `${relative.split("/").pop()}/${frame.split("/").pop()}`,
        frame
      );

    return scale(
      `${frame.split("/").shift()}/${relative.split("/").shift()}`,
      frame
    );
  }
  // function offsetEnd(relative, frame) {
  //     return scale(`${frame.split('/').pop()}/${relative.split('/').pop()}`);
  // }
</script>

<time
  class="data"
  datetime={interval}
  style="{scaleDirection}:{intervalScale}px;"
  bind:this={dataElem}
>
  <slot />
</time>
