import leasingManifest from "$assets/leasing.json";
import { api, auth } from "$utils/auth";
import { toZoneISOString } from "$utils/datetime";
import { items, updateItems } from "@parkingboss/svelte-utils";
import { addHours, addMinutes } from "date-fns";
import debug from "debug";
import { debounce, each, get, has, map, merge, orderBy, pick } from "lodash-es";
import pLimit from "p-limit";
import { limitedPromiseAll } from "./limitedPromiseAll";

const log = debug("boss:api");

export { api };

// update auth header as auth changes
export let authHeader = "";
export let user = "self";
auth.subscribe(($auth) => {
  authHeader = $auth && `Authorization=${$auth.type} ${$auth.token}`;
  user = $auth.sub || "self";
});

export function viewpoint(offset) {
  return encodeURIComponent(
    toZoneISOString(new Date().getTime() + (offset || 0))
  );
}

export function base() {
  return api.settings.apiBase;
}

// async function fetchAndStorePermits(ids) {
//     if(!ids || !ids.length) return {};
//     const res = await fetch(`${api.settings.apiBase}/permits?viewpoint=${viewpoint()}${ids.map(id => "&scope=" + id).join("")}&valid=${addHours(new Date(), -2).toISOString()}/&${authHeader}`);
//     const json = await res.json();
//     updateItems(json);
//     return json;
// }

function updateFor(key, value) {
  if (!key || !value) return;

  log("update for=", key, value);

  // value can be a key containing a for or the map itself?
  const mapFor = value["for"] || value;

  items.update((state) => {
    if (!state[key]) state[key] = {};
    if (!state[key]["for"]) state[key]["for"] = {};

    Object.assign(state[key]["for"], mapFor); // update or add the items by key
    // log("assigning for = ", key, mapFor, "to=", state[key]["for"]);
    return state;
  });
}

function updateItemsAndFor(json) {
  if (!json) return json;
  each(json, (value, key) => {
    //log(key, value);
    if (has(value, "items")) json.items[key] = value.items;
  });

  updateItems(json);

  for (const [key, value] of Object.entries(json)) {
    if (!!value["for"]) updateFor(key, value);
  }
  return json;
}

function formDataToURLSearchParams(formData) {
  const searchParams = new URLSearchParams();

  /**
   * This is all to replace this single line:
   * new URLSearchParams(formData), because Edge
   */
  const keys = [...formData.keys()];
  keys.forEach((key) => {
    /**
     * For 'checkboxes', we need to append the values to the search params
     * and not just add a comma separated string.
     */
    if (keys.filter((x) => x === key).length > 1) {
      /**
       * We grab all the values and append them in one go
       */
      formData.getAll(key).forEach((value) => {
        searchParams.append(key, value);
      });

      /**
       * Then remove all the remaining instances of the key from the
       * keys array we're looping around
       */
      keys.forEach((k, i) => {
        if (k === key) {
          keys.splice(i, 1);
        }
      });
    } else {
      // Strings are simple in comparison
      searchParams.set(key, formData.get(key));
    }
  });

  return searchParams;
}

export async function createNewProperty(formData) {
  log(...formData);
  const result = await fetch(
    `${base()}/properties?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );

  if (!result.ok) return result;

  return result.json();
}

export async function fetchProperties() {
  const json = await Promise.all([
    fetch(
      `${base()}/properties?viewpoint=${viewpoint()}&scope=*&principal=${user}&${authHeader}`
    ),
    fetch(
      `${base()}/billing?viewpoint=${viewpoint()}&scope=*&${authHeader}`
    ),
    //fetch(`${api.settings.apiBase}/payments/collected/metrics?&viewpoint=${viewpoint()}&scope=*&metric=total&interval=${startOfYear(new Date()).toISOString()}/${addYears(startOfYear(new Date()), 1).toISOString()}&${authHeader}`),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchPermits(property, valid, options) {
  const qs = new URLSearchParams();

  if (options)
    for (const [k, v] of Object.entries(options)) {
      if (null != v) qs.set(k, v);
      else qs.delete(k);
    }

  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/permits?scope=${property}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid
      )}&${authHeader}&files=true&sent=false&payments=true&${qs.toString()}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  json.items["contacts"] = json.contacts;
  //json.items["notes"] = json.notes;
  json.items.attachments = json.attachments;
  json.items.fees = json.fees;

  json.permits.items = orderBy(
    resolvePermits(json.permits.items, json.items),
    ["policy.title", "title", "valid.min.utc"],
    ["asc", "asc", "asc"]
  );
  log(json.permits.items);
  return json.permits;
}

export async function fetchViolations(property, valid) {
  property = property.id || property;

  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/violations?scope=${property}&files=true&viewpoint=${viewpoint()}&issued=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  // json.items["contacts"] = json.contacts;
  // json.items["notes"] = json.notes;
  // json.items.attachments = json.attachments;
  // json.items.fees = json.fees;

  json.violations.items = resolveViolations(json.violations.items, json.items);
  //log(json.permits.items);
  return json.violations;
}

export async function fetchPermit(permit) {
  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/permits?permit=${permit}&viewpoint=${viewpoint()}&${authHeader}&files=true&sent=true&payments=true&validations=true`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  json.items["contacts"] = json.contacts;
  //json.items["notes"] = json.notes;
  json.items.attachments = json.attachments;
  json.items.fees = json.fees;
  json.items.validations = json.validations;

  json.permits.items = orderBy(
    resolvePermits(json.permits.items, json.items),
    ["policy.title", "title", "valid.min.utc"],
    ["asc", "asc", "asc"]
  );
  log(json.permits.items);
  return json.permits;
}

export async function fetchViolation(violation) {
  //if(!timezone) return null;

  //valid = valid && valid.split('/').map(i => toZoneISOString(parseISO(i), timezone)).join('/');

  const json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/violations?violation=${violation}&files=true&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  // json.items["contacts"] = json.contacts;
  // json.items["notes"] = json.notes;
  json.items.attachments = json.attachments;
  // json.items.fees = json.fees;
  // json.items.validations = json.validations;

  json.violations.items = orderBy(
    resolveViolations(json.violations.items, json.items),
    ["policy.title", "title", "valid.min.utc"],
    ["asc", "asc", "asc"]
  );
  //log(json.permits.items);
  return json.violations;
}

export async function fetchUnits(
  scope,
  valid = null,
  sent = false,
  authcodes = true
) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${api.settings.apiBase}/units/tenants?scope=${scope}&sent=${
        sent === true
      }&authcode=${
        authcodes === true
      }&viewpoint=${new Date().toISOString()}&valid=${encodeURIComponent(
        valid || new Date().toISOString() + "/"
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchVehicleDetections(scope, valid) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/detections/vehicles?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid
      )}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    .then((values) => merge({}, ...values));

  return json;
}

export async function fetchSpaces(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/spaces?scope=${scope}&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchMedia(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/media?scope=${scope}&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchNotify(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/notify?scope=${scope}&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  for (const [k1, v1] of Object.entries(json.notify.items)) {
    const item = json.items[v1] || json.items[k1] || v1;
    //console.log("item", item);
    if (item.permits?.policy)
      item.permits.policy =
        json.items[item.permits.policy] || item.permits.policy;
  }

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchUnitStatus(scope, valid) {
  if (!scope) return null;
  const json = await Promise.all([
    //fetch(`${api.settings.apiBase}/units?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(valid)}&${authHeader}`),
    fetch(
      `${base()}/units/status?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(
        valid || ""
      )}&${authHeader}`
    ),
    //fetch(`${api.settings.apiBase}/units/status?scope=${scope}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(valid)}&${authHeader}`),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchUnitsSent(scope) {
  if (!scope) return null;
  const json = await fetchUnits(scope, null, true);

  return {
    items: json.items,
    sent: json.sent,
  };

  for (const id in get(json, "sent.items", {})) {
    const item = get(json, ["items", id]);
    if (item) json.sent.items[id] = json.items[id] || id;
  }

  //log("fetchunitssent=", result);

  return json.sent;

  // each(get(json, "tenants.items", {}), (val, id) => {

  //     const item = get(json, [ "items", val ], val);
  //     if(!item) return;
  //     item.sent = map(get(json, [ "sent", "items", item.id ]), (val, id) => get(json, [ "items", val ], val));

  // });

  // // remove
  // if(!json.sent) delete json.sent;

  // return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchPaymentMetrics(interval, options) {
  let url = `${
    api.settings.apiBase
  }/payments/collected/metrics?viewpoint=${viewpoint()}&interval=${interval}&${authHeader}`;
  if (options.property) {
    url += `&scope=${options.property}`;
  }
  if (options.policies) {
    for (const policy of options.policies) {
      url += `&policy=${policy}`;
    }
  }
  if (options.payments && typeof options.payments == "string") {
    url += `&payments=${options.payments}`;
  } else if (options.payments && Array.isArray(options.payments)) {
    for (const payments of options.payments) {
      url += `&payments=${payments}`;
    }
  }
  if (options.metrics && typeof options.metrics == "string") {
    url += `&metric=${options.metrics}`;
  } else if (options.metrics && Array.isArray(options.metrics)) {
    for (const metric of options.metrics) {
      url += `&metric=${metric}`;
    }
  }
  if (options.times) {
    url += `&times=${options.times}`;
  }
  if (options.datetimes && typeof options.datetimes == "string") {
    url += `&datetimes=${options.datetimes}`;
  } else if (options.datetimes && Array.isArray(options.datetimes)) {
    for (const datetime of options.datetimes) {
      url += `&datetimes=${datetime}`;
    }
  }
  if (options.daytimes && typeof options.daytimes == "string") {
    url += `&daytimes=${options.daytimes}`;
  } else if (options.daytimes && Array.isArray(options.daytimes)) {
    for (const daytime of options.daytimes) {
      url += `&daytimes=${daytime}`;
    }
  }
  if (options.days) {
    url += `&days`;
  }
  if (options.tenants) {
    url += `&tenants=${options.tenants}`;
  }
  if (options.count) {
    url += `&metric=count&count=${options.count}`;
  }
  log(`PAYMENT METRICS URL = `, url);
  const response = await fetch(url, { signal: options.signal });
  const data = await response.json();
  return data;
}

export async function fetchViolationMetrics(interval, options) {
  let url = `${api.settings.apiBase}/violations/issued/metrics?viewpoint=${
    options.viewpoint || viewpoint()
  }&interval=${interval}&${authHeader}`;
  if (options.property) {
    url += `&scope=${options.property}`;
  }
  if (options.policies) {
    for (const policy of options.policies) {
      url += `&policy=${policy}`;
    }
  }
  if (options.times) {
    url += `&times=${options.times}`;
  }
  if (options.datetimes && typeof options.datetimes == "string") {
    url += `&datetimes=${options.datetimes}`;
  } else if (options.datetimes && Array.isArray(options.datetimes)) {
    for (const datetime of options.datetimes) {
      url += `&datetimes=${datetime}`;
    }
  }
  if (options.daytimes) {
    url += `&daytimes=${options.daytimes}`;
  }
  if (options.days) {
    url += `&days`;
  }
  // log(`REPORTS URL ${url}`);
  const response = await fetch(url);
  const data = await response.json();
  return data;
}

export async function fetchAmenityMetrics(interval, options) {
  try {
    let url = `${
      api.settings.apiBase
    }/permits/policies/issue/metrics?&viewpoint=${viewpoint()}&public=true&interval=${interval}`;
    if (options.property) {
      url += `&scope=${options.property}`;
    }
    if (options.policies) {
      for (const policy of options.policies) {
        url += `&policy=${policy}`;
      }
    }
    if (options.times) {
      url += `&times=${options.times}`;
    }
    if (options.datetimes && typeof options.datetimes == "string") {
      url += `&datetimes=${options.datetimes}`;
    } else if (options.datetimes && Array.isArray(options.datetimes)) {
      for (const datetime of options.datetimes) {
        url += `&datetimes=${datetime}`;
      }
    }
    if (options.daytimes && typeof options.daytimes == "string") {
      url += `&daytimes=${options.daytimes}`;
    } else if (options.daytimes && Array.isArray(options.daytimes)) {
      for (const daytime of options.daytimes) {
        url += `&daytimes=${daytime}`;
      }
    }
    if (options.capacity) {
      url += `&capacity`;
    }
    if (options.days) {
      url += `&metrics=days&days`;
    }
    if (options.tenants) {
      url += `&metric=tenants&tenants=${options.tenants}`;
    }
    if (options.count) {
      url += `&metric=count&count=${options.count}`;
    }
    log(`AMENITY METRICS URL ${url}`);
    const response = await fetch(url, { signal: options.signal });
    const data = await response.json();
    return data;
  } catch (error) {
    log(error);
  }
}

export const fetchAndStorePropertyPolicies = debounce(
  async function (property) {
    const json = await Promise.all([
      fetch(
        `${
          api.settings.apiBase
        }/files?for=${property}&type=application/pdf&issued=/&viewpoint=${viewpoint()}&${authHeader}`
      ),
      fetch(
        `${
          api.settings.apiBase
        }/stripe/connect?state=${property}&viewpoint=${viewpoint()}&${authHeader}`
      )
        .then((res) => res.json())
        .then((json) => pick(json, ["stripe"])),
      fetch(
        `${
          api.settings.apiBase
        }/permits/policies/issue?scope=${property}&viewpoint=${viewpoint()}&public=true&admin=true&disabled=true&authcodes=true&versions=true&meters.minimum=true&pricing=policy&${authHeader}`
      ),
      fetch(
        `${
          api.settings.apiBase
        }/properties?viewpoint=${viewpoint()}&property=${property}&${authHeader}`
      ),
      //fetch(`${api.settings.apiBase}/spaces?scope=${id}&viewpoint=${viewpoint()}`)
    ])
      .then((values) =>
        Promise.all(values.map((res) => (res.json ? res.json() : res)))
      )
      //.then(values => (values.map(json => pick(json, "items"))))
      .then((values) => merge({}, ...values));

    // morph stripe
    if (json.stripe) {
      json.items.stripe = json.stripe;
    }

    // morph the attachments to documents
    if (json.attachments)
      json.items.documents = {
        items: get(json, ["attachments", "items", property]),
      };

    each(json, (value, key) => {
      //log(key, value);
      if ("spaces" == key) return;
      if ("units" == key) return;
      if (has(value, "items")) json.items[key] = value.items;
    });

    updateItems(json);

    for (const [key, value] of Object.entries(json)) {
      if (!!value["for"]) updateFor(key, value);
    }
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

export const fetchAndStoreAllProperties = debounce(
  async function () {
    const json = await fetchProperties();

    json.items["allproperties"] = json.properties;
    if (json.billing) json.items["billing"] = json.billing;
    if (json.metrics) json.items["metrics"] = json.metrics;

    updateItems(json);
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

export async function fetchAndStorePermits(ids) {
  if (!ids) return {};
  if (typeof ids === "string") ids = [ids];
  if (!ids || !ids.length) return {};
  const res = await fetch(
    `${api.settings.apiBase}/permits?viewpoint=${viewpoint()}${ids
      .map((id) => "&permit=" + id)
      .join("")}`
  );
  const json = await res.json();
  updateItems(json);
  return json;
}

export async function fetchSearchPermit(serial, vehicle) {
  const res = await fetch(
    `${
      api.settings.apiBase
    }/permits/${serial}?viewpoint=${viewpoint()}&vehicle=${vehicle}&${authHeader}`
  );
  const json = await res.json();

  if (!json.permits || !json.permits.item) return null;

  return json.items[json.permits.item];

  return json;
}

export async function fetchSearchMedia(serial) {
  const res = await fetch(
    `${
      api.settings.apiBase
    }/media/${serial}?viewpoint=${viewpoint()}&valid=${viewpoint()}/&${authHeader}`
  );
  const json = await res.json();

  if (!json.media || !json.media.item) return null;

  var item = get(json, ["media", "items", json.media.item]);

  return item;

  // if(!json.permits || !json.permits.item) return null;

  // return json.items[json.permits.item];

  //return json;
}

export async function fetchPropertyViolations(property, valid) {
  const res = await fetch(
    `${
      api.settings.apiBase
    }/violations/?scope=${property}&viewpoint=${viewpoint()}&issued=${encodeURIComponent(
      valid
    )}&${authHeader}`
  );
  // log(`${api.settings.apiBase}/violations/?scope=${property}&viewpoint=${viewpoint()}&valid=${encodeURIComponent(valid)}&${authHeader}`)
  if (res.ok) {
    const json = await responseJson(res);
    return json;
  }

  return await responseJson(res);
}

export async function fetchSearchViolation(serial, vehicle) {
  const res = await fetch(
    `${
      api.settings.apiBase
    }/violations/${serial}?viewpoint=${viewpoint()}&vehicle=${vehicle}&${authHeader}`
  );
  const json = await res.json();

  if (!json.violations || !json.violations.item) return null;

  return json.items[json.violations.item];

  return json;
}

export const fetchAndStorePermitsValid = debounce(
  async function (property) {
    const json = await Promise.all([
      fetch(
        `${
          api.settings.apiBase
        }/permits?viewpoint=${viewpoint()}&vehicle=false&scope=${property}&valid=${addHours(
          new Date(),
          -2
        ).toISOString()}/&${authHeader}`
      ),
    ])
      .then((values) => Promise.all(values.map((res) => res.json())))
      //.then(values => (values.map(json => pick(json, "items"))))
      .then((values) => merge({}, ...values));

    each(json, (value, key) => {
      //log(key, value);
      if (has(value, "items")) json.items[key] = value.items;
    });

    updateItems(json);
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

export async function fetchAndStoreDocuments(scope) {
  if (!scope) return null;
  let json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/files?for=${scope}&type=application/pdf&issued=/&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  // morph the attachments
  if (json.attachments)
    json.items["attachments"] = {
      items: get(json, ["attachments", scope]),
    };

  json = {
    items: json.items,
  };

  each(json, (value, key) => {
    //log(key, value);
    if (has(value, "items")) json.items[key] = value; //.items;
  });

  updateItems(json);
}

export async function fetchPhotos(scope, valid) {
  if (!scope) return null;
  let json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/files?scope=${scope}&type=image/jpeg&issued=${encodeURIComponent(
        valid
      )}&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;
}

export async function fetchCreateFile(scope, file, data, attempt) {
  //var requested = new Date().toISOString();

  // return Promise.join(api.base(), location, file, function(base, location, file) {
  //     var url = base + "v1/locations/" + location + "/files?ts=" + viewpoint();
  //     // + "&name=" + file.name + "&type=" + file.type + "&length=" + file.size + "&created=" + requested + "&latitude=" + latitude + "&longitude=" + longitude;

  var formData = new FormData();
  formData.append("name", file.name);
  formData.append("type", file.type);
  formData.append("length", file.size);
  formData.append("created", viewpoint());
  formData.append("modified", new Date(file.lastModified).toISOString());
  if (!!data)
    for (const [key, value] of Object.entries(data)) {
      if (!!key && !!value) formData.append(key, value);
    }

  const res = await fetch(
    `${base()}/locations/${
      scope.id || scope
    }/files?ts=${viewpoint()}&viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );

  return await res.json();
  // })
  // .catch(function(error) {
  //     if(!attempt) attempt = 1;
  //     if(attempt <= retry) return Promise.delay(1000 * attempt).then(function() {
  //         return createFile(location, file, data, attempt + 1);
  //     });
  //     return Promise.reject(error); // out of retries
  // })
}

export async function fetchAndStoreSpaces(scope) {
  const json = await fetchSpaces(scope);

  each(json, (value, key) => {
    //log(key, value);
    if (has(value, "items")) json.items[key] = value; //.items;
  });

  updateItems(json);

  //state.update(prev => merge(prev, json.items));
}

export async function fetchPrices(scope) {
  if (!scope) return null;
  const json = await Promise.all([
    fetch(
      `${api.settings.apiBase}/prices?scope=${
        scope.id || scope
      }&viewpoint=${viewpoint()}&valid=${viewpoint(1000 * 60)}/&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map((res) => res.json())))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  return json;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStorePrices(scope) {
  const json = await fetchPrices(scope);

  each(json, (value, key) => {
    //log(key, value);
    if (has(value, "items")) json.items[key] = value; //.items;
  });

  updateItems(json);

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreNotify(scope) {
  const json = await fetchNotify(scope);

  each(json, (value, key) => {
    //log(key, value);
    if (has(value, "items")) json.items[key] = value; //.items;
  });

  updateItems(json);

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreMedia(scope) {
  const json = await fetchMedia(scope);

  each(json, (value, key) => {
    //log(key, value);
    if (has(value, "items")) json.items[key] = value; //.items;
  });

  updateItems(json);

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAndStoreUnits(scope, valid) {
  const json = await fetchUnits(scope, valid);

  each(json, (value, key) => {
    //log(key, value);
    if (has(value, "items")) json.items[key] = value; //.items;
  });

  updateItems(json);

  //state.update(prev => merge(prev, json.items));
}

export async function fetchUserValidForProperty(property) {
  var res = await fetch(
    `${
      api.settings.apiBase
    }/authorizations?principal=current&scope=${property}&viewpoint=${viewpoint()}&valid=${addMinutes(
      new Date(),
      5
    ).toISOString()}/&${authHeader}`
  );

  if (res.ok) {
    const json = await responseJson(res);

    return json;
  }

  return await responseJson(res);
}

export async function fetchUser(emailOrId) {
  var res = await fetch(
    `${
      api.settings.apiBase
    }/users/${emailOrId}?viewpoint=${viewpoint()}&${authHeader}`
  );

  return await responseJson(res);
}

export async function fetchUsers(scope) {
  if (!scope) return {};

  var res = await fetch(
    `${
      api.settings.apiBase
    }/authorizations?scope=${scope}&viewpoint=${viewpoint()}&valid=${addMinutes(
      new Date(),
      5
    ).toISOString()}/&${authHeader}`
  );

  if (res.ok) {
    const json = await responseJson(res);

    return json;
  }

  return await responseJson(res);
}

export async function fetchAndStoreUsers(scope) {
  if (!scope) return {};

  var res = await fetch(
    `${
      api.settings.apiBase
    }/authorizations?scope=${scope}&viewpoint=${viewpoint()}&valid=${addMinutes(
      new Date(),
      5
    ).toISOString()}/&${authHeader}`
  );

  if (res.ok) {
    const json = await responseJson(res);

    storeUsers(json);

    return json;
  }

  return await responseJson(res);
}

function storeUsers(json) {
  json.items["authorizations"] = json.authorizations;

  updateItems(json);
  return json;
}

export async function fetchPolicy(policy) {
  const json = await Promise.all([
    fetch(
      `${
        api.settings.apiBase
      }/permits/policies/issue?id=${policy}&viewpoint=${viewpoint()}&public=true&admin=true&disabled=true&meters.minimum=true&pricing=policy&${authHeader}`
    ),
  ])
    .then((values) =>
      Promise.all(values.map((res) => (res.json ? res.json() : res)))
    )
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));
  return json;
}

export const fetchAndStorePolicy = debounce(
  async function (policy) {
    const json = await fetchPolicy(policy);

    updateItemsAndFor(json);

    return json;
  },
  1000,
  {
    trailing: true,
    leading: true,
  }
);

export async function fetchUpdatePolicy(policy, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/locations/${policy.scope}/permits/issuers/${
      policy.policy
    }?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    // store into data
    updateItemsAndFor(json);

    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchUpdatePermit(permit, formData) {
  if (permit.id) permit = permit.id;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/permits/${permit}?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    //const json = await responseJson(res);

    //await fetchAndStorePermits([ permit ]); // refetch
    //return json;
    //return {};
    const json = await responseJson(res);
    updateItems(json);
    return json;
  }

  return await responseJson(res);
}

export async function fetchDeletePermit(permit, formData) {
  if (permit.id) permit = permit.id;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/permits/${permit}?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);
    updateItems(json);
    return json;
  }

  return await responseJson(res);
}

// export async function fetchUpdatePolicyMeters(policy, formData) {
//     const res = await fetch(`${api.settings.apiBase}/properties/${policy.scope}/policies/${policy.policy}/meters?viewpoint=${viewpoint()}&${authHeader}`, {
//         method:"PATCH",
//         body: formData,
//     });
//     if(res.ok) {
//         const json = await responseJson(res);

//         await fetchAndStorePropertyPolicies(policy.scope); // refetch
//         //return json;
//         return json;
//     }

//     return await responseJson(res);

// }

export async function fetchUpdatePolicyClosed(policy, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${policy.scope}/policies/${
      policy.policy
    }/closed?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    // refetch policy
    await fetchAndStorePolicy(policy.policy);

    //await fetchAndStorePropertyPolicies(policy.scope); // refetch
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchUpdatePolicyValidIntervalMax(policy) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${
      policy.scope
    }/permits/policies/issue/${
      policy.policy
    }?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
      //body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    // refetch policy
    await fetchAndStorePolicy(policy.policy);

    //await fetchAndStorePropertyPolicies(policy.scope); // refetch
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchCreatePolicy(scope, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/locations/${
      scope.id || scope
    }/permits/issuers?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await res.json();

    var created = json.items[json.policies.item];

    // each(pick(json, [ "policies" ]), (value, key) => {
    //     //log(key, value);
    //     if(has(value, "items")) json.items[key] = value.items;
    // });

    updateItems(json); // old style

    //await fetchAndStorePropertyPolicies(scope.id || scope); // refetch
    //return json;
    return created;
  }

  return await responseJson(res);
}

export async function fetchCreatePolicyMeter(policy, formData) {
  const res = await fetch(
    `${
      api.settings.apiBase
    }/permits/policies/issue/meters?viewpoint=${viewpoint()}&scope=${
      policy.scope
    }&subject=${policy.policy}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    //const json = await res.json();

    // refetch policy
    await fetchAndStorePolicy(policy.policy);

    //await fetchAndStorePropertyPolicies(policy.scope); // refetch
    //return json;
    //return created;
  }

  return await responseJson(res);
}

export async function fetchDeletePolicyMeters(policy, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${policy.scope}/policies/${
      policy.policy
    }/meters?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok) {
    // refetch policy
    return await fetchAndStorePolicy(policy.policy);

    //await fetchAndStorePropertyPolicies(policy.scope); // refetch
  }

  return await responseJson(res);
}

export async function fetchCreatePolicyPrice(policy, formData) {
  var json = await fetchCreatePrices(policy.scope, formData);

  //if (res.ok) {
  //const json = await res.json();

  // refetch policy
  await fetchAndStorePolicy(policy.policy);

  //await fetchAndStorePropertyPolicies(policy.scope); // refetch
  //return json;
  //return created;
  //}

  return json;

  //return await responseJson(res);
}

export async function fetchCreatePrice(scope, formData, fetchAndStore = false) {
  const res = await fetch(
    `${api.settings.apiBase}/prices?scope=${
      scope.id || scope
    }&viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok && fetchAndStore) {
    //const json = await res.json();

    await fetchAndStorePrices(scope.id || scope); // refetch
    //return json;
    //return created;
  }

  return await responseJson(res);
}

export async function fetchCreatePrices(
  scope,
  formData,
  fetchAndStore = false
) {
  const res = await fetch(
    `${api.settings.apiBase}/prices?scope=${
      scope.id || scope
    }&viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok && fetchAndStore) {
    //const json = await res.json();

    await fetchAndStorePrices(scope.id || scope); // refetch
    //return json;
    //return created;
  }

  return await responseJson(res);
}

export async function fetchDeletePolicyPrices(policy, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${
      policy.scope
    }/prices?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok) {
    // refetch policy
    return await fetchAndStorePolicy(policy.policy);

    //return await fetchAndStorePropertyPolicies(policy.scope);
  }

  return await responseJson(res);
}

export async function fetchDeletePrices(
  scope,
  formData,
  fetchAndStore = false
) {
  if (![...formData.keys()].length) return {};

  const res = await fetch(
    `${api.settings.apiBase}/prices?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok && fetchAndStore) {
    return await fetchAndStorePrices(scope.id || scope);
  }

  return await responseJson(res);
}

export async function fetchCreateNotify(scope, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/notify?scope=${
      scope.id || scope
    }&viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await res.json();

    updateItems(json); // old style

    await fetchAndStoreNotify(scope.id || scope); // refetch

    return json;
  }

  return await responseJson(res);
}

export async function fetchDeleteNotify(scope, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/notify?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await res.json();

    //updateItems(json); // old style

    await fetchAndStoreNotify(scope.id || scope); // refetch

    return json;
  }

  return await responseJson(res);
}

export async function fetchAndStoreProperties(ids) {
  if (!ids || !ids.length) return {};
  var url =
    typeof ids === "string"
      ? `${
          api.settings.apiBase
        }/properties?viewpoint=${viewpoint()}&property=${ids}&${authHeader}`
      : `${api.settings.apiBase}/properties?viewpoint=${viewpoint()}${ids
          .map((id) => "&property=" + id)
          .join("")}&${authHeader}`;
  const res = await fetch(url);
  const json = await res.json();

  // each(get(json, "properties.items"), id => {
  //     resolveProperty(json.items[id], json.items); // expand the property first
  // })

  updateItems(json);

  return map(get(json, "properties.items"), (id) =>
    resolveProperty(json.items[id], json.items)
  ); // expand the property first
}

export async function fetchUpdateProperty(property, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/properties/${
      property.id
    }?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    await fetchAndStorePropertyPolicies(property.id);
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchUpdateViolations(property, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/locations/${
      property.id
    }/violations?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    await fetchAndStoreProperties(property.id);
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchCreateViolationThreshold(property, formData) {
  const res = await fetch(
    `${api.settings.apiBase}/locations/${
      property.id
    }/violations/thresholds?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    await fetchAndStoreProperties(property.id);
    //return json;
    return json;
  }

  return await responseJson(res);
}

export async function fetchCreateUser(email, role, scope, ...scopes) {
  if (scope) scope = scope.id || scope;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/authorizations?viewpoint=${viewpoint()}&scope=${scope}${scopes.reduce(
      (qs, item) => (qs += `&scope=${item.id || item}`),
      ""
    )}&email=${encodeURIComponent(email)}&role=${role}&${authHeader}`,
    {
      method: "POST",
      //body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    if (scopes.length > 0) return json;

    return await fetchAndStoreUsers(scope);
  }

  return await responseJson(res);
}

export async function fetchDeleteUser(scope, formData) {
  scope = scope.id || scope;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/authorizations?viewpoint=${viewpoint()}&scope=${scope}&${authHeader}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    //storeUsers(json);

    //return json;
    //return json;
    return await fetchAndStoreUsers(scope);
  }

  return await responseJson(res);
}

// export async function fetchCreateUnits(scope, items) {
//   scope = scope.id || scope;

//   log(items);

//   for (const item of items) {
//     // var data = new FormData();
//     // data.append("tenant", item.tenant);
//     var qs = new URLSearchParams(item);

//     let res = await fetch(
//       `${
//         api.settings.apiBase
//       }/locations/${scope}/tenants?viewpoint=${viewpoint()}&${qs.toString()}&${authHeader}`,
//       {
//         method: "POST",
//         //body: data,
//       }
//     );
//   }

//   for (let i = 0; i < items.length; i++) {
//     var data = new FormData();
//     data.append("space", items[i]);
//   }

//   return await fetchAndStoreSpaces(scope);
// }

export async function fetchCreateTenants(scope, items) {
  scope = scope.id || scope;

  await limitedPromiseAll(items, (item) =>
    fetch(
      `${
        api.settings.apiBase
      }/locations/${scope}/tenants?viewpoint=${viewpoint()}&${new URLSearchParams(
        item
      ).toString()}&${authHeader}`,
      { method: "POST" }
    )
  );

  return await fetchAndStoreUnits(scope);
}

export async function fetchCreateMedia(scope, data) {
  scope = scope.id || scope;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/locations/${scope}/media?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: data,
    }
  );

  return await fetchAndStoreMedia(scope);
}

// create spaces from a series of items
export async function fetchCreateSpaces(scope, items) {
  scope = scope.id || scope;

  await limitedPromiseAll(items, (item) =>
    fetch(
      `${
        api.settings.apiBase
      }/locations/${scope}/spaces?viewpoint=${viewpoint()}&${new URLSearchParams(
        item
      ).toString()}&${authHeader}`,
      { method: "POST" }
    )
  );

  return await fetchAndStoreSpaces(scope);
}

export async function fetchRemoveSpaces(scope, ...spaces) {
  scope = scope.id || scope;

  var data = new FormData();
  spaces.forEach((item) => data.append("space", item));

  await fetch(
    `${
      api.settings.apiBase
    }/properties/${scope}/spaces?viewpoint=${viewpoint()}&unassign=true&${formDataToURLSearchParams(
      data
    ).toString()}&${authHeader}`,
    {
      method: "DELETE",
      //body:data
    }
  );

  return await fetchAndStoreSpaces(scope);
}

export async function fetchRemoveUnit(tenant, data) {
  const scope = tenant.scope;
  const id =
    (tenant.subject && tenant.subject.id) ||
    tenant.subject ||
    tenant.id ||
    tenant;

  data.append("unit", id);

  await fetch(
    `${
      api.settings.apiBase
    }/properties/${scope}/units?viewpoint=${viewpoint()}&unassign=true&${formDataToURLSearchParams(
      data
    ).toString()}&${authHeader}`,
    {
      method: "DELETE",
      //body:data
    }
  );

  return await fetchAndStoreUnits(scope);
}

export async function fetchResetTenantCode(tenant, formData) {
  const scope = tenant.scope;
  const id = tenant.id || tenant;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/accounts/${id}/code?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    return await fetchAndStoreUnits(scope);
  }

  return await responseJson(res);
}

export async function fetchResetTenantsCodes(scope, formData) {
  scope = scope.scope || scope.id || scope;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/units/tenants/codes?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    return await fetchAndStoreUnits(scope);
  }

  return await responseJson(res);
}

export async function fetchCreateAuthCodes(scope, formData) {
  scope = scope.scope || scope.id || scope;

  const res = await fetch(
    `${api.settings.apiBase}/codes?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );

  const json = await responseJson(res);

  for (const [key, value] of Object.entries(json)) {
    if (!!value["for"]) updateFor(key, value);
  }

  return json;
}

export async function fetchTransferTenant(tenant, formData) {
  const scope = tenant.scope;
  const id = tenant.id || tenant;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/accounts/${id}/subject?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "PATCH",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    return await fetchAndStoreUnits(scope);
  }

  return await responseJson(res);
}

export async function fetchResetUnit(tenantOrUnit, formData) {
  const scope = tenantOrUnit.scope;
  const id =
    (tenantOrUnit.subject && tenantOrUnit.subject.id) ||
    tenantOrUnit.subject ||
    tenantOrUnit.id ||
    tenantOrUnit;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/units/${id}/tenants?revoke=true&viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    return await fetchAndStoreUnits(scope);
  }

  return await responseJson(res);
}

export async function fetchResetUnits(property, formData) {
  const id = property.id || property;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/locations/${id}/tenants?reset=all&viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    return await fetchAndStoreUnits(id);
  }

  return await responseJson(res);
}

export async function fetchCreateSend(data) {
  let res = await fetch(
    `${
      api.settings.apiBase
    }/send?viewpoint=${viewpoint()}&${formDataToURLSearchParams(
      data
    ).toString()}&${authHeader}`,
    {
      method: "POST",
      //body:data
    }
  );

  return await responseJson(res);
}

export async function fetchDeleteProperty(scope, formData) {
  scope = scope.id || scope;

  const res = await fetch(
    `${
      api.settings.apiBase
    }/properties/${scope}?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "DELETE",
      body: formData,
    }
  );
  if (res.ok) {
    const json = await responseJson(res);

    return json;
  }

  return await responseJson(res);
}

export async function fetchCSV(fileToConvert) {
  var data = new FormData();
  data.append("file", fileToConvert);

  let res = await fetch(
    `${api.settings.apiBase}/csv?viewpoint=${viewpoint()}&${authHeader}`,
    {
      method: "POST",
      body: data,
    }
  );

  if (!res.ok) return null;
  return await res.text();
}

async function responseJson(response) {
  if (!response) return {};
  return response
    .text()
    .then(function (text) {
      if (!text)
        return {
          status: response.status,
        };

      return Promise.resolve(text)
        .then(JSON.parse)
        .catch(function (error) {
          return {
            status: response.status,
            message: text,
          };
        });
    })
    .catch(function (error) {
      return {
        status: response.status,
      };
    });
}

function resolveIssued(item, state) {
  if (!item || !state) return item;

  for (const [key, value] of Object.entries(item)) {
    item[key] = state[value] || value;
  }

  return item;
}

export function resolveViolations(values, items) {
  // values is the list of permits, items is the overall state

  if (!values || !items) return null;
  values = map(
    values,
    (value, key) => items[key] || item[value] || value || key
  );
  if (!values.every((item) => !!item && !!item.id)) return null; // not done loading

  return values
    .filter((item) => item)
    .map((item) =>
      !item
        ? item
        : merge(item, {
            property: resolveProperty(
              items[item.location] || item.location,
              items
            ),
            address: items[item.address] || item.address,
            //policy: items[permit.issued.policy] || items[permit.issued.issuer] || permit.issued.issuer,
            vehicle: items[item.vehicle] || item.vehicle,
            //media: items[permit.media] || permit.media,
            //spaces: (permit.spaces || []).map(i => items[i] || i),
            tenant: items[item.tenant] || item.tenant,
            attachments: Object.entries(
              get(items, ["attachments", "items", item.id], {})
            ).map(([id, item]) => items[id] || item),
            //contact: get(items, [ "contacts", "items", permit.id ], permit.contact),
            //subjects: Object.values(permit.subjects || {}).map(i => items[i] || i),
            // attachments: Object.entries(get(items, [ "attachments", "items", permit.id ], {})).map(([ id, item]) => items[id] || item),
            // validations: Object.values(get(items, [ "validations", "for", permit.id ]) || permit.validations || {}).map(item => {
            //     item = items[item] || item;
            //     if(item.meter) item.meter = items[item.meter] || item.meter;
            //     return item;
            // })
            issued: resolveIssued(item.issued, items),
          })
    );
}

export function resolvePermits(values, items) {
  // values is the list of permits, items is the overall state

  if (!values || !items) return null;
  values = map(
    values,
    (value, key) => items[key] || item[value] || value || key
  );
  if (!values.every((item) => !!item && !!item.id)) return null; // not done loading

  return values
    .filter((permit) => permit)
    .map((permit) =>
      !permit
        ? permit
        : merge(permit, {
            property: resolveProperty(
              items[permit.location] || permit.location,
              items
            ),
            address: items[permit.address] || permit.address,
            policy:
              items[permit.issued.policy] ||
              items[permit.issued.issuer] ||
              permit.issued.issuer,
            vehicle: items[permit.vehicle] || permit.vehicle,
            media: items[permit.media] || permit.media,
            spaces: (permit.spaces || []).map((i) => items[i] || i),
            tenant: items[permit.tenant] || permit.tenant,
            contact: get(
              items,
              ["contacts", "items", permit.id],
              permit.contact
            ),
            subjects: Object.values(permit.subjects || {}).map(
              (i) => items[i] || i
            ),
            attachments: Object.entries(
              get(items, ["attachments", "items", permit.id], {})
            ).map(([id, item]) => items[id] || item),
            validations: Object.values(
              get(items, ["validations", "for", permit.id]) ||
                permit.validations ||
                {}
            ).map((item) => {
              item = items[item] || item;
              if (item.meter) item.meter = items[item.meter] || item.meter;
              return item;
            }),
          })
    );
}

export function resolveAddress(item, items) {
  if (!item) return item;
  item.address = items[item.address] || item.address;
  return item;
}

export function resolveProperty(item, items) {
  if (!item) return item;
  if (typeof item === "string") item = items[item];
  if (item) item.stripe = items.stripe;
  return resolveAddress(item, items);
}

export async function fetchLeasing(property) {
  const leasingFor = leasingManifest[property];
  if (!leasingFor) return {};

  const res = await fetch(leasingFor.url);

  if (!res.ok) return {};
  const json = await res.json();

  if (typeof json == "string") return JSON.parse(json);

  return json;
}

export async function fetchPropertyViolationPolicies(property) {
  const json = await Promise.all([
    fetch(
      `${base()}/violations/policies/issued?scope=${property}&viewpoint=${viewpoint()}&${authHeader}`
    ),
  ])
    .then((values) => Promise.all(values.map(responseJson)))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  //console.log(json.policies.items);

  const meta = json.policies.for?.[property];
  //console.log("meta=", meta);

  for (const [key, value] of Object.entries(meta?.items ?? {})) {
    // console.log("policy=", key, value, json.items[key]);
    meta.items[key] = json.items[value] ?? json.items[key] ?? value;
  }

  return meta;
}
export async function fetchCreatePropertyViolationPolicy(property, data) {
  const json = await Promise.all([
    fetch(
      `${base()}/violations/policies/issued?scope=${property}&viewpoint=${viewpoint()}&${authHeader}`,
      {
        method: "POST",
        body: data,
      }
    ),
  ])
    .then((values) => Promise.all(values.map(responseJson)))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  //console.log(json.policies.items);

  const meta = json.policies.created;

  for (const [key, value] of Object.entries(meta?.items ?? {})) {
    // console.log("policy=", key, value, json.items[key]);
    meta.items[key] = json.items[value] ?? json.items[key] ?? value;
  }

  return meta;
}

export async function fetchInvalidatePropertyViolationPolicies(data) {
  const json = await Promise.all([
    fetch(
      `${base()}/violations/policies/issued?viewpoint=${viewpoint()}&${authHeader}`,
      {
        method: "DELETE",
        body: data,
      }
    ),
  ])
    .then((values) => Promise.all(values.map(responseJson)))
    //.then(values => (values.map(json => pick(json, "items"))))
    .then((values) => merge({}, ...values));

  //console.log(json.policies.items);

  const meta = json.policies.updated;

  for (const [key, value] of Object.entries(meta?.items ?? {})) {
    // console.log("policy=", key, value, json.items[key]);
    meta.items[key] = json.items[value] ?? json.items[key] ?? value;
  }

  return meta;
}
