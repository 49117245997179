<script>
  import { scaleTime, timeFormat } from "d3";
  import Axis from "./Axis.svelte";

  export let data;
  export let startDate;
  export let endDate;
  export let width;
  export let height;
  export let handleClick;

  const margin = { top: 0, right: 30, bottom: 35, left: 20 };
  const innerHeight = height - margin.top - margin.bottom;
  const now = new Date();

  const tickFormat = (d, i) => {
    return i % 2 !== 0 ? "" : timeFormat("%a %d")(d);
  };

  const totalWidth = width * 4 - margin.right - margin.left;
  $: xScale = scaleTime()
    .domain([startDate, endDate])
    .range([0, totalWidth])
    .nice();
</script>

<div class="timeline-container">
  <svg class="timeline" width={totalWidth} {height}>
    <g transform={`translate(${margin.left},${margin.top})`}>
      <Axis {innerHeight} {tickFormat} scale={xScale} />
      {#each data as vio}
        <g
          class="timeline-chart-marker"
          transform={`translate(${xScale(new Date(vio.date))}, 15)`}
          on:click={() => handleClick(vio.id)}
        >
          <svg
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 70 63"
          >
            <path
              d="M67.476 50.25l-25.98-45c-2.888-5-10.104-5-12.991 0l-25.98 45c-2.888 5 .72 11.25 6.494 11.25h51.962c5.773 0 9.382-6.25 6.495-11.25z"
              fill="#fff"
              stroke="#fff"
              stroke-width="3"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M40.197 6c-2.31-4-8.083-4-10.393 0L3.824 51c-2.31 4 .577 9 5.196 9h51.96c4.62 0 7.507-5 5.197-9L40.197 6zm-4.78 45.348c1.155 0 2.189-.398 3.065-1.233.836-.796 1.273-1.791 1.273-2.945 0-1.115-.437-2.11-1.273-2.945a4.655 4.655 0 00-1.5-.98 4.222 4.222 0 00-1.565-.294c-1.153 0-2.188.438-3.064 1.274a4.45 4.45 0 00-.943 1.353 3.928 3.928 0 00-.33 1.592c0 1.154.438 2.149 1.273 2.944.876.836 1.911 1.234 3.064 1.234zm3.264-23.12c.318-1.354.597-2.548.835-3.582.2-.995.32-1.83.32-2.468 0-1.114-.44-2.109-1.314-2.944-.876-.836-1.91-1.234-3.105-1.234-1.233 0-2.268.398-3.103 1.234-.876.835-1.314 1.83-1.314 2.944 0 .597.12 1.433.359 2.587.137.668.29 1.337.447 2.013l.349 1.49c.318 1.273.676 2.904 1.074 4.814.437 1.91.875 4.298 1.313 7.163h1.751l.074-.43c.445-2.638.824-4.882 1.239-6.733.398-1.95.757-3.541 1.075-4.855z"
              fill="#FC9526"
            />
          </svg>
        </g>
      {/each}
      <line
        class="timeline-chart-now"
        x1={xScale(now)}
        x2={xScale(now)}
        y1={margin.top}
        y2={innerHeight}
        fill="none"
        stroke="red"
        stroke-width="2"
      />
    </g>
  </svg>
</div>
