<script>
  import { createEventDispatcher } from "svelte";
  export let item;
  export let selectable = true;
  export let selected = false;

  const dispatch = createEventDispatcher();

  function change(e) {
    selected = e.target.checked;
    dispatch("change", {
      item,
      selected,
    });
  }
</script>

<h1>{item.name}</h1>
<address>
  <span class="city">{item.address.city}</span>
  <span class="state">{item.address.state}</span>
</address>
<h2>{item.management}</h2>
{#if selectable}
  <input type="checkbox" class="select" checked={selected} on:change={change} />
{/if}
