<script>
  import { sortBy } from "../util/sort";
  import { query } from "../util/router";
  import { get, map } from "lodash-es";
  import { units } from "../stores";
  import { format, utcToZonedTime, zonedTimeToUtc, toDate } from "date-fns-tz";
  import {
    addDays,
    startOfDay,
    startOfMonth,
    addMonths,
    startOfWeek,
    addWeeks,
    formatISO,
  } from "date-fns";
  import { minuteNow } from "../stores";
  import Timeline2 from "./Timeline2.svelte";
  import EditPolicy from "../EditPolicy.svelte";

  export let policy;
  let versions;
  let timezone;

  $: if (!versions && policy && policy.versions) versions = policy.versions;
  $: if (policy && policy.timezone != timezone) timezone = policy.timezone;

  //$: if(!timezone && policy && policy.timezone) timezone = policy.timezone;
  $: versionsInterval =
    versions &&
    versions.interval
      .split("/")
      .map((d) => d || addDays(new Date(), 30).toISOString())
      .join("/");
</script>

<aside class="modal">
  <header>
    <h1>Versions</h1>
    <button type="button" on:click={(e) => query("version", null)}>close</button
    >
  </header>

  <!-- {#if versionsInterval}
<Timeline2 interval={versionsInterval} timezone={timezone} now={minuteNow} calendar="P1D">
    {#if versions && versions.items}
    {#each sortBy(Object.entries(versions.items), ([ interval, version]) => interval) as [ interval, version ]}
    <time class="policy version" class:selected={policy && version == policy.version.id} datetime="{interval}" on:click={e => (query("version", version))}></time>
    {/each}
    {/if}
</Timeline2>
{/if} -->
  <fieldset class="modal-controls">
    <button
      type="button"
      disabled={!policy || !policy.version.predecessor}
      on:click={(e) => query("version", policy.version.predecessor)}
      >older</button
    >
    {#if versions}
      <select
        on:change={(e) => {
          query("version", e.target.value);
        }}
        value={policy && policy.version.id}
      >
        {#each sortBy(Object.entries(versions.items), ([interval, version]) => interval, undefined, true) as [interval, version]}
          <option value={version}
            >{[interval.split("/")[1]].map((s) =>
              s
                ? format(
                    utcToZonedTime(s, timezone),
                    "MMM d yyyy h:mm:ss a zzz",
                    { timezone: timezone }
                  )
                : "current"
            )}</option
          >
        {/each}
      </select>
    {/if}
    <button
      type="button"
      disabled={!policy || !policy.version.successor}
      on:click={(e) => query("version", policy.version.successor)}>newer</button
    >
  </fieldset>
  {#if policy}
    <EditPolicy {policy} readonly={true} />
  {:else}
    <figure>Loading&hellip;</figure>
  {/if}
</aside>
