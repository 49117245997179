import { each, debounce, throttle, unset, get } from "lodash-es";
import bbox from "geojson-bbox";
import mapbox from "mapbox-gl";


mapbox.accessToken = "pk.eyJ1IjoicGFya2luZ2Jvc3MiLCJhIjoiY2swY3VheHQyMDE1ejNtbjV4M3RoeTQ5cyJ9.toumXl_aMY5GgH45lZyiuA";

const key = {};
const draw = window.MapboxDraw;

export { mapbox, key, draw as MapboxDraw };

const layers = [
    // {
    //     "id": "selected-circle",
    //     "type": "circle",
    //     "source": "property",
    //     "filter": [
    //         "all",
    //         ["==", ["geometry-type"], "Point" ],
    //         [ "has", "selected" ],
    //     ],
    //     "minzoom":18,
    //     "paint": {
    //       "circle-radius": {
    //         stops: [
    //           [5, 1],
    //           [15, 17]
    //         ],
    //         base: 2
    //       },
    //       "circle-opacity": 0,
    //         "circle-stroke-width": 2,
    //         "circle-stroke-color": "red",
    //     },
    // },
    {
        "id": "selectable",
        "type": "fill",
        "source": "property",
        "filter": [ 
            "all",
            ["has", "ref"],
            //["==", ["get", "permittable"], "true" ],
            ["==", ["geometry-type"], "Polygon" ],
            ["==", [ "get", "amenity" ], "parking_space" ], // match parking spaces
        ],
        "minzoom":19,
        // "layout":{
        //     "visibility": "none",
        // },
        "paint": {
            "fill-color": "red",
            "fill-opacity":0,
        }
    },
    {
        "id": "charged",
        "type": "fill",
        "source": "property",
        "filter": [ 
            "all",
            ["has", "ref"],
            //["==", ["get", "permittable"], "true" ],
            ["==", ["geometry-type"], "Polygon" ],
            ["==", [ "get", "amenity" ], "parking_space" ], // match parking spaces
        ],
        "minzoom":19,
        // "layout":{
        //     "visibility": "none",
        // },
        "paint": {
            "fill-color": "red",
            "fill-opacity":0,
        }
    },
    {
        "id": "charge-highlight",
        "type": "line",
        "source": "property",
        "filter": [
            "all",
            ["==", ["geometry-type"], "Polygon" ],
            [ "has", "charge" ],
            ["==", [ "get", "amenity" ], "parking_space" ],
        ],
        "minzoom":19,
        "paint": {
            "line-color": "black",
            // "line-dasharray": [
            //     1,
            //     2
            // ],
            "line-width": 3,
            "line-offset":-4,
            "line-opacity":[
                "case",
                ["boolean", ["feature-state", "hover"], false],
                1,
                0.1
            ]
        },
    },
    // {
    //     "id": "selected-outline",
    //     "type": "line",
    //     "source": "property",
    //     "filter": [
    //         "all",
    //         ["==", ["geometry-type"], "Polygon" ],
    //         [ "has", "highlight" ],
    //     ],
    //     "layout": {
    //         "visibility": "none"
    //     },
    //     "minzoom":17,
    //     "paint": {
    //         "line-color": "red",
    //         // "line-dasharray": [
    //         //     1,
    //         //     2
    //         // ],
    //         "line-width": 2,
    //         "line-offset":-2,
    //     }
    // },
    // {
    //     "index":1,
    //     "id": "road2",
    //     "type": "line",
    //     "source": "esri",
    //     "source-layer": "Road",
    //     "filter": [ "all", 
    //         //["==", ["geometry-type"], "Polygon" ], 
    //         //[ "has", "_name_local" ], 
    //         [ "<=", [ "get", "_symbol" ], 7 ]
    //     ],
    //     "layout": {
    //         "line-cap": "butt",
    //         "line-join": "miter"
    //     },
    //     "paint": {
    //         "line-color": "#F7F6F2",
    //         //"line-opacity": 0.5,
    //         "line-width": 10
    //     }
    // },
    // {
    //     "index":2,
    //     "layout": {
    //         "text-field": "{_name_local}",
    //         "text-font": [
    //             "Roboto Regular",
    //             "Arial Unicode MS Regular"
    //         ],
    //         "text-letter-spacing": 0.25,
    //         "text-transform": "uppercase",
    //         "symbol-placement": "line-center",
    //         "text-size": [
    //         "interpolate",
    //         [
    //             "linear"
    //         ],
    //         [
    //             "zoom"
    //         ],
    //             17,
    //             10,
    //             20,
    //             48
    //         ]
    //     },
    //     // "filter": [ "all", 
    //     //     //["==", ["geometry-type"], "Polygon" ], 
    //     //     //[ "has", "_name_local" ], 
    //     //     [ "<=", [ "get", "_label_class" ], 4 ]
    //     // ],
    //     // "filter": [
    //     //     "all",
    //     //     [
    //     //         "match",
    //     //         [
    //     //             "geometry-type"
    //     //         ],
    //     //         [
    //     //             "LineString"
    //     //         ],
    //     //         true,
    //     //         false
    //     //     ],
    //     //     [
    //     //         "match",
    //     //         [
    //     //             "get",
    //     //             "indoor"
    //     //         ],
    //     //         [
    //     //             "ramp"
    //     //         ],
    //     //         true,
    //     //         false
    //     //     ]
    //     // ],
    //     "type": "symbol",
    //     "source": "esri",
    //     "source-layer": "Road/label",
    //     "id": "road",
    //     "paint": {
    //         "text-opacity": [ "interpolate", [ "linear" ], [ "zoom" ], 12, 0, 16, 0.5 ],
    //         "text-color": "#000",
    //         //"text-opacity": 0.5,
    //     },
    // },
    // {
    //     "id": "unit-3d",
    //     "type": "fill-extrusion",
    //     "paint": {
    //         "fill-extrusion-color": "red",
    //         "fill-extrusion-opacity": 0.25,
    //         "fill-extrusion-base": [ "*", [ "-", [ "get", "level" ], 1 ], 3 ],
    //         "fill-extrusion-height": [ "*", [ "-", [ "get", "level" ], 0 ], 3 ]
    //     },
    //     "layout": {
    //         "visibility": "none"
    //     },
    //     "source": "property",
    //     "filter": [
    //         "all",
    //         [ "has", "addr:unit" ],
    //         [ "has", "highlight" ],
    //         [ "has", "level" ],
    //     ],
    // },
    // {
    // 	"id": "building-3d-new",
    //     "type": "fill-extrusion",
    //     "paint": {
    //         "fill-extrusion-color":[
    //             "case",
    //             [
    //                 "has",
    //                 "amenity"
    //             ],
    //             "#fff",
    //             "#E8E5DD"
    //         ],
    //         "fill-extrusion-height": [
    //             "case",
    //             [
    //                 "has",
    //                 "amenity"
    //             ],
    //             3,
    //             9
    //         ],
    //         "fill-extrusion-opacity": 0.75
    //     },
    //     "source": "property",
    //     "filter": [
    //         "all",
    //         //[ "has", "amenity" ],
    //         [ "!", [ "has", "amenity" ] ],
    //         [ "has", "building" ]
    //     ],
    //     "layout": {
    //         "visibility": "visible"
    //     }
    // },
    // {
    //     "layout": {},
    //     "filter": [
    //         "all",
    //         [
    //             "has",
    //             "level"
    //         ],
    //         [
    //             "match",
    //             [
    //                 "get",
    //                 "indoor"
    //             ],
    //             [
    //                 "level"
    //             ],
    //             true,
    //             false
    //         ]
    //     ],
    //     "type": "fill-extrusion",
    //     "source": "property",
    //     "id": "building-level-3d-new",
    //     "paint": {
    //         "fill-extrusion-color": "#000",
    //         "fill-extrusion-opacity": 0.2,
    //         "fill-extrusion-base": [ "*", [ "-", [ "get", "level" ], 1 ], 3 ],
    //         "fill-extrusion-height": [ "*", [ "-", [ "get", "level" ], 0 ], 3 ]
    //     },
    //     "layout": {
    //         "visibility": "none"
    //     }
    // },
    // {
    //     "minzoom": 16,
    //     "layout": {
    //       "text-field": [
    //         "match",
    //         [
    //           "get",
    //           "name"
    //         ],
    //         [
    //           "maintenance"
    //         ],
    //         [
    //           "to-string",
    //           [
    //             "get",
    //             "name"
    //           ]
    //         ],
    //         [
    //           "to-string",
    //           [
    //             "get",
    //             "name"
    //           ]
    //         ]
    //       ],
    //       "text-font": [
    //         "Roboto Bold",
    //         "Arial Unicode MS Regular"
    //       ],
    //       "text-transform": "uppercase",
    //       "text-size": [
    //         "interpolate",
    //         [
    //           "linear"
    //         ],
    //         [
    //           "zoom"
    //         ],
    //         16,
    //         0,
    //         20,
    //         20
    //       ],
    //       "icon-image": [
    //         "match",
    //         [
    //           "get",
    //           "name"
    //         ],
    //         [
    //           "maintenance"
    //         ],
    //         "maintenance-icon-2",
    //         ""
    //       ],
    //       "icon-size": [
    //         "interpolate",
    //         [
    //           "linear"
    //         ],
    //         [
    //           "zoom"
    //         ],
    //         17,
    //         0,
    //         20,
    //         0.4
    //       ]
    //     },
    //     "filter": [
    //       "all",
    //       [
    //         "match",
    //         [
    //           "geometry-type"
    //         ],
    //         [
    //           "Point"
    //         ],
    //         true,
    //         false
    //       ],
    //       [
    //         "has",
    //         "name"
    //       ],
    //       [
    //         "match",
    //         [
    //           "get",
    //           "indoor"
    //         ],
    //         [
    //           "room",
    //           "yes"
    //         ],
    //         true,
    //         false
    //       ]
    //     ],
    //     "type": "symbol",
    //     "source": "property",
    //     "id": "room-label-new",
    //     "paint": {
    //       "icon-opacity": 0.3,
    //       "text-color": "hsla(0, 0%, 0%, 0.3)",
    //       "text-halo-color": "#fff",
    //       "text-halo-width": 1
    //     },
    //   },
        
];

function rewriteStyle(style) {
    //var style = await loadStyle;

    //console.log("style=", style);

    // check to see if mapbox styles loaded
    let mapbox = false;
    each(style.sources, function (source, id) {
        if (!!source.url && source.url.includes("mapbox.")) mapbox = true;
        if (mapbox && !!source.url) source.url = source.url.replace(/parkingboss\.[a-z0-9_-]+,?/gi, "");//.replace(/,$/, "");
    });
    
    if(!mapbox) style.sources = {};

    each(style.layers, function (layer, i) {
        if (layer["source-layer"] !== "arterra" && layer["source-layer"] !== "property")
            return;
        layer.source = "property";
        unset(layer, "source-layer");

        //if(layer.id === "community-logo") layer.layout.visibility = "none";

        //console.log(layer);

    });

    for(const layer of layers) {
        //layer.source = "property";
        if(layer.index >= 0) {
            style.layers.splice(layer.index, 0, layer);
            //delete layer.index;
            continue;
        }
        style.layers.push(layer);
    }

    // each(layers, function (layer, i) {
        
    // });
    

    style.sources["property"] = {
        "type": "geojson",
        "buffer":256,
        "attribution":"Parking Boss",
        //"data": await loadPropertyGeoJSON,
        "data":{
            "type":"FeatureCollection",
            "features":[],
        }
    };
    style.sources["esri"] = {
        "type": "vector",
        "tiles": ["https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/tile/{z}/{y}/{x}.pbf"]
    };
    return style;
}

export function maxBoundsFromGeoJSON(map, geojson, padding) {
    if(!geojson || !geojson.features || !geojson.features.length) return;

    var bounds = bbox(geojson);
    // map.fitBounds([[ bounds[0], bounds[1] ], [ bounds[2], bounds[3] ]], {
    //     padding: padding || 0,
    //     animate:false,
    // });
    // var scaled = bbox(transformScale(bboxPolygon(bounds), 3));
    // //console.log("scaled", scaled);
    // map.setMaxBounds([[ scaled[0], scaled[1] ], [ scaled[2], scaled[3] ]])
}

export function boundsFromGeoJSON(map, geojson, padding) {

    if(!geojson || !geojson.features || !geojson.features.length) return;

    // console.log("bounds=", geojson.bbox);
    var bounds = geojson.bbox || bbox(geojson);
    map.fitBounds([[ bounds[0], bounds[1] ], [ bounds[2], bounds[3] ]], {
        padding: padding || 0,
        animate:false,
    });
    //var scaled = bbox(transformScale(bboxPolygon(bounds), 3));
    //console.log("scaled", scaled);
    //map.setMaxBounds([[ scaled[0], scaled[1] ], [ scaled[2], scaled[3] ]])
}

export function boundsFromBbox(map, bounds, padding) {
    map.fitBounds([[ bounds[0], bounds[1] ], [ bounds[2], bounds[3] ]], {
        padding: padding || 0,
        animate:false,
    });
    // var scaled = bbox(transformScale(bboxPolygon(bounds), 3));
    // //console.log("scaled", scaled);
    // map.setMaxBounds([[ scaled[0], scaled[1] ], [ scaled[2], scaled[3] ]])
}

// Copied code from MapBoxGL's Camera#fitBounds method
// Adding in rotation logic
export function fitBoundsRotated(map, geojson, padding) {
    let options = {
        padding: {
            top: padding || 0,
            bottom: padding || 0,
            right: padding || 0,
            left: padding || 0
        },
        offset: [0, 0],
        maxZoom: map.transform.maxZoom
    };

    let bounds = geojson.bbox || bbox(geojson);
    bounds = mapbox.LngLatBounds.convert(bounds);

    // we separate the passed padding option into two parts, the part that does not affect the map's center
    // (lateral and vertical padding), and the part that does (paddingOffset). We add the padding offset
    // to the options `offset` object where it can alter the map's center in the subsequent calls to
    // `easeTo` and `flyTo`.
    const paddingOffset = [options.padding.left - options.padding.right, options.padding.top - options.padding.bottom],
        lateralPadding = Math.min(options.padding.right, options.padding.left),
        verticalPadding = Math.min(options.padding.top, options.padding.bottom);
    options.offset = [options.offset[0] + paddingOffset[0], options.offset[1] + paddingOffset[1]];

    options.bearing = options.bearing || map.getBearing();

    const offset = mapbox.Point.convert(options.offset),
        tr = map.transform,
        nw = tr.project(bounds.getNorthWest()),
        se = tr.project(bounds.getSouthEast()),
        size = se.sub(nw);

        /** START CUSTOM ROTATION HACK **/
        // Now using a "cropped rectangle" rotation method
        // https://stackoverflow.com/questions/33866535/how-to-scale-a-rotated-rectangle-to-always-fit-another-rectangle
        // W = w·|cos φ| + h·|sin φ|
        // H = w·|sin φ| + h·|cos φ|
        const theta = options.bearing * (Math.PI/180),
            W = size.x * Math.abs( Math.cos(theta) ) + size.y * Math.abs( Math.sin(theta) ),
            H = size.x * Math.abs( Math.sin(theta) ) + size.y * Math.abs( Math.cos(theta) ),
            rotatedSize = {x: W, y: H}
        /** END CUSTOM ROTATION HACK **/

    let scaleX = (tr.width - (lateralPadding) * 2 - Math.abs(offset.x) * 2) / rotatedSize.x;
    let scaleY = (tr.height - (verticalPadding) * 2 - Math.abs(offset.y) * 2) / rotatedSize.y;

    if (scaleY < 0) scaleY = 0;
    if (scaleX < 0) scaleX = 0;

    options.center = tr.unproject(nw.add(se).div(2));
    options.zoom = Math.min(tr.scaleZoom(tr.scale * Math.min(scaleX, scaleY)), options.maxZoom);

    return options.linear ?
        map.easeTo(options) :
        map.flyTo(options);
}


export function updateStyle(map, style, data) {
    if(!style) return;

    if (style.hasOwnProperty('styleUrl')) {
        map.setStyle(style.styleUrl)
    } else {
        if(style.sources && data) each(data, function(value, id) {
            var source = style.sources[id];
            if(!!source && !!source.data) source.data = value;
        });
    
        map.setStyle(style);
    
        if(style.images) {
            for(const [ id, item ] of Object.entries(style.images)) {
                var img = new Image(item.width, item.height);
                img.onload = () => map.addImage(id, img);
                img.src = item.src;
                //map.addImage(id, img);
                console.log("image=",img);
            }
        }
    } 
}


export function updateData(map, data) {
    if(!data) return;

    each(data, function(value, id) {
        var source = map.getSource(id);
        // value.minzoom = 10;
        // value.tolerance = 0;
        // console.log("source", value);
        if(!!source) {
            source.setData(value);
        } else {
            map.addSource(id, {
                type:"geojson",
                data:value,
            });
        }

        source = map.getSource(id);
        //boundsFromGeoJSON(map, value);
    });

}

export async function getStyle(id) {
    if(id && id.indexOf("http") === 0) return id;
    return rewriteStyle(await (fetch(`https://api.mapbox.com/styles/v1/${id.replace('.','/')}?access_token=${mapbox.accessToken}`).then(res => res.json())));
}

export function mapBBox(mapUI) {
    return mapUI.getBounds().toArray().flat();
}

export const tileSources = {
    "mapbox-satellite-tiles": {
        "type": "raster",
        "url": "mapbox://mapbox.satellite",
        "tileSize": 256
    },
    "osm-tiles": {
        "type": "raster",
        "tiles": [
            "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
            "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
            "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
        ],
        "tileSize": 256,
        "attribution":'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
    }
}

export const tileLayers = {
    "mapbox-satellite-tiles": {
        "id": "mapbox-satellite-tiles",
        "type": "raster",
        "source":"mapbox-satellite-tiles"
    },
    "osm-tiles": {
        "id": "osm-tiles",
        "type": "raster",
        "source":"osm-tiles"
    }
}