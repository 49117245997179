<script>
  import { minuteNow } from "../stores";
  import { sortBy } from "../util/sort";
  import { query } from "../util/router";
  import Timeline2 from "./Timeline2.svelte";
  import TimelineData from "./TimelineData.svelte";
  import TimelineRefs from "./TimelineRefs.svelte";

  export let permits = null;
  export let violations = null;
  export let property = null;
  export let valid = null;

  function groupByVehicle() {
    var grouped = {};
    var vehicles = {};

    for (const items of arguments) {
      if (!items) continue;
      for (const item of Object.values(items)) {
        if (!item.vehicle) continue;
        vehicles[item.vehicle.id || item.vehicle] = item.vehicle;
        (grouped[item.vehicle.id || item.vehicle] =
          grouped[item.vehicle.id || item.vehicle] || []).push(item);
      }
    }
    // if(permits) for(const item of Object.values(permits)) {

    //     if(!item.vehicle) continue;
    //     vehicles[item.vehicle.id || item.vehicle] = item.vehicle;
    //     (grouped[item.vehicle.id || item.vehicle] = grouped[item.vehicle.id || item.vehicle] || []).push(item);

    // }
    // if(violations) for(const item of Object.values(violations)) {

    //     if(!item.vehicle) continue;
    //     vehicles[item.vehicle.id || item.vehicle] = item.vehicle;
    //     (grouped[item.vehicle.id || item.vehicle] = grouped[item.vehicle.id || item.vehicle] || []).push(item);

    // }
    return sortBy(
      Object.entries(grouped).map(([vehicle, items]) => [
        vehicles[vehicle] || vehicle,
        items,
      ]),
      ([vehicle]) => vehicle.display
    );
  }

  async function loaded() {
    return groupByVehicle(
      ...(await Promise.all(arguments)).map((i) => i.items)
    );
  }
</script>

{#await loaded(permits, violations)}
  <figure>Loading&hellip;</figure>
{:then grouped}
  <figure class="timeline">
    <Timeline2
      interval={valid}
      now={minuteNow}
      refs={["P1M", "P1D"]}
      timezone={property && property.timezone}
    >
      <ul>
        {#each grouped as [vehicle, items]}
          <li class="vehicle">
            <h1>
              <data class="vehicle id" value={vehicle.id || vehicle}
                >{vehicle.display || vehicle}</data
              >
            </h1>
            <TimelineRefs
              interval={valid}
              now={minuteNow}
              refs={["P1M", "P1D"]}
              timezone={property && property.timezone}
            />
            <TimelineData
              interval={valid}
              scales={["P1M", "P1D"]}
              timezone={property && property.timezone}
            >
              {#each items as item}
                <time
                  on:click={(e) => query(item.type, item.id)}
                  class={item.type}
                  datetime={item.type == "violation"
                    ? item.issued.datetime
                    : item.valid.interval}
                />
              {/each}
            </TimelineData>
            <!-- <time class="data vehicle permits" datetime="{valid}">
            
                    

            </time> -->
          </li>
        {/each}
      </ul>
    </Timeline2>
  </figure>
{/await}
