<script lang="ts">
  import FormActions from "./FormActions.svelte";
  import Modal from "./Modal.svelte";
  import { state } from "./state";

  export let open = false;

  let loading = false;
  let name = "";

  async function handle_submit() {
    loading = true;
    state.send("CREATE_PROVIDER", { name });
    name = "";
    loading = false;
    open = false;
  }
</script>

<Modal bind:open title="Create Provider">
  <form on:submit|preventDefault={handle_submit}>
    <fieldset>
      <ul>
        <li>
          <label for="provider-name">Name</label>
          <input
            autofocus
            type="text"
            name="name"
            id="provider-name"
            bind:value={name}
            disabled={loading}
          />
        </li>
      </ul>
    </fieldset>
    <FormActions bind:loading on:cancel={() => (open = false)} />
  </form>
</Modal>
