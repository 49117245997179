<script>
  import { createEventDispatcher, onMount } from "svelte";
  import { debounce, uniqBy } from "lodash-es";
  import { query } from "../util/router";
  import MiniSearch from "minisearch";
  import { orderBy } from "lodash-es";
  import { search } from "../stores";

  export let properties;
  export let showList;
  export let results = null;
  export let onSelect;
  export let isDefaultEmpty = false;
  let selected;

  const dispatch = createEventDispatcher();

  const handleSearch = debounce(function (e) {
    e.preventDefault();
    query(e.target.name, e.target.value);
  }, 150);

  const handleSelect = debounce(function (e) {
    onSelect && onSelect(e.target.value);
    dispatch("select", e.target.value);
  }, 100);

  const miniSearch = new MiniSearch({
    fields: ["name", "address.city", "address.state", "management", "format"],
    searchOptions: {
      prefix: true,
      boost: { name: 2 },
    },
    storeFields: ["name"],
    extractField: (document, fieldName) =>
      fieldName.split(".").reduce((doc, key) => doc && doc[key], document),
  });

  $: if (properties) miniSearch.addAll(properties);
  $: propertiesById = (properties || []).reduce((map, item) => {
    return (map[item.id] = item) && map;
  }, {});

  $: searched =
    properties &&
    $search &&
    uniqBy(
      orderBy(miniSearch.search($search), ["score", "name"], ["desc", "asc"]),
      "id"
    );
  $: results =
    !$search && isDefaultEmpty
      ? []
      : (searched && searched.map((result) => propertiesById[result.id])) ||
        properties ||
        [];
  $: results.length && dispatch("search", results);
  // $: index = properties && new Searcher(Object.values(properties), {
  //     threshold:0.8,
  //     keySelector:(item) => [

  //     ].filter(i => !!i)
  // });
</script>

<form>
  <fieldset class="properties-search-container">
    <ul on:input={handleSearch}>
      <li>
        <label for="properties-search">Filter</label>
        <input
          list="properties-search-list"
          id="properties-search"
          type="text"
          name="search"
          value={$search || ""}
          autocomplete={showList ? "off" : "on"}
        />
      </li>
    </ul>
    {#if showList && properties && results.length < properties.length}
      <ul>
        <li>
          <label for="properties-search-list">Select</label>
          <select
            name="search"
            bind:value={selected}
            on:change={handleSelect}
            id="properties-search-list"
          >
            <option selected disabled value="">-- Select a Property --</option>
            {#each results as result}
              <option value={result.id}
                >{result.name} - {result.address.city}, {result.address
                  .state}</option
              >
            {/each}
          </select>
        </li>
      </ul>
    {/if}
  </fieldset>
</form>
