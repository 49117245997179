<script>
  import { select, axisBottom, timeDay, timeHour } from "d3";
  export let innerHeight;
  export let scale;
  export let tickFormat;
  let transform;
  let g;

  $: {
    select(g).selectAll("*").remove();
    let axis = axisBottom(scale)
      .ticks(timeHour.every(12))
      .tickFormat(tickFormat);

    transform = `translate(0, ${innerHeight})`;
    select(g).call(axis);
  }
</script>

<g class="axis" bind:this={g} {transform} />
