<script>
  import { format, utcToZonedTime } from "date-fns-tz";
  import { query } from "$utils/router";
  import Attachments from "./Attachments.svelte";

  export let violation;
</script>

<aside class="details">
  <header>
    <h1>Violation Details</h1>
    <button type="button" on:click={(e) => query("violation", null)}
      >close</button
    >
  </header>
  {#if violation}
    <table class="violation-details">
      <tbody>
        <tr>
          <th>Title</th>
          <td>{violation.title}</td>
        </tr>
        <tr>
          <th>Serial</th>
          <td>{violation.serial}</td>
        </tr>
        <tr>
          <th>Timezone</th>
          <td>{violation.timezone}</td>
        </tr>
        <tr>
          <th>Issued At</th>
          <td>
            <time datetime={violation.issued.datetime || violation.issued}
              >{format(
                utcToZonedTime(
                  violation.issued.datetime || violation.issued,
                  violation.timezone
                ),
                "h:mm a EE MMM d yyyy zzz",
                { timeZone: violation.timezone }
              )}</time
            >
          </td>
        </tr>
        {#if violation.issued.user}
          <tr>
            <th>Issued By</th>
            <td>
              <a
                href="mailto:{violation.issued.user.email}"
                target="_blank"
                rel="noreferrer">{violation.issued.user.display}</a
              >
            </td>
          </tr>
        {/if}
        {#if violation.issued.reason}
          <tr>
            <th>Reason</th>
            <td>
              {violation.issued.reason}
            </td>
          </tr>
        {/if}
        {#if violation.notice}
          <tr>
            <th>Notice</th>
            <td>
              <data class="notice id" value={violation.notice.id}
                >{violation.notice.display}</data
              >
            </td>
          </tr>
        {/if}
        {#if violation.vehicle}
          <tr>
            <th>Vehicle</th>
            <td>
              <data class="vehicle id" value={violation.vehicle.id}
                >{violation.vehicle.display ||
                  violation.title ||
                  violation.name}</data
              >
            </td>
          </tr>
        {/if}
        <Attachments
          attachments={violation.attachments}
          timezone={violation.timezone}
        />
        {#if violation.url}
          <tr>
            <th>Url</th>
            <td>
              <a href={violation.url} target="_blank" rel="noreferrer"
                >{violation.url}</a
              >
            </td>
          </tr>
        {/if}
      </tbody>
    </table>
  {:else}
    <figure>Loading&hellip;</figure>
  {/if}
</aside>
