<script>
  import NewNotifications from "./NewNotifications.svelte";
  import { fetchDeleteNotify as update } from "../api";
  import { get, filter } from "lodash-es";

  export let property;
  export let items;

  let submittable = false;
  let submitting = false;

  let remove = {};
  $: if (filter(remove).length > 0) submittable = true;

  function change(e) {
    submittable = filter(remove).length > 0;
  }

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    await update(property.id, formData);

    submitting = submittable = false;
    remove = {};
  }
</script>

{#if property}
  <NewNotifications {property} />
  <form on:submit={submit} on:change={change}>
    <fieldset>
      <ul class="remove" data-count={get(items, "length", "")}>
        {#if items}
          {#each items as item}
            <li>
              {#if item.scope === property.id}
                <input
                  type="checkbox"
                  name="id"
                  value={item.id}
                  checked={!!remove[item.id]}
                  on:change={(e) => (remove[e.target.value] = e.target.checked)}
                />
              {/if}
              <h1>{(item.user ? item.user.name : item.email) || item.to}</h1>
              <!-- <pre>{JSON.stringify(item, null, 4)}</pre> -->
              <dl>
                <dt>Trigger</dt>
                {#if item.all}
                  <dd>all events</dd>
                {:else}
                  <dd>customized</dd>
                  <dt>Permits</dt>
                  <dd>
                    {item.permits.policy
                      ? item.permits.policy.title
                      : "all policies"}
                  </dd>
                  {#if Object.values(item.permits)
                    .concat(Object.values(item.permits.valid ?? {}))
                    .find((i) => i === true)}
                    {#if item.permits.all}<dd>all events</dd>{:else}
                      {#each [["issued", item.permits.issued], ["expiring", item.permits.valid.ending], ["expired", item.permits.valid.ended]].filter(([name, value]) => value) as [name]}
                        <dd>{name}</dd>
                      {:else}
                        <dd>--</dd>
                      {/each}
                    {/if}
                  {:else}
                    <dt>Temp Permits</dt>
                    {#each [["issued", item.permits.temporary.issued], ["expiring", item.permits.temporary.expiring], ["expired", item.permits.temporary.expired]].filter(([name, value]) => value) as [name]}
                      <dd>{name}</dd>
                    {:else}
                      <dd>--</dd>
                    {/each}
                    <dt>Exempt Permits</dt>
                    {#each [["issued", item.permits.temporary.exempt.issued], ["expiring", item.permits.temporary.exempt.expiring], ["expired", item.permits.temporary.exempt.expired]].filter(([name, value]) => value) as [name]}
                      <dd>{name}</dd>
                    {:else}
                      <dd>--</dd>
                    {/each}
                    <dt>Assigned Permits</dt>
                    {#each [["issued", item.permits.continuous.issued], ["expiring", item.permits.continuous.expiring], ["expired", item.permits.continuous.expired]].filter(([name, value]) => value) as [name]}
                      <dd>{name}</dd>
                    {:else}
                      <dd>--</dd>
                    {/each}
                  {/if}
                  <dt>Violations</dt>
                  {#if item.violations.all}<dd>all events</dd>{:else}
                    {#each [["recorded", item.violations.issued], ["threshold", item.violations.exception]].filter(([name, value]) => value) as [name]}
                      <dd>{name}</dd>
                    {:else}
                      <dd>--</dd>
                    {/each}
                  {/if}
                {/if}
              </dl>
              <!-- <dl>
                        {#each Object.entries(item) as [ key, value ]}
                            <dt>{key}</dt>
                            <dd>{value}</dd>
                        {/each}
                    </dl> -->
            </li>
          {/each}
        {/if}
      </ul>
    </fieldset>
    {#if submitting || submittable}
      <footer>
        <button type="submit" disabled={!submittable || submitting}
          >{submitting ? "Saving" : "Remove"}</button
        >
      </footer>
    {/if}
  </form>
{/if}
