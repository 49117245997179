<script>
  import { get } from "lodash-es";
  import { fetchUpdateProperty as update } from "../api";

  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing)) editing = { ...property };

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    const res = await update(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

{#if editing}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    class="edit property"
  >
    <header>
      <h1>Payment Settings</h1>
    </header>

    <fieldset>
      <ul>
        <li>
          <label for="property-stripe">Stripe</label>
          <select
            id="property-stripe"
            disabled
            value={!!get(editing, "stripe.account", "") + ""}
          >
            <option value="true">customer account</option>
            <option value="false">Parking Boss account</option>
          </select>
        </li>
        {#if !get(editing, "stripe.account", false)}
          <li>
            <label>Connect</label>
            <a
              href={`https://services.communityboss.app/stripe?state=${editing.id}`}
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
        {/if}
      </ul>
    </fieldset>
    <fieldset>
      <p>Transaction fees:</p>
      <ul>
        <li>
          <label for="property-payments-permits">Permits</label>
          <input
            type="number"
            disabled
            value={get(editing, "stripe.fees.permit", 0) * 100}
          />
        </li>
        <li>
          <label for="property-payments-violations">Violations</label>
          <input
            type="number"
            disabled
            value={get(editing, "stripe.fees.fine", 0) * 100}
          />
        </li>
        <li>
          <label for="property-payments-gateway">Stripe Fees</label>
          <select
            id="property-payments-gateway"
            disabled
            value={get(editing, "stripe.fees.gateway", true) + ""}
          >
            <option value="true">customer pays</option>
            <option value="false">Parking Boss pays</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <!-- <footer>
        <button type="submit" disabled={!submittable || submitting}>{ submitting ? "Saving" : "Save" }</button>
    </footer> -->
  </form>
{/if}
