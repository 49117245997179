<script lang="ts">
  import FormActions from "./FormActions.svelte";
  import Modal from "./Modal.svelte";
  import { state } from "./state";

  export let provider_id: string;
  export let open = false;

  let loading = false;
  let category = "";
  let name = "";

  async function handle_submit() {
    loading = true;
    state.send("CREATE_SERVICE", { provider_id, category, name });
    // TODO: handle error
    provider_id = "";
    category = "";
    name = "";
    loading = false;
    open = false;
  }
</script>

<Modal bind:open title="Create Service">
  <form on:submit|preventDefault={handle_submit}>
    <fieldset>
      <ul>
        <li>
          <label for="service-name">Name</label>
          <input
            autofocus
            type="text"
            name="name"
            id="service-name"
            bind:value={name}
            disabled={loading}
          />
        </li>
        <li>
          <label for="service-category">Category</label>
          <input
            type="text"
            name="category"
            id="service-category"
            bind:value={category}
            disabled={loading}
          />
        </li>
      </ul>
    </fieldset>
    <FormActions bind:loading on:cancel={() => (open = false)} />
  </form>
</Modal>
