<script>
  import { param } from "../util/params";
  import Map from "./maps/Map.svelte";
  import MapCompass from "./maps/MapCompass.svelte";
  import MapDraw from "./maps/MapDraw.svelte";
  import { style as mapstyle, styles } from "./maps/stores";
  import { stringify } from "wellknown";
  import { fetchUpdateProperty } from "../api";
  import router from "page";

  export let property;

  let editing = null;
  let wkt = null;
  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && !editing) editing = { ...property }; // shallow clone
  $: if (editing) init(editing.address.area);

  const defaultStyle = "esrisatellite";
  const paramStyle = param("style");
  $: selectedStyle = $paramStyle || defaultStyle;
  const style = mapstyle(defaultStyle);

  $: currentStyle = $style;

  let geojson = null;

  function init(area) {
    if (area.type === "MultiPolygon") {
      geojson = {
        type: "FeatureCollection",
        features: area.coordinates.map((coordinates) => ({
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates,
          },
          properties: {},
        })),
      };
    } else {
      geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: area,
            properties: {},
          },
        ],
      };
    }

    geometryChanged(geojson);
  }

  function route(key, value) {
    var qs = new URLSearchParams(window.location.search);
    if (value) qs.set(key, value);
    else qs.delete(key);
    router(window.location.pathname + "?" + qs.toString());
  }

  function geometryChanged(features) {
    if (!features.length) return;
    if (features.length > 1) {
      const multipolygon = {
        type: "Feature",
        geometry: {
          type: "MultiPolygon",
          coordinates: features.map((feat) => feat.geometry.coordinates),
        },
        properties: {},
        id: "a",
      };
      wkt = stringify(multipolygon);
    } else {
      wkt = stringify(features[0]);
    }
    submittable = !!wkt;
  }

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    const newPolicy = await fetchUpdateProperty(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

{#if geojson && currentStyle}
  <Map {geojson} style={currentStyle}>
    <nav class="styles">
      <ul
        class="styles"
        on:change={(e) =>
          e.target.checked && route(e.target.name, e.target.value)}
      >
        {#each Object.entries($styles || {}) as [id, style]}
          <li>
            <input
              id="style-{id}"
              type="radio"
              name="style"
              checked={id == selectedStyle}
              value={id}
            /> <label for="style-{id}">{style.name}</label>
          </li>
        {/each}
      </ul>
    </nav>
    <MapCompass />
    <MapDraw
      {geojson}
      changed={geometryChanged}
      controls={true}
      geofence={true}
    />
    <figcaption>
      <form on:change={change} on:input={change} on:submit={submit}>
        <input type="hidden" name="area" value={wkt} />
        <button type="submit" disabled={!submittable || submitting}
          >{submitting ? "Saving" : "Save"}</button
        >
      </form>
    </figcaption>
  </Map>
{/if}
