<script lang="ts">
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let loading = false;
</script>

<footer class="flex items-center gap-4">
  <button class="m-0 btn btn-primary flex-1" type="submit" disabled={loading}>
    Create
  </button>
  <button
    class="m-0 btn btn-error btn-outline"
    type="button"
    on:click={() => dispatch("cancel")}
    disabled={loading}
  >
    Cancel
  </button>
</footer>
