<script>
  export let options;
  export let name;
  export let label = "";
  export let onChange;
  export let defaultValue = null;
  export let disabled = false;
  $: selected =
    (!!defaultValue && defaultValue.toString()) ||
    (options.length && options[0].value);
  function handleChange(e) {
    selected = e.target.value;
    onChange(selected, name);
  }
</script>

{#if label}
  <label for={name}>{label}</label>
{/if}
<!-- svelte-ignore a11y-no-onchange -->
<select {disabled} {name} on:change={handleChange} value={selected}>
  {#each options as option}
    <option value={option.value}>
      {option.name}
    </option>
  {/each}
</select>
