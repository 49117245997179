<script>
  import { fetchUpdateProperty as update } from "../api";
  import { get, clone } from "lodash-es";

  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing)) editing = { ...property };
  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    await update(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

{#if editing}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    class="edit property"
  >
    <!-- <header>
        <h1>Settings</h1>
    </header> -->

    <fieldset>
      <ul>
        <li>
          <label for="property-spaces">Type</label>
          <select
            id="property-spaces"
            name="spaces.enabled"
            value={get(editing, "spaces.enabled", false) + ""}
          >
            <option value="true">enabled</option>
            <option value="false">disabled</option>
          </select>
        </li>
        <li>
          <label for="property-spaces-assigned">Assigned</label>
          <select
            id="property-spaces-assigned"
            name="spaces.permits"
            value={get(editing, "spaces.permits", false) + ""}
          >
            <option value="true">enabled</option>
            <option value="false">disabled</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
