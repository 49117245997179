<script>
  import { format, utcToZonedTime } from "date-fns-tz";
  import { parse, parseISO, startOfDay } from "date-fns";
  export let value;
  export let timezone;
  export let id;

  let min,
    max = null;

  $: if (value && timezone) console.log(value);

  $: if (value && timezone)
    [min, max] = value
      .split("/")
      .map((i) => i && utcToZonedTime(parseISO(i), timezone));

  function onDateMin(e) {
    console.log("onDateMin=", e);
    if (!e.target.checkValidity()) return;

    // parse as local
    var d = parse(e.target.value, "yyyy-MM-dd", new Date());

    if (!d) return;

    value =
      format(startOfDay(d), "yyyy-MM-dd'T'HH:mm:ssXXX", {
        timeZone: timezone,
      }) +
      "/" +
      (value.split("/")[1] || "");

    // const q = new URLSearchParams(location.search);
    // q.set("valid", `${dateMin}T00:00:00/${dateMax}T23:59:59`);
    // page(`${location.pathname}?${q.toString()}`); // navigate

    // var calc = calculate(dateMin, timeMin);
    // if(calc != datetimeMin) {
    //     datetimeMin = calc;
    //     doResult(datetimeMin);
    // }
  }
</script>

<input
  {id}
  type="date"
  min="2000-01-01"
  max="2100-01-01"
  value={(min && format(min, "yyyy-MM-dd")) || ""}
  required
  on:input={onDateMin}
  on:change={onDateMin}
/>
