<script>
  import { onMount } from "svelte";
  import { get } from "lodash-es";
  import AddSpaces from "./AddSpaces.svelte";
  import { downloadHandler } from "../util/exportTable";
  import EditPropertySpaces from "./EditPropertySpaces.svelte";
  import NewSpacePrice from "./NewSpacePrice.svelte";
  import EditPrices from "./EditPrices.svelte";
  import EditSpacePrices from "./EditSpacePrices.svelte";
  import { fetchRemoveSpaces as updater } from "../api";
  import Record from "./RecordItem.svelte";

  export let property = null;
  export let items = null;
  export let prices = null;
  let table = null;

  $: spacePrices = [];

  onMount(() => {
    table && table.scrollIntoView();
  });

  $: if (items && prices) {
    //console.log("pricing=", items, prices);

    const pricesBySubject = prices.reduce((map, item) => {
      for (const id of Object.keys(item.subjects)) {
        const prices = (map[id] = map[id] || {});
        prices[item.id] = item;
      }
      // const prices = (map[item.subject.id || item.subject] =
      //   map[item.subject.id || item.subject] || []);
      //prices.push(item);
      return map;
    }, {});

    //console.log("pricesBySubject=", pricesBySubject);

    const list = [];

    for (const item of items) {
      item.prices = Object.values(pricesBySubject[item.id] || []);
      //console.log("item.prices=", item.prices);
      list.push(...item.prices);
    }

    spacePrices = list;
    items = items;
  }

  let add = null;

  async function remove(space) {
    if (!window.confirm("This is permanent and cannot be undone. Continue?"))
      return;
    await updater(property, space.id);
  }
</script>

<!-- <EditTenants property={property} /> -->
<section>
  <EditPropertySpaces {property} />
  {#if property}
    <!-- <button type="button" on:click={e => (add = property)}>Add {get(property, "spaces.title")}s</button> -->
    <a
      href="#"
      type="text/csv"
      download="{get(property, 'name', '')} {get(
        property,
        'spaces.title',
        ''
      )}s.csv"
      on:click={(e) => table && downloadHandler(e, table)}
      >Download Spreadsheet</a
    >
  {/if}
  <AddSpaces bind:property />
</section>

<!-- {#if add}
    <AddSpaces bind:property={add} />
{/if} -->
{#if items}
  <table class="spaces" data-count={get(items, "length", "")} bind:this={table}>
    <thead>
      <tr>
        <th scope="col row" />
        <th scope="col">id</th>
        <th scope="col" />
        <th scope="col">ref</th>
        <th scope="col">format</th>
        <th scope="col">parking</th>
        <th scope="col">parking_space</th>
        <th scope="col">amenity</th>
        <th scope="col">leisure</th>
        <th scope="col">capacity</th>
        <th scope="col">size</th>
        <th scope="col">level</th>
        <th scope="col">level:ref</th>
        <th scope="col">building:ref</th>
        <th scope="col">indoor</th>
        <th scope="col">outdoor</th>
        <th scope="col">covered</th>
        <th scope="col">capacity:lift</th>
        <th scope="col">capacity:charging</th>
        <th scope="col">capacity:disabled</th>
        <th scope="col">capacity:bicycle</th>
        <th scope="col">capacity:storage</th>
        <th scope="col">access</th>
        <th scope="col">access:shared</th>
        <th scope="col">barrier</th>
        <th scope="col">color</th>
        <th scope="col">Options</th>
        <th scope="col">Prices</th>
        <th scope="col" />
      </tr>
    </thead>
    {#if !!items && !!items.length}
      <tbody>
        {#each items as item}
          {#if item}
            <tr>
              <th scope="row"><Record {item} /></th>
              <td>{item.id}</td>
              <td>{item.key}</td>
              <td>{item.ref || ""}</td>
              <td>{item.format || ""}</td>
              <td>{item.parking || ""}</td>
              <td>{item.parking_space || ""}</td>
              <td>{item.amenity || ""}</td>
              <td>{item.leisure || ""}</td>
              <td>{item.capacity || ""}</td>
              <td>{item.size || ""}</td>
              <td>{item.level || ""}</td>
              <td>{item["level:ref"] || ""}</td>
              <td>{item["building:ref"] || ""}</td>
              <td>{item.indoor || ""}</td>
              <td>{item.outdoor || ""}</td>
              <td>{item.covered || ""}</td>
              <td>{item["capacity:lift"] || ""}</td>
              <td>{item["capacity:charging"] || ""}</td>
              <td>{item["capacity:disabled"] || ""}</td>
              <td>{item["capacity:bicycle"] || ""}</td>
              <td>{item["capacity:storage"] || ""}</td>
              <td>{item["access"] || ""}</td>
              <td>{item["access:shared"] || ""}</td>
              <td>{item["barrier"] || ""}</td>
              <td>{item.color || ""}</td>
              <td>{item.tags.join(", ")}</td>
              <td
                >{#each Object.values(item.prices || []) as price}{price.description}{/each}</td
              >
              <td
                ><button type="button" on:click={(e) => remove(item)}
                  >Remove</button
                ></td
              >
            </tr>
          {/if}
        {/each}
      </tbody>
    {/if}
  </table>
{/if}
<section>
  <EditSpacePrices {property} />
</section>
<!-- <section>
    <NewSpacePrice property={property} />
    <EditPrices property={property} items={spacePrices} />
</section> -->
