<script lang="ts">
  import { state, tenantsByUnit } from "$stores";
  import { sortBy } from "$utils/sort";
  import { format, utcToZonedTime } from "date-fns-tz";
  import { capitalize } from "lodash-es";
  import { tick } from "svelte";
  import { fetchResetUnit as update } from "../api";
  import Record from "./RecordItem.svelte";

  let submitting = {};
  let expand = {};
  let query = "";

  async function reset(tenantOrUnit) {
    // resolve object
    if (!tenantOrUnit) return;
    const item = $state[tenantOrUnit] || tenantOrUnit;
    if (!item || !item.id) return;

    submitting[item.id] = true;
    submitting = submitting;

    await tick();

    const res = await update(item);

    submitting[item.id] = false;
    submitting = submitting;

    if (res && res.message) return alert(res.message);
  }

  function toggle(id) {
    expand[id] = !expand[id];
    expand = expand;
  }

  // TODO: better typing when stores are typed
  $: visibleTenants = sortBy(
    Object.values($tenantsByUnit || {}),
    "unit.display"
  ).filter((tenant: any) => {
    if (!query) return true;
    return tenant.unit.display.toLowerCase().includes(query.toLowerCase());
  });
</script>

<section>
  <input
    type="search"
    name="search"
    bind:value={query}
    class="input input-sm bg-base-200 w-full mb-6"
    placeholder="Search by unit..."
  />

  <ul class="units status">
    {#each visibleTenants as { unit, tenants }}
      <li>
        <h1>
          <Record item={unit} />
        </h1>
        <ol>
          <li>
            <button
              type="button"
              class="reset"
              disabled={submitting[unit.id]}
              on:click={(e) => reset(unit)}
            >
              {#if submitting[unit.id]}
                Resetting&hellip;
              {:else}
                Reset {capitalize(unit.format)}
              {/if}</button
            >
          </li>
          {#if tenants}
            {#each sortBy(Object.values(tenants), "valid.utc").reverse() as tenant, i}
              {#if i <= 1 || expand[unit.id]}
                <li>
                  <a
                    href="https://manager.communityboss.app/properties/{tenant.scope}/tenants/{tenant.id}"
                    target="_blank">View {0 == i ? "Current" : "History"}</a
                  >
                  <time
                    class="tenant"
                    class:init={i == Object.values(tenants).length - 1}
                    datetime={tenant.valid.utc}
                    >{format(
                      utcToZonedTime(
                        tenant.valid.utc.split("/")[0],
                        tenant.timezone
                      ),
                      "EEE MMM d yyyy h:mm a",
                      { timeZone: tenant.timezone }
                    )}</time
                  >
                </li>
              {/if}
            {/each}
          {/if}
        </ol>

        {#if tenants && Object.values(tenants).length > 2}
          <button
            class:active={expand[unit.id]}
            type="button"
            on:click={(e) => toggle(unit.id)}
            >{expand[unit.id] ? "Hide" : "Show"} Older</button
          >
        {/if}
      </li>
    {/each}
  </ul>
</section>
