<script>
  import { units } from "./stores";
  import { fetchUpdatePolicy } from "./api";
  import { get, range, filter, map, size, each, clone, find } from "lodash-es";
  import NewTenantMeter from "./components/NewTenantMeter.svelte";
  import EditPolicyMeters from "./EditPolicyMeters.svelte";
  import ItemsSelector from "./components/ItemsSelector.svelte";

  export let policy = null;
  export let readonly = false;

  let submittable = false;
  let submitting = false;

  let editing = null;

  //$: editing = policy;
  $: if (!!policy && (!editing || !submittable)) editing = { ...policy };

  $: selectedItems = map(get(editing, "tenant.items", {}));
  $: console.log(selectedItems);
  $: allItems = map(get(editing, "tenant.items", {})).length === 0;
  $: singleItem = map(get(editing, "tenant.items", {})).length === 1;

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!editing || submitting || !submittable) return;

    submitting = true;

    const form = e.target;

    const formData = new FormData(form);

    await fetchUpdatePolicy(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

<section>
  <header>
    <h1>Units</h1>
  </header>
  <form on:change={change} on:input={change} on:submit={submit}>
    <!-- <fieldset disabled={readonly && "disabled"}>
        <ul class="toggle" on:change={e => { if(e.target.checked) allItems = e.target.value === "true"; }}>
            <li><input id="policy-unit-all" type="radio" value="true" checked={allItems} /><label for="policy-unit-all">All</label></li>
            <li><input id="policy-unit-all" type="radio" value="false" checked={!allItems} /><label for="policy-unit-select">Select</label></li>
        </ul>
    </fieldset> -->
    <fieldset disabled={readonly && "disabled"}>
      <ul>
        <li>
          <label for="policy-field-tenant">Unit</label>
          <select
            id="policy-field-tenant"
            name="permit.tenant"
            value={get(editing, "tenant.required")
              ? "true"
              : get(editing, "tenant.request")
              ? "false"
              : ""}
          >
            <option value="true">required</option>
            <option value="false">optional</option>
            <option value="">disabled</option>
          </select>
        </li>
        <li>
          <label for="policy-field-tenant-auth">Passcode</label>
          <select
            id="policy-field-tenant-auth"
            name="permit.tenant.authentication.required"
            value={get(editing, "permit.tenant.authentication.required")
              ? "true"
              : "false"}
          >
            <option value="true">required</option>
            <option value="false">disabled</option>
          </select>
        </li>
        <li>
          <label for="policy-tenant-donotpermitblock">Banned</label>
          <select
            id="policy-tenant-donotpermitblock"
            name="permit.tenant.donotpermit.reject"
            value={get(editing, "tenant.donotpermit.reject", "true") + ""}
          >
            <option value="false">allow</option>
            <option value="true">block</option>
          </select>
        </li>
        <li>
          <label for="policy-tenant-simplified">Count</label>
          <select
            id="policy-tenant-simplified"
            name="permit.tenant.simplified"
            value={get(editing, "tenant.simplified", false) + ""}
          >
            <option value="false">each permit separately</option>
            <option value="true">overlapping permits once</option>
          </select>
        </li>
        <li>
          <label for="policy-unit-options">Access</label>
          <select
            id="policy-unit-options"
            on:change={(e) => {
              allItems = e.target.value === "all";
              singleItem = e.target.value == "single";
            }}
            value={allItems ? "all" : singleItem ? "single" : "select"}
          >
            <option value="all"
              >all {get(editing, "tenant.format", "unit")}s</option
            >
            <option value="single"
              >single {get(editing, "tenant.format", "unit")}…</option
            >
            <option value="select"
              >select {get(editing, "tenant.format", "unit")}s…</option
            >
          </select>
        </li>
        {#if singleItem}
          <li>
            <label for="policy-unit-options">Selected</label>
            <select id="policy-unit-item" name="permit.tenant.items">
              {#if $units}
                {#each $units as item}
                  <option
                    value={item.display}
                    selected={selectedItems.includes(item.display) ||
                      selectedItems.includes(item.key)}>{item.display}</option
                  >
                {/each}
              {/if}
            </select>
          </li>
        {:else if !allItems}
          <li>
            <label for="policy-unit-items">Selected</label>
            <ItemsSelector
              name="permit.tenant.items"
              items={$units}
              selected={selectedItems}
              value="display"
            />
            <!-- <ul>
                {#if $units}
                    {#each $units as item}
                        <li>
                            <input id="unit-options-{item.id}" type="checkbox" name="permit.tenant.items" checked={selectedItems.length === 0 || selectedItems.includes(item.display) || selectedItems.includes(item.key)} value="{item.key}" /> <label for="unit-options-{item.id}"><data class="id {[ item.format, item.type ].join(" ")}">{item.display}</data></label>
                        </li>
                        
                    {/each}
                    {/if}
                </ul> -->
          </li>
        {/if}
        {#if !allItems}
          <li>
            <label for="policy-unit-list">List</label>
            <select
              id="policy-unit-list"
              name="tenant.list.all"
              value={get(editing, "tenant.list.all", true) + ""}
            >
              <option value="true">all</option>
              <option value="false">with access only</option>
            </select>
          </li>
        {/if}
      </ul>
    </fieldset>

    <!-- <fieldset disabled={readonly && "disabled"}>
        <ul>
            <li>
                <label for="policy-unit-options">Access</label>
                <select id="policy-unit-options" on:blur={e => { allItems = e.target.value === "true"; }} on:change={e => { allItems = e.target.value === "true"; }} value="{allItems+""}">
                    <option value="true">all {get(editing, "tenant.format", "unit")}s</option>
                    <option value="false">select {get(editing, "tenant.format", "unit")}s</option>

                </select>
            </li>
            {#if !allItems}
            <li>
                <label for="policy-unit-items">Selected</label>
                <ul>
                    {#if $units}
                    {#each $units as unit}
                
                        <li>
                            <input id="tenant-options-{unit.id}" type="checkbox" name="permit.tenant.items" checked={selectedItems.length === 0 || selectedItems.includes(unit.key)} value="{unit.key}" /> <label for="tenant-options-{unit.id}"><data class="id {[ unit.format, unit.type ].join(" ")}">{unit.display}</data></label>
                        </li>
                        
                    {/each}
                    {/if}
                </ul>
            
            </li>
            {/if}
        </ul>
    </fieldset> -->

    <input type="hidden" name="permit.tenant.items" value="" />

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
  {#if !readonly}<NewTenantMeter {policy} />{/if}
  <EditPolicyMeters {policy} groups={["unit", "tenant"]} />
</section>
