<script>
  import * as d3 from "d3";
  import ChartAxis from "./ChartAxis.svelte";
  import ChartLine from "./ChartLine.svelte";

  const margin = {
    top: 20,
    bottom: 20,
    left: 40,
    right: 0,
  };

  export let data;

  let width;
  let height;

  $: insetSize = {
    width: width - (margin.left + margin.right),
    height: height - (margin.top + margin.bottom),
  };
  $: xDomain = data?.values
    ? Object.keys(data.values).map((key) => key.split("/")[0])
    : ["00:00:00", "06:00:00", "12:00:00", "18:00:00"];
  $: xScale = d3
    .scaleBand()
    .rangeRound([0, insetSize.width])
    .domain(xDomain)
    .padding(0.1);
  $: yMax = (data?.values && d3.max(Object.values(data.values))) || 1;
  $: yScale = d3
    .scaleLinear()
    .rangeRound([insetSize.height, 0])
    .domain([0, yMax]);

  const tickFormat = (value) => {
    const tick = value.split(":")[0];
    if (tick > 0 && tick % 6 !== 0) {
      return;
    }
    return tick;
  };

  const xValue = (value) => {
    const hour = value.split("/")[0];
    return xScale(hour);
  };
</script>

<div
  class="chart-bar"
  on:click
  bind:clientWidth={width}
  bind:clientHeight={height}
>
  {#if width && height}
    <svg {width} {height}>
      <ChartLine text={yMax} x1={margin.left} x2={width} {margin} />
      <ChartAxis
        scale={xScale}
        position="bottom"
        {height}
        {margin}
        {tickFormat}
      />
      {#if data?.values}
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          {#each Object.entries(data.values) as [key, value]}
            <rect
              x={xValue(key)}
              y={yScale(value)}
              width={4}
              height={value > 0 ? insetSize.height - yScale(value) : 0}
              rx={2}
            />
          {/each}
        </g>
      {/if}
    </svg>
  {/if}
</div>
