<script>
  import { toMilliseconds } from "duration-fns";
  import { fetchUpdatePolicy, fetchCreatePolicy } from "./api";
  import { get, range, filter, clone } from "lodash-es";
  import { parse as parsems } from "duration-fns";

  export let policy = null;
  export let issue = true;
  export let disabled = false;
  export let readonly = false;

  let submittable = false;
  let submitting = false;

  // react only if in an unchanged state
  //$: editingPolicy = policy && !submittable ? {...policy} : editingPolicy;
  let editingPolicy = null;

  // save the first one we get
  //$: if(!!policy && !editingPolicy) editingPolicy  = {...policy};
  $: if (!!policy && (!editingPolicy || !submittable))
    editingPolicy = { ...policy };

  let selectedMinDurationISO = null;

  $: minDurationISO =
    selectedMinDurationISO ||
    get(editingPolicy, "duration.min") ||
    get(editingPolicy, "duration") ||
    "PT30M";
  $: maxDurationISO =
    get(editingPolicy, "duration.max") ||
    get(editingPolicy, "duration") ||
    "PT1H";
  $: issue = !!get(editingPolicy, "issue.enabled");
  $: disabled = !!get(editingPolicy, "disabled");
  $: minDurationMS = toMilliseconds(minDurationISO);
  $: maxDurationMS = toMilliseconds(maxDurationISO);
  $: maxDurationValues = calculateMaxDuration(minDurationMS);

  //$: console.log("editingPolicy=", editingPolicy);
  //$: console.log("issue=", issue, "disabled=", disabled);

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    await fetchUpdatePolicy(editingPolicy, formData);

    // clear the state
    submitting = submittable = false;
    editingPolicy = null;
  }

  const times = [
    0.25 * 60 * 60 * 1000,
    0.5 * 60 * 60 * 1000,
    0.75 * 60 * 60 * 1000,
    1.0 * 60 * 60 * 1000,
    1.25 * 60 * 60 * 1000,
    1.5 * 60 * 60 * 1000,
    1.75 * 60 * 60 * 1000,
    2.0 * 60 * 60 * 1000,
    2.5 * 60 * 60 * 1000,
    3.0 * 60 * 60 * 1000,
    3.5 * 60 * 60 * 1000,
    4.0 * 60 * 60 * 1000,
    5.0 * 60 * 60 * 1000,
    6.0 * 60 * 60 * 1000,
    7.0 * 60 * 60 * 1000,
    8.0 * 60 * 60 * 1000,
    10.0 * 60 * 60 * 1000,
    12.0 * 60 * 60 * 1000,
    14.0 * 60 * 60 * 1000,
    16.0 * 60 * 60 * 1000,
    18.0 * 60 * 60 * 1000,
    20.0 * 60 * 60 * 1000,
    22.0 * 60 * 60 * 1000,
    24.0 * 60 * 60 * 1000,
    36.0 * 60 * 60 * 1000,
    48.0 * 60 * 60 * 1000,
  ];

  function calculateMaxDuration(min) {
    const max = 24 * 60 * 60 * 1000;
    const items = [];
    if (min <= 0) return items;
    let i = min;

    items.push(min);

    for (let i of times.filter((time) => time > min && time < min * 2)) {
      items.push(i);
      //min = i;
    }

    // first 8 steps then on the hour
    for (i = min * 2; i < max; i += min) {
      if (i <= 8 * min) items.push(i);
      else if (i % (60 * 60 * 1000) === 0) items.push(i);
    }

    return items;
  }

  function toISOString(ms) {
    const parsed = parsems(ms);
    return (
      "PT" +
      (parsed.hours > 0 ? parsed.hours + "H" : "") +
      (parsed.minutes > 0 ? parsed.minutes + "M" : "")
    );
  }

  function formatTime(ms) {
    const parsed = parsems(ms);
    return `${parsed.hours}:${parsed.minutes.toString().padStart(2, "0")}`;
  }
</script>

{#if editingPolicy}
  <form on:change={change} on:input={change} on:submit={submit}>
    <fieldset disabled={readonly && "disabled"}>
      <ul>
        <li>
          <label for="policy-field-auth">Passcode</label>
          <select
            id="policy-field-auth"
            name="permit.authentication.required"
            value={get(editingPolicy, "permit.authentication.required")
              ? "true"
              : "false"}
          >
            <option value="true">required</option>
            <option value="false">disabled</option>
          </select>
        </li>
        <li>
          <label>Name</label>
          <select
            name="permit.contact.name"
            value={get(editingPolicy, "name.required")
              ? "true"
              : get(editingPolicy, "name.request")
              ? "false"
              : ""}
          >
            <option value="false">optional</option>
            <option value="true">required</option>
            <option value="">disabled</option>
          </select>
        </li>
        <li>
          <label for="policy-field-tel">Phone</label>
          <select
            id="policy-field-tel"
            name="permit.contact.tel"
            value={get(editingPolicy, "tel.required")
              ? "true"
              : get(editingPolicy, "tel.request")
              ? "false"
              : ""}
          >
            <option value="false">optional</option>
            <option value="true">required</option>
            <option value="">disabled</option>
          </select>
        </li>
        <li>
          <label for="policy-field-email">Email</label>
          <select
            id="policy-field-email"
            name="permit.contact.email"
            value={get(editingPolicy, "email.required")
              ? "true"
              : get(editingPolicy, "email.request")
              ? "false"
              : ""}
          >
            <option value="false">optional</option>
            <option value="true">required</option>
            <option value="">disabled</option>
          </select>
        </li>
        <li>
          <label for="policy-field-notes">Notes</label>
          <select
            id="policy-field-notes"
            name="permit.notes"
            value={get(editingPolicy, "notes.required")
              ? "true"
              : get(editingPolicy, "notes.request")
              ? "false"
              : ""}
          >
            <option value="false">optional</option>
            <option value="true">required</option>
            <option value="">disabled</option>
          </select>
        </li>
        {#if get(editingPolicy, "notes.request", false)}
          <li>
            <label for="policy-field-notes-label">Notes label</label>
            <input
              id="policy-field-notes-label"
              name="permit.notes.label"
              value={get(editingPolicy, "notes.label", "")}
              placeholder="optional"
            />
          </li>
        {/if}
      </ul>
    </fieldset>

    <fieldset disabled={readonly && "disabled"}>
      <p>Agreement:</p>
      <ul>
        <li>
          <!-- <label for="policy-field-agreement">Agreement</label> -->
          <textarea
            id="policy-field-agreement"
            name="permit.agreement"
            value={get(editingPolicy, "agreement.text", "")}
          />
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
