<script>
  import { thumbnail } from "../util/filepreview";

  export let policy = null;
  //$:console.log(policy);
</script>

{#if !!policy && !!policy.id}
  <data class={policy.type} value={policy.id}>
    <figure>
      {#if policy.photo}<img
          alt="amenity"
          src={thumbnail(policy.photo.url, 500)}
        />{:else}<img
          class="policy {policy.title
            .toLowerCase()
            .split(/[^a-z]+/gi)
            .join(' ')}"
          alt="amenity"
        />{/if}
    </figure>
    <h1>{policy.title}</h1>
  </data>
{/if}
