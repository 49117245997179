<script>
  import { fetchCSV } from "../api";
  import uid from "locally-unique-id-generator";
  export let items = [];
  export let selected = [];
  export let name = "subject";
  export let value = "id";
  //export let empty = true;

  let id = uid();

  // todo better handle casing
  function isItemSelectedValue(item, selected) {
    if (selected?.id === item.id) return true;

    // selected is a string
    if (typeof selected === "string") {
      if (selected === item.id) return true;
      if (selected?.toUpperCase() === item.key) return true;
      if (selected?.toUpperCase() === item.display?.toUpperCase()) return true;
      if (selected?.toUpperCase() === item.name?.toUpperCase()) return true;
    }
    return false;
  }

  function isItemSelected(item, selected = []) {
    //if (selectedItems.length === 0) return empty;
    console.log(
      item,
      selected,
      !!selected.find((selected) =>
        Array.isArray(selected)
          ? !!selected.find((selected) => isItemSelectedValue(item, selected))
          : isItemSelectedValue(item, selected)
      )
    );
    return !!selected.find((selected) =>
      Array.isArray(selected)
        ? !!selected.find((selected) => isItemSelectedValue(item, selected))
        : isItemSelectedValue(item, selected)
    );
    // return false;
    // selectedItems.length === 0 ||
    //   selectedItems.includes(item.display) ||
    //   selectedItems.includes(item.key);
  }

  async function processCsvFile(file) {
    if (!file) return;

    let input = file;

    console.log("process=", file);

    if (
      file.type !== "text/csv" &&
      file.name.indexOf(".csv") < 0 &&
      file.name.indexOf(".txt") < 0
    )
      input = await fetchCSV(file);

    // const numeric = /[$.]/gi;

    return new Promise(function (resolve) {
      Papa.parse(input, {
        header: false,
        complete: async function (results) {
          console.log(results);

          // straight update?
          //await fetchCreateSpaces(property, results.data);
          selected = results.data;
          return resolve(results.data);
        },
      });
    });
  }
  async function files(e) {
    //console.log(e);

    e.preventDefault();

    var items =
      (e.target && e.target.files) ||
      (e.dataTransfer && e.dataTransfer.files) ||
      [];
    //console.log("files=", items);

    if (e.target) e.target.disabled = true;

    if (!items.length) return;

    for (let item of items) {
      if (
        item.type !== "text/csv" &&
        item.name.indexOf(".txt") < 0 &&
        item.name.indexOf(".csv") < 0 &&
        item.name.indexOf(".xlsx") < 0 &&
        item.name.indexOf(".xls") < 0
      )
        continue;
      await processCsvFile(item);
    }

    if (e.target) {
      e.target.value = "";
      e.target.disabled = false;
    }
  }
  function all() {
    selected = items;
  }
  function none() {
    selected = [];
  }

  $: console.log("selected=", selected);
</script>

<ul>
  <li>
    <input
      id="items-{id}-file"
      type="file"
      on:change={files}
      accept="text/csv,.csv,.xlsx,.xls,.txt"
    />
    <label class="file" for="items-{id}-file">Match from CSV</label>
  </li>
  <li>
    <button type="button" on:click={all}>All</button><button
      type="button"
      on:click={none}>None</button
    >
  </li>
  {#if items}
    {#each Object.values(items) as item}
      <li>
        <input
          id="item-{id}-{item.id}"
          type="checkbox"
          {name}
          checked={isItemSelected(item, selected)}
          value={item[value]}
        />
        <label for="item-{id}-{item.id}"
          ><data class="id {[item.format, item.type].join(' ')}"
            >{item.display}</data
          ></label
        >
      </li>
    {/each}
  {/if}
</ul>
