<script>
  import { get } from "lodash-es";
  import { fetchUpdateProperty as update } from "./api";

  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing)) editing = { ...property };

  $: amenities = get(editing, "amenities.items", []).includes("amenity");

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    await update(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

{#if editing}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    class="edit property"
  >
    <header>
      <h1>Settings</h1>
    </header>

    <fieldset>
      <ul>
        <li>
          <label for="property-safelist">Amenities</label>
          <select
            for="property-safelist"
            name="amenities"
            value={amenities + ""}
            on:change={(e) => (amenities = e.target.value === "true")}
            on:blur={(e) => (amenities = e.target.value === "true")}
          >
            <option value="true">enabled</option>
            <option value="false">disabled</option>
          </select>
        </li>
      </ul>
    </fieldset>
    {#if !amenities}
      <p>Please note: disabling amenities merely hides in UI.</p>
    {/if}
    {#if amenities}
      <fieldset>
        <ul>
          <li>
            <label>Notices</label>
            <a href="/properties/{editing.id}/notices">open</a>
          </li>
          <li>
            <label>Manager</label>
            <a
              href="https://amenities.communityboss.app/properties/{editing.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
          <li>
            <label>Pass</label>
            <a
              href="http://amenitypass.app/properties/{editing.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
        </ul>
      </fieldset>
    {/if}

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
