<script>
  export let x = "0";
</script>

<!-- <rect class="now" y="0%" x="{timescale(nowlocal)}%" width="4" height="4" transform="translate(-2, -2) rotate(45)" />
    <rect class="now" y="100%" x="{timescale(nowlocal)}%" width="4" height="4" transform="translate(-2, -2) rotate(45)" /> -->
<line class="now" y1="0" y2="100%" x1={x} x2={x} />
<line class="now" y1="0" y2="100%" x1={x} x2={x} />
<circle class="now" cy="0%" cx={x} r="2" />
<circle class="now" cy="100%" cx={x} r="2" />
