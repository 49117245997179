<script>
  import { minuteNow } from "../stores";
  import { fetchViolationMetrics } from "../api";
  import { addDays, startOfDay, differenceInMinutes } from "date-fns";
  import { utcToZoned, toZoneISOString } from "../util/datetime";
  import DateTimeSchedule from "./DateTimeSchedule.svelte";
  import { find } from "lodash-es";

  export let property = null;

  let propertyId = null;
  let timezone = null;
  let fetchTime = null;

  $: if (property && property.id != propertyId) propertyId = property.id;
  $: if (property && property.timezone != timezone)
    timezone = property.timezone;

  $: if (!fetchTime || differenceInMinutes($minuteNow, fetchTime) > 5)
    fetchTime = new Date();

  $: metrics =
    timezone &&
    propertyId &&
    fetchTime &&
    fetchViolationMetrics(
      `${toZoneISOString(
        addDays(startOfDay(utcToZoned(fetchTime, timezone)), -6),
        timezone
      )}/${toZoneISOString(
        addDays(startOfDay(utcToZoned(fetchTime, timezone)), 1),
        timezone
      )}`,
      {
        property: propertyId,
        datetimes: "PT24H",
      }
    );

  async function usageMetrics(metrics, property) {
    if (!metrics) return;

    return find((await metrics).metrics.items, {
      metric: "count",
      count: "cumulative",
      source: "violations",
      intervals: "datetimes",
      datetimes: "PT24H",
      group: "property",
      property: propertyId,
    });
  }
</script>

<!-- <section>
</section> -->
<figure>
  <DateTimeSchedule {timezone} usage={usageMetrics(metrics, property)} />
</figure>
