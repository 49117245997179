<script lang="ts">
  import { comparer, numericAsc } from "$utils/sort";
  import { fetchInvalidatePropertyViolationPolicies } from "../../api";
  import ViolationPolicyItem from "./ViolationPolicyItem.svelte";
  import { createEventDispatcher } from "svelte";

  export let items: any;

  const events = createEventDispatcher();

  let submittable = false;
  let submitting = false;

  let remove = {};
  $: submittable = Object.values(remove).filter(Boolean).length > 0;

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    //await fetchDeleteUser(property.id, formData);
    var json = await fetchInvalidatePropertyViolationPolicies(formData);

    submitting = submittable = false;
    remove = {};

    // event changed items
    events("change", json);
  }
</script>

{#if items}
  <form on:submit|preventDefault={submit}>
    <ul class="remove">
      {#each Object.values(items.items).sort(comparer("order", numericAsc)) as item}
        <li>
          <ViolationPolicyItem {item}>
            {#if item.scope !== item.id}
              <input
                type="checkbox"
                name="id"
                value={item.id}
                checked={!!remove[item.id]}
                on:change={({ currentTarget: target }) =>
                  (remove[target.value] = target.checked)}
              />
            {/if}
          </ViolationPolicyItem>
        </li>
      {/each}
    </ul>
    {#if submitting || submittable}
      <footer>
        <button type="submit" disabled={!submittable || submitting}
          >{submitting ? "Saving" : "Remove"}</button
        >
      </footer>
    {/if}
  </form>
{/if}
