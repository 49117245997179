<script>
  import Toast from "./Toast.svelte";

  export let toasts;
</script>

<div class="toast-container">
  {#each toasts as toast, i}
    <Toast {...toast} idx={i} />
  {/each}
</div>
