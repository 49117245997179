<script>
  import { fetchUpdateViolations as update } from "./api";
  import { format, utcToZonedTime } from "date-fns-tz";
  import { get, clone } from "lodash-es";

  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing)) editing = { ...property };

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    const newPolicy = await update(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

{#if editing}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    class="edit property"
  >
    <header>
      <h1>Violations</h1>
    </header>
    <fieldset>
      <ul>
        <li>
          <label for="property-violations-valid">Starting</label>
          <input
            type="datetime-local"
            id="property-violations-valid"
            name="valid"
            value={format(
              utcToZonedTime(
                get(editing, "violations.valid", "").split("/")[0],
                editing.timezone
              ),
              "yyyy-MM-dd'T'HH:mm"
            )}
          />
        </li>
        <li>
          <label for="property-violations-vehicle">Vehicle</label>
          <select
            id="property-violations-vehicle"
            value={get(editing, "violations.vehicle.required")
              ? "true"
              : get(editing, "violations.vehicle.request")
              ? "false"
              : ""}
            disabled
          >
            <option value="true">required</option>
            <option value="false">optional</option>
            <option value="">disabled</option>
          </select>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <ul>
        <li>
          <label for="property-violations-valid">Valid</label>
          <input
            type="text"
            id="property-violations-valid"
            value={utcToZonedTime(
              get(editing, "violations.valid", "").split("/")[0],
              editing.timezone
            )}
            disabled
          />
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>Unit handling:</p>
      <ul>
        {#if get(editing, "tenants.enabled") && get(editing, "violations")}
          <li>
            <label for="property-violations-tenant">Field</label>
            <select
              id="property-violations-tenant"
              name="tenant.request"
              value={get(editing, "violations.tenant.request", false) + ""}
            >
              <option value="true">optional</option>
              <option value="false">disabled</option>
            </select>
          </li>
        {/if}
        <!-- <li>
                <label for="property-violations-photos">Photos</label>
                <select id="property-violations-photos" value={get(editing, "violations.photos.required") ? "true" : get(editing, "violations.photos.request") ? "false" : ""}>
                    <option value="0">optional</option>
                    <option value="1">1 or more</option>
                    <option value="2">2 or more</option>
                    <option value="3">3 or more</option>
                </select>
            </li> -->
        <li>
          <label for="property-violations-infer">If not set</label>
          <select
            id="property-violations-infer"
            name="vehicle.infer.tenant"
            value={get(editing, "violations.vehicle.infer.tenant", false) + ""}
          >
            <option value="true">infer from history</option>
            <option value="false">do nothing</option>
          </select>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>Info message:</p>
      <ul>
        <li>
          <label for="violation-info">Custom</label>
          <textarea
            id="violation-info"
            name="info.text"
            rows="10"
            cols="30"
            value={get(editing, "violations.info.text", "")}
            placeholder="optional"
          />
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>Automatically notify:</p>
      <ul>
        <li>
          <label for="property-violations-email">Email</label>
          <select
            id="property-violations-sms"
            name="notify.email"
            value={get(editing, "violations.notify.email", false) + ""}
          >
            <option value="true">infer from history</option>
            <option value="false">disabled</option>
          </select>
        </li>
        <li>
          <label for="property-violations-sms">Text</label>
          <select
            id="property-violations-sms"
            name="notify.sms"
            value={get(editing, "violations.notify.sms", false) + ""}
          >
            <option value="true">infer from history</option>
            <option value="false">disabled</option>
          </select>
        </li>
        <li>
          <label for="property-violations-delay">Send</label>
          <select
            name="notify.delay"
            value={get(editing, "violations.notify.delay", "PT0S")}
          >
            <option value="PT8H">after 8 hours</option>
            <option value="PT7H">after 7 hours</option>
            <option value="PT6H">after 6 hours</option>
            <option value="PT5H">after 5 hours</option>
            <option value="PT4H">after 4 hours</option>
            <option value="PT3H">after 3 hours</option>
            <option value="PT2H">after 2 hours</option>
            <option value="PT1H">after 1 hour</option>
            <option value="PT45M">after 45 minutes</option>
            <option value="PT30M">after 30 minutes</option>
            <option value="PT15M">after 15 minutes</option>
            <option value="PT10M">after 10 minutes</option>
            <option value="PT5M">after 5 minutes</option>
            <option value="PT1M">after 1 minute</option>
            <option value="PT0S">immediately</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <p>Reasons</p>
      <ul>
        <li>
          <label for="violation-reasons-default">Default</label>
          <select
            id="violation-reasons-default"
            name="reasons.default"
            value={get(editing, "violations.reasons.default", true) + ""}
          >
            <option value="true">enabled</option>
            <option value="false">disabled</option>
          </select>
        </li>
        <li>
          <label for="violation-reasons-custom">Custom</label>
          <textarea
            id="violation-reasons-custom"
            name="reasons"
            rows="10"
            cols="30"
            value={get(editing, "violations.reasons.custom", []).join("\r")}
            placeholder="one per line"
          />
        </li>
      </ul>
    </fieldset>

    <fieldset>
      <ul>
        <li>
          <label for="property-violations-thresholds">Thresholds</label>
          <select
            id="property-violations-thresholds"
            name="thresholds.enabled"
            value={get(editing, "violations.policies.issued.enabled")
              ? "true"
              : "false"}
          >
            <option value="true">enabled</option>
            <option value="false">disabled</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
