export function saveBlobToFile(blob, filenameWithExtension) {
  const url = window.URL.createObjectURL(blob)
  console.log('url', url)
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = url
  link.target = '_blank'
  link.download = filenameWithExtension
  console.log('link', link)
  document.body.appendChild(link)
  link.click()
  
  window.setTimeout(function () {
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }, 0)
}