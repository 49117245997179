import "temporal-polyfill/global";
import "$styles/app.scss";
import "$styles/app.postcss";
import { end, router } from "$utils/router";
import App from "./App.svelte";


new App({ target: document.body, props: {} });

function view(viewname) {
  return function (ctx, next) {
    ctx.params.view = viewname;
    next();
  };
}

router.redirect("/", "/properties");
router("/properties/map", view("propertiesmap"), end);
router("/properties/bboxmap", view("bboxmap"), end);
router("/users/new", view("newuser"), end);
router("/properties/new", view("newproperty"), end);
router("/properties/:property", (ctx) =>
  router(`/properties/${ctx.params.property}/settings`)
);
router("/properties/:property/policies/new", view("newpolicy"), end);
router("/properties/:property/amenities/new", view("newamenitypolicy"), end);
router("/properties/:property/policies/:policy/edit", view("editpolicy"), end);
router(
  "/properties/:property/amenities/:policy/edit",
  view("editamenitypolicy"),
  end
);
router(
  "/properties/:property/parking/:policy/edit",
  view("editparkingpolicy"),
  end
);
router("/properties/:property/parking/notices", view("parkingnotices"), end);
router("/properties/:property/amenities", view("amenitypolicies"), end);
router("/properties/:property/parking", view("parkingpolicies"), end);
router("/properties/:property/amenities/app", view("amenitypreview"), end);
router("/properties/:property/permits/import", view("permitimport"), end);
router("/properties/:property/permits/agenda", view("permitagenda"), end);
router("/properties/:property/parking/app", view("parkingpreview"), end);
router("/properties/:property/map/enforcement", view("enforcementmap"), end);
router("/properties/:property/map/edit", view("editmap"), end);
router("/properties/:property/map/spaces", view("mapspaces"), end);
router("/properties/:property/amenities/notices", view("amenitynotices"), end);
router(`/properties/:property/:view`, end);
router("/properties/:property/metrics/:policy", view("policymetrics"), end);
router(`/payments/collected`, view("paymentsindex"), end);
router(`/search`, view("search"), end);
router(`/:view`, end);
router(`*`, view("properties"), end);

router.start();
