<script>
  import { fetchSearchViolation as search } from "../api";

  let submittable = false;
  let submitting = false;

  async function submit(e) {
    e.preventDefault();

    const form = e.target;

    if (submitting || !submittable) return;

    submitting = true;

    var violation = await search(
      form.elements["serial"].value,
      form.elements["vehicle"].value
    );

    //console.log("res=", res);
    submitting = false;

    if (!violation) alert("No violation found");
    else {
      //form.reset();
      window.location.href = violation.url;
    }

    submittable = false;
  }

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }
</script>

<form on:change={change} on:input={change} on:submit={submit}>
  <header>
    <h1>Find Violation</h1>
  </header>
  <fieldset>
    <ul>
      <li>
        <label for="violation-code">Number</label>
        <input
          id="violation-code"
          type="number"
          name="serial"
          placeholder="required"
          min="100000"
          required
        />
      </li>
      <li>
        <label for="violation-vehicle">Vehicle</label>
        <input
          id="violation-vehicle"
          placeholder="optional"
          type="text"
          maxlength="10"
          autocorrect="off"
          autocapitalize="characters"
          spellcheck="false"
          name="vehicle"
        />
      </li>
    </ul>
  </fieldset>
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Searching" : "Search"}</button
    >
  </footer>
</form>
