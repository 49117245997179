<script>
  import { fetchUpdateProperty as update } from "../api";
  import { get, clone } from "lodash-es";

  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing)) editing = { ...property };
  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    await update(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

{#if editing}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    class="edit property"
  >
    <!-- <header>
        <h1>Settings</h1>
    </header> -->

    <fieldset>
      <ul>
        <li>
          <label for="property-units">Type</label>
          <select
            id="property-units"
            name="units"
            value={get(editing, "units.format", "")}
          >
            <option value="unit">units</option>
            <option value="home">homes</option>
            <option value="apartment">apartments</option>
            <option value="condo">condos</option>
            <option value="townhome">townhomes</option>
            <option value="suite">suites</option>
            <option value="tenant">tenants</option>
            <option value="">disabled</option>
          </select>
        </li>
        <li>
          <label for="property-passcode">Passcodes</label>
          <select
            id="property-passcode"
            disabled
            value={get(editing, "tenants.authentication", "") + ""}
          >
            <option value="false">disabled</option>
            <option value="4">4 digit</option>
            <option value="5">5 digit</option>
            <option value="6">6 digit</option>
            <option value="7">7 digit</option>
            <option value="8">8 digit</option>
          </select>
        </li>

        <li>
          <label for="property-units-meters">Meters</label>
          <select
            id="property-units-meters"
            name="units.meters.principal"
            value={get(editing, "units.meters.principal", false)}
          >
            <option value={false}>disabled</option>
            <option value={true}>enabled</option>
          </select>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>Self Service:</p>
      <ul>
        <li>
          <label for="property-units-tour">Directory</label>
          <select
            id="property-units-tour"
            name="tenants.tour.enabled"
            value={get(editing, "tenants.tour.enabled", false)}
          >
            <option value={false}>disabled</option>
            <option value={true}>enabled</option>
          </select>
        </li>
        <li>
          <label for="property-units-selfservice">Usage</label>
          <select
            id="property-units-selfservice"
            name="tenants.selfservice.enabled"
            value={get(editing, "tenants.selfservice.enabled", false) + ""}
          >
            <option value="true">check enabled</option>
            <option value="false">admin only</option>
          </select>
        </li>
        <li>
          <label for="property-passcode-selfservice">Passcode</label>
          <select
            id="property-passcode-selfservice"
            name="tenants.selfservice.auth"
            value={get(editing, "tenants.selfservice.auth", false) + ""}
          >
            <option value="false">admin only</option>
            <option value="true">request enabled</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
