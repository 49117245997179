<script context="module">
  import { utcToZonedTime, format, toDate as toUtcDate } from "date-fns-tz";
  import { time as timestore } from "../util/timestores";

  function reduceToInterval(interval, date, i) {
    if (i === 0 && date) interval.start = date;
    else if (i === 1 && date) interval.end = date;
    return interval;
  }
  // todate will utc time if tz info, get utc as if string is local based on timezone
  // this is why utc to zoned time is necessary
  function stringToDateInterval(input, timezone) {
    if (!input) return input;
    return input
      .split("/")
      .map(
        (str) =>
          str &&
          utcToZonedTime(toUtcDate(str, { timeZone: timezone }), timezone)
      )
      .reduce(reduceToInterval, {
        start: utcToZonedTime(new Date(), timezone),
        end: utcToZonedTime(new Date(), timezone),
      });
  }
  const calendar = {
    P1D: "day",
    P1W: "week",
    P1M: "month",
    P1Y: "year",
  };
  const nowutc = timestore({ minutes: 60 });
</script>

<script>
  import { sortBy } from "../util/sort";
  import { addDays } from "date-fns";
  import { downloadHandler } from "../util/exportTable";
  import PropertyPaymentsMiniChart from "./PropertyPaymentsMiniChart.svelte";
  import objectDefault, { values } from "lodash-es/object";

  export let data;
  export let property = null;
  let table;

  // $: nowlocal = utcToZonedTime($nowutc);

  // $: month = {
  //   start: startOfMonth(nowlocal),
  //   end: startOfMonth(addMonths(nowlocal, 1)),
  // };
  // $: monthcomplete =
  //   (month.end - month.start) / (month && nowlocal - month.start);

  $: items = processData(data, property);

  $: console.log("overview=", items);

  function label(freq, interval, timezone) {
    if (freq == "P1D")
      return format(
        utcToZonedTime(interval.split("/")[0], timezone),
        "EEE MMM d"
      );
    if (freq == "P1M")
      return format(
        utcToZonedTime(interval.split("/")[0], timezone),
        "MMM yyyy"
      );
    if (freq == "P1W")
      return `${format(
        utcToZonedTime(interval.split("/")[0], timezone),
        "MMM d"
      )}—${format(
        addDays(utcToZonedTime(interval.split("/")[1], timezone), -1),
        "MMM d"
      )}`;
    return (
      "since " +
      format(utcToZonedTime(interval.split("/")[0], timezone), "MMM d")
    );
  }

  function processData(data, property) {
    if (!data) return {};

    return data.metrics.items.reduce((intervals, item) => {
      if (property && item.group != "property") return intervals;
      //if(item.payments != "total") return map;
      if (!property && item.group == "property") return intervals;

      for (const [interval, cents] of Object.entries(item.values)) {
        if (interval.split("/")[0] < item.interval.split("/")[0]) continue; // don't have full data
        if (!intervals[interval])
          intervals[interval] = {
            ...stringToDateInterval(interval, item.timezone),
            timezone: item.timezone,
            interval,
            datetimes: item.dateimes,
            label: label(item.datetimes, interval, item.timezone),
          };
        // intervals[interval].interval = interval;
        // intervals[interval].datetimes = item.datetimes;
        intervals[interval][item.payments] = cents;
        //intervals[interval].timezone = item.timezone;
        // intervals[interval].label = label(
        //   item.datetimes,
        //   interval,
        //   item.timezone
        // );
      }

      return intervals;
    }, {});
  }
  var currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 0,
  }).format;
</script>

<section>
  <header><h1>Payments Summary</h1></header>
  <!-- <figure class="metrics">
        {#each sortBy(Object.values(items).filter(i => i.datetimes == "P1M"), "interval") as data}
        <time>
            {data.label}
            <data value="">{currency(data.total / 100) || ""}</data>
        </time>         
        {/each}
    </figure> -->
  <a
    href="#"
    type="text/csv"
    download="Payments Summary {format(new Date(), 'yyyy-MM-dd')}.csv"
    on:click={(e) => downloadHandler(e, table)}>Download Spreadsheet</a
  >
  {#if data}
    <PropertyPaymentsMiniChart
      width="100%"
      scale="80"
      item={{
        interval: data?.metrics.interval,
        datetimes: "P1M",
        type: "paid",
        metric: "total",
        values: Object.values(items).reduce((values, item) => {
          values[item.interval] = item.total;
          return values;
        }, {}),
      }}
    />
    <PropertyPaymentsMiniChart
      width="100%"
      scale="80"
      item={{
        interval: data?.metrics.interval,
        datetimes: "P1M",
        type: "paid",
        metric: "total",
        values: Object.values(items).reduce((values, item) => {
          values[item.interval] = item.service;
          return values;
        }, {}),
      }}
    />
  {/if}
</section>
<table bind:this={table}>
  <thead>
    <tr>
      <th scope="column row">Interval</th>
      <th scope="column">$total</th>
      <th scope="column">$property</th>
      <th scope="column">$stripe</th>
      <th scope="column">$service</th>
    </tr>
  </thead>
  <tbody>
    {#each sortBy(Object.values(items), "interval", undefined, true) as data}
      <tr>
        <th scope="row"
          ><a href="{window.location.pathname}?valid={data.interval}"
            ><time datetime={data.interval}>{data.label}</time></a
          ></th
        >
        <td>{currency(data.total / 100) || ""}</td>
        <td>{currency(data.net / 100) || ""}</td>
        <td>{currency(data.gateway / 100) || ""}</td>
        <td>{currency(data.service / 100) || ""}</td>
        <td>{currency(data.tax / 100) || ""}</td>
      </tr>
    {/each}
    {#each sortBy( Object.values(items).filter((i) => i.datetimes == "P1W"), "interval", undefined, true ) as data}
      <tr>
        <th scope="row"
          ><a href="{window.location.pathname}?valid={data.interval}"
            ><time datetime={data.interval}>{data.label}</time></a
          ></th
        >
        <td>{currency(data.total / 100) || ""}</td>
        <td>{currency(data.net / 100) || ""}</td>
        <td>{currency(data.gateway / 100) || ""}</td>
        <td>{currency(data.service / 100) || ""}</td>
        <td>{currency(data.tax / 100) || ""}</td>
      </tr>
    {/each}
    {#each sortBy( Object.values(items).filter((i) => i.datetimes == "P1D"), "interval", undefined, true ) as data}
      <tr>
        <th scope="row"
          ><a href="{window.location.pathname}?valid={data.interval}"
            ><time datetime={data.interval}>{data.label}</time></a
          ></th
        >
        <td>{currency(data.total / 100) || ""}</td>
        <td>{currency(data.net / 100) || ""}</td>
        <td>{currency(data.gateway / 100) || ""}</td>
        <td>{currency(data.service / 100) || ""}</td>
        <td>{currency(data.tax / 100) || ""}</td>
      </tr>
    {/each}
    <!-- {#each Object.entries(items).sort(([a], [b]) => textAsc(a, b)) as [ interval, data ]}
            <tr>
                <th scope="row">{data.label}</th>
                <td>{currency(data.total / 100) || ""}</td>
                <td>{currency(data.net / 100) || ""}</td>
                <td>{currency(data.gateway / 100) || ""}</td>
                <td>{currency(data.service / 100) || ""}</td>
            </tr>
            {/each} -->
  </tbody>
  <tfoot />
</table>
