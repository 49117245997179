<script>
  import { result } from "lodash-es";

  import { fetchUser as search } from "../api";

  let submittable = false;
  let submitting = false;

  async function submit(e) {
    e.preventDefault();

    const form = e.target;

    if (submitting || !submittable) return;

    submitting = true;

    var result = await search(form.elements["email"].value);

    //console.log("res=", res);
    submitting = false;

    if (
      !result ||
      !result.users ||
      !result.users.items ||
      Object.keys(result.users.items).length != 1
    )
      alert("No user found");
    else {
      //form.reset();
      window.location.href = `https://my.communityboss.app/users/${
        Object.keys(result.users.items)[0]
      }`;
    }

    submittable = false;
  }

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }
</script>

<form on:change={change} on:input={change} on:submit={submit}>
  <header>
    <h1>Find User</h1>
  </header>
  <fieldset>
    <ul>
      <li>
        <label for="user-email">Email</label>
        <input
          id="user-email"
          type="email"
          name="email"
          placeholder="required"
          required
        />
      </li>
    </ul>
  </fieldset>
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Searching" : "Search"}</button
    >
  </footer>
</form>
