<script>
  import { param } from "../util/params";
  import { policies } from "../stores";
  import Map from "./maps/Map.svelte";
  import MapMarker from "./maps/MapMarker.svelte";
  import MapInspector from "./maps/MapInspector.svelte";
  import MapCompass from "./maps/MapCompass.svelte";
  import MapPosition from "./maps/MapPosition.svelte";
  import MapPitch from "./maps/MapPitch.svelte";
  import {
    style as mapstyle,
    styles,
    level,
    levels,
    propertyGeoJsonLeveled as geojson,
    inspect,
    position,
  } from "./maps/stores";
  import router from "page";

  export let property;

  const defaultStyle = "warm";
  const paramStyle = param("style");
  $: selectedStyle = $paramStyle || defaultStyle;
  const style = mapstyle(defaultStyle);
  let image = null;

  const emergency = param("emergency");
  const shutoff = param("shutoff");
  const parking = param("parking");
  const amenities = param("amenities");
  const photos = param("photos");

  function route(values) {
    var qs = new URLSearchParams(window.location.search);
    if (values) {
      for (const [key, value] of Object.entries(values)) {
        if (value) qs.set(key, value);
        else qs.delete(key);
      }
    }
    router(window.location.pathname + "?" + qs.toString());
  }

  function selectedFeature(feature) {
    if (feature.properties.policy) route({ policy: feature.properties.policy });
    if (feature.properties.subject)
      route({ subject: feature.properties.subject });
    image = feature.properties.image;
  }

  function deselectedFeature(feature) {
    // route({
    //     image: null,
    //     subject: null,
    //     policy: null,
    // });
  }
</script>

<nav class="options">
  <ul
    class="styles"
    on:change={(e) =>
      e.target.checked && route({ [e.target.name]: e.target.value })}
  >
    {#each Object.entries($styles || {}) as [id, style]}
      <li>
        <input
          id="style-{id}"
          type="radio"
          name="style"
          checked={id == selectedStyle}
          value={id}
        /> <label for="style-{id}">{style.name}</label>
      </li>
    {/each}
  </ul>
  <ul class="options">
    <li>
      <input
        id="option-inspect"
        type="checkbox"
        name="inspect"
        checked={$inspect}
        value="true"
        on:change={(e) =>
          route({ [e.target.name]: e.target.checked ? e.target.value : null })}
      /> <label for="option-inspect">Inspect</label>
    </li>
    <li>
      <input
        id="option-amenities"
        type="checkbox"
        name="amenities"
        checked={$amenities}
        value="true"
        on:change={(e) =>
          route({ [e.target.name]: e.target.checked ? e.target.value : null })}
      /> <label for="option-amenities">Amenities</label>
    </li>
    <li>
      <input
        id="option-parking"
        type="checkbox"
        name="parking"
        checked={$parking}
        value="true"
        on:change={(e) =>
          route({ [e.target.name]: e.target.checked ? e.target.value : null })}
      /> <label for="option-parking">Parking</label>
    </li>
    <li>
      <input
        id="option-photos"
        type="checkbox"
        name="photos"
        checked={$photos}
        value="true"
        on:change={(e) =>
          route({ [e.target.name]: e.target.checked ? e.target.value : null })}
      /> <label for="option-photos">Photos</label>
    </li>
    <li>
      <input
        id="option-position"
        type="checkbox"
        name="position"
        checked={$position}
        value="-117.496095,33.906150"
        on:change={(e) =>
          route({ [e.target.name]: e.target.checked ? e.target.value : null })}
      /> <label for="option-position">Position</label>
    </li>
    <li>
      <input
        id="option-emergency"
        type="checkbox"
        name="emergency"
        checked={$emergency}
        value="true"
        on:change={(e) =>
          route({ [e.target.name]: e.target.checked ? e.target.value : null })}
      /> <label for="option-emergency">Emergency</label>
    </li>
    <li>
      <input
        id="option-shutoff"
        type="checkbox"
        name="shutoff"
        checked={$shutoff}
        value="true"
        on:change={(e) =>
          route({ [e.target.name]: e.target.checked ? e.target.value : null })}
      /> <label for="option-shutoff">Shutoff</label>
    </li>
  </ul>

  <!-- <ul class="units" on:change={e => (e.target.checked && route({ [e.target.name]: e.target.value }))}>
            {#each $geounits as feature}
            <li><input id="unit-{feature.properties.name}" type="radio" name="unit" value="{feature.properties.name}" checked={$params.unit === feature.properties.name} /> <label for="unit-{feature.properties.name}">{feature.properties.name}</label></li>
            {/each}
        </ul>
        <ul class="spaces" on:change={e => (e.target.checked && route({ [e.target.name]: e.target.value }))}>
            {#each $geospaces as feature}
            <li><input id="space-{feature.properties.name}" type="radio" name="space" value="{feature.properties.name}" checked={$space === feature.properties.name} /> <label for="space-{feature.properties.name}">{feature.properties.name}</label></li>
            {/each}
        </ul> -->
</nav>
{#if $geojson && $style}
  <Map geojson={$geojson} style={$style}>
    {#if $inspect}
      <MapInspector />
    {/if}
    <MapCompass />
    <MapPitch />
    {#if $position}
      <MapPosition position={$position} heading={235} />
    {/if}
    {#if $photos}
      {#each $geojson.features.filter((f) => f.geometry.type == "Point" && f.id && f.properties.amenity == "image") as feature}
        <MapMarker
          {feature}
          on:selected={(e) => selectedFeature(e.detail)}
          on:deselected={(e) => deselectedFeature(e.detail)}
        />
      {/each}
    {/if}
    {#if $amenities}
      {#each $geojson.features.filter((f) => f.geometry.type == "Point" && f.id && (f.properties.amenity || f.properties.leisure) && f.properties.amenity != "parking" && f.properties.amenity != "parking_space" && f.properties.amenity != "image") as feature}
        <MapMarker
          {feature}
          on:selected={(e) => selectedFeature(e.detail)}
          on:deselected={(e) => deselectedFeature(e.detail)}
        />
      {/each}
    {/if}
    {#if $parking}
      {#each $geojson.features.filter((f) => f.geometry.type == "Point" && f.id && f.properties.amenity == "parking") as feature}
        <MapMarker
          {feature}
          on:selected={(e) => selectedFeature(e.detail)}
          on:deselected={(e) => deselectedFeature(e.detail)}
        />
      {/each}
    {/if}
    {#if $emergency}
      {#each $geojson.features.filter((f) => f.geometry.type == "Point" && f.id && !!f.properties.emergency) as feature}
        <MapMarker {feature} />
      {/each}
    {/if}
    {#if $shutoff}
      {#each $geojson.features.filter((f) => f.geometry.type == "Point" && f.id && (f.properties.pipeline == "valve" || f.properties.power == "switch")) as feature}
        <MapMarker {feature} />
      {/each}
    {/if}
    <nav class="levels">
      <ul
        class="levels"
        on:change={(e) =>
          e.target.checked && route({ [e.target.name]: e.target.value })}
      >
        {#if $levels && $levels.length > 0}
          {#each [["", "Outside"], ...$levels].sort((a, b) => (b[0] || 0) - (a[0] || 0)) as [id, name]}
            <li>
              <input
                id="level-{id}"
                type="radio"
                name="level"
                checked={id == $level}
                value={id}
              /> <label for="level-{id}">{id || "-"}</label>
            </li>
          {/each}
        {/if}
      </ul>
    </nav>
  </Map>
{/if}
