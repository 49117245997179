<script>
  import { startOfWeek, format, addMilliseconds, isEqual } from "date-fns";
  import { map, flatMap, flatMapDeep } from "lodash-es";

  export let concurrent = 0;
  export let valid = []; // range of day time ranges

  let increment = null;

  // cache so it's only set once w values

  $: console.log("values=", valid);

  $: closed = concurrent === 0;
  $: incrementFromData = valid.find(
    (item) => item.indexOf(":15") > 0 || item.indexOf(":45") > 0
  )
    ? 15 * 60 * 1000
    : valid.find((item) => item.indexOf(":30") > 0)
    ? 30 * 60 * 1000
    : 60 * 60 * 1000;
  //$:console.log("increment valid=", incrementFromData, valid);

  $: increment = incrementFromData;

  $: items = build(increment, valid); // increment will restart
  //$: console.log("discrete=", calculateDiscretePeriods(items).map(({ start, end }) => `${format(start, "i'T'HH:mm")}/${format(end, "i'T'HH:mm")}`).join(","));

  //$: console.log("calculated valids=", valid);

  function calculateDiscretePeriods(items) {
    console.log("calculateDiscretePeriods=", items);

    const result = items.reduce(
      (result, { startdaytime, enddaytime, selected }, i, items) => {
        if (!selected) return result; // skip me!

        var prev = result.length && result[result.length - 1];

        // test for merge back case
        if (
          prev &&
          doDayTimeIntervalsOverlap(
            `${prev.startdaytime}/${prev.enddaytime}`,
            `${startdaytime}/${enddaytime}`,
            true
          )
        ) {
          //if(prev && prev.enddaytime >= startdaytime) {
          prev.enddaytime = enddaytime;
          return result; // no prev to merge to
        }

        result.push({ startdaytime, enddaytime });

        return result;
      },
      []
    );

    // test for last item overlapping with start item, move to front
    if (result.length == 2) {
      const first = result[0];
      const last = result[result.length - 1];
      console.log("testing first/last, first=", first, "last=", last);
      if (
        last.enddaytime == first.startdaytime &&
        last.startdatetime == first.enddaytime
      ) {
        return [];
      }

      if (last.enddaytime == first.startdaytime) {
        result.shift(); // remove first
        last.enddaytime = first.enddaytime;
        //result.pop();
        //first.start = last.start;
      }
    } else if (result.length > 2) {
      const first = result[0];
      const last = result[result.length - 1];
      //console.log("testing first/last, first=", first, "last=", last);
      if (last.enddaytime == first.startdaytime) {
        result.shift(); // remove first
        last.enddaytime = first.enddaytime;
        //result.pop();
        //first.start = last.start;
      }
    }

    return result;
  }

  // function doesOverlap(a1, a2, b1, b2, includeAdjacent = false) {

  //     if(includeAdjacent) return a2 >= b1 && b2 >= a1; // or a1 <= b2 && a2 >= b1

  //     // covers non-zero overlap
  //     return a2 > b1 && b2 > a1; // same?

  // }

  function compareDayTime(aDay, aTime, bDay, bTime) {
    const [aH, aM] = aTime.split(":").map((i) => parseInt(i));
    const [bH, bM] = bTime.split(":").map((i) => parseInt(i));

    //console.log("compareDayTime=", aDay, aTime, aH, aM, bDay, bTime, bH, bM);

    if (aDay < bDay) return -1;
    if (aDay > bDay) return 1;

    // same day...

    if (aH < bH) return -1;
    if (aH > bH) return 1;

    // same hour...

    if (aM < bM) return -1;
    if (aM > bM) return 1;

    // same minute...

    // equal
    return 0;
  }

  function doDayTimeIntervalsOverlap(a, b, adjacent = false) {
    //console.log("doDayTimeIntervalsOverlap=", a, b, adjacent);

    const [
      aMinDay,
      aMinTime,
      aMaxDay,
      aMaxTime,
      bMinDay,
      bMinTime,
      bMaxDay,
      bMaxTime,
    ] = flatMapDeep([a, b].map((i) => i.split("/").map((i) => i.split("T"))));

    var aWeekReset =
      aMaxDay < aMinDay || (aMinDay == aMaxDay && aMaxTime < aMinTime);
    var bWeekReset =
      bMaxDay < bMinDay || (bMinDay == bMaxDay && bMaxTime < bMinTime);
    var aEmpty = aMinDay == aMaxDay && aMinTime == aMaxTime;
    var bEmpty = bMinDay == bMaxDay && bMinTime == bMaxTime;

    //console.log(a, b, aMinDay, aMinTime, aMaxDay, aMaxTime, bMinDay, bMinTime, bMaxDay, bMaxTime);

    //return doesOverlap(a1, a2, b1, b2, includeAdjacent);

    if (aEmpty && bEmpty && !adjacent) return false; // have to be adjacent for OK for this to happen

    // shortcut adjacent
    if (0 == compareDayTime(aMinDay, aMinTime, bMaxDay, bMaxTime))
      return adjacent; // allowing adjacent
    if (0 == compareDayTime(aMaxDay, aMaxTime, bMinDay, bMinTime))
      return adjacent; // allowing adjacent

    // don't have to care about adjacent for these...
    if (aWeekReset && bWeekReset) {
      // both span the week reset boundary, we know they both share at least that point in time - can't be adjacent at just that point because one wouldn't be crossing
      return true;
    } else if (!aWeekReset && bWeekReset) {
      // since a ends in the normal week flow, b has to start before a ends OR has to end after a starts
      return (
        compareDayTime(bMinDay, bMinTime, aMaxDay, aMaxTime) < 0 ||
        compareDayTime(bMaxDay, bMaxTime, aMinDay, aMinTime) > 0
      );
      //return b.Minimum.CompareTo(a.Maximum) < 0 || b.Maximum.CompareTo(a.Minimum) > 0;
    } else if (aWeekReset && !bWeekReset) {
      // same in reverse
      //return a.Minimum.CompareTo(b.Maximum) < 0 || a.Maximum.CompareTo(b.Minimum) > 0;

      return (
        compareDayTime(aMinDay, aMinTime, bMaxDay, bMaxTime) < 0 ||
        compareDayTime(aMaxDay, aMaxTime, bMinDay, bMinTime) > 0
      );
    }

    return (
      compareDayTime(bMinDay, bMinTime, aMaxDay, aMaxTime) < 0 &&
      compareDayTime(bMaxDay, bMaxTime, aMinDay, aMinTime) > 0
    );

    // this is a normal range set
    if (adjacent)
      return (
        compareDayTime(aMaxDay, aMaxTime, bMinDay, bMinTime) >= 0 &&
        compareDayTime(bMaxDay, bMaxTime, aMinDay, aMinTime) >= 0
      );

    //a2 > b1 && b2 > a1
    return (
      compareDayTime(aMaxDay, aMaxTime, bMinDay, bMinTime) > 0 &&
      compareDayTime(bMaxDay, bMaxTime, aMinDay, aMinTime) > 0
    );

    //return Range.Overlaps(a.Minimum, a.Maximum, b.Minimum, b.Maximum, adjacent);
  }

  function build(increment, values) {
    const max = 7 * 24 * 60 * 60 * 1000; // one full week
    const items = [];

    //let start = startOfWeek(Date.now());
    let start = new Date(2020, 4, 3);
    let total = 0;
    while (total < max) {
      const end = addMilliseconds(start, increment);

      // handle daylight savings

      const daytime = `${format(start, "i'T'HH:mm")}/${format(
        end,
        "i'T'HH:mm"
      )}`;
      const overlap =
        values &&
        values.find((item) => doDayTimeIntervalsOverlap(item, daytime));
      //console.log("overlaps=", daytime, overlap);
      items.push({
        start: start,
        end: end,
        day: format(start, "i"),
        daytime,
        startdaytime: format(start, "i'T'HH:mm"),
        enddaytime: format(end, "i'T'HH:mm"),
        time: `${format(start, "HH:mm")}/${format(end, "HH:mm")}`,
        selected: !!overlap,
      });
      // increments
      total += increment;
      start = end;
    }

    return items;
  }

  function set(day, time, value) {
    // update all matching items
    //day = parseInt(day);

    console.log("setting=", items, day, time, value);

    // single item
    if (day && time) {
      console.log(
        "setting day and time=",
        day,
        time,
        items.filter((i) => i.day == day && i.time == time)
      );
      items
        .filter((i) => i.day == day && i.time == time)
        .forEach((i) => (i.selected = value === true));
    } else if (day) {
      //console.log("setting day=", day, items.filter(i => i.day == day));
      items
        .filter((i) => i.day == day)
        .forEach((i) => (i.selected = value === true));
    } else if (time) {
      //console.log("setting time=", time, items.filter(i => i.time == time));
      items
        .filter((i) => i.time == time)
        .forEach((i) => (i.selected = value === true));
    }

    //valid = calculateDiscretePeriods(items).map(({start, end}) => `${format(start, "i'T'HH:mm")}/${format(end, "i'T'HH:mm")}`);
    valid = calculateDiscretePeriods(items).map(
      ({ startdaytime, enddaytime }) => `${startdaytime}/${enddaytime}`
    );
    //items = items;
  }
</script>

{#each valid as valid}
  <input type="hidden" name="valid" value={valid} />
{/each}
<table class="daytime" class:closed>
  <caption>
    <!-- {#if closed}
        <h1>Closed</h1>
        {/if}
        Takes effect on: choose day -->

    <ul on:change={(e) => (increment = parseInt(e.target.value))}>
      <li>
        <input
          id="increment-60m"
          type="radio"
          name="increment"
          value={60 * 60 * 1000}
          disabled={incrementFromData < 60 * 60 * 1000}
          checked={increment == 60 * 60 * 1000}
        /><label for="increment-60m">1:00</label>
      </li>
      <li>
        <input
          id="increment-30m"
          type="radio"
          name="increment"
          value={30 * 60 * 1000}
          checked={increment == 30 * 60 * 1000}
        /><label for="increment-30m">0:30</label>
      </li>
      <li>
        <input
          id="increment-15m"
          type="radio"
          name="increment"
          value={15 * 60 * 1000}
          checked={increment == 15 * 60 * 1000}
        /><label for="increment-15m">0:15</label>
      </li>
    </ul>
  </caption>
  <thead>
    <tr on:change={(e) => set(e.target.value, null, e.target.checked)}>
      <th scope="col" />
      {#each items.filter((i) => i.time.startsWith("00:00/")) as byDay}
        <th scope="col"
          ><input type="checkbox" value={byDay.day} checked={false} />{format(
            byDay.start,
            "EEE"
          )}</th
        >
      {/each}
    </tr>
  </thead>
  <tbody>
    {#each items.filter((i) => i.day == "7") as byTime}
      <tr>
        <th scope="row">
          <input
            type="checkbox"
            on:change={(e) => set(null, byTime.time, e.target.checked)}
          />
          <time datetime={format(byTime.end, "HH:mm")}
            >{format(
              byTime.start,
              byTime.start.getMinutes() != 0 ? ":mm" : "h a"
            )}</time
          >
          <time datetime={format(byTime.end, "HH:mm")}
            >{format(byTime.end, "h a")}</time
          >
        </th>
        {#each items.filter((i) => i.time === byTime.time) as byDay, i}
          <td
            ><input
              type="checkbox"
              value={byDay.daytime}
              checked={byDay.selected}
              on:change={(e) => set(byDay.day, byTime.time, e.target.checked)}
            /></td
          >
        {/each}
      </tr>
    {/each}
  </tbody>
</table>
