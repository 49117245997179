<script lang="ts">
  import type { UploadFormViewModel } from "./stores";

  export let view_model: UploadFormViewModel;

  function set_header(index: number, e: Event) {
    const elm = e.currentTarget as HTMLInputElement;
    const value = elm.value;
    view_model.set_header(index, value);
  }
</script>

{#if $view_model.json?.length}
  <div class="container">
    <table>
      <thead>
        <tr>
          <th class="text-left">
            {$view_model.json.length}
            <br />
            Rows
          </th>
          {#each $view_model.headers as header, i}
            <th>
              {header}
              <form action="">
                <select
                  name="Type"
                  on:change={(e) => set_header(i, e)}
                  disabled={$view_model.importing}
                >
                  <option value="">Ignore</option>
                  <option value="media">Smart Decal</option>
                  <option value="vehicle">License Plate</option>
                  <option value="tenant"
                    >Current Unit Tenant (Revokes on Reset)</option
                  >
                  <option value="unit">Unit (Survives Reset)</option>
                  <option value="space">Space</option>
                  <option value="name">Name</option>
                  <option value="tel">Phone</option>
                  <option value="email">Email</option>
                  <option value="notes">Notes</option>
                  <option value="endDate">Valid Through</option>
                </select>
              </form>
            </th>
          {/each}
        </tr>
      </thead>
      <tbody>
        {#each $view_model.json as item, i}
          <tr>
            <td>{i + 1}</td>
            {#each Object.entries(item) as [key, val]}
              <td>{val}</td>
            {/each}
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
{/if}

<style lang="scss">
  .container {
    overflow: auto;
    max-height: 600px;
  }
  table {
    width: 100%;

    th {
      position: sticky;
    }
  }
</style>
