<script>
  import { policies } from "$stores";
  import { upload } from "$utils/upload";
  import { debounce } from "lodash-es";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let editing;
  export let property;

  function onChange(e) {
    updateProperties(e.target.form, editing);
  }

  const updateProperties = debounce(
    function (form, editing) {
      if (!editing || !form) return;

      //form = form || e.target.form || e.target;

      console.log("editing form", form);

      const properties = {};
      for (let i = 0; i < form.elements.length; i++) {
        if (!form.elements[i].name) continue;
        if (!form.elements[i].value) continue;
        properties[form.elements[i].name] = form.elements[i].value;
      }

      editing.properties = properties; // this will update
      editing = editing;

      // if(updated[editing.id]) {
      //     updated[editing.id].properties = properties;
      // } else {
      //     updated[editing.id] = editing;
      //     updated = updated;
      // }

      dispatch("change", editing);

      //console.log("updatedOnChange=", updated);
    },
    10,
    {
      leading: true,
      trailing: true,
    }
  );

  async function onimage(e) {
    // upload and copy to previous input

    const input = e.target;

    if (!input) return;

    if (!input.files || input.files.length <= 0) return;

    const file = input.files[0];

    var json = await upload(property, file);

    input.value = "";

    var dest = input.previousElementSibling;

    dest.value = json.url;
    onChange({
      target: dest,
    });
  }
</script>

{#if editing}
  <form on:change={onChange} on:input={onChange} on:blur={onChange}>
    <fieldset>
      <ul>
        <li>
          <textarea>
            {JSON.stringify(editing, null, 4)}
          </textarea>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>...basic</p>
      <ul>
        <li>
          <label for="geo-ref"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:ref"
              target="_blank"
              rel="noreferrer">#ref</a
            ></label
          >
          <input
            id="geo-ref"
            type="text"
            name="ref"
            value={editing.properties.ref || ""}
          />
        </li>
        <li>
          <label for="geo-name"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:name"
              target="_blank"
              rel="noreferrer">#name</a
            ></label
          >
          <input
            id="geo-name"
            type="text"
            name="name"
            value={editing.properties.name || ""}
          />
        </li>
        <li>
          <label for="geo-image"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:image"
              target="_blank"
              rel="noreferrer">#image</a
            ></label
          >
          <input
            id="geo-image"
            type="url"
            name="image"
            value={editing.properties.image || ""}
          />
          <input
            type="file"
            title="Select photo"
            accept="image/*"
            name="photo"
            on:change={onimage}
          />
        </li>

        <li>
          <label for="geo-description"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:description"
              target="_blank"
              rel="noreferrer">#description</a
            ></label
          >
          <textarea id="geo-description" type="text" name="description"
            >{editing.properties.description || ""}</textarea
          >
        </li>
        <li>
          <label for="geo-url"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:website"
              target="_blank"
              rel="noreferrer">#website</a
            ></label
          >
          <input
            id="geo-url"
            type="url"
            name="website"
            value={editing.properties.website || ""}
          />
        </li>
        <li>
          <label for="geo-phone"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:phone"
              target="_blank"
              rel="noreferrer">#phone</a
            ></label
          >
          <input
            id="geo-phone"
            type="tel"
            name="phone"
            value={editing.properties.phone || ""}
          />
        </li>
        <li>
          <label for="geo-email"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:email"
              target="_blank"
              rel="noreferrer">#email</a
            ></label
          >
          <input
            id="geo-email"
            type="email"
            name="email"
            value={editing.properties.email || ""}
          />
        </li>
        <li>
          <label for="geo-opening-hours"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:opening_hours"
              target="_blank"
              rel="noreferrer">#opening_hours</a
            ></label
          >
          <textarea id="geo-opening-hours" type="text" name="opening_hours"
            >{editing.properties.opening_hours || ""}</textarea
          >
        </li>
        <li>
          <label for="geo-policy">#policy</label>
          <select
            id="geo-policy"
            value={($policies &&
              $policies.length &&
              editing.properties.subject) ||
              ""}
            name="policy"
          >
            <option value="" />
            {#each $policies || [] as policy}
              <option value={policy.policy}>{policy.title}</option>
            {/each}
          </select>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>...physical</p>
      <ul>
        <li>
          <label for="geo-level"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:level"
              target="_blank"
              rel="noreferrer">#level</a
            ></label
          >
          <input
            id="geo-level"
            type="text"
            name="level"
            value={editing.properties.level || ""}
          />
          <!-- <select id="geo-level" value="{(($levels && $levels.length && editing.properties.level) || $level || "")+""}" name="level">
                    <option value=""></option>
                    {#each [ [ "", "Outside" ], ...$levels ].sort((a, b) => (b[0] || 0) - (a[0] || 0)) as [ id, name ]}
                    <option value="{id}">{id||name}</option>
                    {/each}
                </select> -->
        </li>
        <li>
          <label for="geo-level-ref"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:level:ref"
              target="_blank"
              rel="noreferrer">#level:ref</a
            ></label
          >
          <input
            id="geo-level-ref"
            type="text"
            name="level:ref"
            value={editing.properties["level:ref"] || ""}
          />
        </li>
        <li>
          <label for="geo-building">#building</label>
          <select
            id="geo-building"
            value={editing.properties.building || ""}
            name="building"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="apartments">apartments</option>
            <option value="parking">parking</option>
            <option value="house">house</option>
            <option value="roof">roof</option>
            <option value="residential">residential</option>
            <option value="office">office</option>
            <option value="retail">retail</option>
            <option value="kiosk">kiosk</option>
            <option value="warehouse">warehouse</option>
            <option value="toilets">toilets</option>
            <option value="greenhouse">greenhouse</option>
            <option value="shed">shed</option>
            <option value="carport">carport</option>
            <option value="garage">garage</option>
            <option value="garages">garages</option>
            <option value="service">service</option>
          </select>
        </li>
        <li>
          <label for="geo-building-ref"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:building:ref"
              target="_blank"
              rel="noreferrer">#building:ref</a
            ></label
          >
          <input
            id="geo-building-ref"
            type="text"
            name="building:ref"
            value={editing.properties["building:ref"] || ""}
          />
        </li>
        <li>
          <label for="geo-location">#location</label>
          <select
            id="geo-location"
            value={editing.properties.location || ""}
            name="location"
          >
            <option value="" />
            <option value="underground">underground</option>
            <option value="roof">roof</option>
            <option value="indoor">indoor</option>
            <option value="outdoor">outdoor</option>
          </select>
        </li>
        <li>
          <label for="geo-indoor">#indoor</label>
          <select
            id="geo-indoor"
            value={editing.properties.indoor || ""}
            name="indoor"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="level">level</option>
            <option value="room">room</option>
            <option value="area">area</option>
            <option value="corridor">corridor</option>
          </select>
        </li>
        <li>
          <label for="geo-outdoor">#outdoor</label>
          <select
            id="geo-outdoor"
            value={editing.properties.outdoor || ""}
            name="outdoor"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="level">level</option>
            <option value="area">area</option>
          </select>
        </li>
        <li>
          <label for="geo-room">#room</label>
          <select
            id="geo-room"
            value={editing.properties.room || ""}
            name="room"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="entrance">entrance</option>
            <option value="office">office</option>
            <option value="stairs">stairs</option>
            <option value="elevator">elevator</option>
            <option value="toilet">toilet</option>
            <option value="living">living</option>
            <option value="dining">dining</option>
            <option value="bedroom">bedroom</option>
            <option value="kitchen">kitchen</option>
            <option value="storage">storage</option>
            <option value="balcony">balcony</option>
            <option value="reception">reception</option>
            <option value="lounge">lounge</option>
            <option value="conference">conference</option>
            <option value="delivery">delivery</option>
          </select>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>...services</p>
      <ul>
        <li>
          <label for="geo-amenity">#amenity</label>
          <select
            id="geo-amenity"
            value={editing.properties.amenity || ""}
            name="amenity"
          >
            <option value="" />
            <option value="image">image</option>
            <optgroup label="parking">
              <option value="parking">parking</option>
              <option value="parking_entrance">parking entrance</option>
              <option value="parking_space">parking space</option>
            </optgroup>
            <optgroup label="bicycles">
              <option value="bicycle_parking">bicycle parking</option>
              <option value="bicycle_repair_station">bicycle repair</option>
            </optgroup>
            <option value="loading_dock">loading dock</option>
            <option value="compressed_air">tire pump</option>
            <option value="car_wash">car wash</option>
            <optgroup label="post">
              <option value="post_box">mail (outgoing)</option>
              <option value="letter_box">mail (incoming)</option>
              <option value="post_depot">mail room</option>
              <option value="package_lockers">package lockers</option>
              <option value="post_office">post office</option>
            </optgroup>
            <option value="waste_disposal">garbage</option>
            <option value="waste_basket">garbage basket</option>

            <option value="cinema">cinema</option>
            <option value="lounge">lounge</option>
            <option value="toy_library">toys/games</option>
            <optgroup label="financial">
              <option value="bank">bank</option>
              <option value="atm">atm</option>
            </optgroup>

            <option value="bbq">BBQ</option>
            <option value="toilets">restroom</option>
            <option value="vending_machine">vending</option>
            <option value="library">library</option>
            <option value="studio">studio</option>
            <option value="coworking_space">coworking</option>
            <option value="meeting">meeting</option>
            <option value="kitchen">kitchen</option>
            <option value="shelter">shelter</option>
            <option value="theatre">theatre</option>
            <option value="dressing_room">dressing/changing room</option>
            <optgroup label="dining">
              <option value="cafe">cafe</option>
              <option value="pub">pub</option>
              <option value="bar">bar</option>
              <option value="fast_food">fast food</option>
              <option value="restaurant">restaurant</option>
            </optgroup>
            <optgroup label="healthcare">
              <option value="clinic">clinic</option>
              <option value="pharmacy">pharmacy</option>
              <option value="hospital">hospital</option>
              <option value="doctors">doctors</option>
              <option value="dentist">dentist</option>
            </optgroup>
            <optgroup label="dogs">
              <option value="dog_toilet">dog relief</option>
              <option value="pet_grooming">dog wash/grooming</option>
            </optgroup>
          </select>
        </li>
        <li>
          <label for="geo-tourism">#tourism</label>
          <select
            id="geo-tourism"
            value={editing.properties.tourism || ""}
            name="tourism"
          >
            <option value="" />
            <option value="apartment">apartment</option>
          </select>
        </li>
        <li>
          <label for="geo-office">#office</label>
          <select
            id="geo-office"
            value={editing.properties.office || ""}
            name="office"
          >
            <option value="" />
            <option value="guide">guide</option>
            <option value="property_management">property management</option>
            <option value="leasing">leasing</option>
            <option value="management">management</option>
            <option value="security">security</option>
            <option value="coworking">coworking</option>
            <option value="estate_agent">real estate</option>
          </select>
        </li>
        <li>
          <label for="geo-leisure"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:leisure"
              target="_blank"
              rel="noreferrer">#leisure</a
            ></label
          >
          <select
            id="geo-leisure"
            value={editing.properties.leisure || ""}
            name="leisure"
          >
            <option value="" />
            <option value="swimming_pool">swimming</option>
            <option value="fitness_centre">fitness center</option>
            <option value="playground">playground</option>
            <option value="dog_park">dog park</option>
            <option value="firepit">firepit</option>
            <option value="amusement_arcade">games/amusement</option>
            <!-- <option value="toy_library">toys/games</option> -->
            <option value="fitness_centre">fitness</option>
            <option value="track">track</option>
            <option value="pitch">pitch</option>
            <option value="fitness_station">fitness station/equipment</option>
            <option value="garden">garden</option>
            <option value="picnic_table">picnic_table</option>
            <option value="sauna">sauna</option>
            <option value="garden">garden</option>
            <option value="park">park</option>
            <option value="outdoor_seating">outdoor seating</option>
            <option value="bleachers">bleachers</option>
            <option value="massage">massage</option>
            <option value="tanning_salon">tanning</option>
            <option value="bowling_alley">bowling alley</option>
            <option value="beach_resort">beach resort</option>
          </select>
        </li>
        <li>
          <label for="geo-shop"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:shop"
              target="_blank"
              rel="noreferrer">#shop</a
            ></label
          >
          <select
            id="geo-shop"
            value={editing.properties.shop || ""}
            name="shop"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="clothes">clothes</option>
            <option value="florist">florist</option>
            <option value="cosmetics">cosmetics</option>
            <option value="pet">pet supplies</option>
            <option value="boutique">boutique</option>
            <option value="mobile_phone">mobile phone</option>
            <option value="sports">sports</option>
            <option value="alcohol">alcohol</option>
            <optgroup label="food to-go (otherwise amenity=cafe)">
              <option value="coffee">coffee</option>
              <option value="pastry">pastry</option>
              <option value="ice_cream">ice cream</option>
            </optgroup>
            <optgroup label="services">
              <option value="laundry">laundry</option>
              <option value="dry_cleaning">dry cleaning</option>
              <option value="pet_grooming">pet grooming</option>
              <option value="hairdresser">hairdresser</option>
              <option value="beauty">beauty</option>
              <option value="massage">massage</option>
            </optgroup>
          </select>
        </li>

        <li>
          <label for="geo-vending"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:vending"
              target="_blank"
              rel="noreferrer">#vending</a
            ></label
          >
          <select
            id="geo-vending"
            value={editing.properties.vending || ""}
            name="vending"
          >
            <option value="" />
            <option value="excrement_bags">pet waste bags</option>
          </select>
        </li>
        <li>
          <label for="geo-waste"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:waste"
              target="_blank"
              rel="noreferrer">#waste</a
            ></label
          >
          <select
            id="geo-waste"
            value={editing.properties.waste || ""}
            name="waste"
          >
            <option value="" />
            <option value="trash">trash</option>
            <option value="dog_excrement">pet waste</option>
            <option value="organic">compost</option>
            <option value="cigarettes">cigarettes</option>
          </select>
        </li>
        <li>
          <label for="geo-self-service"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:self_service"
              target="_blank"
              rel="noreferrer">#self_service</a
            ></label
          >
          <select
            id="geo-self-service"
            value={editing.properties["self_service"] || ""}
            name="self_service"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="no">no</option>
            <option value="only">only</option>
            <option value="partially">partially</option>
          </select>
        </li>
        <li>
          <label for="studio"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:studio"
              target="_blank"
              rel="noreferrer">#studio</a
            ></label
          >
          <input
            id="studio"
            type="text"
            name="studio"
            value={editing.properties.studio || ""}
          />
        </li>
        <li>
          <label for="geo-internet-access"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:internet_access"
              target="_blank"
              rel="noreferrer">#internet_acess</a
            ></label
          >
          <select
            id="geo-self-service"
            value={editing.properties["internet_access"] || ""}
            name="internet_access"
          >
            <option value="" />
            <option value="wlan">wifi</option>
          </select>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>
        <a
          href="https://wiki.openstreetmap.org/wiki/Key:payment:*"
          target="_blank"
          rel="noreferrer">...payment</a
        >
      </p>
      <ul>
        <li>
          <label for="geo-fee"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:fee"
              target="_blank"
              rel="noreferrer">#fee</a
            ></label
          >
          <select
            id="geo-fee"
            value={editing.properties["fee"] || ""}
            name="fee"
          >
            <option value="">n/a</option>
            <option value="yes">yes</option>
            <option value="no">no</option>
            <option value="donation">donation</option>
          </select>
        </li>
        <li>
          <label for="geo-charge"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:charge"
              target="_blank"
              rel="noreferrer">#charge</a
            ></label
          >
          <textarea id="geo-charge" name="charge"
            >{editing.properties.charge || ""}</textarea
          >
        </li>
        <li>
          <label for="geo-payment-cash">#payment:cash</label>
          <select
            id="geo-payment-cash"
            value={editing.properties["payment:cash"] || ""}
            name="payment:cash"
          >
            <option value="">n/a</option>
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </li>
        <li>
          <label for="geo-payment-coins">#payment:coins</label>
          <select
            id="geo-payment-coins"
            value={editing.properties["payment:coins"] || ""}
            name="payment:coins"
          >
            <option value="">n/a</option>
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </li>
        <li>
          <label for="geo-payment-notes">#payment:notes</label>
          <select
            id="geo-payment-notes"
            value={editing.properties["payment:notes"] || ""}
            name="payment:notes"
          >
            <option value="">n/a</option>
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </li>
        <li>
          <label for="geo-payment-cards">#payment:cards</label>
          <select
            id="geo-payment-cards"
            value={editing.properties["payment:cards"] || ""}
            name="payment:cards"
          >
            <option value="">n/a</option>
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </li>
        <li>
          <label for="geo-payment-debit-cards">#payment:debit_cards</label>
          <select
            id="geo-payment-debit-cards"
            value={editing.properties["payment:debit_cards"] || ""}
            name="payment:debit_cards"
          >
            <option value="">n/a</option>
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </li>
        <li>
          <label for="geo-payment-credit-cards">#payment:credit_cards</label>
          <select
            id="geo-payment-credit-cards"
            value={editing.properties["payment:credit_cards"] || ""}
            name="payment:credit_cards"
          >
            <option value="">n/a</option>
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </li>
        <li>
          <label for="geo-payment-contactless">#payment:contactless</label>
          <select
            id="geo-payment-contactless"
            value={editing.properties["payment:contactless"] || ""}
            name="payment:contactless"
          >
            <option value="">n/a</option>
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <ul>
        <li>
          <label for="geo-access">#surface</label>
          <select
            id="geo-surface"
            value={editing.properties.surface || ""}
            name="surface"
          >
            <option value="" />
            <option value="paved">paved</option>
            <option value="unpaved">unpaved</option>
            <option value="asphalt">asphalt</option>
            <option value="concrete">concrete</option>
            <option value="gravel">gravel</option>
            <option value="wood">wood</option>
            <option value="metal">metal</option>
            <option value="sand">sand</option>
            <option value="dirt">dirt</option>
            <option value="earth">earth</option>
            <option value="grass">grass</option>
            <option value="woodchips">woodchips</option>
            <option value="clay">clay</option>
            <option value="artificial_turf">artificial turf</option>
            <option value="carpet">carpet</option>
            woodchips
          </select>
        </li>

        <li>
          <label for="geo-entrance">#entrance</label>
          <select
            id="geo-entrance"
            value={editing.properties.entrance || ""}
            name="entrance"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="main">main</option>
            <option value="home">home</option>
            <option value="garage">garage</option>
            <option value="staircase">staircase</option>
            <option value="service">service</option>
            <option value="exit">exit</option>
            <option value="emergency">emergency</option>
          </select>
        </li>
        <li>
          <label for="geo-exit">#exit</label>
          <select
            id="geo-exit"
            value={editing.properties.exit || ""}
            name="exit"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="emergency">emergency</option>
          </select>
        </li>
        <li>
          <label for="geo-covered">#covered</label>
          <select
            id="geo-covered"
            value={editing.properties.covered || ""}
            name="covered"
          >
            <option value="" />
            <option value="yes">yes</option>
          </select>
        </li>
        <li>
          <label for="geo-sport"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:sport"
              target="_blank"
              rel="noreferrer">#sport</a
            ></label
          >
          <select
            id="geo-sport"
            value={editing.properties.sport || ""}
            name="sport"
          >
            <option value="" />
            <option value="boules">bocce</option>
            <option value="squash">squash</option>
            <option value="soccer">soccer</option>
            <option value="tennis">tennis</option>
            <option value="basketball">basketball</option>
            <option value="baseball">baseball</option>
            <option value="golf">golf</option>
            <option value="pickleball">pickleball</option>
            <option value="volleyball">volleyball</option>
            <option value="horseshoes">horseshoes</option>
            <option value="cornhole">cornhole</option>
            <option value="croquet">croquet</option>
            <option value="10pin">10 pin bowling</option>
            <option value="pilates">pilates</option>
            <option value="yoga">yoga</option>
            <option value="dance">dance</option>
            <option value="running">running</option>
            <option value="athletics">athletics</option>
            <option value="canoe">canoe</option>
            <option value="cycling">cycling</option>
            <option value="fitness">fitness</option>
          </select>
        </li>
        <li>
          <label for="geo-pipeline">#pipeline</label>
          <select
            id="geo-pipeline"
            value={editing.properties.pipeline || ""}
            name="pipeline"
          >
            <option value="" />
            <option value="valve">shutoff valve</option>
          </select>
        </li>
        <li>
          <label for="geo-substance">#substance</label>
          <select
            id="geo-substance"
            value={editing.properties.substance || ""}
            name="substance"
          >
            <option value="" />
            <option value="water">water</option>
            <option value="sewage">sewage</option>
            <option value="gas">gas</option>
          </select>
        </li>
        <li>
          <label for="geo-power">#power</label>
          <select
            id="geo-power"
            value={editing.properties.pipeline || ""}
            name="power"
          >
            <option value="" />
            <option value="switch">switch</option>
          </select>
        </li>
        <li>
          <label for="geo-highway">#highway</label>
          <select
            id="geo-highway"
            value={editing.properties.highway || ""}
            name="highway"
          >
            <option value="" />
            <option value="elevator">elevator</option>
            <option value="steps">steps</option>
          </select>
        </li>
        <li>
          <label for="geo-stairs">#stairs</label>
          <select
            id="geo-stairs"
            value={editing.properties.stairs || ""}
            name="stairs"
          >
            <option value="" />
            <option value="yes">yes</option>
          </select>
        </li>

        <li>
          <label for="geo-heading">#heading</label>
          <input
            id="geo-heading"
            type="number"
            name="heading"
            value={editing.properties.heading || ""}
          />
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>...usage</p>
      <ul>
        <li>
          <label for="geo-access">#access</label>
          <select
            id="geo-access"
            value={editing.properties.access || ""}
            name="access"
          >
            <option value="" />
            <option value="delivery">delivery</option>
            <option value="customer">customer</option>
            <option value="visitor">visitor</option>
            <option value="private">private</option>
            <option value="permit">permit</option>
          </select>
        </li>
        <li>
          <label for="geo-unisex">#unisex</label>
          <select
            id="geo-unisex"
            value={editing.properties.unisex || ""}
            name="unisex"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </li>
        <li>
          <label for="geo-male">#male</label>
          <select
            id="geo-male"
            value={editing.properties.male || ""}
            name="male"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </li>
        <li>
          <label for="geo-female">#female</label>
          <select
            id="geo-female"
            value={editing.properties.female || ""}
            name="female"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="no">no</option>
          </select>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>...emergency</p>
      <ul>
        <li>
          <label for="geo-emergency"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:emergency"
              target="_blank"
              rel="noreferrer">#emergency</a
            ></label
          >
          <select
            id="geo-emergency"
            value={editing.properties.emergency || ""}
            name="emergency"
          >
            <option value="" />
            <option value="fire_extinguisher">fire extinguisher</option>
            <option value="fire_hydrant">fire hydrant</option>
            <option value="first_aid_kit">first aid kit</option>
            <option value="assembly_point">gathering place</option>
          </select>
        </li>
        <li>
          <label for="geo-emergency-phone"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:emergency:phone"
              target="_blank"
              rel="noreferrer">^:phone</a
            ></label
          >
          <input
            id="geo-emergency-phone"
            type="tel"
            name="emergency:phone"
            value={editing.properties["emergency:phone"] || ""}
          />
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>...management</p>
      <ul>
        <li>
          <label for="geo-brand"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:brand"
              target="_blank"
              rel="noreferrer">#brand</a
            ></label
          >
          <input
            id="geo-brand"
            type="text"
            name="brand"
            value={editing.properties.brand || ""}
          />
        </li>
        <li>
          <label for="geo-operator"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:operator"
              target="_blank"
              rel="noreferrer">#operator</a
            ></label
          >
          <input
            id="geo-operator"
            type="text"
            name="operator"
            value={editing.properties.operator || ""}
          />
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>...food</p>
      <ul>
        <li>
          <label for="geo-cuisine"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:cuisine"
              target="_blank"
              rel="noreferrer">#cuisine</a
            ></label
          >
          <input
            id="geo-cuisine"
            type="text"
            name="cuisine"
            value={editing.properties.cuisine || ""}
          />
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>...personal services</p>
      <ul>
        <li>
          <label for="geo-healthcare"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:healthcare"
              target="_blank"
              rel="noreferrer">#healthcare</a
            ></label
          >
          <select
            id="geo-healthcare"
            value={editing.properties.healthcare || ""}
            name="healthcare"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="doctor">doctor</option>
            <option value="clinic">clinic</option>
            <option value="dentist">dentist</option>
            <option value="hospital">hospital</option>
            <option value="laboratory">laboratory</option>
            <option value="rehabilitation">rehabilitation</option>
            <option value="optometrist">optometrist</option>
          </select>
        </li>
        <li>
          <label for="geo-healthcare-specialty"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:healthcare:specialty"
              target="_blank"
              rel="noreferrer">^:specialty</a
            ></label
          >
          <input
            id="geo-healthcare-specialty"
            type="text"
            name="healthcare:specialty"
            value={editing.properties["healthcare:specialty"] || ""}
          />
        </li>
        <li>
          <label for="geo-beauty"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:beauty"
              target="_blank"
              rel="noreferrer">#beauty</a
            ></label
          >
          <input
            id="geo-beauty"
            type="text"
            name="beauty"
            value={editing.properties.beauty || ""}
          />
        </li>
        <li>
          <label for="geo-clothes"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:clothes"
              target="_blank"
              rel="noreferrer">#clothes</a
            ></label
          >
          <select
            id="geo-clothes"
            value={editing.properties.clothes || ""}
            name="clothes"
          >
            <option value="" />
            <option value="yes">yes</option>
            <option value="wedding">wedding</option>
          </select>
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>#addr:</p>
      <ul>
        <li>
          <label for="addr-housenumber">:housenumber</label>
          <input
            id="addr-housenumber"
            type="text"
            name="addr:housenumber"
            value={editing.properties["addr:housenumber"] || ""}
          />
        </li>
        <li>
          <label for="addr-street">:street</label>
          <input
            id="addr-street"
            type="text"
            name="addr:street"
            value={editing.properties["addr:street"] || ""}
          />
        </li>
        <li>
          <label for="addr-city">:city</label>
          <input
            id="addr-city"
            type="text"
            name="addr:city"
            value={editing.properties["addr:city"] || ""}
          />
        </li>
        <li>
          <label for="addr-state">:state</label>
          <input
            id="addr-state"
            type="text"
            name="addr:state"
            value={editing.properties["addr:state"] || ""}
          />
        </li>
        <li>
          <label for="addr-postcode">:postcode</label>
          <input
            id="addr-postcode"
            type="text"
            name="addr:postcode"
            value={editing.properties["addr:postcode"] || ""}
          />
        </li>
        <li>
          <label for="addr-country">:country</label>
          <input
            id="addr-country"
            type="text"
            name="addr:country"
            value={editing.properties["addr:country"] || ""}
          />
        </li>
        <li>
          <label for="addr-flats">:flats</label>
          <input
            id="addr-flats"
            type="text"
            name="addr:flats"
            value={editing.properties["addr:flats"] || ""}
          />
        </li>
        <li>
          <label for="addr-unit">:unit</label>
          <input
            id="addr-unit"
            type="text"
            name="addr:uni"
            value={editing.properties["addr:unit"] || ""}
          />
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>#post:</p>
      <ul>
        <li>
          <label for="post-housenumber">:housenumber</label>
          <input
            id="post-housenumber"
            type="text"
            name="post:housenumber"
            value={editing.properties["post:housenumber"] || ""}
          />
        </li>
        <li>
          <label for="post-street">:street</label>
          <input
            id="post-street"
            type="text"
            name="post:street"
            value={editing.properties["post:street"] || ""}
          />
        </li>
        <li>
          <label for="post-city">:city</label>
          <input
            id="post-city"
            type="text"
            name="post:city"
            value={editing.properties["post:city"] || ""}
          />
        </li>
        <li>
          <label for="post-state">:state</label>
          <input
            id="post-state"
            type="text"
            name="post:state"
            value={editing.properties["post:state"] || ""}
          />
        </li>
        <li>
          <label for="post-postcode">:postcode</label>
          <input
            id="post-postcode"
            type="text"
            name="post:postcode"
            value={editing.properties["post:postcode"] || ""}
          />
        </li>
        <li>
          <label for="post-country">:country</label>
          <input
            id="post-country"
            type="text"
            name="post:country"
            value={editing.properties["post:country"] || ""}
          />
        </li>
        <li>
          <label for="post-flats">:flats</label>
          <input
            id="post-flats"
            type="text"
            name="post:flats"
            value={editing.properties["post:flats"] || ""}
          />
        </li>
        <li>
          <label for="post-box">:box</label>
          <input
            id="post-box"
            type="text"
            name="post:box"
            value={editing.properties["post:box"] || ""}
          />
        </li>
      </ul>
    </fieldset>
    <fieldset>
      <p>...signs</p>
      <ul>
        <li>
          <label for="man-made"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:man_made"
              target="_blank"
              rel="noreferrer">#man_made</a
            ></label
          >
          <select
            id="man-made"
            value={editing.properties.man_made || ""}
            name="man_made"
          >
            <option value="" />
            <option value="sign">sign</option>
          </select>
        </li>
        <li>
          <label for="information"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:information"
              target="_blank"
              rel="noreferrer">#information</a
            ></label
          >
          <select
            id="information"
            value={editing.properties.information || ""}
            name="information"
          >
            <option value="" />
            <option value="sign">sign</option>
            <option value="map">map</option>
          </select>
        </li>
        <li>
          <label for="message">
            <a
              href="https://wiki.openstreetmap.org/wiki/Key:message"
              target="_blank"
              rel="noreferrer">#message</a
            >
          </label>
          <input
            id="message"
            type="text"
            name="message"
            value={editing.properties.message || ""}
          />
        </li>
        <li>
          <label for="inscription"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:inscription"
              target="_blank"
              rel="noreferrer">#inscription</a
            ></label
          >
          <input
            id="inscription"
            type="text"
            name="inscription"
            value={editing.properties.inscription || ""}
          />
        </li>
        <li>
          <label for="support"
            ><a
              href="https://wiki.openstreetmap.org/wiki/Key:support"
              target="_blank"
              rel="noreferrer">#support</a
            ></label
          >
          <input
            id="support"
            type="text"
            name="support"
            value={editing.properties.support || ""}
          />
        </li>
      </ul>
    </fieldset>
  </form>
{/if}
