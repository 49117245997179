import { get, padStart } from "lodash-es";
import { fetchCreateFile } from "../api";


    const chunkBytes = 1024 * 1024; // 1MB
    const simultaneous = 4;
    const retry = 3;


    async function uploadFileChunk(url, chunk, id, attempt) {
        //console.log(url, chunk, id, attempt);
        return fetch(url + "&comp=block&blockid=" + id, {
            method:"PUT",
            // headers: {
            //     "Content-Type": file.type,
            //     "x-ms-blob-type": "BlockBlob",
            //     "Cache-Control":"public, max-age=31536000",
            // },
            body:chunk,
        })
        .then(function(response) {
            if(!response.ok) return Promise.reject(new Error("Uploading chunk failed"));
            return response;
        })
        .catch(function(error) {
            if(!attempt) attempt = 1;
            if(attempt <= retry) return Promise.delay(1000 * attempt).then(function() {
                return uploadFileChunk(url, chunk, id, attempt + 1)
            });
            return Promise.reject(error); // out of retries
        });
    }

    async function commitFile(url, type, list, attempt) {
        return fetch(url+"&comp=blocklist", {
            method:"PUT",
            headers: {
                "x-ms-blob-content-type": type,
                "x-ms-blob-cache-control":"public, max-age=31536000",
            },
            body:'<?xml version="1.0" encoding="utf-8"?><BlockList>' + Object.values(list).reduce(function(xml, item) {
                return xml + '<Latest>' + item + '</Latest>';
            }, '') + '</BlockList>',
        })
        .then(function(response) {
            if(!response.ok) return Promise.reject(new Error("Committing blocklist failed"));
            return response;
        })
        .catch(function(error) {
            if(!attempt) attempt = 1;
            if(attempt <= retry) return Promise.delay(1000 * attempt).then(function() {
                return commitFile(url, type, list, attempt + 1);
            });
            return Promise.reject(error); // out of retries
        });
    }

    function chunked(url, file, current, blocks, chunks) {

        // initialize
        if(!current) current = 0; // start;
        if(!blocks) blocks = [];
        if(!chunks) chunks = [];

        //if(current >= file.size) 

        //console.log(file.size, current);

        //var next;

        var working = [];
        for(var i = 0; i < simultaneous; i++) {

            if(current >= file.size) break; // end the loop

            var block = btoa("CHUNK" + padStart(current.toString(), file.size.toString().length, "0")); // make all blocks the same length
            blocks.push(block);
            var upload = uploadFileChunk(url, file.slice(current, current = current + chunkBytes + 1), block); // slice, increment current
            //console.log(chunks);
            chunks.push(upload);
            //console.log(working);
            working.push(upload);
        }

        if(working.length < 1) {
            return Promise.all(chunks)
            .then(function() {
                return commitFile(url, file.type, blocks);
            }); // no more work to do
        }

        return Promise.all(working)
        .then(function() {

            return chunked(url, file, current, blocks, chunks); // another pass

        });

    };

    export const upload = async function(scope, fileOrBlob, data) {

        let json = await fetchCreateFile(scope, fileOrBlob, data);

        var record = get(json, "files.item", json.file);
        if(!!record) record = get(json, [ "files", "items", record ], record);

        await chunked(record.upload, fileOrBlob);

        return record;

    };

    export default upload;
