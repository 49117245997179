<script>
  import { fetchUpdateProperty } from "./api";
  import { get, clone } from "lodash-es";

  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing)) editing = { ...property };

  $: placement = get(editing, "media.placement");

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    const newPolicy = await fetchUpdateProperty(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

{#if editing}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    class="edit property"
  >
    <!-- <header>
        <h1>Settings</h1>
    </header> -->

    <fieldset>
      <ul>
        <li>
          <label for="property-media">Type</label>
          <select
            id="property-media"
            name="media.format"
            value={get(editing, "media.format", "")}
          >
            <option value="smart-decal">smart decals</option>
            <option value="hang">hang tags</option>
            <option value="window">window decals</option>
            <option value="">disabled</option>
          </select>
        </li>
        <li>
          <label for="media-placement">Placement</label>
          <select
            name="media.placement"
            value={get(editing, "media.placement")}
          >
            <option value="">not set</option>
            <option value="interior-front-driver">interior front driver</option>
            <option value="exterior-rear-driver">exterior rear driver</option>
            <option value="interior-front-passenger"
              >interior front passenger</option
            >
            <option value="exterior-rear-passenger"
              >exterior rear passenger</option
            >
          </select>
          <!-- <ul on:change={e => {if(e.target.checked) placement = e.target.value;}}>
                    {#each Object.entries({
                        "exterior-rear-driver":"exterior rear driver",
                        "exterior-rear-passenger":"exterior rear passenger",
                        "interior-front-driver":"interior front driver",
                        "interior-front-passenger":"interior front passenger",
                    }) as [ id, title ]}
                        <li>
                            <input id="media-placement-{id}" type="radio" name="media.placement" checked={placement === id} value="{id}" required />
                            <label for="media-placement-{id}">{title}</label>
                        </li>
                    {/each}
                </ul> -->
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
