import esrisatellite from "$assets/esrisatellite.json";
import monochrome from "$assets/greyscale.json";
import warm from "$assets/warm-11.json";
import { propertyId } from "$stores";
import { filterLevel } from "$utils/geopipeline";
import { param } from "$utils/params";
import debug from "debug";
import { get } from "lodash-es";
import { derived, readable, writable } from "svelte/store";

const log = debug("boss:components:maps:stores");

export const styles = readable({
  warm: warm,
  monochrome: monochrome,
  esrisatellite: esrisatellite,
  // Mapbox styles
  mapboxnavigation: {
    name: "Mapbox Navigation",
    styleUrl: "mapbox://styles/mapbox/navigation-guidance-day-v4",
  },
  "mapboxsatellite-v11": {
    name: "Mapbox Satellite v11",
    styleUrl: "mapbox://styles/mapbox/satellite-streets-v11",
  },
  "mapboxstreets-v11": {
    name: "Mapbox Streets v11",
    styleUrl: "mapbox://styles/mapbox/streets-v11",
  },
});

export const layers = readable({
  azureradar: {
    name: "Azure Radar",
    layer: {
      id: "azureradar",
      type: "raster",
      source: "azure-radar-tiles",
    },
  },
  propertiesoutline: {
    name: "Properties Outline",
    layer: {
      id: "propertiesoutline",
      type: "fill",
      source: "properties",
      paint: {
        "fill-color": "#0080ff",
        "fill-opacity": 0.6,
        "fill-outline-color": "rgba(0,0,0,0.8)",
      },
    },
  },
});

export function style(def) {
  let style;

  return derived([param("style"), styles], async ([$branch, $styles], set) => {
    const val = $branch || def;
    log("checking style:", val);
    if (val !== style) {
      log("new style:", val);
      style = val;

      var loaded = $styles[style];

      log("style:", loaded);

      if (val === style) set(loaded); // only update if still the value
    }
  });
}
export const mapbranch = derived(param("branch"), ($value) => $value || "main");

export const position = derived(
  param("position"),
  ($value) => $value && $value.split(",").map(parseFloat)
);

export const inspect = derived(
  param("inspect"),
  ($value) => $value === "true" || $value === true
);

export const displayImages = derived(
  param("images"),
  ($value) => $value === "true" || $value === true
);

export const propertyGeojson = derived(
  [propertyId, mapbranch],
  async ([$propertyId, $branch], set) => {
    const api = await import("$components/maps/api");
    if (!$propertyId)
      set({
        type: "FeatureCollection",
        features: [],
      });

    log("fetching geojson:", $propertyId, $branch);

    if (!!$propertyId) {
      const geojson = await api.fetchAndStorePropertyGeojson(
        $propertyId,
        $branch
      );
      log("geojson:", geojson);
      set(geojson);
    }
  },
  {
    type: "FeatureCollection",
    features: [],
  }
);

export const level = param("level");

export const levels = derived(propertyGeojson, ($geojson) => {
  if (!$geojson) return {};

  return Object.entries(
    $geojson.features.reduce((levels, feature) => {
      if (
        null != feature.properties.level &&
        "" !== feature.properties.level &&
        !(
          feature.properties.level.includes &&
          feature.properties.level.includes(";")
        )
      ) {
        const l = feature.properties.level + "";
        if (!levels[l] || feature.properties["level:ref"])
          levels[l] = feature.properties["level:ref"] || levels[l] || l;
      }
      return levels;
    }, {})
  );
});

export const features = writable({
  type: "FeatureCollection",
  features: [],
});

propertyId.subscribe(async ($id) => {
  if (!$id) return;
  const api = await import("$components/maps/api");
  const json = await api.fetchPropertyGeoFeatures($id);
  log("loaded property features:", json);
  features.set(json);
});

features.subscribe(($data) => log("geo features:", $data));

export const propertyGeoJsonLeveled = derived(
  [propertyGeojson, features, level],
  ([$geojson, $features, $level], set) => {
    if (!$geojson || !$geojson.features.length) return;

    set(
      Object.assign({}, $geojson, {
        type: "FeatureCollection",
        bbox: $geojson.bbox,
        features: filterLevel(
          $geojson.features.concat($features.features),
          $level
        ).features,
      })
    );
  },
  {
    type: "FeatureCollection",
    features: [],
  }
);

export const imageFeatures = derived(propertyGeoJsonLeveled, ($geojson) => {
  return Object.assign(
    {},
    {
      type: "FeatureCollection",
      bbox: $geojson.bbox,
      features: $geojson.features.filter((feature) => {
        //console.log("feature=", feature);
        return (
          feature.geometry.type === "Point" &&
          get(feature, "properties.amenity") == "image"
        );
      }),
    }
  );
});

export const mapExportConfig = writable({
  width: 8.5,
  height: 11,
  dpi: 600,
});
export const mapRenders = writable([]);
