<script>
  import { downloadHandler } from "$utils/exportTable";
  import { sortBy } from "$utils/sort";
  import { addDays } from "date-fns";
  import { format, utcToZonedTime } from "date-fns-tz";
  import PropertyPaymentsMiniChart from "./PropertyPaymentsMiniChart.svelte";

  export let data;
  let table;

  $: items = processData(data);

  $: console.log("data=", data);

  $: intervals = sortBy(
    Object.values(items).filter((item) => item.interval),
    "interval",
    undefined,
    true
  );

  function label(freq, interval, timezone) {
    if (freq == "P1D")
      return format(
        utcToZonedTime(interval.split("/")[0], timezone),
        "EEE MMM d"
      );
    if (freq == "P1M")
      return format(
        utcToZonedTime(interval.split("/")[0], timezone),
        "MMM yyyy"
      );
    if (freq == "P1W")
      return `${format(
        utcToZonedTime(interval.split("/")[0], timezone),
        "MMM d"
      )}—${format(
        addDays(utcToZonedTime(interval.split("/")[1], timezone), -1),
        "MMM d"
      )}`;
    return (
      "since " +
      format(utcToZonedTime(interval.split("/")[0], timezone), "MMM d")
    );
  }

  function processData(data) {
    if (!data) return {};

    return data.metrics.items.reduce(
      (intervals, item) => {
        const interval = item.interval;

        if (!intervals[interval])
          intervals[interval] = {
            interval,
          };

        const scope = item.property?.id ?? item.property ?? "*";
        if (!intervals[item.interval][scope])
          intervals[item.interval][scope] = {};

        const data = intervals[item.interval][scope];

        if (item.property && !intervals.properties[scope])
          intervals.properties[scope] = item.property;

        //if (property && item.group != "property") return intervals;
        //if(item.payments != "total") return map;

        for (const [k, cents] of Object.entries(item.values)) {
          //   if (interval.split("/")[0] < item.interval.split("/")[0]) continue; // don't have full data
          //   if (!intervals[interval]) intervals[interval] = {};
          //intervals[interval].interval = interval;
          data.datetimes = item.datetimes;
          data[item.payments] = cents;
          data.timezone = item.timezone;
          intervals[interval].label = label(
            item.datetimes,
            interval,
            item.timezone
          );
        }

        return intervals;
      },
      { properties: {} }
    );
  }
  var currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 0,
  }).format;
</script>

<section>
  <header><h1>Properties By Month</h1></header>
  <a
    href="#"
    type="text/csv"
    download="Properties Payments Comparison {format(
      new Date(),
      'yyyy-MM-dd'
    )}.csv"
    on:click={(e) => downloadHandler(e, table)}>Download Spreadsheet</a
  >
</section>
<table bind:this={table}>
  <thead>
    <tr>
      <th scope="col row">Property</th>
      {#each intervals as item}
        <th scope="col"
          ><time datetime={item.interval}>{label("P1M", item.interval)}</time
          ></th
        >
      {/each}
    </tr>
  </thead>
  <tbody>
    {#each sortBy(Object.values(items.properties || {}), "name") as property}
      <tr>
        <th scope="row"
          ><a
            href="https://manager.communityboss.app/properties/{property.id}/payments"
            target="_blank"
            rel="noreferrer">{property.name}</a
          >
          <PropertyPaymentsMiniChart
            item={{
              interval: data.metrics.interval,
              datetimes: "P1M",
              type: "paid",
              metric: "total",
              timezone: property.timezone,
              values: intervals.reduce((values, item) => {
                values[item.interval] =
                  items[item.interval][property.id]?.total ?? 0;
                return values;
              }, {}),
            }}
          />
        </th>
        {#each intervals as item}
          <td
            ><time datetime={item.interval}
              >{currency(
                (items[item.interval][property.id]?.total ?? 0) / 100
              )}</time
            ></td
          >
        {/each}
      </tr>
    {/each}
    <!-- {#each sortBy( Object.values(items).filter((i) => i.datetimes == "P1M"), "interval", undefined, true ) as data}
      <tr>
        <th scope="row"
          ><a href="{window.location.pathname}?valid={data.interval}"
            ><time datetime={data.interval}>{data.label}</time></a
          ></th
        >
        <td>{currency(data.total / 100) || ""}</td>
        <td>{currency(data.net / 100) || ""}</td>
        <td>{currency(data.gateway / 100) || ""}</td>
        <td>{currency(data.service / 100) || ""}</td>
      </tr>
    {/each} -->
  </tbody>
  <tfoot />
</table>
