<script>
  import { fetchUpdatePolicy } from "./api";
  import {
    get,
    range,
    filter,
    map,
    size,
    each,
    clone,
    find,
    uniq,
  } from "lodash-es";
  import NewVehicleMeter from "./components/NewVehicleMeter.svelte";
  import EditPolicyMeters from "./EditPolicyMeters.svelte";

  export let policy = null;
  export let readonly = false;

  let submittable = false;
  let submitting = false;

  let editingPolicy = null;

  //$: editingPolicy = policy;
  $: if (!!policy && (!editingPolicy || !submittable))
    editingPolicy = { ...policy };

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!editingPolicy || submitting || !submittable) return;

    submitting = true;

    const form = e.target;

    const formData = new FormData(form);

    await fetchUpdatePolicy(editingPolicy, formData);

    submitting = submittable = false;
    editingPolicy = null;
  }
</script>

<section>
  <header>
    <h1>Vehicles</h1>
  </header>
  <form on:change={change} on:input={change} on:submit={submit}>
    <fieldset disabled={readonly && "disabled"}>
      <ul>
        <li>
          <label for="policy-field-vehicle">Vehicle</label>
          <select
            id="policy-field-vehicle"
            name="permit.vehicle"
            value={get(editingPolicy, "vehicle.required")
              ? "true"
              : get(editingPolicy, "vehicle.request")
              ? "false"
              : ""}
          >
            <option value="true">required</option>
            <option value="false">optional</option>
            <option value="">disabled</option>
          </select>
        </li>
        <li>
          <label for="policy-vehicle-violationblock">Violations</label>
          <select
            id="policy-vehicle-violationblock"
            name="permit.vehicle.violationblock"
            value={get(editingPolicy, "vehicle.violation.deny", "PT0S")}
          >
            <option value="PT0S">no block</option>
            <option value="PT15M">block for 0:15</option>
            <option value="PT30M">block for 0:30</option>
            <option value="PT45M">block for 0:45</option>
            <option value="PT1H">block for 1:00</option>
            <option value="PT2H">block for 2:00</option>
          </select>
        </li>
        <li>
          <label for="policy-vehicle-fineblock">Fines</label>
          <select
            id="policy-vehicle-fineblock"
            name="permit.vehicle.fineblock"
            value={get(
              editingPolicy,
              "vehicle.violation.unpaid.deny",
              "false"
            ) + ""}
          >
            <option value="false">ignore</option>
            <option value="true">block if any unpaid</option>
          </select>
        </li>
        <li>
          <label for="policy-vehicle-donotpermitblock">Banned</label>
          <select
            id="policy-vehicle-donotpermitblock"
            name="permit.vehicle.donotpermit.reject"
            value={get(editingPolicy, "vehicle.donotpermit.reject", "true") +
              ""}
          >
            <option value="false">allow</option>
            <option value="true">block</option>
          </select>
        </li>
        <li>
          <label for="policy-vehicle-assigned">Assigned</label>
          <select
            id="policy-vehicle-assigned"
            name="rules.assigneduse"
            value={get(editingPolicy, "rules.assigneduse", true) + ""}
          >
            <option value="true">allow</option>
            <option value="false">block</option>
          </select>
        </li>
        <li>
          <label for="policy-vehicle-media-required"
            >{get(editingPolicy, "media.title", "Smart Decal")}</label
          >
          <select
            id="policy-vehicle-media-required"
            name="permit.vehicle.media.required"
            value={get(editingPolicy, "vehicle.media.required", false) + ""}
          >
            <option value="false">policy defaults</option>
            <option value="true">prerequisite</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <fieldset disabled={readonly && "disabled"}>
      <p>Photos:</p>
      <ul>
        <li>
          <label for="policy-vehicle-registration">Registration</label>
          <select
            id="policy-vehicle-registration"
            name="permit.vehicle.registration"
            value={get(
              find(get(editingPolicy, "files.items", []), {
                title: "Vehicle Registration",
              }),
              "required",
              false
            )
              ? "true"
              : get(
                  find(get(editingPolicy, "files.items", []), {
                    title: "Vehicle Registration",
                  }),
                  "request",
                  false
                )
              ? "false"
              : ""}
          >
            <option value="true">required</option>
            <option value="false">optional</option>
            <option value="">disabled</option>
          </select>
        </li>
        <li>
          <label for="policy-vehicle-insurance">Insurance</label>
          <select
            id="policy-vehicle-insurance"
            name="permit.vehicle.insurance"
            value={get(
              find(get(editingPolicy, "files.items", []), {
                title: "Vehicle Insurance",
              }),
              "required",
              false
            )
              ? "true"
              : get(
                  find(get(editingPolicy, "files.items", []), {
                    title: "Vehicle Insurance",
                  }),
                  "request",
                  false
                )
              ? "false"
              : ""}
          >
            <option value="true">required</option>
            <option value="false">optional</option>
            <option value="">disabled</option>
          </select>
        </li>
        <li>
          <label for="policy-vehicle-license">License</label>
          <select
            id="policy-vehicle-license"
            name="permit.vehicle.license"
            value={get(
              find(get(editingPolicy, "files.items", []), {
                title: "Driver's License",
              }),
              "required",
              false
            )
              ? "true"
              : get(
                  find(get(editingPolicy, "files.items", []), {
                    title: "Driver's License",
                  }),
                  "request",
                  false
                )
              ? "false"
              : ""}
          >
            <option value="true">required</option>
            <option value="false">optional</option>
            <option value="">disabled</option>
          </select>
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
  {#if !readonly}<NewVehicleMeter {policy} />{/if}
  <EditPolicyMeters {policy} groups={["vehicle"]} />
</section>
