import { parse } from "csv-parse/sync";
import debug from "debug";
import plimit from "p-limit";
import { get, writable, type Readable } from "svelte/store";
import { Err, Ok, Result } from "ts-results";
import { fetchCSV } from "../../api";
import { propertyId, token } from "../../stores";

const log = debug("boss:components:import-permits:stores");

type ErrorMessage = {
  status: number;
  message: string;
};

// use a limited subset of fields that we need
type Policy = {
  id: string;
  title: string;
};

type UploadFormViewModelState = {
  csv: string;
  errors: Array<ErrorMessage>;
  headers: Array<string>;
  importing: boolean;
  json: Array<Record<string, string>>;
  policies: Array<Policy>;
  progress: number;
  selected_policy: string;
  user_selected_headers: Array<string>;
};

export class UploadFormViewModel implements Readable<UploadFormViewModelState> {
  private store = writable<UploadFormViewModelState>({
    csv: "",
    errors: [],
    headers: [],
    importing: false,
    json: [],
    policies: [],
    progress: 0,
    selected_policy: "",
    user_selected_headers: [],
  });

  update_csv(
    csv: string,
    opts: { update_headers: boolean } = { update_headers: false }
  ) {
    const json = this.parse_csv(csv);
    const headers = json?.length ? Object.keys(json[0]) : [];
    const update: Partial<UploadFormViewModelState> = { csv, json, headers };
    if (opts.update_headers && json)
      update.user_selected_headers = Array.from({ length: headers.length });

    log("updating csv:", update);

    this.partial(update);
  }

  async create_permits() {
    this.partial({ importing: true, errors: [], progress: 0 });

    const concurrency = 6; // # items at a time
    const limit = plimit(concurrency);

    const rows = this.labelled_rows;

    log("labelled rows:", rows);

    const num_items = rows.length;
    const failures: string[] = [];
    const errors: Array<ErrorMessage> = [];
    let curr_item = 0;

    const promises = rows.map((row, index) =>
      limit(async () => {
        curr_item++;
        this.partial({ progress: (curr_item / num_items) * 100 });

        const resp = await this.create_permit(row, index);

        if (resp.err) {
          errors.push(resp.val);
          failures.push(Object.values(row).join(","));
        }
      })
    );

    return Promise.all(promises).finally(() => {
      this.partial({ importing: false, progress: 0, errors });
      if (failures?.length) {
        this.update_csv([this.state.headers.join(","), ...failures].join("\n"));
      } else {
        this.update_csv("");
      }
    });
  }

  async upload_csv(file?: File) {
    log("loading and parsing CSV...");

    this.partial({ csv: "", user_selected_headers: [], errors: [] });

    if (!file) {
      this.partial({ errors: [{ status: 400, message: "No file selected" }] });
      return;
    }

    // If an Excel file, send to API for processing first then set the CSV text.
    let text: string;
    if (file.type !== "text/csv" && !file.name.endsWith(".csv"))
      text = (await fetchCSV(file)) ?? "";
    else text = await file.text();

    log("text:", text);

    this.update_csv(text, { update_headers: true });
  }

  set_header(index: number, value: string) {
    log("setting header:", { index, value });

    this.store.update(($store) => {
      $store.user_selected_headers[index] = value;
      return $store;
    });
  }

  /**
   * Loop through every row and omit "ignore" fields to construct a new array
   * of only the fields the user selects columns for.
   */
  private get labelled_rows() {
    const headers = this.state.user_selected_headers;
    return this.state.json.reduce(
      (all, item) => {
        const vals = Object.values(item);
        all.push(
          vals.reduce(
            (selected_values, val, i) => {
              const header = headers[i];
              log("labelled row:", header, val);
              if (header && header !== "ignore") {
                if (selected_values[header]?.length) {
                  selected_values[header].push(val);
                } else {
                  selected_values[header] = [val];
                }
              }
              return selected_values;
            },
            {} as Record<string, string[]>
          )
        );
        return all;
      },
      [] as Array<Record<string, string[]>>
    );
  }

  /**
   * Takes the given permit info an calls the API to create the permit.
   *
   * Returns a Result type with the error message if the request fails
   * or void if successful.
   */
  private async create_permit(
    row: Record<string, string[]>,
    index: number
  ): Promise<Result<void, ErrorMessage>> {
    log("create permit:", { row, index });

    if (!Object.keys(row)?.length)
      return Err({ status: 500, message: "No row data to upload" });

    const property_id = get(propertyId);

    log("row:", row.toString());

    // Turn the CSV data into a FormData object to pass to the API.
    const body = new FormData();
    Object.entries(row).forEach(([header, value]) => {
      value.forEach((v) => body.append(header, v));
    });

    // TODO: move to @communityboss/api?
    const url = new URL(
      `https://api.parkingboss.com/v1/locations/${property_id}/permits/exempt`
    );
    url.searchParams.append("assigned", "true");
    if (this.state.selected_policy)
      url.searchParams.append("policy", this.state.selected_policy);

    try {
      const resp = await fetch(url, {
        method: "POST",
        body,
        headers: { Authorization: `bearer ${get(token)}` },
      });

      if (!resp) {
        return Err({
          status: 500,
          message: `Found blank row at index ${index}`,
        });
      }

      const data: any = await resp?.json();

      // The request didn't fail so we assume successful creation.
      if (resp.status >= 200 && resp.status < 300) {
        console.log("permit uploaded:", { index, row, data });
        return Ok(undefined);
      }

      // Attempt to show the user the actual failure message or fallback to
      // a generic error.
      return Err({
        status: resp?.status ?? 500,
        message: `${resp.statusText}: ${
          data?.message ?? "unknown error"
        } at row ${index + 1}`,
      });
    } catch (e: any) {
      // Shouldn't really get here unless there is a network issue or
      // the API is suffering an outage.
      return Err({
        status: e?.status ?? 500,
        message: e?.message ?? "An unknown error occurred",
      });
    }
  }

  private parse_csv(text: string) {
    return (
      (parse(text, {
        columns: true,
        skip_empty_lines: true,
        skip_records_with_error: true,
      }) as Record<string, string>[]) ?? []
    );
  }

  private partial(changes: Partial<UploadFormViewModelState>) {
    this.store.update((state) => ({ ...state, ...changes }));
  }

  private get state() {
    return get(this.store);
  }

  set policies(policies: Array<Policy>) {
    this.partial({ policies });
  }

  get subscribe() {
    return this.store.subscribe;
  }
}
