<script>
  import { fetchCreateMedia as update } from "./api";
  import { get } from "lodash-es";

  export let property = null;

  let submittable = false;
  let submitting = false;

  function change(e) {
    submittable = e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    const form = e.target;

    submitting = true;

    await update(property, new FormData(form));

    submitting = submittable = false;

    property = null;

    form.reset();
  }
</script>

{#if property}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    on:reset={(e) => (property = null)}
  >
    <fieldset>
      <input type="hidden" name="format" value="smart-decal" />
      <ul>
        <li>
          <label for="add-media-label">Type</label>
          <select id="add-media-label" name="label">
            <option value="Resident">resident</option>
            <option value="Permit">permit</option>
          </select>
        </li>
        <li>
          <label for="add-media-color">Color</label>
          <select id="add-media-color" name="color">
            <option value="Green">green</option>
            <option value="Red">red</option>
            <option value="Blue">blue</option>
            <option value="Black">black</option>
            <option value="Purple">purple</option>
            <option value="Yellow">yellow</option>
            <option value="Lime">lime</option>
            <option value="Brown">brown</option>
            <option value="Orange">orange</option>
          </select>
        </li>
        <li>
          <label for="add-media-title">Title</label>
          <input
            id="add-media-title"
            name="title"
            type="text"
            placeholder="optional"
          />
        </li>
        <li>
          <label for="add-media-count">Count</label>
          <input
            id="add-media-count"
            type="number"
            name="serialcount"
            value="0"
          />
        </li>
        <li>
          <label for="add-media-start">From</label>
          <input
            id="add-media-start"
            type="number"
            name="serialstart"
            value="1600000"
          />
        </li>
        <li>
          <label for="add-media-end">Through</label>
          <input
            id="add-media-end"
            type="number"
            name="serialend"
            value="1600000"
          />
        </li>
      </ul>
    </fieldset>
    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting
          ? "Saving"
          : "Add " + get(property, "media.title") + "s"}</button
      >
      <!-- <button type="reset" disabled={submitting}>Cancel</button> -->
    </footer>
  </form>
{/if}
