<script>
  import { minuteNow } from "../stores";
  import { fetchPaymentMetrics } from "../api";
  import { addDays, startOfDay, differenceInMinutes } from "date-fns";
  import { utcToZoned, toZoneISOString } from "../util/datetime";
  import DateTimeSchedule from "./DateTimeSchedule.svelte";
  import { find } from "lodash-es";

  export let property = null;

  let propertyId = null;
  let timezone = null;
  let fetchTime = null;

  $: if (property && property.id != propertyId) propertyId = property.id;
  $: if (property && property.timezone != timezone)
    timezone = property.timezone;

  $: if (!fetchTime || differenceInMinutes($minuteNow, fetchTime) > 5)
    fetchTime = new Date();

  $: metrics =
    timezone &&
    propertyId &&
    fetchTime &&
    fetchPaymentMetrics(
      `${toZoneISOString(
        addDays(startOfDay(utcToZoned(fetchTime, timezone)), -27),
        timezone
      )}/${toZoneISOString(
        addDays(startOfDay(utcToZoned(fetchTime, timezone)), 1),
        timezone
      )}`,
      {
        property: propertyId,
        datetimes: [`PT${24}H`, `PT${7 * 24}H`, `PT${28 * 24}H`],
      }
    );

  async function usageMetrics(metrics, property) {
    if (!metrics) return;

    const item = find((await metrics).metrics.items, {
      metric: "cents",
      //"count": "cumulative",
      source: "payments",
      intervals: "datetimes",
      datetimes: "PT24H",
      group: "property",
      property: propertyId,
    });

    console.log("metrics item=", item);

    if (!item) return item;

    //if(item) for(let i = 0; i < item.values.length; i++) item.values[i] = item.values[i] / 100;

    return {
      ...item,
      values: Object.entries(item.values).reduce(
        (result, [key, value]) =>
          Object.assign(result, {
            [key]: value / 100,
          }),
        {}
      ),
    };
  }
</script>

<!-- <section>
</section> -->
<figure>
  <DateTimeSchedule {timezone} usage={usageMetrics(metrics, property)} />
</figure>
