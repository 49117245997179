<script lang="ts">
  import { dropzone } from "@communityboss/svelte-utils";
  import debug from "debug";
  import { createEventDispatcher } from "svelte";
  import { blur } from "svelte/transition";

  const log = debug("boss:components:import-permits:UploadDropzone");

  export let dragging = false;

  const dispatch = createEventDispatcher<{
    drop: { files: FileList };
  }>();
</script>

<section
  use:dropzone={{
    on_update: (show) => {
      if (dragging !== show) {
        log("SHOW:", show);
        dragging = show;
      }
    },
    on_drop: async (dropped_files) => {
      dragging = false;
      dispatch("drop", { files: dropped_files });
    },
  }}
>
  {#if dragging}
    <div
      transition:blur
      class="fixed top-0 left-0 right-0 bottom-0 z-50 flex h-full w-full items-center justify-center bg-base-100/50 opacity-100 backdrop-blur-md transition-all"
    >
      <div class="flex flex-col items-center justify-center">
        <p class="mb-6 text-6xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 640 512"
            width="12rem"
          >
            <path
              class="text-white"
              fill="currentColor"
              d="M223 296.1C213.7 287.6 213.7 272.4 223 263L303 183C312.4 173.7 327.6 173.7 336.1 183L416.1 263C426.3 272.4 426.3 287.6 416.1 296.1C407.6 306.3 392.4 306.3 383 296.1L344 257.9V392C344 405.3 333.3 416 320 416C306.7 416 296 405.3 296 392V257.9L256.1 296.1C247.6 306.3 232.4 306.3 223 296.1V296.1z"
            />
            <path
              class="text-secondary"
              fill="currentColor"
              d="M144 480C64.47 480 0 415.5 0 336C0 273.2 40.17 219.8 96.2 200.1C96.07 197.4 96 194.7 96 192C96 103.6 167.6 32 256 32C315.3 32 367 64.25 394.7 112.2C409.9 101.1 428.3 96 448 96C501 96 544 138.1 544 192C544 204.2 541.7 215.8 537.6 226.6C596 238.4 640 290.1 640 352C640 422.7 582.7 480 512 480H144zM223 263C213.7 272.4 213.7 287.6 223 296.1C232.4 306.3 247.6 306.3 256.1 296.1L296 257.9V392C296 405.3 306.7 416 320 416C333.3 416 344 405.3 344 392V257.9L383 296.1C392.4 306.3 407.6 306.3 416.1 296.1C426.3 287.6 426.3 272.4 416.1 263L336.1 183C327.6 173.7 312.4 173.7 303 183L223 263z"
            />
          </svg>
        </p>
        <h1 class="text-2xl font-bold">Drop file anywhere to upload</h1>
      </div>
    </div>
  {/if}
</section>
