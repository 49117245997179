<script>
  import { fetchCreateAuthCodes as update } from "../api";
  import { get } from "lodash-es";
  import { authCodesFromPolicy } from "../util/policy";

  export let policy = null;
  export let readonly = false;

  let submittable = false;
  let submitting = false;
  let form;

  $: if (form) change();

  $: items = policy && authCodesFromPolicy(policy);

  //$: console.log("codes=", items);

  function change(e) {
    if (e && e.target && e.target.form)
      submittable = e.target.form.checkValidity();
    else if (form) submittable = form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!policy || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    const json = await update(policy, formData);

    submitting = submittable = false;

    console.log("json=", json);

    items = Object.values(json.authcodes["for"][policy.subject].items);

    console.log(
      "items=",
      items,
      Object.values(json.authcodes["for"][policy.subject].items)
    );

    form.reset();
  }
</script>

{#if policy}
  <form
    on:submit={submit}
    on:change={change}
    on:reset={change}
    bind:this={form}
  >
    <fieldset disabled={readonly && "disabled"}>
      <p>Policy passcode:</p>
      <ul>
        {#if items && items.length}
          {#each items as item}
            <li data-subject="{item.type}/{item.id}">
              <label>Current</label><data class={item.type} value="item.id"
                >{item.code}</data
              >
            </li>
          {/each}
        {/if}
        <li>
          <label for="update-policy-new-code">New </label>
          <input
            id="update-policy-new-code"
            type="number"
            name={policy.subject}
            placeholder="autogenerate"
            min="0001"
            max="999999"
          />
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Set Passcode"}</button
      >
    </footer>
  </form>
{/if}
