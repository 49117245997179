<script>
  import { documents } from "$stores";
  import { thumbnail } from "$utils/filepreview";
  import { each, map, size } from "lodash-es";
  import { fetchUpdateProperty as update } from "./api";

  export let property = null;

  let newFiles = {};
  let unattach = {};

  let submittable = false;
  let submitting = false;

  let editing = null;

  $: if (!!property && (!editing || !submittable)) editing = { ...property };

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!editing || submitting || !submittable) return;

    submitting = true;

    const form = e.target;

    const formData = new FormData(form);
    each(newFiles, (item) => {
      if (!!item.attach) formData.append("file", item.file);
    });

    await update(editing, formData);

    each(newFiles, (item) => {
      URL.revokeObjectURL(item.url);
    });

    // clear the state
    newFiles = {};
    unattach = {};
    submitting = submittable = false;
    editing = null;
  }

  function addFile(e) {
    if (!e.target.files || e.target.files.length <= 0) return;
    each(e.target.files, (file) => {
      // only take pdf
      if (file.type !== "application/pdf") return;
      const key = `file${size(newFiles) + 1}`;
      newFiles[key] = {
        id: key,
        file,
        url: URL.createObjectURL(file),
        attach: true,
      };
    });
    //console.log(newFiles);
    newFiles = newFiles;
    e.target.value = "";
  }
</script>

<form on:change={change} on:input={change} on:submit={submit}>
  <header>
    <h1>Documents</h1>
  </header>
  {#if editing}
    <fieldset>
      <ul>
        {#if $documents}
          {#each $documents as document}
            <li>
              <input
                type="checkbox"
                name="unattach"
                checked={!!unattach[document.id]}
                value={document.id}
                on:change={(e) => (unattach[e.target.value] = e.target.checked)}
              />
              <a
                href={document.url}
                target="_blank"
                rel="noreferrer"
                type={document.format}
              >
                <data class="document file" value={document.id}>
                  <figure>
                    <img alt="preview" src={thumbnail(document.url, 200)} />
                    <figcaption>
                      <h1>
                        {document.name || document.title || document.name}
                      </h1>
                    </figcaption>
                  </figure>
                </data>
              </a>
            </li>
          {/each}
        {/if}
        {#each map(newFiles) as newFile}
          <li>
            <input
              type="checkbox"
              name="unattach"
              checked={!newFile.attach}
              value={newFile.id}
              on:change={(e) => (newFile.attach = !e.target.checked)}
            />
            <a
              href={newFile.url}
              target="_blank"
              rel="noreferrer"
              type={newFile.file.type}
            >
              <data class="document file" value={newFile.id}>
                <figure>
                  <img alt="preview" />
                  <figcaption>
                    <h1>{newFile.file.name}</h1>
                  </figcaption>
                </figure>
              </data>
            </a>
          </li>
        {/each}
        <li>
          <input
            id="property-file-new"
            type="file"
            name="file"
            accept="application/pdf"
            on:change={addFile}
            multiple
          />
          <label for="property-file-new">Attach document</label>
        </li>
      </ul>
    </fieldset>
    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  {/if}
</form>
