<script>
  import { thumbnail } from "$utils/filepreview";
  import { each, get, map, size } from "lodash-es";
  import { fetchUpdatePolicy } from "./api";

  export let policy = null;
  export let readonly = false;

  let newFiles = {};
  let unattach = {};

  let submittable = false;
  let submitting = false;

  let editingPolicy = null;

  //$: editingPolicy = policy;
  $: if (!!policy && (!editingPolicy || !submittable))
    editingPolicy = { ...policy };

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!editingPolicy || submitting || !submittable) return;

    submitting = true;

    const form = e.target;

    const formData = new FormData(form);
    each(newFiles, (item) => {
      if (!!item.attach) formData.append("file", item.file);
    });

    await Promise.all([
      fetchUpdatePolicy(editingPolicy, formData),
      //fetchUpdatePolicyMeters(editingPolicy, formData)
    ]);

    each(newFiles, (item) => {
      URL.revokeObjectURL(item.url);
    });

    // clear the state
    newFiles = {};
    unattach = {};
    submitting = submittable = false;
    editingPolicy = null;
  }

  function addFile(e) {
    if (!e.target.files || e.target.files.length <= 0) return;
    each(e.target.files, (file) => {
      // only take pdf
      if (file.type !== "application/pdf") return;
      const key = `file${size(newFiles) + 1}`;
      newFiles[key] = {
        id: key,
        file,
        url: URL.createObjectURL(file),
        attach: true,
      };
    });
    //console.log(newFiles);
    newFiles = newFiles;
    e.target.value = "";
  }
</script>

<form on:change={change} on:input={change} on:submit={submit}>
  <header>
    <h1>Additional Info</h1>
  </header>
  {#if editingPolicy}
    <fieldset disabled={readonly && "disabled"}>
      <ul>
        {#each map(get(editingPolicy, "documents.items", [])) as document}
          <li>
            <input
              type="checkbox"
              name="unattach"
              checked={!!unattach[document.id]}
              value={document.id}
              on:change={(e) => (unattach[e.target.value] = e.target.checked)}
            />
            <a
              href={document.url}
              target="_blank"
              rel="noreferrer"
              type={document.format}
            >
              <data class="document file" value={document.id}>
                <figure>
                  <img alt="preview" src={thumbnail(document.url, 200)} />
                  <figcaption>
                    <h1>{document.title || document.name}</h1>
                  </figcaption>
                </figure>
              </data>
            </a>
          </li>
        {/each}
        {#each map(newFiles) as newFile}
          <li>
            <input
              type="checkbox"
              name="unattach"
              checked={!newFile.attach}
              value={newFile.id}
              on:change={(e) => (newFile.attach = !e.target.checked)}
            />
            <a
              href={newFile.url}
              target="_blank"
              rel="noreferrer"
              type={newFile.file.type}
            >
              <data class="document file" value={newFile.id}>
                <figure>
                  <img alt="preview" />
                  <figcaption>
                    <h1>{newFile.file.name}</h1>
                  </figcaption>
                </figure>
              </data>
            </a>
          </li>
        {/each}
        <li>
          <input
            id="policy-file-new"
            type="file"
            name="file"
            accept="application/pdf"
            on:change={addFile}
            multiple
          />
          <label for="policy-file-new">Attach document</label>
        </li>
      </ul>
    </fieldset>
    <fieldset disabled={readonly && "disabled"}>
      <p>Instructions:</p>
      <ul>
        <li>
          <!-- <label for="policy-field-rules">Message</label> -->
          <textarea
            id="policy-field-instructions"
            name="instructions"
            value={get(editingPolicy, "instructions", "")}
            placeholder="optional"
          />
        </li>
      </ul>
    </fieldset>
    <fieldset disabled={readonly && "disabled"}>
      <p>Additional rules:</p>
      <ul>
        <li>
          <!-- <label for="policy-field-rules">Message</label> -->
          <textarea
            id="policy-field-rules"
            name="rules"
            value={get(editingPolicy, "rules.custom", "")}
            placeholder="optional"
          />
        </li>
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  {/if}
</form>
