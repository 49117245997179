<script lang="ts">
  import { token } from "$utils/auth"
  import { faPlus, faSync, faWarning } from "@fortawesome/free-solid-svg-icons"
  import Fa from "svelte-fa"
  import AreaForm from "./AreaForm.svelte"
  import CreateProviderForm from "./CreateProviderForm.svelte"
  import ProviderItem from "./ProviderItem.svelte"
  import { state } from "./state"
  import { fetching } from "./stores"

  let show_create_provider_form = false

  $: $token && state.send("SET_TOKEN", { token: $token })
  $: providers = [...$state.context.providers.values()].sort((a, b) =>
    a.name.localeCompare(b.name)
  )
  $: disabled =
    $state.matches("initializing") || $state.matches("fetching_providers")

  $: console.log("STATE:", $state.value, "\nCONTEXT:", $state.context)
</script>

<main class="max-w-screen-lg">
  <section>
    <header class="flex items-center">
      <h1 class="text-xl font-bold">Provider Directory</h1>
      <div class="alert alert-warning my-6">
        <div>
          <Fa icon={faWarning} size="lg" class="text-white" />
          The service provider directory is a work in progress and likely has many
          issues, please don't use it yet 🤓
        </div>
      </div>

      <div class="ml-auto flex items-center gap-3">
        {#if $state.context.error}
          <strong>
            {$state.context.error}
          </strong>
        {/if}
        <strong>
          {JSON.stringify($state.value)}
        </strong>
        <button
          class="btn btn-outline gap-3"
          on:click={() => state.send("FETCH_PROVIDERS")}
          {disabled}
        >
          <Fa icon={faSync} spin={$fetching} />
        </button>
        <button
          class="btn btn-primary gap-3"
          on:click={() => (show_create_provider_form = true)}
        >
          <Fa icon={faPlus} /> provider
        </button>
      </div>
    </header>

    <form on:submit|preventDefault>
      <input
        type="search"
        class="input input-bordered w-full"
        placeholder="Search providers..."
      />
    </form>

    {#if show_create_provider_form}
      <CreateProviderForm bind:open={show_create_provider_form} />
    {/if}
    {#if providers}
      {#each providers as provider (provider.id)}
        <ProviderItem {provider} />
      {/each}
    {/if}

    <!-- <pre class="mt-12 text-sm bg-base-200 rounded-lg p-6">{JSON.stringify(
        $providers,
        null,
        2
      )}</pre> -->
  </section>
</main>

{#if $state.matches("editing_area")}
  <AreaForm />
{/if}
