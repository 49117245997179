<script lang="ts">
  import type { Area } from "@communityboss/api"
  import { faMagnifyingGlassLocation } from "@fortawesome/free-solid-svg-icons"
  import debug from "debug"
  import { onMount } from "svelte"
  import Fa from "svelte-fa"
  import { create_map_store } from "./stores"

  import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css"
  import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"
  import "mapbox-gl/dist/mapbox-gl.css"

  export let area: Area | undefined

  const map = create_map_store(area)

  let container: HTMLElement

  const log = debug(`boss:routes:providers:[id]:ServiceAreaForm:[${area?.id}]`)

  function handle_change_radius_km(e: Event) {
    const elm = e.currentTarget as HTMLInputElement
    map.change_radius_km(Number(elm.value))
  }

  function handle_change_selected_boundary(e: Event) {
    const elm = e.currentTarget as HTMLSelectElement
    map.change_boundary(elm?.value)
  }

  onMount(() => {
    map.mount(container)
    return () => map.unmount()
  })

  $: log("map store:", $map)
</script>

<section class="flex flex-col gap-6">
  <section>
    <div class="flex items-center gap-4">
      <div class="btn-group [&>*]:btn-sm">
        <button
          class="btn"
          on:click={() => map.change_area_type("radius")}
          class:btn-active={$map.area_type === "radius"}>Radius</button
        >
        <button
          class="btn"
          on:click={() => map.change_area_type("boundary")}
          class:btn-active={$map.area_type === "boundary"}>Boundary</button
        >
        <button
          class="btn"
          on:click={() => map.change_area_type("polygon")}
          class:btn-active={$map.area_type === "polygon"}>Custom Area</button
        >
      </div>
      {#if $map.area_type === "radius"}
        <input
          type="number"
          min="0.1"
          max="1000"
          step="1.0"
          class="input input-sm input-bordered"
          value={$map.radius_km}
          on:input={handle_change_radius_km}
        />
        KM
      {/if}
      {#if $map.area_type === "boundary" && $map?.boundaries?.length}
        <select
          bind:value={$map.selected_boundary}
          on:change={handle_change_selected_boundary}
          class="select select-sm select-bordered w-full flex-1"
        >
          <option value="">Choose boundary...</option>
          {#each $map?.boundaries as boundary}
            {@const addr = boundary.address}
            <option value={boundary.dataSources?.geometry?.id}>
              {addr?.freeformAddress}
              ({boundary.type})
            </option>
          {/each}
        </select>
      {/if}
      {#if $map.features}
        <div class="tooltip ml-auto" data-tip="Zoom to fit">
          <button class="btn btn-sm" on:click={() => map.fit_to_features()}>
            <Fa icon={faMagnifyingGlassLocation} fw />
          </button>
        </div>
      {/if}
    </div>
  </section>

  <section bind:this={container} class="w-full h-[600px]" />

  <div class="flex items-center">
    <div class="grid grid-flow-col grid-cols-2 gap-6">
      <div>
        <h3 class="text-xl font-bold mb-4">Map state</h3>

        <pre class="text-xs bg-base-200 p-4 rounded-lg">{JSON.stringify(
            {
              rendered: $map.rendered,
              area_type: $map.area_type,
              center: $map.center,
              bbox: $map.bbox,
              radius_km: $map.radius_km,
              // boundaries: $map.boundaries,
              selected_boundary: $map.selected_boundary,
              features: $map.features,
              geofence: $map.geofence,
            },
            null,
            2
          )}</pre>
      </div>
      <div>
        <h3 class="text-xl font-bold mb-4">Area</h3>
        <pre class="text-xs bg-base-200 p-4 rounded-lg">{JSON.stringify(
            area,
            null,
            2
          )}</pre>
      </div>
    </div>
  </div>
</section>
