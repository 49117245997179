<script>
  import TableColumnHeader from "./TableColumnHeader.svelte";

  export let columns;
  // export let onSort;
  // export let sortBy;

  const onColumnClick = (event) => {
    // const index = parseInt(event.currentTarget.id)
    // const column = columns[index].column;
    // sortBy = { column, ascending: sortBy?.ascending ? !sor }
    // onSort($$props.id, sortBy);
  };
</script>

<table>
  <thead>
    <tr>
      {#each columns as column, i}
        <TableColumnHeader id={i} title={column.title} />
      {/each}
    </tr>
  </thead>
  <tbody>
    <slot />
  </tbody>
</table>
