<script>
  import TimelineData from "./TimelineData.svelte";
  import TimelineRefs from "./TimelineRefs.svelte";

  export let interval;
  export let timezone;
  export let now; // store
  export let refs = ["P1M"];
  export let direction = "horizontal asc";
</script>

<time class="timeline {direction}" datetime={interval}>
  <TimelineRefs
    {refs}
    {interval}
    {timezone}
    label={true}
    {now}
    nowInView={true}
    {direction}
  />
  <TimelineData scales={refs} {interval} mutate={true} {direction}>
    <slot />
  </TimelineData>
</time>
