<script>
  import { differenceInDays, format, parseISO } from "date-fns";
  import { downloadHandler } from "../../util/exportTable";
  import { orderBy } from "lodash-es";

  export let data = null;
  let items = [];
  let table;

  function process(json) {
    return Object.values(json.authorizations.items).map(function (item) {
      item = json.items[item] || item;

      for (const k of ["scope", "principal"]) {
        item[k] = json.items[item[k]] || k;
      }

      return item;
    });
  }

  // on change resolve promise...
  $: data && data.then((data) => (items = process(data)));
</script>

<main>
  <section>
    <header><h1>Users</h1></header>
    <!-- <form>
          <fieldset>
            <ul on:change={(e) => query(e.target.name, e.target.value)}>
              <li>
                <label for="payments-valid">Range</label>
                <select id="payments-valid" name="valid" value={$valid}>
                  <option value="">--</option>
                  {#each valids as [label, interval]}
                    <option value={interval}>{label}</option>
                  {/each}
                </select>
              </li>
              <li>
                <label for="payments-total">Totals by</label>
                <select id="payments-total" name="total" value={showTotalFor}>
                  <option value="">property</option>
                  <option value="policy">policy</option>
                </select>
              </li>
            </ul>
          </fieldset>
        </form> -->
    <a
      href="#"
      type="text/csv"
      download="Users {format(new Date(), 'yyyy-MM-dd')}.csv"
      on:click={(e) => downloadHandler(e, table)}>Download Spreadsheet</a
    >
  </section>
  <table bind:this={table}>
    <thead>
      <tr>
        <th scope="row column">Name</th>
        <th scope="column">Email</th>
        <th scope="column">Property</th>
        <th scope="column">Segment</th>
        <th scope="column">Role</th>
        <th scope="column">Last Login</th>
        <th scope="column">Added</th>
      </tr>
    </thead>
    <tbody>
      {#each orderBy( items.filter((i) => i.scope.paid), ["scope.format", "scope.name", "principal.name"], ["asc", "asc", "asc"] ) as item}
        <tr data-id={item.id}>
          <th scope="row"
            ><data value={item.principal.id}>{item.principal.name || ""}</data
            ></th
          >
          <td>{item.principal.email}</td>
          <td
            ><data value={item.scope.id || item.scope}>{item.scope.name}</data
            ></td
          >
          <td>{item.scope.format}</td>
          <td
            >{item.roles.admin
              ? "Manager"
              : item.roles.patrol
              ? "Field Agent"
              : "View Only"}</td
          >
          <td
            ><time datetime={item.principal.active || ""}
              >{item.principal.active
                ? differenceInDays(
                    new Date(),
                    parseISO(item.principal.active)
                  ) + " days ago"
                : "Never"}</time
            ></td
          >
          <td
            ><time datetime={item.principal.created}
              >{item.principal.created}</time
            ></td
          >
        </tr>
      {/each}
    </tbody>
    <tfoot />
  </table>
</main>
