<script context="module">
  import { propertyId, property as propertyStore } from "../stores";
  import { fetchAndStoreProperties, fetchUpdateProperty } from "../api";
  import { derived } from "svelte/store";

  let propertyID;

  const billing = derived(
    [propertyId, propertyStore],
    async ([$id, $property], set) => {
      if (!$id) return set(null);

      // bootstrap init
      if ($id !== propertyID) {
        var properties = await fetchAndStoreProperties($id);
        for (const item of properties) {
          if (item.billing)
            set({
              ...item.billing,
            });
        }
        propertyID = $id;
      }

      if ($property?.billing) set($property.billing);
    }
  );
</script>

<script>
  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing))
    editing = {
      ...property,
    };

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  $: console.log({ billing: $billing });

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    await fetchUpdateProperty(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

<form
  on:change={change}
  on:input={change}
  on:submit={submit}
  class="edit property"
>
  <header>
    <h1>Billing</h1>
  </header>

  <fieldset>
    <ul>
      {#if $billing}
        <li>
          <label for="property-billing-arr">Annual</label><input
            id="property-billing-arr"
            name="billing.arr"
            type="numeric"
            min="0"
            value={$billing.arr}
            required
            placeholder="required"
          />
        </li>
        <li>
          <label for="property-billing-mrr">Monthly</label><input
            id="property-billing-mrr"
            name="billing.mrr"
            type="numeric"
            min="0"
            value={$billing.mrr}
            required
            placeholder="required"
          />
        </li>
        <li>
          <label for="property-billing-permit">Permit Fee</label>
          <input
            id="property-billing-permit"
            name="billing.permit"
            type="numeric"
            min="0"
            value={$billing.permit ?? $billing.permitfee}
            required
            placeholder="required"
          />
        </li>
        <li>
          <label for="property-billing-unit-mrr">Unit Monthly</label><input
            id="property-billing-unit-mrr"
            name="billing.unitmrr"
            type="numeric"
            min="0"
            value={$billing.unitmrr}
            required
            placeholder="required"
          />
        </li>
        <li>
          <label for="property-unit-count">Units</label><input
            id="property-unit-count"
            name="units.count"
            type="numeric"
            min="0"
            value={$billing.units ?? 0}
            required
            placeholder="required"
          />
        </li>
        <li>
          <label for="property-billing-external">QuickBooks</label><input
            id="property-billing-external"
            name="billing.external"
            type="text"
            value={$billing.external || ""}
            placeholder="optional"
          />
        </li>
      {/if}
      <li>
        <label for="property-info-external">ALN</label><input
          id="property-info-external"
          name="info.external"
          type="text"
          value={$propertyStore?.info?.external || ""}
          placeholder="optional"
        />
      </li>
    </ul>
  </fieldset>

  <fieldset>
    <p>Suspend as of:</p>
    <ul>
      <li>
        <label for="property-suspend-datetime">Date</label><input
          id="property-suspend-datetime"
          name="suspend.datetime"
          type="date"
          value={$propertyStore?.suspend?.datetime?.split("T")?.[0] || ""}
          placeholder="optional"
        />
      </li>
    </ul>
  </fieldset>

  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Saving" : "Save"}</button
    >
  </footer>
</form>
