import { utcToZonedTime, format as formatTZ } from "date-fns-tz";
import { derived } from "svelte/store";
import { toMilliseconds, toString } from "duration-fns";
import { startOfDay, addDays } from "date-fns";

export function toZoneISOString(local, tz) {
    //console.log(local, tz);
    //if(!tz) return local.toISOString();
    return formatTZ(local, "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: tz });
}

export function timeLocal(store, tz) {
    return derived(store, value => utcToZonedTime(value, tz));
}

export function utcToZoned(value, timezone) {
    return utcToZonedTime(value, timezone);
}


export function zonedInterval(interval, timezone) {
    if(!interval) return null;
    if(interval.split) {
        return interval.split("/").reduce((result, value, i) => {
            result[!!i ? "end" : "start"] = value && utcToZonedTime(value, timezone);
            return result;
        }, {
            start:null,
            end:null,
            timezone
        });
    } else if(interval.start && interval.end) {
        if(interval.timezone != timezone) interval.timezone = timezone;
        return interval;
    } else if(Array.isArray(interval) && interval.length == 2) return interval.reduce((result, value, i) => {
        result[!!i ? "end" : "start"] = value && (value.toISOString ? value : utcToZonedTime(value, timezone));
        return result;
    }, {
        start:null,
        end:null,
        timezone
    });
    return null;
}

export function utcIntervalToZonedInterval(interval, timezone) {
    if(!interval) return null;
    if(interval.split) {
        return interval.split("/").reduce((result, value, i) => {
            result[!!i ? "end" : "start"] = value && utcToZonedTime(value, timezone);
            return result;
        }, {
            start:null,
            end:null,
            timezone
        });
    } else if(interval.start && interval.end) {
        return {
            start: utcToZonedTime(interval.start, timezone),
            end: utcToZonedTime(interval.end, timezone),
            timezone
        };
    } else if(Array.isArray(interval) && interval.length == 2) return interval.reduce((result, value, i) => {
        result[!!i ? "end" : "start"] = value && utcToZonedTime(value, timezone);
        return result;
    }, {
        start:null,
        end:null,
        timezone
    });
    return null;
}

export function durationMS(duration) {
    if(null == duration) return null;
    if(typeof(duration) == "string") return toMilliseconds(duration);
}

export function formatDuration(duration) {
    if(!duration) return duration;
    if(typeof(duration) == "string") return duration;
    return toString(duration);
}

export function utcToZonedDayInterval(datetime, timezone) {

    const zoned = utcToZonedTime(datetime, timezone);
    return {
        start:startOfDay(zoned),
        end:addDays(startOfDay(zoned), 1),
        timezone,
    }

}