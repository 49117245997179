import bearing from "@turf/bearing";
import midpoint from "@turf/midpoint";
import distance from "@turf/distance";
import lineSegment from "@turf/line-segment";

export function longBisectingLinePoints(polygon) {
  // finds the axis line
  // chooses the longest line that connects segment midpoints
  return lineSegment(polygon)
  .features
  .map(f => midpoint(...f.geometry.coordinates).geometry.coordinates)
  .reduce((longest, point, i, values) => {
      // compare to every other midpoint
      return values.reduce((longest, point2, i2) => {
          if(i2 == i) return longest;
          if(!longest || !longest.length || distance(point, point2) > distance(...longest)) return [ point, point2 ];
          return longest;
      }, longest);
  }, []);
  // returns [ [ a, b ], [ c, d ]]
}

function getHeading(feature) {
  const [ a, b ] = longBisectingLinePoints(feature);
  let heading = Math.min(bearing(a, b, { final: true }), bearing(b, a, { final: true }));

  if (heading > 179) heading -= 180;
  heading -= 90;
  if (heading < 0) heading += 360;

  return heading;
}

export function spaceHeadings(geojson) {
  const features = geojson.features || geojson;
  for(const feature of features) {
      if(feature.geometry.type != "Polygon") continue;
      if(feature.properties.amenity != "parking_space") continue;
      feature.properties.heading = getHeading(feature);
  }
  return geojson
}

export function rotateAlignLabelsToMap(geojson, bearing=0) {
  const features = geojson.features || geojson;
  for(const feature of features) {
    if (feature.properties.hasOwnProperty("heading") && feature.properties.hasOwnProperty("label")) {
      if (bearing < 0) bearing += 360;
      if (feature.properties.heading < 0) feature.properties.heading += 360;

      let delta = Math.abs(bearing - feature.properties.heading);
      if (delta >= 90 && delta <= 270) {
        feature.properties.heading -= 180;
      }
    }
  }
  return geojson;
}