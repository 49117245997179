import {
  client,
  Service,
  type Area,
  type ExpandedProvider,
} from "@communityboss/api";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
// import bbox_from_geometry from "@turf/bbox";
import { bbox, type Feature, type FeatureCollection } from "@turf/turf";
import type { SearchAddressResponse } from "azure-maps-rest";
import debug from "debug";
import mapboxgl, { type FitBoundsOptions } from "mapbox-gl";
import { assign, createMachine, interpret } from "xstate";
import {
  BOUNDARY,
  DEFAULT_CENTER_LAT_LNG,
  POLYGON,
  RADIUS,
  type AreaType,
} from "./constants";
import { azure } from "./stores/azure";
import { utils } from "./utils/map";

const log = debug("boss:providers:state");

const DEFAULT_PADDING = 50;
const DEFAULT_BOUNDS_OPTIONS: FitBoundsOptions = {
  padding: DEFAULT_PADDING,
  animate: false,
};

mapboxgl.accessToken =
  "pk.eyJ1IjoicGFya2luZ2Jvc3MiLCJhIjoiY2swY3VheHQyMDE1ejNtbjV4M3RoeTQ5cyJ9.toumXl_aMY5GgH45lZyiuA"; //PUBLIC_MAPBOX_ACCESS_TOKEN;
const Draw = new MapboxDraw({
  controls: {
    line_string: false,
    point: false,
    combine_features: false,
    uncombine_features: false,
  },
});

type ActiveArea = Partial<Area>;
type PlaceType = "zip" | "city" | "state" | "country" | "address";

type SetTokenEvent = { type: "SET_TOKEN"; token: string };
type FetchProvidersEvent = { type: "FETCH_PROVIDERS" };
type CreateProviderEvent = { type: "CREATE_PROVIDER"; name: string };
type RemoveProviderEvent = { type: "REMOVE_PROVIDER"; id: string };
type CreateServiceEvent = {
  type: "CREATE_SERVICE";
  provider_id: string;
  name: string;
  category: string;
};
type RemoveServiceEvent = {
  type: "REMOVE_SERVICE";
  provider_id: string;
  service_id: string;
};
type EditAreaEvent = {
  type: "EDIT_AREA";
  provider_id: string;
  service_id: string;
  area: ActiveArea;
};
type StopEditingAreaEvent = { type: "STOP_EDITING_AREA" };
type CreateAreaMapEvent = { type: "CREATE_AREA_MAP"; container: HTMLElement };
type ChangeAreaTypeEvent = { type: "CHANGE_AREA_TYPE"; area_type: AreaType };
type ChangeRadiusEvent = { type: "CHANGE_RADIUS"; radius: number };
type ChangeBoundaryEvent = { type: "CHANGE_BOUNDARY"; boundary: string };
type UpdatePolygonEvent = {
  type: "UPDATE_POLYGON";
  features?: FeatureCollection;
};
type LocationSearchEvent = {
  type: "LOCATION_SEARCH";
  result?: MapboxGeocoder.Result;
};

type Events =
  | SetTokenEvent
  | FetchProvidersEvent
  | CreateProviderEvent
  | RemoveProviderEvent
  | CreateServiceEvent
  | RemoveServiceEvent
  | EditAreaEvent
  | StopEditingAreaEvent
  | CreateAreaMapEvent
  | ChangeAreaTypeEvent
  | ChangeRadiusEvent
  | ChangeBoundaryEvent
  | UpdatePolygonEvent
  | LocationSearchEvent;

type Services = {
  fetch_providers: { data: ExpandedProvider[] };
  create_provider: { data: ExpandedProvider };
  remove_provider: { data: ExpandedProvider };
  create_service: { data: { service: Service; provider_id: string } };
  remove_service: { data: { service_id: string; provider_id: string } };
  create_area_map: { data: mapboxgl.Map };
  update_area_map: { data: void };
  fetch_boundaries: {
    data: Awaited<ReturnType<typeof azure.boundaries.fetch>>;
  };
  fetch_boundary_features: {
    data: Awaited<ReturnType<typeof azure.boundaries.get>>;
  };
};

export type Context = {
  token?: string;
  providers: Map<string, ExpandedProvider>;
  error?: Error;

  /** The area that is being viewed/editing and the temporary state prior to updating it. */
  active_area?: {
    provider_id: string;
    service_id: string;
    /** The peristed Area details. */
    area: ActiveArea;
    area_type: AreaType;
    radius?: {
      radius?: number;
      center?: [number, number];
    };
    boundary?: {
      type?: PlaceType;
      bounds?: [number, number, number, number];
      center?: [number, number];
      place_name?: string;
      boundaries?: SearchAddressResponse["results"];
      selected_boundary?: string;
      features?: GeoJSON.FeatureCollection;
    };
    polygon?: {
      features?: FeatureCollection;
    };
  };
  map_instance?: mapboxgl.Map;
};

export const machine = createMachine(
  {
    id: "providers",
    initial: "initializing",
    context: { providers: new Map() },
    on: {
      SET_TOKEN: {
        target: "fetching_providers",
        actions: assign({ token: (ctx, e) => e.token }),
      },
      FETCH_PROVIDERS: {
        target: "fetching_providers",
        // actions: assign({ }),
        cond: (ctx, e) => Boolean(ctx.token),
      },
      CREATE_PROVIDER: {
        target: "creating_provider",
        cond: (ctx, e) => Boolean(ctx.token),
      },
      REMOVE_PROVIDER: {
        target: "removing_provider",
        cond: (ctx, e) => Boolean(ctx.token) && ctx.providers.has(e.id),
      },
      CREATE_SERVICE: {
        target: "creating_service",
        cond: (ctx, e) => Boolean(ctx.token),
      },
      REMOVE_SERVICE: {
        target: "removing_service",
        cond: (ctx, e) =>
          Boolean(ctx.token) &&
          ctx.providers.has(e.provider_id) &&
          Boolean(
            ctx.providers
              .get(e.provider_id)
              ?.services?.find((s) => s.id === e.service_id)
          ),
      },
      EDIT_AREA: {
        target: "editing_area",
        cond: (ctx, e) =>
          Boolean(ctx.token) &&
          ctx.providers.has(e.provider_id) &&
          Boolean(
            ctx.providers
              .get(e.provider_id)
              ?.services?.find((s) => s.id === e.service_id)
          ), //&&
        // Boolean(
        //   ctx.providers
        //     .get(e.provider_id)
        //     ?.services?.find((s) => s.id === e.service_id)
        //     ?.areas?.find((a) => a.id === e.area_id)
        // ),
      },
    },
    states: {
      initializing: {},
      fetching_providers: {
        invoke: {
          src: "fetch_providers",
          onDone: {
            target: "idle",
            actions: assign({
              providers: (ctx, e) => new Map(e.data.map((p) => [p.id, p])),
            }),
          },
          onError: {
            target: "idle",
            actions: assign({ error: (ctx, e) => e.data }),
          },
        },
      },
      creating_provider: {
        invoke: {
          src: "create_provider",
          onDone: {
            target: "idle",
            actions: assign({
              providers: (ctx, e) => {
                ctx.providers.set(e.data.id, e.data);
                return ctx.providers;
              },
            }),
          },
          onError: {
            target: "idle",
            actions: assign({ error: (ctx, e) => e.data }),
          },
        },
      },
      removing_provider: {
        invoke: {
          src: "remove_provider",
          onDone: {
            target: "idle",
            actions: assign({
              providers: (ctx, e) => {
                ctx.providers.delete(e.data.id);
                return ctx.providers;
              },
            }),
          },
          onError: {
            target: "idle",
            actions: assign({ error: (ctx, e) => e.data }),
          },
        },
      },
      creating_service: {
        invoke: {
          src: "create_service",
          onDone: {
            target: "idle",
            actions: assign({
              providers: (ctx, { data: { service, provider_id } }) => {
                log("data:", service);
                log("providers", ctx.providers);
                const provider = ctx.providers.get(provider_id);
                if (!provider) return;
                log("found provider:", provider);
                const updated = ctx.providers.set(provider_id, {
                  ...provider,
                  services: [...(provider?.services ?? []), service],
                });
                log("updated provider:", provider);
                return updated;
              },
            }),
          },
          onError: {
            target: "idle",
            actions: assign({ error: (ctx, e) => e.data }),
          },
        },
      },
      removing_service: {
        invoke: {
          src: "remove_service",
          onDone: {
            target: "idle",
            actions: assign({
              providers: (ctx, { data: { service_id, provider_id } }) => {
                log("data:", service_id);
                log("providers", ctx.providers);
                const provider = ctx.providers.get(provider_id);
                if (!provider) return;
                log("found provider:", provider);
                const updated = ctx.providers.set(provider_id, {
                  ...provider,
                  services: provider?.services?.filter(
                    (s) => s.id !== service_id
                  ),
                });
                log("updated provider:", provider);
                return updated;
              },
            }),
          },
          onError: {
            target: "idle",
            actions: assign({ error: (ctx, e) => e.data }),
          },
        },
      },
      editing_area: {
        initial: "editing",
        entry: "set_active_area",
        states: {
          editing: {
            on: {
              CREATE_AREA_MAP: "creating_area_map",
              CHANGE_AREA_TYPE: {
                actions: [
                  assign({
                    active_area: (ctx, e) => ({
                      ...ctx.active_area,
                      area_type: e.area_type as AreaType,
                    }),
                  }),
                  "reset_area_map",
                  "update_area_map",
                ],
              },
              CHANGE_RADIUS: {
                // target: "updating_area_map",
                // target: "editing",
                actions: [
                  assign({
                    active_area: (ctx, e) => ({
                      ...ctx.active_area,
                      radius: {
                        ...ctx.active_area?.radius,
                        radius: e.radius,
                      },
                    }),
                  }),
                  "update_area_map",
                ],
              },
              CHANGE_BOUNDARY: {
                target: "fetching_boundary_features",
                actions: [
                  assign({
                    active_area: (ctx, e) => ({
                      ...ctx.active_area,
                      area_type: BOUNDARY,
                      boundary: {
                        ...ctx.active_area?.boundary,
                        selected_boundary: e.boundary,
                      },
                    }),
                  }),
                  "reset_area_map",
                ],
              },
              UPDATE_POLYGON: {
                // target: "editing",
                actions: [
                  assign({
                    active_area: (ctx, e) => ({
                      ...ctx.active_area,
                      polygon: {
                        ...ctx.active_area?.polygon,
                        features: e.features,
                      },
                    }),
                  }),
                  "update_area_map",
                ],
              },
              LOCATION_SEARCH: {
                target: "fetching_boundaries",
                actions: assign({
                  active_area: (ctx, e) => {
                    const raw_type = e.result.place_type[0];
                    let type: PlaceType;
                    switch (raw_type) {
                      case "address":
                        type = "address";
                        break;
                      case "postcode":
                        type = "zip";
                        break;
                      case "place":
                        type = "city";
                        break;
                      case "region":
                        type = "state";
                        break;
                      case "country":
                        type = "country";
                        break;
                      default:
                        // place_type = raw_type
                        break;
                    }
                    return {
                      ...ctx.active_area,
                      area_type: BOUNDARY,
                      boundary: {
                        ...ctx.active_area?.boundary,
                        place_name: e.result.place_name,
                        bounds: e.result.bbox,
                        center: e.result.center as [number, number],
                        type,
                      },
                    };
                  },
                }),
              },
              // FIT_TO_FEATURES: {}
              // SAVE_AREA
              // CHANGE_CENTER: [number, number]
            },
          },
          creating_area_map: {
            invoke: {
              src: "create_area_map",
              onDone: {
                target: "editing",
                actions: [
                  assign({ map_instance: (ctx, e) => e.data }),
                  "update_area_map",
                ],
              },
            },
          },
          fetching_boundaries: {
            invoke: {
              src: "fetch_boundaries",
              onDone: {
                target: "editing",
                cond: (ctx) => Boolean(ctx.active_area?.boundary?.place_name),
                actions: [
                  assign({
                    active_area: (ctx, e) => ({
                      ...ctx.active_area,
                      boundary: {
                        ...ctx.active_area?.boundary,
                        boundaries: e.data,
                      },
                    }),
                  }),
                  "reset_area_map",
                  "update_area_map",
                ],
              },
            },
          },
          fetching_boundary_features: {
            invoke: {
              src: "fetch_boundary_features",
              onDone: {
                target: "editing",
                cond: (ctx) =>
                  Boolean(ctx.active_area?.boundary?.selected_boundary),
                actions: [
                  assign({
                    active_area: (ctx, e) => ({
                      ...ctx.active_area,
                      boundary: {
                        ...ctx.active_area?.boundary,
                        bounds: e.data.bbox,
                        features: e.data,
                      },
                    }),
                  }),
                  "reset_area_map",
                  "update_area_map",
                ],
              },
            },
          },
        },
        on: {
          STOP_EDITING_AREA: {
            target: "idle",
            actions: [
              "remove_area_map",
              assign({ active_area: undefined, map_instance: undefined }),
            ],
          },
        },
      },
      idle: {},
    },
    schema: {
      context: {} as Context,
      events: {} as Events,
      services: {} as Services,
    },
    tsTypes: {} as import("./state.typegen").Typegen0,
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {
      set_active_area: assign({
        active_area: (ctx, e) => {
          const area_type = e.area?.street
            ? BOUNDARY
            : e.area?.json
            ? POLYGON
            : RADIUS;

          const active_area = {
            provider_id: e.provider_id,
            service_id: e.service_id,
            // Only store area if it is persisted in the backend
            area: e.area?.id ? e.area : undefined,
            area_type,
            radius: {},
            boundary: {},
            polygon: {},
          };

          if (area_type === RADIUS) {
            active_area.radius = {
              radius: e.area?.radius ?? 65 * 1000, // 65km
              // Set the default area to be a RADIUS type centered on the
              // Empire State Building for shits and giggles.
              center:
                e.area?.latitude && e.area?.longitude
                  ? ([e.area.longitude, e.area.latitude] as [number, number])
                  : DEFAULT_CENTER_LAT_LNG,
            };
          }

          return active_area;
        },
      }),
      remove_area_map(ctx) {
        const { map_instance } = ctx;
        map_instance?.remove();
      },
      reset_area_map(ctx) {
        log("reset area map");
        const { map_instance } = ctx;

        if (map_instance.hasControl(Draw)) map_instance.removeControl(Draw);

        const style = map_instance.getStyle();
        // Remove drawing layers:
        style.layers?.forEach((layer) => {
          if (
            layer.id.startsWith(RADIUS) ||
            layer.id.startsWith(BOUNDARY) ||
            layer.id.startsWith(POLYGON)
          ) {
            map_instance.removeLayer(layer.id);
          }
        });
        Object.keys(style.sources ?? {})?.forEach((source) => {
          if (
            source.startsWith(RADIUS) ||
            source.startsWith(BOUNDARY) ||
            source.startsWith(POLYGON)
          ) {
            map_instance.removeSource(source);
          }
        });
      },
      update_area_map(ctx) {
        log("updating area map...");
        const {
          map_instance,
          active_area: { radius, polygon, boundary, area, area_type },
        } = ctx;

        switch (area_type) {
          case RADIUS:
            log("render radius circle:", radius);
            const { feature } = utils.radius_cicle.render({
              instance: map_instance,
              center: radius.center,
              radius_meters: radius.radius,
            });

            log("fitting bounds...");
            map_instance.fitBounds(
              bbox(feature) as [number, number, number, number],
              DEFAULT_BOUNDS_OPTIONS
            );
            break;
          case BOUNDARY:
            log("rendering boundary:", boundary);
            if (!boundary.features) return;
            utils.boundary.render({
              instance: map_instance,
              features: boundary.features,
              name: BOUNDARY,
            });

            log("fitting bounds...");
            map_instance.fitBounds(boundary.bounds, DEFAULT_BOUNDS_OPTIONS);
            break;
          case POLYGON:
            log("rendering polygon draw control:", polygon);
            if (!map_instance.hasControl(Draw))
              map_instance.addControl(Draw, "top-left");

            if (polygon?.features) {
              log("setting custom polygon features...");
              Draw.set(polygon.features);

              log("fitting bounds...");
              map_instance.fitBounds(
                bbox(polygon.features) as [number, number, number, number],
                DEFAULT_BOUNDS_OPTIONS
              );
            }
            break;

          default:
            break;
        }
      },
    },
    services: {
      async fetch_providers(ctx) {
        log("fetching providers");
        const resp = await client(ctx.token).providers.all();
        if (resp.success === false) {
          log("error fetching providers:", resp.error);
          throw resp.error;
        }
        log("fetched providers:", resp.data);
        return resp.data;
      },

      async create_provider(ctx, { name }) {
        log("creating provider:", name);
        const resp = await client(ctx.token).providers.create({ name });
        if (resp.success === false) throw resp.error;
        log("created provider:", resp);
        return resp.data;
      },

      // async update_provider(ctx, { name }) {
      //   log("creating provider:", name);
      //   const resp = await client(ctx.token).providers.update({ id, name });
      //   if (resp.success === false) throw resp.error;
      //   log("created provider:", resp);
      //   return resp.data;
      // },

      async remove_provider(ctx, e) {
        const yes = confirm("Are you sure you want to delete this provider?");
        if (!yes) return;
        log("removing provider:", e.id);
        const resp = await client(ctx.token).providers.remove(e.id);
        if (resp.success === false) throw resp.error;
        log("removed provider:", resp.data);
        return resp.data;
      },

      async create_service(ctx, { provider_id, category, name }) {
        log("creating service:", { name, category });
        const resp = await client(ctx.token).services.create({
          provider_id,
          category,
          name,
        });
        if (resp.success === false) throw resp.error;
        log("created service:", resp);
        return { service: resp.data, provider_id };
      },

      async remove_service(ctx, e) {
        const yes = confirm("Are you sure you want to delete this service?");
        if (!yes) return;
        log("removing service:", e.service_id);
        const resp = await client(ctx.token).services.remove(e.service_id);
        if (resp.success === false) throw resp.error;
        log("removed service:", resp.data);
        return e;
      },

      async fetch_boundaries(ctx) {
        const name = ctx.active_area?.boundary?.place_name;
        const boundaries = await azure.boundaries.fetch(name);
        return boundaries;
      },

      async fetch_boundary_features(ctx) {
        log("fetch boundary features...");
        const features = await azure.boundaries.get(
          ctx.active_area.boundary.selected_boundary
        );
        return features;
      },

      create_area_map: (ctx, e) => async (callback, onReceive) => {
        const { area } = ctx.active_area!;

        log("creating area map:", area);

        return new Promise((resolve) => {
          const instance = new mapboxgl.Map({
            container: e.container,
            // style: styles.satellite,
            style: "mapbox://styles/mapbox/satellite-v9",
            center:
              area?.latitude && area?.longitude
                ? [area.longitude, area.latitude]
                : DEFAULT_CENTER_LAT_LNG,
            // radius: 65 * 1000, // 65km
            zoom: 17,
          } as mapboxgl.MapboxOptions);

          // Map is rendered and ready
          instance.on("load", () => {
            log("map loaded");
            resolve(instance);
            // map.update((s) => ({ ...s, rendered: true }));
            // map.update_features({
            //   type: "FeatureCollection",
            //   features: area?.geojson ? [{ properties: {}, ...area.geojson }] : [],
            // });
            // map.change_area_type(state.area_type, false);
            // map.fit_to_features();
          });

          // Draw events
          instance.on("draw.create", (e: { features: Feature[] }) => {
            log("drawing created:", e.features);
            callback({
              type: "UPDATE_POLYGON",
              features: {
                type: "FeatureCollection",
                features: e?.features,
              },
            } as UpdatePolygonEvent);
            // map._disable_polygon_button();
          });
          instance.on("draw.update", (e: { features: Feature[] }) => {
            log("drawing updated:", e?.features);
            callback({
              type: "UPDATE_POLYGON",
              features: {
                type: "FeatureCollection",
                features: e?.features,
              },
            } as UpdatePolygonEvent);
          });
          instance.on("draw.delete", (e) => {
            log("removed drawing:", e.features);
            callback("UPDATE_POLYGON");
            // map._enable_polygon_button();
          });

          // Add location geocoder search UI
          const search = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            mapboxgl,
            zoom: 12,
            flyTo: { animate: false },
          });
          search.on("result", ({ result }: { result: MapboxGeocoder.Result }) =>
            callback({ type: "LOCATION_SEARCH", result })
          );
          instance.addControl(search);

          return instance;
        });
      },
    },
  }
);

export const state = interpret(machine).start();
