<script context="module">
  import NewSpaceMeter from "./components/NewSpaceMeter.svelte";
  import EditPolicyMeters from "./EditPolicyMeters.svelte";
</script>

<script>
  import { spaces } from "./stores";
  import { fetchUpdatePolicy } from "./api";
  import { get, range, filter, map, size, each, clone, find } from "lodash-es";
  import ItemsSelector from "./components/ItemsSelector.svelte";

  export let policy = null;
  export let readonly = false;
  $: issue = !!get(policy, "issue.enabled");
  $: disabled = !!get(policy, "disabled");

  let submittable = false;
  let submitting = false;

  let editingPolicy = null;

  //$: editingPolicy = policy;
  $: if (!!policy && (!editingPolicy || !submittable))
    editingPolicy = { ...policy };

  $: selectedItems = map(get(editingPolicy, "space.items", {}));
  $: console.log(selectedItems);
  $: allItems = map(get(editingPolicy, "space.items", {})).length === 0;
  $: singleItem = map(get(editingPolicy, "space.items", {})).length === 1;

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!editingPolicy || submitting || !submittable) return;

    submitting = true;

    const form = e.target;

    const formData = new FormData(form);

    await fetchUpdatePolicy(editingPolicy, formData);

    submitting = submittable = false;
    editingPolicy = null;
  }
</script>

<section>
  <header>
    <h1>Spaces</h1>
  </header>
  <form on:change={change} on:input={change} on:submit={submit}>
    <fieldset disabled={readonly && "disabled"}>
      <ul>
        <li>
          <label for="policy-field-space">Space</label>
          <select
            id="policy-field-space"
            name="permit.space"
            value={get(editingPolicy, "space.required")
              ? "true"
              : get(editingPolicy, "space.request")
              ? "false"
              : ""}
          >
            <option value="true">required</option>
            <option value="false">optional</option>
            <option value="">disabled</option>
          </select>
        </li>
        <li>
          <label for="policy-space-assigned">Assignment</label>
          <select
            id="policy-space-assigned"
            name="permit.space.assigned"
            value={get(editingPolicy, "space.assigned.required", false) + ""}
          >
            <option value="true">required</option>
            <option value="false">not checked</option>
          </select>
        </li>
        <li>
          <label for="policy-space-unpermitted">Capacity</label>
          <select
            id="policy-space-unpermitted"
            name="permit.space.unpermitted"
            value={get(editingPolicy, "space.unpermitted.required", false) + ""}
          >
            <option value="false">not checked</option>
            <option value="true">enforced</option>
          </select>
        </li>
        <li>
          <label for="policy-space-options">Options</label>
          <select
            id="policy-space-options"
            on:blur={(e) => {
              allItems = e.target.value === "all";
              singleItem = e.target.value == "single";
            }}
            on:change={(e) => {
              allItems = e.target.value === "all";
              singleItem = e.target.value == "single";
            }}
            value={allItems ? "all" : singleItem ? "single" : "select"}
          >
            <option value="all">all spaces</option>
            <option value="single">single space…</option>
            <option value="select">select spaces…</option>
          </select>
        </li>
        {#if singleItem}
          <li>
            <label for="policy-space-options">Selected</label>
            <select id="policy-space-item" name="permit.space.items">
              {#if $spaces}
                {#each $spaces as item}
                  <option
                    value={item.display}
                    selected={selectedItems.includes(item.display) ||
                      selectedItems.includes(item.key)}>{item.display}</option
                  >
                {/each}
              {/if}
            </select>
          </li>
        {:else if !allItems}
          <li>
            <label for="policy-space-items">Selected</label>
            <ItemsSelector
              name="permit.space.items"
              items={$spaces}
              selected={selectedItems}
              value="display"
            />
            <!-- <ul> -->
            <!-- <li><button type="button" on:click={e => (selectedItems = [])}>select all</button></li> -->
            <!-- <li><button type="button" on:click={e => (selectedItems = [ "dummy" ])}>unselect all</button></li> -->
            <!-- {#if $spaces}
                    {#each $spaces as item}
                        <li>
                            <input id="space-options-{item.id}" type="checkbox" name="permit.space.items" checked={selectedItems.length === 0 || selectedItems.includes(item.display) || selectedItems.includes(item.key)} value="{item.display}" /> <label for="space-options-{item.id}"><data class="id {[ item.format, item.type ].join(" ")}">{item.display}</data></label>
                        </li>
                        
                    {/each}
                    {/if}
                </ul> -->
          </li>
        {/if}
      </ul>
    </fieldset>

    <!-- <fieldset disabled={readonly && "disabled"}>
        <ul class="toggle" on:change={e => { if(e.target.checked) allItems = e.target.value === "true"; }}>
            <li><input id="policy-space-all" type="radio" value="true" checked={allItems} /><label for="policy-space-all">All</label></li>
            <li><input id="policy-space-all" type="radio" value="false" checked={!allItems} /><label for="policy-space-select">Select</label></li>
        </ul>
    </fieldset> -->

    <input type="hidden" name="permit.space.items" value="" />

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
  {#if !readonly && (issue || disabled)}<NewSpaceMeter {policy} />{/if}
  <EditPolicyMeters {policy} groups={["space"]} />
</section>
