<script>
  export let policy;

  $: url =
    policy &&
    `https://display.amenitypass.app/properties/${
      policy.scope.id || policy.scope
    }/amenities/${policy.policy}`;

  const supported = !!(navigator.clipboard && navigator.clipboard.writeText);
  let success = false;
  let input;

  function copy() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(url);
    }

    success = true;

    setTimeout(() => (success = false), 1000);
  }
</script>

{#if supported}
  <!-- <form> -->
  <!-- <input bind:this={input} type="text" required readonly value="{url||""}" on:click={copy} /> -->
  <button
    type="button"
    class="copy"
    disabled={!supported}
    class:success
    on:click={copy}>Copy Link</button
  >
  <!-- </form> -->
{/if}
