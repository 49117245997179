<script>
  import bbox from "geojson-bbox";
  import { find, isEmpty, clone } from "lodash-es";
  import { param } from "../util/params";
  import { query } from "../util/router";
  import { mapbox, boundsFromGeoJSON, updateStyle } from "./maps/mapbox";
  import { centerOfMass } from "@turf/turf";
  import { onMount } from "svelte";
  import PropertySearch from "./PropertySearch.svelte";
  import Dropdown from "../components/maps/Dropdown.svelte";
  import {
    style as mapstyle,
    propertyGeoJsonLeveled as geojson,
    styles,
    layers as mapLayers,
  } from "./maps/stores";

  export let properties;
  let allGeojson;
  let cssLoaded;
  let styleLoaded;
  let map;
  let container;
  let results = [];
  let hasSelected;
  let activePopup;

  const paramStyle = param("style");
  const defaultStyle = $paramStyle || "mapboxnavigation";
  const style = mapstyle(defaultStyle);
  const styleOptions = Object.entries($styles).map((entry) => ({
    name: entry[1].name,
    value: entry[0],
  }));

  const paramLayers = param("layers");
  $: selectedLayers = $paramLayers || [];

  function handleStyleChange(style, name) {
    query(name, style);
    styleLoaded = false;
  }

  function handleToggleLayer(e, id) {
    let updatedLayers = clone(selectedLayers);

    if (!Array.isArray(updatedLayers)) {
      updatedLayers = [selectedLayers];
    }

    if (selectedLayers.includes(id)) {
      updatedLayers.splice(selectedLayers.indexOf(id), 1);
    } else {
      updatedLayers.push(id);
    }

    query("layers", updatedLayers);
  }

  function addDataLayers() {
    // Add properties geojson
    map.addSource("properties", {
      type: "geojson",
      data: allGeojson,
    });

    // Add radar
    map.addSource("azure-radar-tiles", {
      type: "raster",
      tiles: [
        "https://atlas.microsoft.com/map/tile?subscription-key=ooF9ny-5e5MfLHdea_vVNwnpuUP6Hia9ju7_p5cObYg&api-version=2.0&tilesetId=microsoft.weather.radar.main&zoom={z}&x={x}&y={y}",
      ],
    });

    const layers = map.getStyle().layers;

    // Find the index of the first symbol layer in the map style
    let firstSymbolId;
    for (var i = 0; i < layers.length; i++) {
      if (layers[i].type === "symbol") {
        firstSymbolId = layers[i].id;
        break;
      }
    }

    Object.values($mapLayers).forEach((layerData) => {
      const { layer } = layerData;

      if (layer.type === "raster") {
        map.addLayer(layer, firstSymbolId);
      } else {
        map.addLayer(layer);
      }
    });
  }

  function getGeojsonFromProperties(properties) {
    const geojson = {
      type: "FeatureCollection",
      features: [],
    };

    geojson.features = properties.map((property) => ({
      type: "Feature",
      geometry: {
        type: property.address.area.type,
        coordinates: property.address.area.coordinates,
      },
      properties: {
        id: property.id,
        address: `${property.address.city}, ${property.address.state} ${property.address.postal}`,
        name: property.address.name,
      },
    }));

    return geojson;
  }

  $: if (properties && cssLoaded) {
    allGeojson = getGeojsonFromProperties(properties);
    allGeojson.bbox = bbox(allGeojson);

    if (!map) initMap();
  }

  $: if (map) {
    map.on("style.load", function () {
      styleLoaded = true;
      addDataLayers();
    });

    map.on("zoomend", function () {
      if (map.getZoom() < 4) {
        hasSelected = false;
      }
    });
  }

  $: if (map && $style) {
    console.log("STYLE=", $style);
    updateStyle(map, $style);
  }

  $: if (map && styleLoaded && selectedLayers) {
    Object.keys($mapLayers).forEach((layerId) => {
      const layerButton = document.body.querySelector(
        `.properties-map-layer-toggle[data-id=${layerId}]`
      );
      if (selectedLayers.includes(layerId)) {
        map.setLayoutProperty(layerId, "visibility", "visible");
        layerButton.classList.add("asActive");
      } else {
        map.setLayoutProperty(layerId, "visibility", "none");
        layerButton.classList.remove("asActive");
      }
    });
  }

  const onSearch = (searchResults) => {
    results = searchResults;

    for (const marker of document.querySelectorAll(".properties-marker")) {
      if (!find(results, { id: marker.getAttribute("data-id") })) {
        marker.style.visibility = "hidden";
      } else {
        marker.style.visibility = "visible";
      }
    }

    map && boundsFromGeoJSON(map, getGeojsonFromProperties(results), 40);
  };

  const onSelect = (selectedId) => {
    const selectedProperty = find(properties, { id: selectedId });

    const { name, address, amenities, vehicles, management, format } =
      selectedProperty;
    let products = [];

    if (amenities.items.includes("amenity")) products.push("Amenity Boss");
    if (amenities.items.includes("parking")) products.push("Parking Boss");
    if (selectedProperty.map) products.push("Smart Map");
    if (vehicles.recognition.enabled) products.push("LPR");

    const popupElem = document.createElement("div");
    popupElem.className = "properties-popup";
    const popupName = document.createElement("h1");
    popupName.className = "properties-popup-name";
    popupName.innerText = name;
    const popupAddress = document.createElement("div");
    popupAddress.className = "properties-popup-address";
    popupAddress.innerText = `${address.city}, ${address.state}`;
    const popupManagement = document.createElement("div");
    popupManagement.className = "properties-popup-management";
    popupManagement.innerText = management;
    const popupFormat = document.createElement("div");
    popupFormat.className = "properties-popup-format";
    popupFormat.innerText = format;
    const popupLink = document.createElement("a");
    popupLink.className = "properties-popup-link";
    popupLink.setAttribute("href", `/properties/${selectedId}`);
    popupLink.setAttribute("target", "_blank");
    popupLink.innerText = "VIEW PROPERTY";
    popupLink.onclick = handleClickLink;
    const usedProducts = document.createElement("ul");
    usedProducts.className = "properties-products";
    products.forEach((product) => {
      const prodLi = document.createElement("li");
      prodLi.innerText = product;
      usedProducts.appendChild(prodLi);
    });

    popupElem.appendChild(popupName);
    popupElem.appendChild(popupAddress);
    popupElem.appendChild(popupManagement);
    popupElem.appendChild(popupFormat);
    popupElem.appendChild(usedProducts);
    popupElem.appendChild(popupLink);

    const point = centerOfMass({
      type: address.area.type,
      coordinates: address.area.coordinates,
    });

    if (activePopup) activePopup.remove();

    activePopup = new mapbox.Popup({ closeOnClick: false, offset: [0, -20] })
      .setLngLat(point.geometry.coordinates)
      .setDOMContent(popupElem)
      .addTo(map);

    if (map.getZoom() < 7) {
      map.setZoom(7);
    }

    if (!hasSelected) map.jumpTo({ center: point.geometry.coordinates });
    hasSelected = true;
  };

  function handleClickLink(e) {
    e.preventDefault();
    window.location.href = e.target.href;
  }

  function createMapMarker(map, feature) {
    const { id } = feature.properties;
    const property = find(properties, { id });

    const markerElem = document.createElement("div");
    markerElem.className = "properties-marker mapboxgl-marker";

    if (property) markerElem.className += ` marker-${property.format}`;

    markerElem.setAttribute("data-id", id);
    const marker = new mapbox.Marker(markerElem);
    const point = centerOfMass(feature);
    markerElem.onclick = () => onSelect(id);

    marker
      .setLngLat(point.geometry.coordinates)
      // .setPopup(popup)
      .addTo(map);
  }

  async function initMap() {
    map = await new mapbox.Map({
      container,
      style: defaultStyle,
    });

    // Add scale bar
    map.addControl(new mapbox.ScaleControl(), "bottom-right");

    // Add markers
    allGeojson.features.forEach((feature) => createMapMarker(map, feature));
    boundsFromGeoJSON(map, allGeojson, 20);
  }

  onMount(() => {
    const styleUrl = `https://api.mapbox.com/mapbox-gl-js/v${mapbox.version}/mapbox-gl.css`;
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = styleUrl;
    link.onload = async () => (cssLoaded = true);

    document.head.appendChild(link);
  });
</script>

<main>
  <figure class="properties-map map" bind:this={container}>
    {#if map && $styles}
      <PropertySearch
        {properties}
        showList={true}
        on:search={(e) => onSearch(e.detail)}
        on:select={(e) => onSelect(e.detail)}
      />
      <div class="properties-map-styles">
        <h2>Styles</h2>
        <div class="properties-map-style-dropdown">
          <Dropdown
            name="style"
            defaultValue={defaultStyle}
            options={styleOptions}
            onChange={handleStyleChange}
          />
        </div>
      </div>
      <div class="properties-map-layers">
        <h2>Layers</h2>
        <div class="properties-map-layers-menu">
          {#each Object.entries($mapLayers) as [id, layer]}
            <div
              class="properties-map-layer-toggle"
              data-id={id}
              on:click={(e) => handleToggleLayer(e, id)}
            >
              {layer.name}
            </div>
          {/each}
        </div>
      </div>
    {:else}
      <h1>Loading&hellip;</h1>
    {/if}
  </figure>
</main>
