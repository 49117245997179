import { get } from "lodash-es";

export function resolvePolicy(id, state) {
  return hydratePolicy(state[id], state);
}

function hydratePolicy(item, state) {
  if (!item) return item;

  item.pricing =
    (state &&
      state["pricing"] &&
      state["pricing"]["for"] &&
      state["pricing"]["for"][item.id]) ||
    (state &&
      state["pricing"] &&
      state["pricing"]["for"] &&
      state["pricing"]["for"][item.subject]);
  item.metered =
    (state &&
      state["metered"] &&
      state["metered"]["for"] &&
      state["metered"]["for"][item.id]) ||
    (state &&
      state["metered"] &&
      state["metered"]["for"] &&
      state["metered"]["for"][item.subject]);
  item.authcodes =
    (state &&
      state["authcodes"] &&
      state["authcodes"]["for"] &&
      state["authcodes"]["for"][item.id]) ||
    (state &&
      state["authcodes"] &&
      state["authcodes"]["for"] &&
      state["authcodes"]["for"][item.subject]);

  return item;
}

export function pricesFromPolicy(policy) {
  if (!policy) return;
  return Object.values(
    get(policy, "pricing.prices.items") ||
      get(policy, "pricing.prices") ||
      get(policy, "prices.items") ||
      {}
  );
}

export function metersFromPolicy(policy) {
  if (!policy) return;
  return Object.values(
    get(policy, "metered.meters.items") ||
      get(policy, "metered.meters") ||
      get(policy, "meters.items") ||
      {}
  );
}

export function authCodesFromPolicy(policy) {
  if (!policy) return;
  return Object.values(
    get(policy, "authcodes.codes.items") ||
      get(policy, "authcodes.codes") ||
      get(policy, "authcodes.items") ||
      {}
  );
  //return get(policy, "authcodes");
}
