import { api, authHeader } from "../../api";
import { stringify as toWKT } from "wellknown";
import { merge } from "lodash-es";
import { updateItems } from '@parkingboss/svelte-utils';

export async function fetchPropertyGeoFeatures(property) {
    const res = await fetch(`${api.settings.apiBase}/geo?viewpoint=${new Date().toISOString()}&scope=${property.id || property}&valid=${new Date().toISOString()}`)
    //const res = await fetch(`https://customapps.parkingboss.com/geojson/master/${property.uuid.replace("-","")}.json`);
    const json = await res.json();
    return json;
}

// export async function fetchCreateGeoFeature(property, feature, features) {

//     const form = new FormData();
//     form.append("geometry", toWKT(feature.geometry)); // feature.geometry
//     for(const [name, value] in Object.entries(feature.properties)) form.append(name, value);

//     const doCreate = fetch(`${api.settings.apiBase}/geo?viewpoint=${new Date().toISOString()}&scope=${property.id || property}`, {
//         method:"POST",
//         body: form,
//     });

//     const [ createJson ] = await Promise.all([
//         doCreate
//     ])
//     .then(values => Promise.all(values.map(res => res.json())));

//     if(features) {
//         features.push(...createJson.features);
//     }

//     return createJson;
// }

export async function fetchRemoveGeoFeature(property, features, state) {

    const ids = [];
    if(typeof features == "string") ids.push(feature); // single string
    else if (features && features.id) ids.push(features.id); // single feature
    else if (features && features.length) ids.push(...features.map(i => i.id || i)); // array of features or ids
    else if (features && features.features) ids.push(...features.features.map(i => i.id)); // feature collection

    if(!ids.length) return {
        type: "FeatureCollection",
        features: [],
    };

    const doRemove = fetch(`${api.settings.apiBase}/geo?viewpoint=${new Date().toISOString()}${ids.reduce((str, id) => str + `&id=${id}`, "")}&${authHeader}`, {
        method:"DELETE",
    });

    const [ removeJson ] = await Promise.all([
        doRemove,
    ])
    .then(values => Promise.all(values.map(res => res.json())));

    if(state && state.features) state = state.features;

    if(state) {

        // update this to use json?
        for(const feature of removeJson.features) {
            let targetIndex = null;
            for(let i = 0; i < state.length; i++) {
                if(feature.id === state[i].id) targetIndex = i;
            }
            if(null != targetIndex) state.splice(targetIndex, 1);
        }
        
    }

    return removeJson;
}

export async function fetchUpdateGeoFeature(property, feature, features) {

    const doRemove = fetch(`${api.settings.apiBase}/geo?viewpoint=${new Date().toISOString()}&id=${feature.id || ""}&${authHeader}`, {
        method:"DELETE",
    });

    const form = new FormData();
    form.append("geometry", toWKT(feature.geometry)); // feature.geometry
    for(const [name, value] of Object.entries(feature.properties)) form.append(name, value);

    const doCreate = fetch(`${api.settings.apiBase}/geo?viewpoint=${new Date().toISOString()}&scope=${property.id || property}&${authHeader}`, {
        method:"POST",
        body: form,
    });

    const [ removeJson, createJson ] = await Promise.all([
        doRemove,
        doCreate
    ])
    .then(values => Promise.all(values.map(res => res.json())));

    let targetIndex = null;

    if(features && features.features) features = features.features;

    if(features) {
        if(feature.id) {
            for(let i = 0; i < features.length; i++) {
                if(feature.id === features[i].id) targetIndex = i;
            }
            if(null != targetIndex) features.splice(targetIndex, 1);
        }
        features.push(...createJson.features);
    }

    return merge(removeJson, createJson);
}

export async function fetchAndStorePropertyGeojson(property, branch) {
    const res = await fetch(`${api.settings.apiBase}/properties/${property.id || property}/map?viewpoint=${new Date().toISOString()}&branch=${branch || "develop"}`)
    //const res = await fetch(`https://customapps.parkingboss.com/geojson/master/${property.uuid.replace("-","")}.json`);
    const json = await res.json();
    updateItems({
        items:{
            "geojson":json,
        },
    });
    return json;
}

export async function fetchPropertyGeojson(property) {
    const res = await fetch(`${api.settings.apiBase}/properties/${property.id || property}/map?viewpoint=${new Date().toISOString()}`)
    //const res = await fetch(`https://customapps.parkingboss.com/geojson/master/${property.uuid.replace("-","")}.json`);
    const json = await res.json();
    return json;
}