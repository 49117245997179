export function percentFromStart(interval, value) {

    // var duration = intervalToDuration({
    //     start,
    //     end
    // }).

    if(!interval || !value) return null;

    if(value <= interval.start) return 0;

    var dur = (interval.end.getTime ? interval.end.getTime() : interval.end) - (interval.start.getTime ? interval.start.getTime() : interval.start);
    var diff = (value.getTime ? value.getTime() : value) - (interval.start.getTime ? interval.start.getTime() : interval.start);

    return (diff / dur) * 100;

}

export function percentFromEnd(interval, value) {

    if(!interval || !value) return null;

    // var duration = intervalToDuration({
    //     start,
    //     end
    // }).

    //console.log("percentFromEnd", interval.start, value, interval.end);

    if(value >= interval.end) return 0;

    var dur = (interval.end.getTime ? interval.end.getTime() : interval.end) - (interval.start.getTime ? interval.start.getTime() : interval.start);
    var diff = (interval.end.getTime ? interval.end.getTime() : interval.end) - (value.getTime ? value.getTime() : value);

    return (diff / dur) * 100;

}

function overlap(valueMin, valueMax, compareMin, compareMax) {

    if(valueMin <= compareMin && valueMax >= compareMax) return compareMax - compareMin;
    
    if(valueMax <= compareMin && valueMin >= compareMax) return 0;

    if(valueMin >= compareMin && valueMax <= compareMax) return valueMax - valueMin;

    if(valueMin <= compareMin && valueMax <= compareMax) return valueMax - compareMin;

    if(valueMin >= compareMin && valueMax >= compareMax) return compareMax - valueMin;

    return 0;


}

function dateAsMS(value) {
    if(!!value && value.getTime) return value.getTime();
    return value;
}

export function percentOf(interval, value) {

    if(!interval || !value) return null;

    var diff = overlap(dateAsMS(value.start), dateAsMS(value.end), dateAsMS(interval.start), dateAsMS(interval.end));

    if(diff <= 0) return 0; // 0%;

    var dur = dateAsMS(interval.end) - dateAsMS(interval.start);

    return (diff / dur) * 100;

}

export function getPercentInterval(a, b) {
    if(!a || !b) return null;
    return {
        start: percentFromStart(a, b.start),
        end: percentFromEnd(a, b.end),
    };
}