<script lang="ts" context="module">
  const midnight = Temporal.PlainTime.from("00:00:00");
  const endofday = Temporal.PlainTime.from("23:59:59.999");

  class DayOfWeek {
    static Sunday = 7;
    static Monday = 1;
    static Tuesday = 2;
    static Wednesday = 3;
    static Thursday = 4;
    static Friday = 5;
    static Saturday = 6;
  }

  class PlainDayTime {
    day: number;
    time: Temporal.PlainTime;
    constructor(day: number, time: Temporal.PlainTime) {
      this.day = day;
      this.time = time;
    }
    static from(s: string): PlainDayTime {
      const [day, time] = s.split("T");
      return new PlainDayTime(+day, Temporal.PlainTime.from(time));
    }
    addDays(days: number): PlainDayTime {
      if (days > 7) return new PlainDayTime(this.day + (days % 7), this.time); // man gpt got this one
      if (this.day + days > 7)
        return new PlainDayTime(this.day + days - 7, this.time);
      return new PlainDayTime(this.day + days, this.time);
    }
    static compare(a: PlainDayTime, b: PlainDayTime): number {
      if (a.day > b.day) return 1;
      if (a.day < b.day) return -1;
      return Temporal.PlainTime.compare(a.time, b.time);
    }
    toString(): string {
      return `${this.day}T${this.time}`;
    }
    static StartOfWeek = new PlainDayTime(DayOfWeek.Monday, midnight);
    static EndOfWeek = new PlainDayTime(DayOfWeek.Sunday, endofday);
    static SundayMidnight = new PlainDayTime(DayOfWeek.Sunday, midnight);
  }

  class PlainDayTimeInterval {
    start: PlainDayTime;
    end: PlainDayTime;
    constructor(start: PlainDayTime, end: PlainDayTime) {
      this.start = start;
      this.end = end;
    }
    static from(s: string): PlainDayTimeInterval {
      const [start, end] = s.split("/").map(PlainDayTime.from);
      //console.log("from", s, start, end);
      if (
        [start, end].every(
          (i) => PlainDayTime.compare(i, PlainDayTime.SundayMidnight) == 0
        )
      )
        return PlainDayTimeInterval.FullWeek;

      return new PlainDayTimeInterval(start, end);
    }
    toString(): string {
      return `${this.start}/${this.end}`;
    }

    duration(): Temporal.Duration {
      if (this.wrapsWeek()) {
        // two parts
        const days = 6 - this.start.day + this.end.day;
        return Temporal.Duration.from({ days })
          .add(this.start.time.until(midnight))
          .add(this.end.time.since(midnight));
      }
      const days = this.end.day - this.start.day;
      return Temporal.Duration.from({ days })
        .add(this.start.time.until(midnight))
        .add(this.end.time.since(midnight));

      return null;
    }
    contains(t: PlainDayTime | PlainDayTimeInterval): boolean {
      if (t instanceof PlainDayTimeInterval) {
        // console.log(
        //   "contains interval",
        //   this.toString(),
        //   t.toString(),
        //   this.contains(t.start) && this.contains(t.end)
        // );
        return this.contains(t.start) && this.contains(t.end);
      }
      //   console.log(
      //     "contains",
      //     this.start.toString(),
      //     t.toString(),
      //     this.end.toString(),
      //     PlainDayTime.compare(this.start, t),
      //     PlainDayTime.compare(this.end, t),
      //     PlainDayTime.compare(this.start, t) <= 0 &&
      //       PlainDayTime.compare(this.end, t) > 0
      //   );
      if (this.wrapsWeek()) {
        return (
          PlainDayTime.compare(this.start, t) <= 0 ||
          PlainDayTime.compare(this.end, t) > 0
        );
      }
      return (
        PlainDayTime.compare(this.start, t) <= 0 &&
        PlainDayTime.compare(this.end, t) > 0
      );
    }
    // overlaps(other: PlainDayTimeInterval): boolean {
    //   return (
    //     this.contains(other.start) ||
    //     this.contains(other.end) ||
    //     other.contains(this.start) ||
    //     other.contains(this.end)
    //   );
    // }
    wrapsWeek(): boolean {
      return this.start.day > this.end.day;
    }
    static FullWeek = new PlainDayTimeInterval(
      PlainDayTime.StartOfWeek,
      PlainDayTime.EndOfWeek
    );
  }
</script>

<script lang="ts">
  export let values: string[] = [];
  const days: PlainDayTimeInterval[] = [
    DayOfWeek.Sunday,
    DayOfWeek.Monday,
    DayOfWeek.Tuesday,
    DayOfWeek.Wednesday,
    DayOfWeek.Thursday,
    DayOfWeek.Friday,
    DayOfWeek.Saturday,
  ].map(
    (day) =>
      new PlainDayTimeInterval(
        PlainDayTime.from(`${day}T00:00:00`),
        PlainDayTime.from(`${day}T00:00:00`).addDays(1)
      )
  );
  const dayw = 24;
  var labels: Record<string, string> = {
    "7": "S",
    "1": "M",
    "2": "T",
    "3": "W",
    "4": "T",
    "5": "F",
    "6": "S",
  };
  $: intervals = values.map(PlainDayTimeInterval.from);

  $: console.log(
    "values=",
    values,
    intervals,
    intervals.map((i) => i.toString())
    //days.map((d) => d.toString())
  );
</script>

<figure class="chart">
  <svg width="100%" viewBox="0 0 {dayw * 7 + 2} 16" class="daysofweek">
    {#each days as day, i}
      <text
        font-size="12"
        font-weight="bold"
        text-anchor="middle"
        x={i * dayw + 1 + dayw / 2}
        y="10"
        class="label">{labels[day.start.day]}</text
      >
    {/each}
    {#each days as day, i}
      <line stroke-width="0.5" y1="0" y2="100%" x1="1" x2="1" stroke="#ccc" />
      <line
        stroke-width="0.5"
        y1="0"
        y2="100%"
        x1={(i + 1) * dayw + 1}
        x2={(i + 1) * dayw + 1}
        stroke="#ccc"
      />
    {/each}
  </svg>
  <svg width="100%" viewBox="0 0 {dayw * 7 + 2} 16" class="daysofweek">
    {#each days as day, i}
      <rect
        x={i * dayw + 1}
        y="0"
        width={dayw}
        height="100%"
        fill="#fcfcfc"
        data-datetime={day.toString()}
      />
      {#each intervals as interval}
        {#if day.contains(interval)}
          <!-- fully contains-->
          <rect
            x={i * dayw +
              1 +
              interval.start.time.since(midnight).total("hours")}
            y="0"
            width={interval.end.time.since(interval.start.time).total("hours")}
            height="100%"
            fill="#222"
            data-datetime={interval.toString()}
          />
        {:else if day.contains(interval.start)}
          <!-- partial to end of day -->
          <rect
            x={i * dayw +
              1 +
              interval.start.time.since(midnight).total("hours")}
            y="0"
            width={24 - interval.start.time.since(midnight).total("hours")}
            height="100%"
            fill="#222"
            data-datetime={interval.toString()}
          />
        {:else if day.contains(interval.end)}
          <!-- start of day to partial-->
          <rect
            x={i * dayw + 1}
            y="0"
            width={interval.end.time.since(midnight).total("hours")}
            height="100%"
            fill="#222"
            data-datetime={interval.toString()}
          />
        {:else if interval.contains(day)}
          <!-- full day-->
          <rect
            x={i * dayw + 1}
            y="0"
            width={dayw}
            height="100%"
            fill="#222"
            data-datetime={interval.toString()}
          />
        {/if}
      {/each}
    {/each}
    {#each days as day, i}
      <line stroke-width="0.5" y1="0" y2="100%" x1="1" x2="1" stroke="#ccc" />
      <line
        stroke-width="0.5"
        y1="0"
        y2="100%"
        x1={(i + 1) * dayw + 1}
        x2={(i + 1) * dayw + 1}
        stroke="#ccc"
      />
    {/each}
  </svg>
</figure>
