<script>
  import { sortBy, textAsc } from "../util/sort";
  import { utcToZonedTime, format } from "date-fns-tz";
  import EditPropertyPayments from "./EditPropertyPayments.svelte";
  import { prices, policies } from "../stores";
  export let property;
  export let data;

  $: items = processData(data);

  function label(freq, interval, timezone) {
    if (freq == "P1D")
      return format(
        utcToZonedTime(interval.split("/")[0], timezone),
        "EEE MMM d"
      );
    if (freq == "P1M")
      return format(
        utcToZonedTime(interval.split("/")[0], timezone),
        "MMM yyyy"
      );
    if (freq == "P1W")
      return `${format(
        utcToZonedTime(interval.split("/")[0], timezone),
        "MMM d"
      )}—${format(utcToZonedTime(interval.split("/")[1], timezone), "MMM d")}`;
    return (
      "since " +
      format(utcToZonedTime(interval.split("/")[0], timezone), "MMM d")
    );
  }

  function processData(data) {
    if (!data) return {};

    return data.metrics.items.reduce((intervals, item) => {
      if (item.group != "property") return intervals;
      //if(item.payments != "total") return map;

      for (const [interval, cents] of Object.entries(item.values)) {
        if (interval.split("/")[0] < item.interval.split("/")[0]) continue; // don't have full data
        if (!intervals[interval]) intervals[interval] = {};
        intervals[interval].interval = interval;
        intervals[interval].datetimes = item.datetimes;
        intervals[interval][item.payments] = cents;
        intervals[interval].timezone = item.timezone;
        intervals[interval].label = label(
          item.datetimes,
          interval,
          item.timezone
        );
      }

      return intervals;
    }, {});
  }
  var currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 0,
  }).format;
</script>

<EditPropertyPayments {property} />
<section>
  <header><h1>Prices In Effect</h1></header>
  <figure>
    {#if $policies}
      <ul>
        {#each $policies.filter((p) => p.pricing) as item}
          <li>
            <h1>{item.title}:</h1>
            <p>{item.pricing.description}</p>
          </li>
        {/each}
      </ul>
    {/if}
    <!-- {#if $prices}
        <ul>
        {#each $prices as item}
        <li>
            <h1>{item.subject.title}:</h1>
        {item.description}
        </li>
        {/each}
        </ul>
        {/if} -->
  </figure>
</section>
<!-- <section>
    <header><h1>Payment Metrics?</h1></header>
    <figure class="metrics">
        {#each sortBy(Object.values(items).filter(i => i.datetimes == "P1M"), "interval") as data}
        <time>
            {data.label}
            <data value="">{currency(data.total / 100) || ""}</data>
        </time>         
        {/each}
    </figure>
</section> -->
<table>
  <thead>
    <tr>
      <th scope="column row">Interval</th>
      <th scope="column">$total</th>
      <th scope="column">$property</th>
      <th scope="column">$stripe</th>
      <th scope="column">$service</th>
    </tr>
  </thead>
  <tbody>
    {#each sortBy( Object.values(items).filter((i) => i.datetimes == "P1M"), "interval", undefined, true ) as data}
      <tr>
        <th scope="row">{data.label}</th>
        <td>{currency(data.total / 100) || ""}</td>
        <td>{currency(data.net / 100) || ""}</td>
        <td>{currency(data.gateway / 100) || ""}</td>
        <td>{currency(data.service / 100) || ""}</td>
        <td>{currency(data.tax / 100) || ""}</td>
      </tr>
    {/each}
    {#each sortBy( Object.values(items).filter((i) => i.datetimes == "P1W"), "interval" ) as data}
      <tr>
        <th scope="row">{data.label}</th>
        <td>{currency(data.total / 100) || ""}</td>
        <td>{currency(data.net / 100) || ""}</td>
        <td>{currency(data.gateway / 100) || ""}</td>
        <td>{currency(data.service / 100) || ""}</td>
        <td>{currency(data.tax / 100) || ""}</td>
      </tr>
    {/each}
    {#each sortBy( Object.values(items).filter((i) => i.datetimes == "P1D"), "interval" ) as data}
      <tr>
        <th scope="row">{data.label}</th>
        <td>{currency(data.total / 100) || ""}</td>
        <td>{currency(data.net / 100) || ""}</td>
        <td>{currency(data.gateway / 100) || ""}</td>
        <td>{currency(data.service / 100) || ""}</td>
        <td>{currency(data.tax / 100) || ""}</td>
      </tr>
    {/each}
    <!-- {#each Object.entries(items).sort(([a], [b]) => textAsc(a, b)) as [ interval, data ]}
            <tr>
                <th scope="row">{data.label}</th>
                <td>{currency(data.total / 100) || ""}</td>
                <td>{currency(data.net / 100) || ""}</td>
                <td>{currency(data.gateway / 100) || ""}</td>
                <td>{currency(data.service / 100) || ""}</td>
            </tr>
            {/each} -->
  </tbody>
  <tfoot />
</table>
