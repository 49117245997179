import { token } from "$stores";
import { Area, ExpandedProvider, client } from "@communityboss/api";
// import { styles } from "@communityboss/carto";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import * as turf from "@turf/turf";
import type { SearchAddressResponse } from "azure-maps-rest";
import debug from "debug";
import mapboxgl from "mapbox-gl";
import { get, writable } from "svelte/store";
import { z } from "zod";
import { utils, type BBox } from "../utils/map";
import { azure } from "./azure";

mapboxgl.accessToken =
  "pk.eyJ1IjoicGFya2luZ2Jvc3MiLCJhIjoiY2swY3VheHQyMDE1ejNtbjV4M3RoeTQ5cyJ9.toumXl_aMY5GgH45lZyiuA"; //PUBLIC_MAPBOX_ACCESS_TOKEN;

const log = debug("boss:providers:stores:index");

export const providers = writable<ExpandedProvider[]>([]);

export const fetching = writable<boolean>(false);

export const actions = {
  provider: {
    async fetch() {
      fetching.set(true);
      const resp = await client(get(token)).providers.all();
      log("fetched providers:", resp);
      if (resp.success === false) {
        // TODO: add toast errors
        alert(resp.error);
        fetching.set(false);
        return;
      }
      providers.set(
        resp.data.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        )
      );
      fetching.set(false);
    },

    async create(name: string) {
      const resp = await client(get(token)).providers.create({ name });
      // TODO: show error if fail
      if (resp.success === false) return alert(resp.error);
      log("create provider response:", resp);
      actions.provider.fetch();
    },

    async remove(id: string) {
      const yes = confirm("Are you sure you want to delete this provider?");
      if (!yes) return;
      const resp = await client(get(token)).providers.remove(id);
      if (resp.success === false) return alert(resp.error);
      actions.provider.fetch();
    },
  },
  service: {
    async create({
      provider_id,
      name,
      category,
    }: {
      provider_id: string;
      name: string;
      category: string;
    }) {
      const resp = await client(get(token)).services.create({
        name,
        category,
        provider_id,
      });
      log("create service response:", resp);
      if (resp.success === false) return alert(resp.error);
      log(resp.data);
    },
  },
  // areas: {
  //   async create(props: any) {
  //     const resp = await client(get(token)).providers.areas.create(props);
  //     log("create service response:", resp);
  //     if (resp.success === false) return alert(resp.error);
  //     log(resp.data);
  //   },
  // },
};

export interface MapStore {
  rendered: boolean;
  instance?: mapboxgl.Map;
  result?: MapboxGeocoder.Result;
  boundaries: SearchAddressResponse["results"];
  selected_boundary?: string;
  area_type: "radius" | "boundary" | "polygon";
  place_type?: "zip" | "city" | "state" | "country" | "address";
  radius_km?: number;
  center?: [number, number];
  bbox?: BBox;
  geofence?: [number, number][];
  features?: GeoJSON.FeatureCollection;
}

const RADIUS_SOURCE_NAME: MapStore["area_type"] = "radius";
const BOUNDARY_SOURCE_NAME: MapStore["area_type"] = "boundary";
const POLYGON_SOURCE_NAME: MapStore["area_type"] = "polygon";
const POLYGON_BUTTON_SELECTOR = ".mapbox-gl-draw_polygon";

const Draw = new MapboxDraw({
  controls: {
    line_string: false,
    point: false,
    combine_features: false,
    uncombine_features: false,
  },
});

const GeocoderResult: z.ZodType<Omit<MapboxGeocoder.Result, "address">> =
  z.object({
    bbox: z.tuple([z.number(), z.number(), z.number(), z.number()]).optional(),
    center: z.tuple([z.number(), z.number()]),
    context: z.array(z.object({ id: z.string(), text: z.string() })),
    geometry: z.object({
      type: z.literal("Point"),
      coordinates: z.array(z.number()),
    }),
    id: z.string(),
    place_name: z.string(),
    place_type: z.array(z.string()),
    properties: z.object({}),
    relevance: z.number(),
    text: z.string(),
    type: z.literal("Feature"),
  });

export function create_map_store(area?: Area) {
  const log = debug(`boss:components:providers:stores:area[${area?.id}]`);

  function center_from_area(area?: Area): [number, number] {
    const fallback: [number, number] = [-80.19362, 25.774173];

    if (!area) return fallback;
    // Use lat/long
    if (area?.latitude && area?.longitude)
      return [area?.latitude, area?.longitude];

    // Use geojson center point
    if (
      area?.geojson?.geometry?.type === "Point" &&
      area?.geojson?.geometry?.coordinates
    )
      return area.geojson.geometry.coordinates;

    // Get center point of polygon geometry
    if (
      area?.geojson?.geometry?.type === "Polygon" &&
      area?.geojson?.geometry?.coordinates?.length
    ) {
      const features = turf.points(area.geojson.geometry.coordinates[0]);
      log("features:", features);
      const center = turf.center(features);
      log("center:", center);
      const resp = z
        .tuple([z.number(), z.number()])
        .safeParse(center?.geometry?.coordinates);
      if (!resp.success) return fallback;
      log("polygon center:", resp.data);
      return resp.data as [number, number];
    }

    // Fallback to default
    return fallback;
  }

  const store = writable<MapStore>({
    rendered: false,
    boundaries: [],
    selected_boundary: "",
    area_type: area?.radius
      ? RADIUS_SOURCE_NAME
      : area?.geojson
      ? POLYGON_SOURCE_NAME
      : BOUNDARY_SOURCE_NAME,
    radius_km: area?.radius && area?.radius / 1000,
    center: center_from_area(area),
  });

  const { subscribe, set, update } = store;

  const map = {
    subscribe,
    set,
    update,
    mount(container: HTMLElement) {
      log("creating map...");

      const state = get(store);

      const instance = new mapboxgl.Map({
        container,
        // style: styles.satellite,
        center: state.center,
        zoom: 17,
      } as mapboxgl.MapboxOptions);

      map.update((s) => ({ ...s, instance }));

      // Map is rendered and ready
      instance.on("load", () => {
        log("map loaded");
        map.update((s) => ({ ...s, rendered: true }));
        map.update_features({
          type: "FeatureCollection",
          features: area?.geojson ? [{ properties: {}, ...area.geojson }] : [],
        });
        map.change_area_type(state.area_type, false);
        map.fit_to_features();
      });

      // Draw events
      instance.on("draw.create", (e) => {
        log("created geofence:", e.features);
        map.update_features(e?.features);
        map._disable_polygon_button();
      });
      instance.on("draw.update", (data) => {
        log("drawing updated:", data);
        map.update_features({
          type: "FeatureCollection",
          features: data?.features ?? [],
        });
      });
      instance.on("draw.delete", (e) => {
        log("removed geofence:", e.features);
        map.update((s) => ({ ...s, geofence: [] }));
        map._enable_polygon_button();
        map.update_features(null);
      });

      // Add location geocoder UI
      const geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl,
        zoom: 12,
        flyTo: { animate: false },
      });
      geocoder.on("result", ({ result }: { result: MapboxGeocoder.Result }) =>
        map._handle_geocoder_result(result)
      );
      instance.addControl(geocoder);

      return instance;
    },
    unmount() {
      const { instance } = get(store);
      log("unmounting map instance...");
      instance?.remove();
    },
    change_area_type(area_type: MapStore["area_type"], clear = true) {
      const { instance, radius_km } = get(store);

      if (!instance) throw new Error("no map instance to change area type on");

      if (clear) {
        log('clearing map state for area type "%s"', area_type);
        map.update((s) => ({
          ...s,
          area_type,
          geofence: [],
          features: null,
          // radius_km: undefined,
        }));

        map.clear_geometry();
      }

      /**
       * If switching to a radius view, we want to re-render the radius circle
       * by default.
       */
      if (area_type === RADIUS_SOURCE_NAME && radius_km) {
        map.render_radius_circle(true);
      }

      /**
       * If moving off the boundary view, we want to clear the selected boundary
       * so it isn't populate when moving back. This means the user has to reselect
       * so we can re-render the boundary.
       */
      if (area_type !== BOUNDARY_SOURCE_NAME) {
        map.update((s) => ({ ...s, selected_boundary: "" }));
      }

      /**
       * If switching to a polygon view, we want draw the polygon so it is editable and we
       * need to to re-render the polygon drawing tool. If not on the polygon view, remove it.
       */
      if (area_type === POLYGON_SOURCE_NAME) {
        const features = get(store)?.features;
        log("change area type to polygon", { features });
        instance.addControl(Draw, "top-left");
        Draw.set(features);
      } else if (instance.hasControl(Draw)) {
        instance.removeControl(Draw);
      }
    },
    clear_geometry() {
      log("clearing geometry...");

      const { instance } = get(store);

      if (!instance) throw new Error("no map instance to clear geometry from");

      if (instance.getSource(RADIUS_SOURCE_NAME)) {
        log("removing radius source");
        instance?.removeLayer(RADIUS_SOURCE_NAME);
        instance?.removeSource(RADIUS_SOURCE_NAME);
      }
      if (instance.getSource(BOUNDARY_SOURCE_NAME)) {
        log("removing boundary source");
        instance?.removeLayer(BOUNDARY_SOURCE_NAME);
        instance?.removeSource(BOUNDARY_SOURCE_NAME);
      }
      // if (area_type !== "radius" && instance?.getSource(RADIUS_SOURCE_NAME)) {
      // 	map.update((s) => ({ ...s, radius_km: undefined }));
      // 	map._remove_radius_circle();
      // }
      // if (
      // 	area_type !== "boundary" &&
      // 	instance?.getSource(BOUNDARY_SOURCE_NAME)
      // ) {
      // 	map.update((s) => ({ ...s, boundaries: [], selected_boundary: "" }));
      // 	map._remove_boundary();
      // }
      // if (area_type !== "polygon" && map_instance?.getSource(POLYGON_SOURCE_NAME)) {
      // 	polygons = [];
      // 	map_instance?.removeLayer(POLYGON_SOURCE_NAME);
      // 	map_instance?.removeSource(POLYGON_SOURCE_NAME);
      // }
    },
    set bbox(bbox: BBox) {
      log("setting bbox:", bbox);

      const { instance } = get(store);

      if (!instance) throw new Error("no map instance to set bbox on");

      map.update((s) => ({ ...s, bbox }));

      utils.bbox.set(instance, bbox);
    },
    update_features(features?: GeoJSON.FeatureCollection | null) {
      log("update features:", features);
      // geofence:
      //   area?.geojson?.geometry?.type === "Polygon"
      //     ? area?.geojson?.geometry?.coordinates?.[0]
      //     : undefined,
      // geometry:
      //   area?.geojson?.geometry?.type === "Polygon"
      //     ? (area.geojson?.geometry as GeoJSON.GeoJSON)
      //     : undefined,
      const geofence =
        features?.features?.[0]?.geometry?.coordinates?.[0] ?? [];

      log("updating features:", { features, geofence });

      map.update((s) => ({ ...s, features, geofence }));
    },
    // set geometry(geometry: GeoJSON.GeoJSON | undefined) {
    //   map.update((s) => ({ ...s, geometry }));
    // },
    set center(center: [number, number]) {
      log("setting center:", center);
      const { instance } = get(store);
      map.update((s) => ({ ...s, center }));
      instance?.setCenter(center);
    },
    async _handle_geocoder_result(result: MapboxGeocoder.Result) {
      log("geocoder result:", result);

      GeocoderResult.parse(result);

      const { area_type } = get(store);

      const raw_type = result.place_type[0];
      let place_type: MapStore["place_type"];
      switch (raw_type) {
        case "address":
          place_type = "address";
          break;
        case "postcode":
          place_type = "zip";
          break;
        case "place":
          place_type = "city";
          break;
        case "region":
          place_type = "state";
          break;
        case "country":
          place_type = "country";
          break;
        default:
          // place_type = raw_type
          break;
      }

      map.update((s) => ({ ...s, place_type }));

      map.clear_geometry();
      map.bbox = result.bbox;
      map.center = result.center as [number, number];

      const boundaries = await azure.boundaries.fetch(result.place_name);
      map.update((s) => ({ ...s, boundaries }));

      if (area_type === RADIUS_SOURCE_NAME) {
        map.render_radius_circle(true);
      }
    },
    fit_to_features() {
      const { instance, features } = get(store);

      if (!instance) throw new Error("no map instance to fit to features");
      if (!features) throw new Error("no features to fit to");

      map.bbox = utils.bbox.set_from_geometry(instance, features);
    },
    change_radius_km(radius_km: number) {
      log("change radius:", radius_km, "km");
      map.update((s) => ({ ...s, radius_km }));
      map.render_radius_circle();
    },
    render_radius_circle(update_bbox = false) {
      log("render radius circle");

      const { instance, center, radius_km } = get(store);

      if (!instance) throw new Error("no map instance to create circle on");
      if (!center) throw new Error("no center to set for radius circle");
      if (!radius_km) throw new Error("no radius_km to use for radius circle");

      const geojson = utils.radius_cicle.render({
        instance,
        center,
        radius_km,
        name: RADIUS_SOURCE_NAME,
      });

      map.update_features(geojson.data);

      if (update_bbox && geojson.data)
        map.bbox = utils.bbox.set_from_geometry(instance, geojson.data);
    },
    async change_boundary(boundary?: string) {
      log("boundary ID:", boundary);

      if (!boundary)
        throw new Error("cannot change boundary because it is missing");

      map.update((s) => ({ ...s, selected_boundary: boundary }));

      const { instance } = get(store);

      if (!instance) throw new Error("no map instance to change boundary on");

      const features = await azure.boundaries.get(boundary);

      map.clear_geometry();

      map.update_features(
        utils.boundary.render({
          instance,
          features,
          name: BOUNDARY_SOURCE_NAME,
        })
      );

      if (features?.bbox) {
        const bbox = features.bbox as BBox;
        log("update bbox:", bbox);
        map.bbox = bbox;
      }
    },
    _disable_polygon_button() {
      const polygon_btn = document.querySelector(
        POLYGON_BUTTON_SELECTOR
      ) as HTMLButtonElement;
      if (polygon_btn) polygon_btn.disabled = true;
    },
    _enable_polygon_button() {
      const polygon_btn = document.querySelector(
        POLYGON_BUTTON_SELECTOR
      ) as HTMLButtonElement;
      if (polygon_btn) polygon_btn.disabled = false;
    },
  };

  return map;
}
