<script>
  import * as d3 from "d3";

  export let value;
  export let max = 1;
  export let lineWidth = 4;

  let width;
  let height;

  $: percent = value / max;
  $: startAngle = 0;
  $: endAngle = 180;
  $: halfWidth = width / 2;
  $: halfHeight = height / 2;

  $: backgroundArc = d3
    .arc()
    .innerRadius(halfWidth - lineWidth)
    .outerRadius(halfWidth - lineWidth * 2)
    .startAngle(startAngle)
    .endAngle(degreesToRadians(endAngle));
  $: arc = d3
    .arc()
    .innerRadius(halfWidth - lineWidth)
    .outerRadius(halfWidth - lineWidth * 2)
    .startAngle(startAngle)
    .endAngle(degreesToRadians(endAngle * percent));

  const degreesToRadians = (degrees) => {
    const radians = degrees * (Math.PI / 180);
    return radians;
  };

  const normalizedValue = (value) => {
    if (value > 99999) {
      return "99k+";
    } else if (value < 999) {
      return value;
    } else {
      const normalized = `${Math.round(value / 1000)}k`;
      return normalized;
    }
  };
</script>

<div class="odometer" bind:clientWidth={width} bind:clientHeight={height}>
  <svg width="100%" height="100%">
    <path
      d={backgroundArc()}
      transform={`translate(${halfWidth}, ${halfHeight}) rotate(-90)`}
      class="arc-track"
    />
    <path
      d={arc()}
      transform={`translate(${halfWidth}, ${halfHeight}) rotate(-90)`}
      class="arc"
    />
    <text
      x="50%"
      y="50%"
      text-anchor="middle"
      dominant-baseline="middle"
      class="value"
    >
      {normalizedValue(value)}
    </text>
  </svg>
</div>
