<script>
  import { onMount } from "svelte";
  import { get, capitalize } from "lodash-es";
  import {
    fetchResetTenantCode,
    fetchResetUnit,
    fetchTransferTenant,
    fetchRemoveUnit,
    fetchResetTenantsCodes,
  } from "./api";
  import { units } from "./stores";

  export let tenant = null;

  let element = null;
  onMount(() => {
    element && element.scrollIntoView();
  });

  let submittable = false;
  let submitting = false;

  let action = false;

  let update = {
    version: fetchResetUnit,
    code: fetchResetTenantsCodes,
    transfer: fetchTransferTenant,
    remove: fetchRemoveUnit,
  };

  async function submit(e) {
    e.preventDefault();

    if (!tenant || submitting || !submittable) return;

    if (!window.confirm(`This is permanent and cannot be undone. Continue?`))
      return;

    const form = e.target;
    const formData = new FormData(form);

    submitting = true;

    const res = await update[action](tenant, formData);

    //console.log("res=", res);
    submitting = false;

    if (res && res.message) return alert(res.message);

    submittable = false;
    tenant = null;
  }

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }
</script>

{#if tenant}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    bind:this={element}
    on:reset={(e) => (tenant = null)}
  >
    <header>
      <h1>Edit {capitalize(tenant.format)}</h1>
    </header>
    <fieldset>
      <ul>
        <li>
          <label for="update-tenant-unit">{capitalize(tenant.format)}</label>
          <data
            class="{tenant.type} id {[tenant.format, tenant.size]
              .filter((i) => !!i)
              .join(' ')}"
            value={tenant.id}>{tenant.display}</data
          >
        </li>
        <li>
          <label for="update-tenant-action">Action</label>
          <!-- <ul on:change={e => {if(e.target.checked) action = e.target.value;}}>
                    <li><input id="update-tenant-code" type="radio" name="action" value="code" required><label for="update-tenant-code">new passcode</label></li>
                    <li><input id="update-tenant-version" type="radio" name="action" value="version" required><label for="update-tenant-version">turn over {tenant.format}</label></li>
                    <li><input id="update-tenant-transfer" type="radio" name="action" value="transfer" required><label for="update-tenant-transfer">transfer {tenant.format}</label></li>
                    <li><input id="update-unit-remove" type="radio" name="action" value="remove" required><label for="update-unit-remove">remove {tenant.format}</label></li>
                </ul> -->
          <select
            id="update-tenant-type"
            required
            on:blur={(e) => (action = e.target.value)}
            on:change={(e) => (action = e.target.value)}
          >
            <option value="">--------</option>
            <option value="code">change passcode</option>
            <option value="version">turn over {tenant.format}</option>
            <option value="transfer">transfer {tenant.format}</option>
            <option value="remove">remove {tenant.format}</option>
          </select>
        </li>
        {#if "transfer" === action}
          <li>
            <label for="update-tenant-transfer-to">Transfer to</label>
            <select id="update-tenant-transfer-to" name="transfer">
              {#each $units as unit}
                <option value={unit.id}>{unit.display}</option>
              {/each}
            </select>
          </li>
        {/if}
        {#if "code" === action}
          <li>
            <label for="update-tenant-passcode-valid">Effective</label>
            <input
              id="update-tenant-passcode-valid"
              type="datetime-local"
              name="valid"
              placeholder="now"
            />
          </li>
          <li>
            <label for="update-tenant-passcode">Passcode</label>
            <input
              id="update-tenant-passcode"
              type="number"
              name={tenant.id}
              placeholder="autogenerate"
              min="1000"
              max="999999"
            />
          </li>
        {/if}
        {#if "version" === action}
          <li>
            <label for="update-tenant-passcode">Passcode</label>
            <input
              id="update-tenant-passcode"
              type="number"
              name="password"
              placeholder="autogenerate"
              min="1000"
              max="999999"
            />
          </li>
        {/if}
        {#if action && "code" !== action}
          <li>
            <label for="reset-tenant-notes">Notes</label>
            <textarea
              id="reset-tenant-notes"
              name="notes"
              placeholder="required"
              required
            />
          </li>
        {/if}
      </ul>
    </fieldset>
    {#if "reset" === action}
      <p>
        Important: turning over will archive the current history and create a
        clean {tenant.format} record. This is permanent and cannot be undone.
      </p>
    {/if}
    {#if "transfer" === action}
      <p>
        Important: turning over will archive the current history and create a
        clean {tenant.format} record. This is permanent and cannot be undone.
      </p>
    {/if}
    {#if "remove" === action}
      <p>
        Important: removing will alter the history in potentially unexpected
        ways. This is permanent and cannot be undone.
      </p>
    {/if}
    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
      <button type="reset" disabled={submitting}>Cancel</button>
    </footer>
  </form>
{/if}
