<script>
  export let text;
  export let x1;
  export let x2;
  export let textYOffset = 0;
  export let margin;
</script>

<g class="chart-line" transform={`translate(0, ${margin.top})`}>
  <text
    class="value"
    x={0}
    y={textYOffset}
    text-anchor="start"
    alignment-baseline="middle">{text}</text
  >
  <line class="line" {x1} y1={0} {x2} y2={0} />
</g>
