<script>
  import { param } from "../util/params";
  import { debounce, filter, find, pick } from "lodash-es";
  import { mapbox, updateStyle } from "./maps/mapbox";
  import { centerOfMass } from "@turf/turf";
  import { onMount } from "svelte";
  import PropertySearch from "./PropertySearch.svelte";
  import Dropdown from "../components/maps/Dropdown.svelte";
  import Modal from "./Modal.svelte";

  import { style as mapstyle, styles } from "./maps/stores";
  import router from "page";
  import { query } from "../util/router";
  import { saveBlobToFile } from "./maps/saveFile";

  export let properties;
  let cssLoaded;
  let styleLoaded;
  let map;
  let container;
  let nw = "";
  let se = "";
  let propertyId = "";
  let results = [];
  let saveStyles = [];
  let saveProgress = 0;
  let zoom = 0;

  const bboxParam = param("bbox");
  const bearingParam = param("bearing");
  $: bbox = $bboxParam;

  const paramStyle = param("style");
  const defaultStyle = $paramStyle || "mapboxsatellite-v11";
  const style = mapstyle(defaultStyle);
  const styleOptions = Object.entries(
    pick($styles, ["mapboxsatellite-v11", "mapboxstreets-v11", "esrisatellite"])
  ).map((entry) => ({ name: entry[1].name, value: entry[0] }));

  function route(key, value) {
    let qs = new URLSearchParams(window.location.search);
    if (value) qs.set(key, value);
    else qs.delete(key);
    router(window.location.pathname + "?" + qs.toString());
  }

  $: if (cssLoaded) {
    if (!map) initMap();
  }

  $: if (container && saveProgress > 0) {
    // Prevent changing map
    container.style.pointerEvents = "none";
  }

  $: if (map) {
    map.on("style.load", function () {
      styleLoaded = true;

      if (!map.getSource("bbox-text")) addDataLayers();
      displayBounds(map);
    });

    map.on(
      "moveend",
      debounce(function () {
        displayBounds(map);
      }, 150)
    );

    map.on(
      "rotateend",
      debounce(function () {
        route("bearing", map.getBearing());
      }, 150)
    );

    map.on(
      "zoomend",
      debounce(function () {
        zoom = map.getZoom();
      }, 150)
    );

    map.once("idle", function () {
      if (saveProgress > 0) {
        // Save as image for all styles
        const canvas = map.getCanvas();

        canvas.toBlob(function (blob) {
          let fileName = `${saveStyles[saveProgress - 1].value}`;
          if (propertyId) {
            fileName = propertyId + "-" + fileName;
          }

          saveBlobToFile(blob, fileName);

          if (saveProgress < styleOptions.length) {
            handleExportAll();
          } else {
            handleStopExport();
          }
        });
      }
    });
  }

  $: if (map && $style) {
    console.log("style", $style);
    updateStyle(map, $style);
  }

  function addDataLayers() {
    // const layers = map.getStyle().layers;

    // // Find the index of the first symbol layer in the map style
    // let firstSymbolId;
    // for (let i = 0; i < layers.length; i++) {
    //   if (layers[i].type === "symbol") {
    //     firstSymbolId = layers[i].id;
    //     break;
    //   }
    // }

    map.addSource("bbox-text", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: [],
      },
    });

    // // Add satellite
    // map.addSource("mapbox-satellite-tiles", {
    //   "type": "raster",
    //   "tiles": [
    //     "https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}@2x.pngraw?access_token=pk.eyJ1IjoicGFya2luZ2Jvc3MiLCJhIjoiY2swY3VheHQyMDE1ejNtbjV4M3RoeTQ5cyJ9.toumXl_aMY5GgH45lZyiuA"
    //   ]
    // });

    // map.addLayer({
    //   name: "Mapbox Satellite Tiles",
    //   layer: {
    //       id: "mapbox-satellite-tiles",
    //       type: "raster",
    //       source: "mapbox-satellite-tiles",
    //   }
    // })

    // Add bbox text layer
    map.addLayer({
      id: "bbox-text",
      type: "symbol",
      source: "bbox-text",
      minzoom: 10,
      layout: {
        "text-ignore-placement": true,
        "text-allow-overlap": true,
        "text-field": ["get", "title"],
        "text-size": 20,
        "text-anchor": "top-left",
        "text-max-width": 100,
      },
      paint: {
        "text-color": "#000",
        "text-halo-color": "#fff",
        "text-halo-width": 1,
      },
    });
  }

  const onSearch = (searchResults) => {
    results = searchResults;
  };

  const onSelect = (selectedId) => {
    const selected = find(properties, { id: selectedId });
    const { address } = selected;
    propertyId = selectedId;

    const point = centerOfMass({
      type: address.area.type,
      coordinates: address.area.coordinates,
    });

    map.jumpTo({ center: point.geometry.coordinates, zoom: 16 });

    bboxFromHash(map.getBounds().toArray().flat());
  };

  function handleStyleChange(style, name) {
    query(name, style);
    styleLoaded = false;
  }

  function handleExportAll() {
    if (!map) return;

    if (saveProgress === 0) {
      // Set order of styles such that style is always changed to trigger idle event
      // and to return to original style before saving
      const currOption = find(styleOptions, {
        value: $paramStyle || defaultStyle,
      });
      saveStyles = [
        ...filter(styleOptions, (option) => option.value !== currOption.value),
        currOption,
      ];
    }

    handleStyleChange(saveStyles[saveProgress].value, "style");
    saveProgress++;

    handleCopyCoordinates();
  }

  function handleStopExport() {
    saveStyles = [];
    saveProgress = 0;
    if (container) container.style.pointerEvents = "initial";
  }

  function handleCopyCoordinates() {
    let str = `@bbox=[${nw},${se}]`;
    if (propertyId) str = `#ref:boss:property=${propertyId} ` + str;
    navigator.clipboard.writeText(str);
  }

  function pointFromHash() {
    if (!bbox) return;
    let parts = bbox.split(",");
    if (parts.length !== 2) return;
    return [parseFloat(parts[0]), parseFloat(parts[1])];
  }

  function bboxFromHash(value) {
    if (!!value && value.length === 4) {
      route("bbox", value.join(","));
      return;
    }
    if (!bbox) return;
    let parts = bbox.split(/[, ]+/g);
    if (parts.length !== 4) return;
    return parts.map(parseFloat);
    //return [ parts[0].split(",").map(parseFloat), parseFloat(parts[1]) ];
  }

  function updateCardinalBounds(bounds) {
    const corners = bounds.toArray().flat();
    nw = `${corners[0].toFixed(6)},${corners[3].toFixed(6)}`;
    se = `${corners[2].toFixed(6)},${corners[1].toFixed(6)}`;
  }

  function displayBounds(map) {
    let bounds = map.getBounds();
    updateCardinalBounds(bounds);

    // let bearing = map.getBearing();
    // route("bearing", bearing);

    const corners = bounds.toArray().flat();
    bboxFromHash(corners);

    const bboxSrc = map.getSource("bbox-text");

    if (bboxSrc) {
      bboxSrc.setData({
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [corners[0], corners[3]],
            },
            properties: {
              title: `${nw}, ${se}`,
            },
          },
        ],
      });
    }
  }

  async function initMap() {
    map = await new mapbox.Map({
      container,
      attributionControl: false,
      style: defaultStyle,
      center: pointFromHash() || [-98.5795, 39.828175],
      zoom: 16, // starting zoom
      bounds: bboxFromHash(),
      dragRotate: false,
      pitchWithRotate: false,
    });

    let bounds = map.getBounds();
    zoom = map.getZoom();
    updateCardinalBounds(bounds);
  }

  onMount(() => {
    const styleUrl = `https://api.mapbox.com/mapbox-gl-js/v${mapbox.version}/mapbox-gl.css`;
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = styleUrl;
    link.onload = async () => (cssLoaded = true);

    document.head.appendChild(link);
  });
</script>

<main>
  {#if saveProgress > 0}
    <Modal
      content={`Saving ${saveProgress} of ${styleOptions.length}...`}
      onCloseModal={handleStopExport}
    />
  {/if}
  <figure id="map" class="properties-map map" bind:this={container}>
    {#if properties}
      <PropertySearch
        {properties}
        showList={true}
        bind:results
        on:select={(e) => onSelect(e.detail)}
      />
    {/if}
    <div class="properties-map-styles">
      <h2>Styles</h2>
      <div class="properties-map-style-dropdown">
        <Dropdown
          name="style"
          defaultValue={defaultStyle}
          options={styleOptions}
          onChange={handleStyleChange}
        />
      </div>
    </div>
    {#if map && nw && se}
      <div class="bounds-tool">
        <div>Zoom: {zoom.toFixed(2)}</div>
        <div>{nw}</div>
        <div>{se}</div>
        {#if propertyId}
          <div>{propertyId}</div>
        {/if}
        <div class="bbox-controls">
          <button class="bbox-save-button" on:click={handleExportAll}
            >Save All</button
          >
          <button class="bbox-copy-button" on:click={handleCopyCoordinates}
            >Copy</button
          >
        </div>
      </div>
    {/if}
  </figure>
</main>
