<script>
  import { get, filter, flatMap, clone } from "lodash-es";
  import { fetchDeleteProperty } from "./api";

  export let property = null;

  let submittable = false;
  let submitting = false;

  let editing = null;

  $: if (!!property && (!editing || !submittable)) editing = { ...property };

  function change(e) {
    // because changes recalculate the inputs, it can nuke out form
    ///console.log("changed form", e.target.form);
    submittable = !e.target.form || e.target.form.checkValidity();
    //console.log("changed", submittable, e.target);
  }

  async function submit(e) {
    e.preventDefault();

    if (!window.confirm("This is permanent and cannot be undone. Continue?"))
      return;

    if (!editing || submitting || !submittable) return;

    submitting = true;

    const form = e.target;

    await fetchDeleteProperty(editing);

    // clear the state
    submitting = submittable = false;

    window.location.href = "https://my.parkingboss.com/";

    editing = null;
  }
</script>

{#if editing}
  <form class="delete" on:submit={submit}>
    <header>
      <h1>Close Property</h1>
    </header>
    <fieldset>
      <ul>
        <li>
          <label for="property-close-confirm">Close</label>
          <select
            id="property-close-confirm"
            name="confirm"
            on:blur={(e) => (submittable = e.target.value === "true")}
            on:change={(e) => (submittable = e.target.value === "true")}
          >
            <option value="false">no</option>
            <option value="true">yes, permanently close</option>
          </select>
        </li>
      </ul>
    </fieldset>
    <p>
      <strong
        >Important: closing a property is permanent and cannot be undone.</strong
      >
    </p>
    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Closing" : "Close Property"}</button
      >
    </footer>
  </form>
{/if}
