<script>
  export let property;

  $: parking = property && property.amenities.items.includes("parking");
  $: amenities = property && property.amenities.items.includes("amenity");
</script>

{#if property}
  <form class="edit property">
    {#if parking}
      <fieldset>
        <p>Parking:</p>
        <ul>
          <li>
            <label>Manager</label>
            <a
              href="https://manager.communityboss.app/properties/{property.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
          <li>
            <label>Reports</label>
            <a
              href="https://reports.communityboss.app/{property.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
          <li>
            <label>Field Agent</label>
            <a
              href="https://fieldagent.parkingboss.app/{property.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
          <li>
            <label>Attendant</label>
            <a href={property.url} target="_blank" rel="noreferrer"
              >open in new tab</a
            >
          </li>
        </ul>
      </fieldset>
    {/if}

    {#if amenities}
      <fieldset>
        <p>Amenities:</p>
        <ul>
          <li>
            <label>Manager</label>
            <a
              href="https://amenities.communityboss.app/properties/{property.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
          <li>
            <label>Public</label>
            <a
              href="http://amenitypass.app/properties/{property.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
        </ul>
      </fieldset>
    {/if}

    <fieldset>
      <p>Community:</p>
      <ul>
        <li>
          <label>Directory</label>
          <a
            href="https://my.communityhq.app/?property={property.id}"
            target="_blank"
            rel="noreferrer">open in new tab</a
          >
        </li>
      </ul>
    </fieldset>
  </form>
{/if}
