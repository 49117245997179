<script>
  import { utcToZonedTime, format as formatTZ } from "date-fns-tz";
  import {
    format,
    areIntervalsOverlapping,
    eachDayOfInterval,
    startOfDay,
    endOfDay,
    addDays,
    min,
    isWithinInterval,
  } from "date-fns";
  import { percentOf, getPercentInterval } from "../util/interval";
  import { sortBy } from "lodash-es";
  import { timeLocal, toZoneISOString } from "../util/datetime";
  import { minuteNow, propertyNow } from "../stores";

  export let timezone = "America/New_York";
  export let x = "datetime";
  export let permits = [];
  let days = [];

  let values = null;
  let chart = null;
  let nowLocal = propertyNow;

  //$: if(timezone) nowLocal = timeLocal(minuteNow, timezone);

  $: values = [].concat(permitsToValues(permits));

  $: y = x === "datetime" ? "value" : "datetime";

  // $: if(minLocal && maxLocal) days = eachDayOfInterval({ start: minLocal, end: maxLocal }).filter(day => day < maxLocal).map(day => ({
  //     start:startOfDay(day),
  //     end:endOfDay(day)
  // }));
  //$: if(minLocal && maxLocal) chart.x = { start: minLocal, end: maxLocal, type: "datetime" };

  // $: if(values && values.length) chart = {
  //     datetime: {
  //         start: values.reduce((result, item) =>  null != result ? Math.min(result, item.datetime.start) : item.datetime.start, null),
  //         end: values.reduce((result, item) =>  null != result ? Math.max(result, item.datetime.end) : item.datetime.end, null),
  //         type: "datetime",
  //     },

  // };

  $: chart = permitsToChart(permits);

  $: if (chart) {
    let interval = Object.values(chart).find(
      (item) => item.type === "datetime"
    );
    console.log("days interval=", chart, interval);
    days = eachDayOfInterval(interval)
      .filter((day) => day < interval.end)
      .map((day) => ({
        start: startOfDay(day),
        end: endOfDay(day),
      }));
  }

  function permitsToChart(json) {
    if (!json) return null;

    const [minLocal, maxLocal] =
      json.permits.valid &&
      json.permits.valid.split("/").map((i) => utcToZonedTime(i, timezone));

    // there's only a datetime axis
    return {
      datetime: {
        start: minLocal,
        end: maxLocal,
        type: "datetime",
      },
    };
  }

  function permitsToValues(json) {
    if (!json || !json.permits) return null;

    // overall valid, use to fill in open ended
    const validMinMaxLocal =
      json.permits.valid &&
      json.permits.valid.split("/").map((i) => utcToZonedTime(i, timezone));

    return sortBy(
      Object.keys(json.permits.items).reduce((result, id) => {
        var item = json.items[id];
        if (!item) return result;

        const [minLocal, maxLocal] =
          item &&
          item.valid.interval
            .split("/")
            .map((interval, index) =>
              !interval
                ? validMinMaxLocal[index]
                : utcToZonedTime(interval, item.timezone)
            );

        // if(areIntervalsOverlapping({
        //     start: minLocal,
        //     end: maxLocal
        // }, {
        //     start: validMinMaxLocal[0],
        //     end: validMinMaxLocal[1]
        // }))
        result.push({
          type: "permit",
          value: item,
          datetime: {
            timezone: item.timezone,
            start: minLocal,
            end: maxLocal,
            type: "datetime",
          },
        });
        return result;
      }, []),
      ["datetime.start"]
    );
  }

  function toISOZoneString(local, tz) {
    console.log(local, tz);
    if (!tz) return local.toISOString();
    return formatTZ(local, "yyyy-MM-dd'T'HH:mm:ssxxx", { timeZone: tz });
  }

  function getInterval(item) {
    if (!!item && !!item.datetime) item = item.datetime;

    if (!!item) {
      return `${toZoneISOString(
        item.start,
        item.timezone || timezone
      )}/${toZoneISOString(item.end, item.timezone || timezone)}`;
    }

    return "";
  }

  function getTopLeftBottomRight(item) {
    console.log("getTLBR=", item, x, y);

    if (item.start && item.end)
      item = {
        datetime: item,
      };

    const tb = getPercentInterval(chart[y], item[y]) || {
      start: null,
      end: null,
    };
    const lr = getPercentInterval(chart[x], item[x]) || {
      start: null,
      end: null,
    };

    if (x === "value") return [tb.start, lr.end, tb.end, lr.start];

    return [tb.end, lr.end, tb.start, lr.start];
  }

  function getTopLeftBottomRightHeightWidth(item) {
    console.log("getTLBRHW=", item, x, y);

    if (item.getTime)
      item = {
        start: item,
        end: item,
      };

    if (item.start && item.end)
      item = {
        datetime: item,
      };

    const tb = getPercentInterval(chart[y], item[y]) || {
      start: null,
      end: null,
    };
    const lr = getPercentInterval(chart[x], item[x]) || {
      start: null,
      end: null,
    };

    const w = percentOf(chart[x], item[x]);
    const h = percentOf(chart[y], item[y]);

    if (x === "value") return [tb.start, lr.end, tb.end, lr.start, h, w];

    return [tb.end, lr.end, tb.start, lr.start, h, w];
  }

  function getStyle(item) {
    const tlbr = getTopLeftBottomRightHeightWidth(item);
    //.map(i => typeof(i) === "number" ? `${i}%` : "auto");
    const top = null != tlbr[0] ? tlbr[0] + "%" : "auto";
    const left = null != tlbr[3] ? tlbr[3] + "%" : "auto";
    const right = null != tlbr[1] ? tlbr[1] + "%" : "auto";
    const bottom = null != tlbr[2] ? tlbr[2] + "%" : "auto";
    const height = null != tlbr[4] ? tlbr[4] + "%" : "auto";
    const width = null != tlbr[5] ? tlbr[5] + "%" : "auto";
    return `top:${top};left:${left};bottom:${bottom};right:${right};width:${width};height:${height};`;
  }

  //console.log("parsed = ", parseDayTimeInterval("7T01:00/1T00:00:00").join(","));
</script>

{#if chart}
  <time datetime={getInterval(chart)} data-x={x}>
    <!-- {#each days as item }
        <time datetime="{getInterval(item, timezone)}" class="marker" style="{getStyle(item)}">{format(item.start, "E MMM d")}</time>
    {/each} -->
    {#if nowLocal && $nowLocal && isWithinInterval($nowLocal, chart.datetime)}
      <time
        datetime={toZoneISOString($nowLocal, timezone)}
        class="now"
        style={getStyle($nowLocal)}
        title={format($nowLocal, "h:mm a")}
      />
    {/if}
    {#each days as item}
      <time
        datetime={getInterval(item)}
        class="day"
        title={format(item.start, "E MMM d")}
      />
    {/each}
    <!-- <span class="permits"> -->
    {#each values || [] as item}
      <time
        datetime={getInterval(item)}
        class={item.type}
        style={getStyle(item)}
      />
    {/each}
    <!-- </span> -->
  </time>
{/if}
