<script>
  import { utcToZonedTime, format as formatTZ } from "date-fns-tz";
  import {
    format,
    isWithinInterval,
    isBefore,
    isAfter,
    areIntervalsOverlapping,
    eachDayOfInterval,
    startOfDay,
    endOfDay,
    addDays,
    min,
    addHours,
    differenceInHours,
  } from "date-fns";
  import { percentOf, getPercentInterval } from "../util/interval";
  import { timeLocal, toZoneISOString } from "../util/datetime";
  import { minuteNow } from "../stores";

  export let timezone = null;
  export let x = "datetime";
  export let capacity = null;
  export let usage = null;
  export let now = false;

  let values = null;
  let chart = null;
  let nowLocal = null;

  $: if (timezone && now) nowLocal = timeLocal(minuteNow, timezone);

  $: updateValues(usage, capacity);

  async function updateValues(usage, capacity) {
    values = [].concat(
      metricsToValues(await capacity),
      metricsToValues(await usage)
    );
    console.log("updated values=", values);
  }

  $: y = x === "datetime" ? "value" : "datetime";

  // $: if(minLocal && maxLocal) days = eachDayOfInterval({ start: minLocal, end: maxLocal }).filter(day => day < maxLocal).map(day => ({
  //     start:startOfDay(day),
  //     end:endOfDay(day)
  // }));
  //$: if(minLocal && maxLocal) chart.x = { start: minLocal, end: maxLocal, type: "datetime" };

  // projector
  $: if (values && values.length) {
    const newChart = {
      datetime: {
        start: values.reduce(
          (result, item) =>
            null != result
              ? Math.min(result, item.datetime.start)
              : item.datetime.start,
          null
        ),
        end: values.reduce(
          (result, item) =>
            null != result
              ? Math.max(result, item.datetime.end)
              : item.datetime.end,
          null
        ),
        timezone,
        type: "datetime",
        ticks: [],
      },
      value: {
        start: values.reduce(
          (result, item) => Math.min(result, item.value.start),
          0
        ),
        end: values.reduce(
          (result, item) => Math.max(result, item.value.end),
          0
        ),
        type: "value",
        ticks: [],
      },
    };

    // add a marker for the value
    if (newChart.value.end > 0) {
      newChart.value.ticks.push(newChart.value.end);
      newChart.value.end = newChart.value.end * 1.25; // scale
    }

    if (
      differenceInHours(newChart.datetime.end, newChart.datetime.start) > 48
    ) {
      let start = startOfDay(newChart.datetime.start);
      while (start < newChart.datetime.end) {
        if (isAfter(start, newChart.datetime.start))
          newChart.datetime.ticks.push(start);

        start = addHours(start, 24);
      }
    } else {
      let start = startOfDay(newChart.datetime.start);
      while (start < newChart.datetime.end) {
        if (isAfter(start, newChart.datetime.start))
          newChart.datetime.ticks.push(start);

        start = addHours(start, 3);
      }
    }

    console.log("new chart = ", newChart);

    chart = newChart; // update
  }

  function metricsToValues(metrics) {
    if (!metrics || !metrics.values) return [];

    return Object.entries(metrics.values).reduce(
      (result, [interval, value]) => {
        if (value == 4294967295) return result; // skip unlimited

        const [minLocal, maxLocal] =
          interval &&
          interval.split("/").map((i) => utcToZonedTime(i, metrics.timezone));
        result.push({
          type: metrics.source,
          datetime: {
            start: minLocal,
            end: maxLocal,
            timezone: metrics.timezone,
            type: "datetime",
          },
          value: {
            start: 0,
            end: value,
            type: "value",
          },
        });
        return result;
      },
      []
    );
  }

  //$: console.log("values=", values);
  //$: console.log("chart=", chart);

  //console.log('days=', days);

  // $: if(values) processed = Object.entries(values).map(([ interval, status]) => [ interval.split('/').map(i => utcToZonedTime(i, timezone)).reduce((interval, item, i) => {
  //     if(i === 0) interval.start = item;
  //     if(i === 1) interval.end = item;
  //     return interval;
  // } , { start: null, end: null }), status ]);

  //console.log("values=", values);

  //     function parseTimeMS(time) {
  //     const [ hr, min, sec ] = time.split(':');
  //     return [
  //         (parseInt(hr) || 0) * 60 * 60 * 1000,
  //         (parseInt(min) || 0) * 60 * 1000,
  //         (parseInt(sec) || 0) * 1000
  //     ].reduce((result, value) => result + value, 0);
  // }

  // function intervals(min, max, frequencyMS) {

  //     //console.log("intervals=", min, max, frequencyMS);

  //     var values = [];
  //     while (min < max) {

  //         //console.log('test=', min, min.getTime() + frequencyMS, max);

  //         values.push([ min, min = new Date(Math.min(min.getTime() + frequencyMS, max)) ]);
  //     }

  //     return values;
  // }

  // function parseDayTimeInterval(interval) {
  //     const [ aDay, aTime, bDay, bTime ] = interval.split(/[T\\/]/ig);

  //     let bTimeParsed = parseTimeMS(bTime);
  //     if(bTimeParsed === 0) bTimeParsed = dayMS;

  //     return [ parseInt(aDay), parseTimeMS(aTime), parseInt(bDay), bTimeParsed ];
  // }

  // function percentOfDayFromStart(ms) {
  //     return (ms / dayMS) * 100;
  // }
  // function percentOfDayFromEnd(ms) {
  //     return ((dayMS - ms) / dayMS) * 100;
  // }

  function getInterval(item) {
    if (!!item && !!item.datetime) item = item.datetime;

    if (!!item) {
      return `${toZoneISOString(
        item.start,
        item.timezone || timezone
      )}/${toZoneISOString(item.end, item.timezone || timezone)}`;
    }

    return "";
  }

  // returns an interval that's a percent from the start and end of containing interval

  function getTopLeftBottomRight(item) {
    //console.log("getTLBR=", item, x, y);

    if (item.getTime)
      item = {
        start: item,
        end: item,
      };

    if (item.start && item.end)
      item = {
        datetime: item,
      };

    const tb = getPercentInterval(chart[y], item[y]) || {
      start: null,
      end: null,
    };
    const lr = getPercentInterval(chart[x], item[x]) || {
      start: null,
      end: null,
    };

    if (x === "value") return [tb.start, lr.end, tb.end, lr.start];

    return [tb.end, lr.end, tb.start, lr.start];
  }

  function getStyle(item) {
    const tlbr = getTopLeftBottomRight(item).map((i) =>
      typeof i === "number" ? `${i}%` : "auto"
    );
    return `top:${tlbr[0]};right:${tlbr[1]};bottom:${tlbr[2]};left:${tlbr[3]};`;
  }

  function formatDateTimeLabel(point) {
    if (point.getHours() == 0) return format(point, "EEE");
    return format(point, "h a");
  }

  //console.log("parsed = ", parseDayTimeInterval("7T01:00/1T00:00:00").join(","));
</script>

{#if chart}
  <time class="schedule" datetime={getInterval(chart)} data-x={x}>
    {#each chart.datetime.ticks as item}
      <time
        datetime={toZoneISOString(item, chart.datetime.timezone)}
        class="marker"
        style={getStyle(item)}
        title={formatDateTimeLabel(item)}
      />
    {/each}
    {#each chart.value.ticks as item}
      <time
        datetime={getInterval(chart.datetime)}
        class="marker"
        data-value={item}
        style={getStyle({
          datetime: chart.datetime,
          value: {
            start: 0,
            end: item,
          },
        })}
        title={item}
      />
    {/each}
    {#if nowLocal && $nowLocal && isWithinInterval($nowLocal, chart.datetime)}
      <time
        datetime={toZoneISOString($nowLocal, timezone)}
        class="now"
        style={getStyle($nowLocal)}
        title={format($nowLocal, "h:mm a")}
      />
    {/if}
    <!-- {#each days as item }
        <time datetime="{getInterval(item, timezone)}" class="day" title="{format(item.start, "E MMM d")}"></time>
    {/each} -->
    {#each values || [] as item}
      <time
        datetime={getInterval(item)}
        class:past={isBefore(item.datetime.end, $nowLocal)}
        class:future={isAfter(item.datetime.start, $nowLocal)}
        class={item.type}
        data-value={item.value.end}
        style={getStyle(item)}
      />
    {/each}
  </time>
{/if}
