<script lang="ts">
  import EditPropertyUnits from "$components/EditPropertyUnits.svelte";
  import EditTenantCodes from "$components/EditTenantCodes.svelte";
  import Record from "$components/RecordItem.svelte";
  import ResetAllUnitCodes from "$components/ResetAllUnitCodes.svelte";
  import UnitTenants from "$components/UnitTenants.svelte";
  import { downloadHandler } from "$utils/exportTable";
  import { format } from "date-fns";
  import { get } from "lodash-es";
  import AddUnits from "./AddUnits.svelte";
  import ResetTenant from "./ResetTenant.svelte";
  import { fetchResetUnits } from "./api";
  import { stringToDates } from "./datetime";

  export let property = null;
  export let tenants = null;

  let table = null;
  let tenant = null;
  let query = "";

  $: visibleTenants = tenants?.filter((tenant) => {
    if (!query) return true;
    return tenant.subject.display.toLowerCase().includes(query.toLowerCase());
  });
</script>

<section>
  <EditPropertyUnits {property} />
  {#if property}
    <a
      href="#"
      type="text/csv"
      download="{get(property, 'name', '')} Units.csv"
      on:click={(e) => downloadHandler(e, table)}>Download Spreadsheet</a
    >
  {/if}
  <AddUnits bind:property />
  <button
    class="resetall"
    type="button"
    on:click={async (e) => {
      if (window.confirm("This is permanent and cannot be undone. Continue?"))
        await fetchResetUnits(property);
    }}>Turn Over All</button
  >
  <ResetAllUnitCodes {property} {tenants} />
</section>
<!-- {#if add}
    
{/if} -->
<UnitTenants />
{#if visibleTenants}
  <table class="tenants" data-count={visibleTenants?.length} bind:this={table}>
    <thead>
      <tr>
        <th scope="col" />
        <th scope="col row">
          <input
            type="search"
            name="search"
            bind:value={query}
            class="input input-sm bg-base-200 w-full mb-1 min-w-[10rem]"
            placeholder="Search by unit..."
          />
        </th>
        <th scope="col row">id</th>
        <th scope="col" />
        <th scope="col">Passcode</th>
        <th scope="col">Last Turned Over</th>
        <th scope="col">format</th>
        <th scope="col">style</th>
        <th scope="col">bedrooms</th>
        <th scope="col">bathrooms</th>
        <th scope="col">squarefeet</th>
        <th scope="col">ref</th>
        <th scope="col">indoor</th>
        <th scope="col">room</th>
        <th scope="col">level</th>
        <th scope="col">level:ref</th>
        <th scope="col">building:ref</th>
        <th scope="col">addr:housenumber</th>
        <th scope="col">addr:street</th>
        <th scope="col">addr:unit</th>
        <th scope="col">addr:city</th>
        <th scope="col">addr:state</th>
        <th scope="col">addr:country</th>
        <th scope="col">addr:postcode</th>
        <th scope="col">post:box</th>
        <th scope="col">URL</th>
      </tr>
    </thead>
    {#if !!visibleTenants && !!visibleTenants.length}
      <tbody>
        {#each visibleTenants as item}
          {#if item}
            <tr>
              <td
                ><button
                  type="button"
                  disabled={tenant && tenant.id === item.id}
                  on:click={(e) => (tenant = item)}>Edit</button
                ></td
              >
              <th scope="row"><Record item={item.subject} /></th>
              <td>{item.subject.id}</td>
              <td>{item.key}</td>
              <td><span>{item.authcode || ""}</span></td>
              <td
                ><time datetime={item.valid.utc}
                  >{format(
                    stringToDates(item.valid.utc)[0],
                    "EE MMM d yyyy h:mm a"
                  )}</time
                ></td
              >
              <td>{item.subject.format || ""}</td>
              <td>{item.subject.style || ""}</td>
              <td>{item.subject.bedrooms || 0}</td>
              <td>{item.subject.bathrooms || 0}</td>
              <td>{item.subject.squarefeet || 0}</td>
              <td>{item.subject.ref || ""}</td>
              <td>{item.subject.indoor || ""}</td>
              <td>{item.subject.room || ""}</td>
              <td>{item.subject.level || ""}</td>
              <td>{item.subject["level:ref"] || ""}</td>
              <td>{item.subject["building:ref"] || ""}</td>
              <td>{item.subject["addr:housenumber"] || ""}</td>
              <td>{item.subject["addr:street"] || ""}</td>
              <td>{item.subject["addr:unit"] || ""}</td>
              <td>{item.subject["addr:city"] || ""}</td>
              <td>{item.subject["addr:state"] || ""}</td>
              <td>{item.subject["addr:country"] || ""}</td>
              <td>{item.subject["addr:postcode"] || ""}</td>
              <td>{item.subject["post:box"] || ""}</td>
              <td
                ><a
                  href="https://my.communityhq.app?account={item.id}"
                  target="_blank"
                  rel="noreferrer">link</a
                ></td
              >
            </tr>
          {/if}
        {/each}
      </tbody>
    {/if}
  </table>
{/if}
{#if tenant}
  <ResetTenant bind:tenant />
{:else}
  <EditTenantCodes {tenants} {property} />
{/if}
<!-- {#if !!tenants && tenants.length === 0}
    <NoTenants />
{/if} -->
