<script>
  import { onMount, onDestroy, getContext } from "svelte";
  import { mapbox, key } from "./mapbox.js";
  import { debounce } from "lodash-es";

  const { getMap } = getContext(key);
  const map = getMap();

  let heading = map.getBearing();
  let pitch = map.getPitch();

  function updateHeading() {
    requestAnimationFrame(() => (heading = map.getBearing()));
  }

  function updatePitch() {
    requestAnimationFrame(() => (pitch = map.getPitch()));
  }

  map.on("rotateend", updateHeading);
  map.on("rotate", updateHeading);
  map.on("rotatestart", updateHeading);

  map.on("pitchend", updatePitch);
  map.on("pitch", updatePitch);
  map.on("pitchstart", updatePitch);

  // function onMouse(e) {

  // }

  // onMount(() => {

  // });

  // onDestroy(() => {

  // });

  function setHeading(heading, pitch) {
    map.easeTo(
      {
        bearing: heading,
        //pitch: pitch
      },
      {
        duration: 400,
      }
    );
  }
</script>

<figure
  class="heading"
  style="transform:rotateZ({360 - heading}deg);"
  on:click={(e) => setHeading(0, 0)}
/>
