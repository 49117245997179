<script>
  import * as d3 from "d3";
  import ChartAxis from "./ChartAxis.svelte";
  import ChartLine from "./ChartLine.svelte";

  /**
   * data = { current: {}, previous: {}}
   */
  export let data;
  export let displayYAxis = false;
  export let xAxisTitle;

  const margin = {
    top: 20,
    right: 0,
    bottom: 20,
    left: 0,
  };

  let width;
  let height;

  $: insetSize = {
    width: width - (margin.left + margin.right),
    height: height - (margin.top + margin.bottom),
  };
  $: xScale = d3.scaleLinear().range([0, insetSize.width]).domain([0, 31]);
  $: yMax =
    (data?.current &&
      data?.previous &&
      d3.max(
        Object.values(data.current).concat(Object.values(data.previous))
      )) ||
    1;
  $: yScale = d3.scaleLinear().range([insetSize.height, 0]).domain([0, yMax]);

  $: lineGenerator = d3
    .line()
    .x((_, i) => {
      return xScale(i);
    })
    .y(([_, value]) => {
      let y = yScale(value);
      return y;
    });
</script>

<div
  bind:clientWidth={width}
  bind:clientHeight={height}
  class="chart-sparkline"
>
  <svg {width} {height}>
    <ChartLine
      text={yMax}
      x1={0}
      x2={insetSize.width}
      textYOffset={-4}
      {margin}
    />
    <ChartAxis
      scale={xScale}
      position="bottom"
      {height}
      {margin}
      tickFormat={() => {
        return " ";
      }}
    />
    {#if displayYAxis}
      <ChartAxis scale={yScale} position="left" height={yScale(0)} {margin} />
    {/if}
    {#if data}
      {#if data?.previous}
        <path
          class="previous"
          d={lineGenerator(Object.entries(data.previous))}
          transform={`translate(0, ${margin.top})`}
        />
      {/if}
      {#if data?.current}
        <path
          class="current"
          d={lineGenerator(Object.entries(data.current))}
          transform={`translate(0, ${margin.top})`}
        />
      {/if}
    {/if}
    {#if xAxisTitle}
      <text
        text-anchor="middle"
        transform={`translate(${width / 2}, ${height - margin.top})`}
      >
        {xAxisTitle}
      </text>
    {/if}
  </svg>
</div>
