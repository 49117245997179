<script>
  import { param } from "../util/params";
  import Map from "./maps/Map.svelte";
  import MapDraw from "./maps/MapDraw.svelte";
  import MapInspector from "./maps/MapInspector.svelte";
  import MapCompass from "./maps/MapCompass.svelte";
  import EditFeatureProperties from "./maps/EditFeatureProperties.svelte";
  import {
    style as mapstyle,
    styles,
    level,
    levels,
    propertyGeoJsonLeveled as geojson,
    inspect,
    position,
    displayImages,
    imageFeatures,
    features as apiFeatures,
  } from "./maps/stores";
  import { fetchUpdateGeoFeature, fetchRemoveGeoFeature } from "./maps/api";
  import router from "page";

  export let property;

  $: features = $apiFeatures;

  const defaultStyle = "monochrome";
  const paramStyle = param("style");
  $: selectedStyle = $paramStyle || defaultStyle;
  const style = mapstyle(defaultStyle);

  function route(key, value) {
    var qs = new URLSearchParams(window.location.search);
    if (value) qs.set(key, value);
    else qs.delete(key);
    router(window.location.pathname + "?" + qs.toString());
  }

  $: console.log("api features=", $apiFeatures);
  $: console.log("geojson=", $geojson);
  $: console.log("style=", $style);
  $: console.log("images=", $imageFeatures);

  let selected = null;
  let drawMode = "simple_select";
  let updated = {};
  let deleted = {};
  let editing = null;

  // update editing state
  $: if (
    selected &&
    selected.length == 1 &&
    (!editing || editing.id != selected[0].id)
  )
    editing =
      !deleted[selected[0].id] && (updated[selected[0].id] || selected[0]);
  $: if (!selected || selected.length != 1) editing = null;

  let submittable = false;
  let submitting = false;

  $: if (Object.keys(updated).length || Object.keys(deleted).length)
    submittable = true;

  const fields = {
    amenity: "Amenity",
    heading: "Heading",
    image: "Image",
    url: "URL",
  };

  function featuresDeleted(features) {
    for (const feature of features) {
      deleted[feature.id] = feature;
      if (updated[feature.id]) delete updated[feature.id];
    }
    deleted = deleted;
    //submittable = true;
    console.log("featuresDeleted", deleted);
  }

  function geometryChanged(features) {
    for (const feature of features) {
      if (updated[feature.id]) updated[feature.id].geometry = feature.geometry;
      else updated[feature.id] = feature;
    }
    updated = updated;
    //submittable = true;
    console.log("featuresChanged", updated);
  }

  function propertiesChanged(features) {
    for (const feature of features) {
      if (updated[feature.id])
        updated[feature.id].properties = feature.properties;
      else updated[feature.id] = feature;
    }
    updated = updated;
    //submittable = true;
    console.log("propertiesChanged", updated);
  }

  // async function remove(e) {
  //     e.preventDefault();

  //     if(!property || submitting) return;

  //     const form = e.target;

  //     submitting = true;

  //     for(const feature of selected) {
  //         await fetchRemoveGeoFeature(property, feature, features);
  //     }

  //     $apiFeatures = features;

  //     submitting = false;

  //     drawMode = "simple_select";
  //     selected = null;

  //     //form.reset();
  // }

  async function update() {
    if (!property || submitting) return;

    submitting = true;
    drawMode = "simple_select";
    selected = null;

    await fetchRemoveGeoFeature(property, Object.keys(deleted), features);
    // for(const [ id, feature ] of Object.entries(deleted)) {
    //     await fetchRemoveGeoFeature(property, feature, features);
    // }
    deleted = {};

    for (const [id, feature] of Object.entries(updated)) {
      await fetchUpdateGeoFeature(property, feature, features);
    }

    updated = {};

    $apiFeatures = features;

    submitting = false;

    submittable = false;
  }
</script>

<section>
  <form on:submit|preventDefault={update}>
    <fieldset>
      <ul>
        <li>
          <label>Draw</label>
          <ul>
            <li>
              <input
                id="geo-draw-select"
                type="radio"
                name="draw"
                on:change={(e) =>
                  e.target?.checked && (drawMode = e.target?.value)}
                checked={drawMode == "simple_select"}
                value="simple_select"
              /> <label for="geo-draw-select">Select</label>
            </li>
            <li>
              <input
                id="geo-draw-point"
                type="radio"
                name="draw"
                on:change={(e) =>
                  e.target.checked && (drawMode = e.target.value)}
                checked={drawMode == "draw_point"}
                value="draw_point"
              /> <label for="geo-draw-point">Point</label>
            </li>
            <li>
              <input
                id="geo-draw-polygon"
                type="radio"
                name="draw"
                on:change={(e) =>
                  e.target.checked && (drawMode = e.target.value)}
                checked={drawMode == "draw_polygon"}
                value="draw_polygon"
              /> <label for="geo-draw-polygon">Polygon</label>
            </li>
            <li>
              <input
                id="geo-draw-line"
                type="radio"
                name="draw"
                on:change={(e) =>
                  e.target.checked && (drawMode = e.target.value)}
                checked={drawMode == "draw_line_string"}
                value="draw_line_string"
              /> <label for="geo-draw-line">Line</label>
            </li>
            <li>
              <input
                id="geo-draw-rect"
                type="radio"
                name="draw"
                on:change={(e) =>
                  e.target.checked && (drawMode = e.target.value)}
                checked={drawMode == "draw_rectangle"}
                value="draw_rectangle"
              /> <label for="geo-draw-rect">Rect</label>
            </li>
          </ul>
        </li>
      </ul>
    </fieldset>
    <footer>
      <button type="submit" disabled={submitting || !submittable}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>

  {#if editing}
    <EditFeatureProperties
      {property}
      {editing}
      on:change={(e) => propertiesChanged([e.detail])}
    />
  {/if}
</section>
{#if $geojson && $style}
  <Map geojson={$geojson} style={$style}>
    {#if $inspect}
      <MapInspector />
    {/if}
    <MapCompass />
    <!-- <MapPitch /> -->
    <MapDraw
      geojson={$apiFeatures}
      bind:selected
      bind:mode={drawMode}
      deleted={featuresDeleted}
      changed={geometryChanged}
    />
    <!-- {#each $imageFeatures.features as feature}
        <MapMarker feature={feature} />
    {/each} -->
    <nav class="levels">
      <ul
        class="levels"
        on:change={(e) =>
          e.target.checked && route(e.target.name, e.target.value)}
      >
        {#if $levels && $levels.length > 0}
          {#each [["", "Outside"], ...$levels].sort((a, b) => (b[0] || 0) - (a[0] || 0)) as [id, name]}
            <li>
              <input
                id="level-{id}"
                type="radio"
                name="level"
                checked={id == $level}
                value={id}
              /> <label for="level-{id}">{id || "-"}</label>
            </li>
          {/each}
        {/if}
      </ul>
    </nav>
    <nav class="options">
      <ul
        class="styles"
        on:change={(e) =>
          e.target.checked && route(e.target.name, e.target.value)}
      >
        {#each Object.entries($styles || {}) as [id, style]}
          <li>
            <input
              id="style-{id}"
              type="radio"
              name="style"
              checked={id == selectedStyle}
              value={id}
            /> <label for="style-{id}">{style.name}</label>
          </li>
        {/each}
      </ul>
      <ul class="options">
        <li>
          <input
            id="option-inspect"
            type="checkbox"
            name="inspect"
            checked={$inspect}
            value="true"
            on:change={(e) =>
              route(e.target.name, e.target.checked ? e.target.value : null)}
          /> <label for="option-inspect">Inspect</label>
        </li>
        <!-- <li><input id="option-poi" type="checkbox" name="poi" checked={$displayPoi} value="true" on:change={e => (route(e.target.name, e.target.checked ? e.target.value : null))} /> <label for="option-poi">POI</label></li>-->
        <li>
          <input
            id="option-photos"
            type="checkbox"
            name="images"
            checked={$displayImages}
            value="true"
            on:change={(e) =>
              route(e.target.name, e.target.checked ? e.target.value : null)}
          /> <label for="option-photos">Photos</label>
        </li>
        <!-- <li><input id="option-position" type="checkbox" name="position" checked={$position} value="-117.496095,33.906150" on:change={e => (route(e.target.name, e.target.checked ? e.target.value : null))} /> <label for="option-position">Position</label></li> -->
      </ul>

      <!-- <ul class="units" on:change={e => (e.target.checked && route(e.target.name, e.target.value))}>
            {#each $geounits as feature}
            <li><input id="unit-{feature.properties.name}" type="radio" name="unit" value="{feature.properties.name}" checked={$params.unit === feature.properties.name} /> <label for="unit-{feature.properties.name}">{feature.properties.name}</label></li>
            {/each}
        </ul>
        <ul class="spaces" on:change={e => (e.target.checked && route(e.target.name, e.target.value))}>
            {#each $geospaces as feature}
            <li><input id="space-{feature.properties.name}" type="radio" name="space" value="{feature.properties.name}" checked={$space === feature.properties.name} /> <label for="space-{feature.properties.name}">{feature.properties.name}</label></li>
            {/each}
        </ul> -->
    </nav>
  </Map>
{/if}
