<script>
  import ProviderDirectory from "$components/providers/ProviderDirectory.svelte";
  import { fetchUsers } from "./api";
  import BboxMap from "./components/BboxMap.svelte";
  import NewProperty from "./components/NewProperty.svelte";
  import PropertiesMap from "./components/PropertiesMap.svelte";
  import PropertiesPaymentsMetrics from "./components/PropertiesPaymentsMetrics.svelte";
  import Search from "./components/Search.svelte";
  import AllUsers from "./components/sections/AllUsers.svelte";
  import Toasts from "./components/Toasts.svelte";
  import NewUser from "./NewUser.svelte";
  import Properties from "./Properties.svelte";
  import Property from "./Property.svelte";
  import { properties, property, toasts, view } from "./stores";
  import { params } from "./util/params";
  import "@communityboss/tailwind/daisy-overrides.css";
</script>

{#if $params.property}
  <Property property={$property} />
{:else}
  <header>
    <h1>Welcome Bosses</h1>
  </header>
  <Toasts toasts={$toasts} />
  <nav>
    <ul>
      <li>
        <a href="/properties" class:selected={$view == "properties"}
          >All Properties</a
        >
      </li>
      <li>
        <a href="/properties/map" class:selected={$view == "propertiesmap"}
          >Properties Map</a
        >
      </li>
      <li>
        <a href="/properties/bboxmap" class:selected={$view == "bboxmap"}
          >BBox Map</a
        >
      </li>
      <li>
        <a href="/payments/collected" class:selected={$view == "paymentsindex"}
          >Payment Metrics</a
        >
      </li>
      <li>
        <a href="/users" class:selected={$view == "users"}>All Users</a>
      </li>
      <li><a href="/search" class:selected={$view == "search"}>Find a…</a></li>
      <li>
        <a href="/users/new" class:selected={$view == "newuser"}>New User</a>
      </li>
      <li>
        <a href="/properties/new" class:selected={$view == "newproperty"}
          >New Property</a
        >
      </li>
      <li>
        <a href="/providers" class:selected={$view == "providers"}>
          Provider Directory
          <span class="badge badge-accent ml-3">ALPHA</span>
        </a>
      </li>
    </ul>
  </nav>
  {#if $view == "propertiesmap"}
    <PropertiesMap properties={$properties} />
  {:else if $view === "bboxmap"}
    <BboxMap properties={$properties} />
  {:else if $view == "paymentsindex"}
    <PropertiesPaymentsMetrics />
  {:else if $view == "search"}
    <Search />
  {:else if $view == "users"}
    <AllUsers data={fetchUsers("*")} />
  {:else if $view == "newuser"}
    <main>
      <section>
        <header>
          <h1>New User</h1>
        </header>
        <NewUser />
      </section>
      <section />
    </main>
  {:else if $view == "newproperty"}
    <NewProperty />
  {:else if $view == "providers"}
    <ProviderDirectory />
  {:else}
    <Properties properties={$properties} />
  {/if}
{/if}

<footer>
  <nav class="system">
    <ul>
      <li><a href="/">Boss Global</a></li>
      <li><a href="https://status.parkingboss.com">System Status</a></li>
      <li><a href="https://updates.parkingboss.com">Updates</a></li>
      <li><a href="https://my.parkingboss.com/user/logout">Log Out</a></li>
    </ul>
  </nav>
</footer>
