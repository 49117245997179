<script>
  import * as d3 from "d3";
  import ChartAxis from "./ChartAxis.svelte";
  import ChartLine from "./ChartLine.svelte";
  import { days, isoDayMap } from "../../util/metrics";

  const margin = {
    top: 20,
    bottom: 20,
    left: 32,
    right: 0,
  };
  const barWidth = 12;

  export let data;

  let width;
  let height;

  $: insetSize = {
    width: width - (margin.left + margin.right),
    height: height - (margin.top + margin.bottom),
  };
  $: xScale = d3.scaleBand().range([0, insetSize.width]).domain(days);
  $: yMax = (data?.values && d3.max(Object.values(data.values))) || 1;
  $: yScale = d3.scaleLinear().range([insetSize.height, 0]).domain([0, yMax]);

  const tickFormat = (value) => {
    return value[0].toLowerCase();
  };

  const xValue = (value) => {
    const [start] = value.split("/");
    const [day] = start.split("T");
    const dayOfWeek = isoDayMap[day];
    const x = xScale(dayOfWeek) + xScale.bandwidth() / 2 - barWidth / 2;
    return x;
  };
</script>

<div
  class="chart-bar"
  on:click
  bind:clientWidth={width}
  bind:clientHeight={height}
>
  {#if width && height}
    <svg {width} {height}>
      <ChartLine text={yMax} x1={margin.left} x2={width} {margin} />
      <ChartAxis
        scale={xScale}
        position="bottom"
        {height}
        {margin}
        {tickFormat}
      />
      {#if data?.values}
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          {#each Object.entries(data.values) as [key, value]}
            <rect
              x={xValue(key)}
              y={yScale(value)}
              width={barWidth}
              height={value > 0 ? insetSize.height - yScale(value) : 0}
              rx={6}
              data={`${key}::${value}`}
            />
          {/each}
        </g>
      {/if}
    </svg>
  {/if}
</div>
