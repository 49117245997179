<script>
  import { get } from "lodash-es";
  import { fetchUpdateProperty } from "./api";

  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing)) editing = { ...property };

  $: parking = get(editing, "amenities.items", []).includes("parking");

  $: publicSafelist = get(
    editing,
    "attendant.permits.selfservice.list.enabled",
    false
  );

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    const newPolicy = await fetchUpdateProperty(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

{#if editing}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    class="edit property"
  >
    <header>
      <h1>Settings</h1>
    </header>

    <fieldset>
      <ul>
        <li>
          <label for="property-safelist">Parking</label>
          <select
            for="property-safelist"
            name="parking"
            value={parking + ""}
            on:change={(e) => (parking = e.target.value === "true")}
            on:blur={(e) => (parking = e.target.value === "true")}
          >
            <option value="true">enabled</option>
            <option value="false">disabled</option>
          </select>
        </li>
        {#if parking}
          <li>
            <label for="property-passes">Passes</label>
            <select
              id="property-passesnp"
              name="passes.enabled"
              value={!!get(editing, "passes.enabled", false) + ""}
            >
              <option value="true">enabled</option>
              <option value="false">disabled</option>
            </select>
          </li>
          <li>
            <label for="property-units-selfservice">Check Usage</label>
            <select
              id="property-units-selfservice"
              name="tenants.selfservice.enabled"
              value={get(editing, "tenants.selfservice.enabled", false) + ""}
            >
              <option value="true">enabled</option>
              <option value="false">disabled</option>
            </select>
          </li>
        {/if}
      </ul>
    </fieldset>

    {#if parking}
      <fieldset>
        <ul>
          <li>
            <label>QR Codes</label>
            <a
              href="https://boss.parkingboss.com/qr/properties/{editing.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
          <li>
            <label>Notices</label>
            <a href="/properties/{editing.id}/notices">open</a>
          </li>
          <li>
            <label>Manager</label>
            <a
              href="https://manager.communityboss.app/properties/{editing.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
          <li>
            <label>Reports</label>
            <a
              href="https://reports.communityboss.app/{editing.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
          <li>
            <label>Field Agent</label>
            <a
              href="https://fieldagent.parkingboss.app/{editing.id}"
              target="_blank"
              rel="noreferrer">open in new tab</a
            >
          </li>
          <li>
            <label>Attendant</label>
            <a href={editing.url} target="_blank" rel="noreferrer"
              >open in new tab</a
            >
          </li>
        </ul>
      </fieldset>

      <fieldset>
        <ul>
          <li>
            <label for="property-map">Map</label>
            <select
              id="property-map"
              name="map"
              value={!!get(editing, "map", "") + ""}
            >
              <option value="true">enabled</option>
              <option value="false">disabled</option>
            </select>
          </li>
          {#if get(editing, "map", false)}
            <li>
              <label>Preview</label>
              <a
                href="https://maps.communityboss.app/properties/{editing.id}"
                target="_blank"
                rel="noreferrer">open in new tab</a
              >
            </li>
            <li>
              <label>Printable</label>
              <a
                href="https://maps.communityboss.app/properties/{editing.id}/print"
                target="_blank">open in new tab</a
              >
            </li>
          {/if}
        </ul>
      </fieldset>

      <fieldset>
        <p>Public safelist:</p>
        <ul>
          <li>
            <label for="property-safelist">Status</label>
            <select
              for="property-safelist"
              name="attendant.permits.selfservice.list.enabled"
              value={publicSafelist + ""}
              on:change={(e) => (publicSafelist = e.target.value === "true")}
              on:blur={(e) => (publicSafelist = e.target.value === "true")}
            >
              <option value="true">enabled</option>
              <option value="false">disabled</option>
            </select>
          </li>
          {#if publicSafelist}
            <li>
              <label for="property-safelist">Special</label>
              <select
                for="property-safelist"
                name="attendant.permits.selfservice.list.exempt"
                value={get(
                  editing,
                  "attendant.permits.selfservice.list.exempt",
                  false
                ) + ""}
              >
                <option value="true">indicated</option>
                <option value="false">not indicated</option>
              </select>
            </li>
          {/if}
        </ul>
      </fieldset>
    {/if}

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
