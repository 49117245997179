<script>
  import {
    startOfDay,
    endOfDay,
    startOfWeek,
    subWeeks,
    endOfWeek,
  } from "date-fns";
  import { utcToZonedTime } from "date-fns-tz";
  import DailyUsageChart from "./DailyUsageChart.svelte";
  import Odometer from "./Odometer.svelte";
  import {
    getCurrentCount,
    getCountForDateRange,
    getDailyUsageChartData,
  } from "../../util/metrics.js";
  import { propertyTimezone } from "../../stores";

  export let data;
  export let capacity;
  export let title;

  $: now = utcToZonedTime(new Date(), $propertyTimezone);
  $: thisWeekStart = utcToZonedTime(startOfWeek(now), $propertyTimezone);
  $: lastWeek = utcToZonedTime(subWeeks(now, 1));
  $: lastWeekEnd = utcToZonedTime(endOfWeek(lastWeek), $propertyTimezone);
  $: lastWeekStart = utcToZonedTime(startOfWeek(lastWeek), $propertyTimezone);

  $: activeCount = (data?.values && getCurrentCount(data.values, now)) || 0;
  $: currentCapacity =
    capacity?.values && getCurrentCount(capacity.values, now);
  $: todayCount =
    data?.values &&
    getCountForDateRange(data.values, startOfDay(now), endOfDay(now));
  $: thisWeekCount =
    data?.values && getCountForDateRange(data.values, thisWeekStart, now);
  $: previousWeekCount =
    data?.values &&
    getCountForDateRange(data.values, lastWeekStart, lastWeekEnd);
  $: weekDifference =
    thisWeekCount &&
    previousWeekCount &&
    Math.round((thisWeekCount / previousWeekCount) * 100);
  $: dailyUsageData =
    data?.values &&
    getDailyUsageChartData(
      data.values,
      { start: thisWeekStart, end: now },
      { start: lastWeekStart, end: lastWeekEnd },
      $propertyTimezone
    );
</script>

<tr class="metrics-table-row" on:click>
  <td class="title">{title}</td>
  <td class="active">
    {#if currentCapacity === 4294967295}
      <p class="capacity-message">unlimited</p>
    {:else if currentCapacity === 0}
      <p class="capacity-message">closed</p>
    {:else if !!currentCapacity}
      <Odometer value={activeCount} max={currentCapacity} />
    {:else}
      <p>{"—"}</p>
    {/if}
  </td>
  <td class="today">{todayCount || "—"}</td>
  <td>
    <div class="week">
      <span class="current">{thisWeekCount || "—"}</span>
      {#if weekDifference}
        <span
          class="difference"
          class:neutral={weekDifference === 100 || !isFinite(weekDifference)}
          class:positive={weekDifference > 100}
          class:negative={weekDifference < 100}
        >
          {#if weekDifference > 100}
            {`${weekDifference}%`}
          {:else if weekDifference < 100}
            {`${weekDifference}%`}
          {/if}
        </span>
      {/if}
    </div>
  </td>
  <td>
    <DailyUsageChart data={dailyUsageData} />
  </td>
</tr>
