// import { PUBLIC_AZURE_MAP_KEY } from "$env/static/public";
import type {
  SearchAddressResponse,
  SearchPolygonResponse,
} from "azure-maps-rest";
import debug from "debug";
import { utils } from "../utils/map";

const log = debug("boss:providers:stores:azure");

const PUBLIC_AZURE_MAP_KEY = "ooF9ny-5e5MfLHdea_vVNwnpuUP6Hia9ju7_p5cObYg";
const API_BASE = "https://atlas.microsoft.com";

// const api = fetcher({
//   base: "https://atlas.microsoft.com",
// });

//

export const azure = {
  boundaries: {
    async get(id: string) {
      const url = new URL(`${API_BASE}/search/polygon/json`);
      url.searchParams.set("geometries", id);
      url.searchParams.set("api-version", "1.0");
      url.searchParams.set("subscription-key", PUBLIC_AZURE_MAP_KEY);

      const boundaries: SearchPolygonResponse = await fetch(url).then((r) =>
        r.json()
      );

      log("boundaries:", boundaries);

      const features = boundaries?.additionalData
        ? boundaries?.additionalData[0].geometryData
        : {};

      log("features:", features);

      if (!features) throw new Error(`No features found for boundary "${id}"`);

      features.bbox = utils.bbox.get_from_geometry(features);

      return features;
    },
    async fetch(place_name: string) {
      log("fetching boundaries for:", place_name);

      const url = new URL(`${API_BASE}/search/address/json`);
      url.searchParams.set("query", place_name);
      url.searchParams.set("view", "Auto");
      url.searchParams.set("api-version", "1.0");
      url.searchParams.set("subscription-key", PUBLIC_AZURE_MAP_KEY);

      const result: SearchAddressResponse = await fetch(url).then((r) =>
        r.json()
      );

      log("results:", result?.results);

      const boundaries = result?.results?.filter((r) => r.dataSources);

      log("updated boundaries:", boundaries);

      return boundaries;
    },
  },
};
