<script context="module">
  import { writable } from "svelte/store";

  const policies = writable(null);

  propertyId.subscribe(($value) => {
    if (!$value) return;
    fetchPropertyViolationPolicies($value).then(policies.set);
  });

  function created(updated) {
    policies.update(($current) => {
      for (const [k, v] of Object.entries(updated.items)) {
        $current.items[k] = v;
      }
      $current.count = Object.entries($current.items).length;
      return $current;
    });
  }
  function removed(updated) {
    policies.update(($current) => {
      for (const [k, v] of Object.entries(updated.items)) {
        if ($current.items[k]) delete $current.items[k];
      }
      $current.count = Object.entries($current.items).length;
      return $current;
    });
  }
</script>

<script>
  import {
    fetchCreateViolationThreshold as update,
    fetchPropertyViolationPolicies,
  } from "./api";
  import { get, clone, map } from "lodash-es";
  import { propertyId } from "$stores";
  import ViolationPolicyItems from "$components/violation/EditViolationPolicyItems.svelte";
  import NewViolationPolicyItem from "$components/violation/NewViolationPolicyItem.svelte";

  export let property = null;
</script>

<section>
  <header>
    <h1>Thresholds</h1>
  </header>
  <NewViolationPolicyItem
    {property}
    on:change={({ detail: policies }) => created(policies)}
  />
  <ViolationPolicyItems
    items={$policies}
    on:change={({ detail: policies }) => removed(policies)}
  />
</section>
