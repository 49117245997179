<script>
  import DateTimeSchedule from "$components/DateTimeSchedule.svelte";
  import PermitsAgenda from "$components/PermitsAgenda.svelte";
  import PermitsTimeline from "$components/PermitsTimeline.svelte";
  import PoliciesLive from "$components/PoliciesLive.svelte";
  import PoliciesSummary from "$components/PoliciesSummary.svelte";
  import PolicySchedule from "$components/PolicySchedule.svelte";
  import Property from "$components/Property.svelte";
  import Scans from "$components/Scans.svelte";
  import Spaces from "$components/Spaces.svelte";
  import { addDays, format, formatISO } from "date-fns";
  import { first, get } from "lodash-es";
  import {
    fetchLeasing,
    fetchPermits,
    fetchUnitsSent,
    fetchVehicleDetections,
    fetchViolations,
  } from "./api";
  import EditEnforcementSettings from "./EditEnforcementSettings.svelte";
  import EditPolicy from "./EditPolicy.svelte";
  import EditPropertyAmenities from "./EditPropertyAmenities.svelte";
  import EditPropertyDocs from "./EditPropertyDocs.svelte";
  import EditPropertyParking from "./EditPropertyParking.svelte";
  import EditProperty from "./EditPropertySettings.svelte";
  import Media from "./Media.svelte";
  import NewPolicy from "./NewPolicy.svelte";
  import PermitsReport from "./PermitsReport.svelte";
  import {
    media,
    mediaId,
    minuteNow,
    permit,
    permitId,
    permits,
    policies,
    policy,
    policyId,
    policyVersion,
    policyVersionId,
    prices,
    propertyId,
    propertyNow,
    propertyPaymentMetrics,
    propertyTimezone,
    spaceId,
    spaces,
    tenantId,
    tenants,
    token,
    user,
    valid,
    validDefaultPropertyToday,
    validDefaultPropertyWeek,
    vehicleId,
    view,
    violation,
    violationId,
  } from "./stores";
  import Tenants from "./Tenants.svelte";
  import TenantsNotify from "./TenantsNotify.svelte";
  import { smscreen } from "./util/envstores";
  import { param } from "./util/params";
  // import Photos from "$components/Photos.svelte";
  import Apps from "$components/Apps.svelte";
  import CommunityBoss from "$components/CommunityBoss.svelte";
  import EditPropertyGeofence from "$components/EditPropertyGeofence.svelte";
  import EditPropertyMap from "$components/EditPropertyMap.svelte";
  import EnforcementMap from "$components/EnforcementMap.svelte";
  import ImportPermits from "$components/import-permits/ImportPermits.svelte";
  import PermitDetails from "$components/PermitDetails.svelte";
  import PoliciesDisplay from "$components/PoliciesDisplay.svelte";
  import PolicyVersion from "$components/PolicyVersion.svelte";
  import PropertyPaymentMetrics from "$components/PropertyPaymentMetrics.svelte";
  import PropertyRevenue from "$components/PropertyRevenue.svelte";
  import PropertyViolations from "$components/PropertyViolations.svelte";
  import UnitResidentTimeline from "$components/UnitResidentTimeline.svelte";
  import UnitStatus from "$components/UnitStatus.svelte";
  import ValidInput from "$components/ValidInput.svelte";
  import Vehicles from "$components/Vehicles.svelte";
  import ViewPropertyMap from "$components/ViewPropertyMap.svelte";
  import ViolationDetails from "$components/ViolationDetails.svelte";
  import Metrics from "./Metrics.svelte";
  import PolicyMetrics from "./PolicyMetrics.svelte";

  const timeline = param("timeline");

  const defaultTitle = document.title;

  export let property = null;
  //export let properties;

  $: if ($user && window.Intercom)
    window.Intercom("update", {
      email: $user.email,
      name: $user.name,
      user_id: $user.id,
      created_at: new Date($user.created).getTime() / 1000,
    });

  $: document.documentElement[$view ? "setAttribute" : "removeAttribute"](
    "data-view",
    $view
  );
  $: document.documentElement[$propertyId ? "setAttribute" : "removeAttribute"](
    "data-property",
    $propertyId
  );
  $: document.documentElement[$policyId ? "setAttribute" : "removeAttribute"](
    "data-policy",
    $policyId
  );
  //$: document.documentElement[$permitId ? "setAttribute" : "removeAttribute"]("data-permit", $permitId);

  $: {
    document.title = [get(property, "name"), defaultTitle]
      .filter((item) => !!item)
      .join(" - ");
  }

  $: amenities = get(property, "amenities.items", []).includes("amenity");
  $: parking = get(property, "amenities.items", []).includes("parking");

  let canUseNotify = true; //property && (new Date(property.created) > new Date(2020, 4, 26));
</script>

<header>
  <!-- <select on:change|preventDefault={e => (window.location.href = "/properties/" + e.target.value)} on:blur={e => (window.location.href = "/properties/" + e.target.value)} value="{get(property, "id", "")}">
            {#if properties}
            {#each properties as item}
            <option value="{item.id}">{item.name} ({item.address.city}, {item.address.state})</option>
            {/each}
        {:else if property}
        <option value="{property.id}">{property.name} ({property.address.city}, {property.address.state})</option>
        {/if}
        </select> -->

  {#if property}
    <Property {property} />
  {:else}
    <h1>Loading…</h1>
  {/if}
</header>
<nav>
  {#if property && $user && !$smscreen}
    <ul>
      <li>
        <a
          href="/properties/{$propertyId}/live"
          class:selected={$view == "live"}
          >Live <time>{format($propertyNow, "h:mm a")}</time></a
        >
      </li>

      <li>
        <a
          href="/properties/{$propertyId}/settings"
          class:selected={$view == "settings"}>General Settings</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/area"
          class:selected={$view == "area"}>Geofence</a
        >
      </li>

      <!-- <li><a href="/properties/{$propertyId}/community" class:selected={ $view == "community" }>Community</a></li> -->
      <li>
        <a
          href="/properties/{$propertyId}/parking"
          class:selected={$view == "parkingpolicies"}
          >{parking ? "Parking" : "No Parking"}</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/amenities"
          class:selected={$view == "amenitypolicies"}
          >{amenities ? "Amenities" : "No Amenities"}</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/spaces"
          class="space"
          class:selected={$view == "spaces"}
          >{get(property, "spaces.title", "No Space") + "s"}</a
        >
      </li>
      {#if $user.roles.admin}
        <li>
          <a
            href="/properties/{$propertyId}/tenants"
            class={get(property, "tenants.format", "unit")}
            class:selected={$view == "tenants"}
            >{property.tenants.enabled ? "Units" : "No Units"}</a
          >
        </li>
      {/if}
      <li>
        <a
          href="/properties/{$propertyId}/vehicles"
          class="vehicles"
          data-preview="2021-08-26T12:00:00Z"
          class:selected={$view == "vehicles"}>Vehicles</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/integration"
          class="integration"
          data-preview="2021-09-07T12:00:00Z"
          class:selected={$view == "integration"}>Integration</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/media"
          class="media"
          class:selected={$view == "media"}
          >{get(property, "media.title", "No Physical Permit") + "s"}</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/payments"
          class:selected={$view == "payments"}>Payments</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/permits"
          class:selected={$view == "permits"}>Permit Log</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/permits/agenda"
          class:selected={$view == "permitagenda"}>Permit Agenda</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/apps"
          class:selected={$view == "apps"}>Apps</a
        >
      </li>
      <li>
        <a href="/properties/{$propertyId}/tv" class:selected={$view == "tv"}
          >TV Display</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/notify"
          class:selected={$view == "notify"}>Notify</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/notices"
          class:selected={$view == "notices"}>Notices</a
        >
      </li>
      <!-- <li><a href="/properties/{$propertyId}/parking/notices" class:selected={ $view == "parkingnotices"}>Parking Notices</a></li> -->

      <li>
        <a
          href="/properties/{$propertyId}/enforcement"
          class:selected={$view == "enforcement"}>Enforcement</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/scans"
          class:selected={$view == "scans"}>Scans</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/photos"
          class:selected={$view == "photos"}>Photos</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/permits/import"
          class:selected={$view == "permitimport"}>Import Permits</a
        >
      </li>
      <!-- <li><a href="/properties/{$propertyId}/metrics" class:selected={ $view == "metrics"}>Metrics</a></li> -->
      <li>
        <a
          href="/properties/{$propertyId}/map/enforcement"
          class:selected={$view == "enforcementmap"}>Enforcement Map</a
        >
      </li>
      <li>
        <a
          href="/properties/{$propertyId}/map/edit"
          class:selected={$view == "editmap"}>Mapper</a
        >
      </li>
      <!-- <li>
        <a href="/properties/{$propertyId}/map" class:selected={$view == "map"}
          >Map</a
        >
      </li> -->
      <!-- <li>
        <a
          href="/properties/{$propertyId}/map/spaces"
          class:selected={$view == "mapspaces"}>Map Spaces</a
        >
      </li> -->
      <!-- <li><a href="/properties/{$propertyId}/violations" class:selected={ $view == "violations"}>Violations</a></li>
            <li><a href="/properties/{$propertyId}/revenue" class:selected={ $view == "revenue"}>Revenue</a></li> -->
      <!-- <li><a href="/properties/{$propertyId}/leasing" class:selected={ $view == "leasing"}>Leasing</a></li> -->
      <!-- <li><a href="/properties/{$propertyId}/old" class:selected={ $view == "old"}>Old Boss</a></li> -->
      <!-- <li><a href="/properties/{$propertyId}/users" class:selected={ $view == "users"}>Users & Permissions</a></li> -->

      <!-- <li><a href="https://docs.amenityboss.com/properties/{$propertyId}" target="_blank" rel="noreferrer">Notices</a></li> -->

      <!-- <li><a href="/properties/{$propertyId}/amenities/settings" class:selected={ $view == "policysettings"}>Settings</a></li> -->
    </ul>
    <ul>
      <li>
        <a
          href="/properties/{$propertyId}/policies/new"
          class:selected={$view == "newpolicy"}>Add Policy</a
        >
      </li>
      <!-- <li><a href="/properties/{$propertyId}/settings">Settings</a></li> -->
    </ul>
  {/if}
</nav>

<main>
  {#if $view == "editpolicy"}
    <EditPolicy policy={$policy} />
  {:else if $view == "live"}
    <!-- <header><h1>Live <time datetime="{$propertyNow.toISOString()}">{format($propertyNow, "h:mm a")}</time></h1></header> -->
    <PoliciesLive
      {property}
      policy={$policy || ($smscreen && first($policies))}
      policies={$policies}
      permits={$permits}
      valid={$valid || $minuteNow}
    />
  {:else if $view == "permitagenda"}
    <!-- <header><h1>Live <time datetime="{$propertyNow.toISOString()}">{format($propertyNow, "h:mm a")}</time></h1></header> -->
    <PermitsAgenda
      {property}
      policies={$policies}
      permits={fetchPermits($propertyId, $validDefaultPropertyToday, {
        all: true,
        tenant: $tenantId,
        policy: $policyId,
        vehicle: $vehicleId,
        space: $spaceId,
        media: $mediaId,
      })}
      valid={$valid || $minuteNow}
    />
  {:else if $view == "tv"}
    <PoliciesDisplay
      policy={$policy}
      policies={$policies &&
        $policies.filter((item) => item.amenity != "parking")}
    />
  {:else if $view == "community"}
    <CommunityBoss {property} />
  {:else if $view == "payments"}
    <PropertyPaymentMetrics {property} data={$propertyPaymentMetrics} />
  {:else if $view == "apps"}
    <Apps {property} />
    {#if amenities}
      <figure class="preview">
        <iframe title="mobile app" src={get(property, "public.amenities")} />
        <figcaption>
          <h1>
            <a
              href={get(property, "public.amenities")}
              target="_blank"
              rel="noreferrer">Amenity Pass</a
            >
          </h1>
        </figcaption>
      </figure>
    {/if}
    {#if parking}
      <figure class="preview">
        <iframe title="mobile app" src={get(property, "public.parking")} />
        <figcaption>
          <h1>
            <a
              href={get(property, "public.parking")}
              target="_blank"
              rel="noreferrer">Parking Attendant</a
            >
          </h1>
        </figcaption>
      </figure>
    {/if}
    <CommunityBoss {property} tenants={$tenants} />
    <!-- {#if amenities}
    <figure class="preview">
        <iframe title="mobile app" src="https://amenities.communityboss.app/properties/{$propertyId}#access_token={$token}"></iframe>
        <figcaption>
            <h1><a href="https://amenities.communityboss.app/properties/{$propertyId}#access_token={$token}" target="_blank" rel="noreferrer">Amenity Manager</a></h1>
        </figcaption>
    </figure>
    {/if}
    {#if parking}
    <figure class="preview">
        <iframe title="mobile app" src="https://manager.communityboss.app/properties/{$propertyId}?access_token={$token}"></iframe>
        <figcaption>
            <h1><a href="https://manager.communityboss.app/properties/{$propertyId}?access_token={$token}" target="_blank" rel="noreferrer">Parking Manager</a></h1>
        </figcaption>
    </figure>
    <figure class="preview">
        <iframe title="mobile app" src="https://fieldagent.parkingboss.com/{$propertyId}?access_token={$token}"></iframe>
        <figcaption>
            <h1><a href="https://fieldagent.parkingboss.com/{$propertyId}?access_token={$token}" target="_blank" rel="noreferrer">Field Agent</a></h1>
        </figcaption>
    </figure>
    {/if} -->
  {:else if $view == "newpolicy"}
    <NewPolicy {property} />
  {:else if $view == "amenitypolicies"}
    <EditPropertyAmenities {property} />
    <PoliciesSummary
      policies={$policies &&
        $policies.filter((item) => item.amenity !== "parking")}
    />
  {:else if $view == "parkingpolicies"}
    <EditPropertyParking {property} />
    <PoliciesSummary
      policies={$policies &&
        $policies.filter((item) => item.amenity === "parking")}
    />
  {:else if $view == "old"}
    {#if $propertyId}
      <iframe
        title="old"
        src="https://boss.parkingboss.com/locations/{$propertyId}"
      />
    {/if}
  {:else if $view == "permitimport"}
    <ImportPermits />
  {:else if $view == "enforcement"}
    <EditEnforcementSettings {property} />
  {:else if $view == "tenants"}
    <Tenants {property} tenants={$tenants?.filter((i) => i.current)} />
  {:else if $view == "spaces"}
    <Spaces {property} items={$spaces} prices={$prices} />
  {:else if $view == "media"}
    <Media {property} items={$media} />
  {:else if $view == "notify"}
    <TenantsNotify
      {property}
      tenants={$tenants?.filter((i) => i.current)}
      sent={fetchUnitsSent($propertyId)}
    />
  {:else if $view == "scans"}
    <section>
      <ValidInput
        value={$validDefaultPropertyWeek}
        timezone={$propertyTimezone}
      />
    </section>
    <Scans
      {property}
      valid={$validDefaultPropertyWeek}
      data={fetchVehicleDetections($propertyId, $validDefaultPropertyWeek)}
    />
  {:else if $view == "photos"}
    <section>
      <ValidInput
        value={$validDefaultPropertyWeek}
        timezone={$propertyTimezone}
      />
    </section>
    <!-- <Photos property={property} valid={$validDefaultPropertyWeek} data={fetchPhotos($propertyId, $validDefaultPropertyWeek)} /> -->
  {:else if $view == "notices" && property}
    <EditPropertyDocs {property} />
    {#if parking}
      <figure class="docs">
        <iframe
          title="docs"
          src="https://docs.parkingboss.app/properties/{property.id}#access_token={$token}"
        />
      </figure>
    {/if}
    {#if amenities}
      <figure class="docs">
        <iframe
          title="docs"
          src="https://docs.amenityboss.com/properties/{property.id}#access_token={$token}"
        />
      </figure>
    {/if}
  {:else if $view == "amenitynotices" && property}
    <figure class="docs">
      <iframe
        title="docs"
        src="https://docs.amenityboss.com/properties/{property.id}#access_token={$token}"
      />
    </figure>
  {:else if $view == "parkingnotices" && property}
    <figure class="docs">
      <iframe
        title="docs"
        src="https://docs.parkingboss.app/properties/{property.id}#access_token={$token}"
      />
    </figure>
  {:else if $view == "enforcementmap" && property}
    <EnforcementMap {property} />
  {:else if $view == "editmap" && property}
    <EditPropertyMap {property} />
    <!-- {:else if $view == "map" && property}
    <PropertyMap
      {property}
      permits={fetchPermits($propertyId, $validDefaultPropertyToday, {
        all: true,
        tenant: $tenantId,
        policy: $policyId,
        vehicle: $vehicleId,
        space: $spaceId,
        media: $mediaId,
      })}
    /> -->
  {:else if $view == "permits"}
    <PermitsReport
      {property}
      permits={fetchPermits($propertyId, $validDefaultPropertyToday, {
        all: true,
        tenant: $tenantId,
        policy: $policyId,
        vehicle: $vehicleId,
        space: $spaceId,
        media: $mediaId,
      })}
    />

    <!-- {:else if $view == "users"}
     -->
  {:else if $view == "maps"}
    <ViewPropertyMap {property} />
  {:else if $view == "area"}
    <EditPropertyGeofence {property} />
  {:else if $view == "leasing"}
    {#if $propertyId}
      <pre>
        {#await fetchLeasing($propertyId)}
          Loading...
        {:then leasing}
          <code>
        {JSON.stringify(leasing, null, 4)}
        </code>
        {/await}
    </pre>
    {/if}
  {:else if $view == "violations"}
    <PropertyViolations {property} />
  {:else if $view == "revenue"}
    <PropertyRevenue {property} />
  {:else if $view == "settings"}
    <EditProperty {property} />
  {:else if $view == "analytics"}
    <section>
      <PolicySchedule />
      <DateTimeSchedule />
      <PermitsTimeline />
    </section>
  {:else if $view === "metrics"}
    <Metrics />
  {:else if $view == "policymetrics"}
    <PolicyMetrics />
  {:else if $view == "vehicles"}
    <Vehicles
      valid="{formatISO(addDays(new Date(), -30))}/{formatISO(
        addDays(new Date(), 30)
      )}"
      {property}
      permits={fetchPermits(
        $propertyId,
        `${formatISO(addDays(new Date(), -30))}/${formatISO(
          addDays(new Date(), 30)
        )}`
      )}
      violations={fetchViolations(
        $propertyId,
        `${formatISO(addDays(new Date(), -30))}/${formatISO(
          addDays(new Date(), 30)
        )}`
      )}
    />
  {:else if $view == "integration"}
    <!-- <UnitStatus {property} data={$unitStatus} />
    {#if $timeline == "true"}
      <UnitResidentTimeline {property} data={$unitStatus} />
    {/if} -->
  {/if}
  {#if $violationId}
    <ViolationDetails violation={$violation} />
  {/if}
  {#if $permitId}
    <PermitDetails permit={$permit} />
  {/if}
  {#if $policyVersionId}
    <PolicyVersion policy={$policyVersion} />
  {/if}
</main>
<!-- {#if $permit}
    <aside class="">
        <header>
            <Permit permit={$permit} />
        </header>
        <PermitExempt permit={$permit} />
    </aside>
{/if} -->
