<script>
  import { format, utcToZonedTime } from "date-fns-tz";
  import {
    user,
    policyId,
    policies,
    tenantId,
    validDefaultPropertyToday as valid,
    vehicleId,
    permit,
    mediaId,
    media as medias,
    spaces,
    spaceId,
  } from "./stores";
  import { get, capitalize, find, merge, pick } from "lodash-es";
  import { addMinutes, isWithinInterval, parseISO } from "date-fns";
  import { downloadHandler } from "./util/exportTable";
  import ValidInput from "./components/ValidInput.svelte";
  import UnitsTenantsSelect from "./components/UnitsTenantsSelect.svelte";
  import ValidMaxInput from "./components/ValidMaxInput.svelte";
  import ValidMinInput from "./components/ValidMinInput.svelte";
  import PolicySelect from "./components/PolicySelect.svelte";
  import { fetchDeletePermit, fetchUpdatePermit } from "./api";
  import { query } from "./util/router";
  import VehicleSelect from "./components/VehicleSelect.svelte";
  import MediaSelect from "./components/MediaSelect.svelte";
  import SpaceSelect from "./components/SpaceSelect.svelte";

  export let permits = null;
  export let property = null;

  let table = null;

  async function cancel(permit) {
    if (!window.confirm("This is permanent and cannot be undone. Continue?"))
      return;

    const json = await fetchDeletePermit(permit);

    // need to update
    console.log("update", permits, permits.items);
    const previous = find(get(await permits, "items"), { id: permit.id });
    const next = get(json, ["items", permit.id]);
    console.log("update", previous, next);
    if (previous && next) {
      merge(
        previous,
        pick(next, ["cancelled", "valid", "revoked", "updated", "generated"])
      );
      permits = permits;
    }
  }

  async function revoke(permit) {
    if (!window.confirm("This is permanent and cannot be undone. Continue?"))
      return;

    const data = new FormData();
    data.append("valid.maximum", addMinutes(new Date(), -1).toISOString());

    const json = await fetchUpdatePermit(permit, data);

    // need to update
    console.log("update", permits, permits.items);
    const previous = find(get(await permits, "items"), { id: permit.id });
    const next = get(json, ["items", permit.id]);
    console.log("update", previous, next);
    if (previous && next) {
      merge(
        previous,
        pick(next, ["cancelled", "valid", "revoked", "updated", "generated"])
      );
      permits = permits;
    }
  }

  async function exempt(permit) {
    if (!window.confirm("This is permanent and cannot be undone. Continue?"))
      return;

    const data = new FormData();
    data.append("exempt", "true");

    const json = await fetchUpdatePermit(permit, data);

    // need to update
    console.log("update", permits, permits.items);
    const previous = find(get(await permits, "items"), { id: permit.id });
    const next = get(json, ["items", permit.id]);
    console.log("update", previous, next);
    if (previous && next) {
      merge(
        previous,
        pick(next, ["cancelled", "valid", "revoked", "updated", "generated"])
      );
      permits = permits;
    }
  }

  let vehicles = {};

  $: if (permits)
    permits.then((permits) => {
      console.log("permits=", permits);
      for (const permit of Object.values(permits.items)) {
        if (permit.vehicle) vehicles[permit.vehicle.id] = permit.vehicle;
      }
      //vehicles = vehicles;
    });

  $: timezone = property && property.timezone;

  $: console.log(timezone);

  let interval;
  let tenant;
  let policy;
  let vehicle;
  let media;
  let space;

  $: if ($policyId) policy = $policyId;
  $: if ($valid) interval = $valid;
  $: if ($tenantId) tenant = $tenantId;
  $: if ($vehicleId) vehicle = $vehicleId;
  $: if ($mediaId) media = $mediaId;
  $: if ($spaceId) space = $spaceId;

  function update(e) {
    e.preventDefault();
    let form = e.target;

    if (!e.target.checkValidity()) return;

    query({
      valid: interval,
      tenant: tenant,
      policy: policy,
      vehicle: vehicle,
      media: media,
      space: space,
    });
  }

  function processPermits(permits) {
    return permits;
  }
</script>

<form on:submit|preventDefault={update}>
  <fieldset>
    <ul>
      <li>
        <label for="permits-tenant">Unit</label><UnitsTenantsSelect
          id="permits-tenant"
          bind:value={tenant}
        />
      </li>
      <li>
        <label for="permits-policy">Amenity</label><PolicySelect
          id="permits-policy"
          policies={$policies}
          bind:value={policy}
        />
      </li>
      <li>
        <label for="permits-space">Space</label><SpaceSelect
          id="permits-space"
          items={$spaces}
          bind:value={space}
        />
      </li>
      <li>
        <label for="permits-vehicle">Vehicle</label><VehicleSelect
          id="permits-vehicle"
          items={Object.values(vehicles)}
          bind:value={vehicle}
        />
      </li>
      <li>
        <label for="permits-media">Media</label><MediaSelect
          id="permits-media"
          items={Object.values($medias || {})}
          bind:value={media}
        />
      </li>
    </ul>
  </fieldset>
  <fieldset>
    <ul>
      <li>
        <label>From</label><ValidMinInput bind:value={interval} {timezone} />
      </li>
      <li>
        <label>To</label><ValidMaxInput bind:value={interval} {timezone} />
      </li>
    </ul>
  </fieldset>
  <footer>
    <button type="submit">Update</button>
  </footer>
  <a
    download="{get(property, 'name', '')} Permit Log.csv"
    on:click={(e) => downloadHandler(e, table)}>Download Spreadsheet</a
  >
</form>
<!-- <section>
    {#if valid && property}
    <ValidInput value={valid} timezone={property.timezone} />
    {/if}
    <a  download="{get(property, "name", "")} Permit Log.csv" on:click={e => downloadHandler(e, table)}>Download Spreadsheet</a>
</section> -->
{#await permits}
  <figure>Loading&hellip;</figure>
{:then permits}
  <!-- <section>
       <Timeline timezone="{property && property.timezone}" viewpoint={$minuteNow} interval="{validUtc}" permits={permits.items.filter(item => !!item.spaces.find(s => s.id == "tm8ehgm1s14jbf0rgzzpycn0kr"))} />
   </section> -->
  <table
    class="permits"
    data-count={get(permits.items, "length", "")}
    bind:this={table}
  >
    <thead>
      <tr>
        <th scope="col row">Type</th>
        <th scope="col">Policy</th>
        <th scope="col">Vehicle</th>
        <th scope="col">Unit</th>
        <th scope="col">Spaces</th>
        <th scope="col">Media</th>
        <th scope="col">Name</th>
        <th scope="col">Notes</th>
        <th scope="col">Start</th>
        <th scope="col">End</th>
        <th scope="col">Canceled</th>
        <th scope="col">Issued</th>
        <th scope="col">Phone</th>
        <th scope="col">Email</th>
        <th scope="col">Usage Fee</th>
        <th scope="col">Deposit</th>
        <th scope="col">Time Limits</th>
        {#if get($user, "roles.admin", false)}
          <th scope="col" />
          <th scope="col" />
        {/if}
      </tr>
    </thead>
    <tbody>
      {#each permits.items as permit}
        <tr>
          <th scope="row"><h1>{get(permit, "title")}</h1></th>
          <!-- <td><h2>{!!permit.name ? "Person" : `${capitalize(get(permit, "tenant.format", "Unit"))}`}</h2></td> -->
          <td
            >{#if !!permit.policy}
              <data value={permit.policy.id}>{permit.policy.title}</data>
            {/if}</td
          >
          <td
            >{#if !!permit.vehicle}
              <data
                class="{permit.vehicle.type} id {[
                  permit.vehicle.format,
                  permit.vehicle.size,
                ]
                  .filter((i) => !!i)
                  .join(' ')}"
                value={permit.vehicle.id}>{permit.vehicle.display}</data
              >
            {/if}</td
          >
          <td
            >{#if !!permit.tenant}
              <data
                class="{permit.tenant.type} id {[
                  permit.tenant.format,
                  permit.tenant.size,
                ]
                  .filter((i) => !!i)
                  .join(' ')}"
                value={permit.tenant.id}>{permit.tenant.display}</data
              >
            {/if}</td
          >
          <td
            >{#if !!permit.spaces}
              {#each permit.spaces as space}
                <data
                  class="{space.type} id {[space.format, space.size]
                    .filter((i) => !!i)
                    .join(' ')}"
                  value={space.id}>{space.display}</data
                >
              {/each}
            {/if}</td
          >
          <td
            >{#if !!permit.media && !!permit.media.id}
              <data
                class="{permit.media.type} id {[
                  permit.media.format,
                  permit.media.size,
                ]
                  .filter((i) => !!i)
                  .join(' ')}"
                value={permit.media.id}>{permit.media.display}</data
              >
            {/if}</td
          >
          <td><h2>{permit.name || ""}</h2></td>
          <td>{permit.description || ""}</td>
          <td
            ><time datetime={get(permit, "valid.min.utc")}
              >{format(
                utcToZonedTime(get(permit, "valid.min.utc"), permit.timezone),
                "h:mm a EE MMM d yyyy zzz",
                { timeZone: permit.timezone }
              )}</time
            ></td
          >
          <td
            ><time datetime={get(permit, "valid.max.utc")}>
              {#if permit.cancelled}
                Canceled
              {:else if !get(permit, "valid.max.utc")}
                Until Revoked
              {:else}
                {format(
                  utcToZonedTime(get(permit, "valid.max.utc"), permit.timezone),
                  "h:mm a EE MMM d yyyy zzz",
                  { timeZone: permit.timezone }
                )}
              {/if}</time
            ></td
          >
          <td
            ><time datetime={get(permit, "revoked.utc")}
              >{permit.cancelled
                ? format(
                    utcToZonedTime(get(permit, "revoked.utc"), permit.timezone),
                    "h:mm a EE MMM d yyyy zzz",
                    { timeZone: permit.timezone }
                  )
                : ""}</time
            ></td
          >
          <td
            ><time datetime={get(permit, "issued.utc")}
              >{format(
                utcToZonedTime(get(permit, "issued.utc"), permit.timezone),
                "h:mm a EE MMM d yyyy zzz",
                { timeZone: permit.timezone }
              )}</time
            ></td
          >
          <td
            >{get(permit, "contact.tel") || get(permit, "contact.phone", "")}
          </td>
          <td>{get(permit, "contact.email", "")}</td>
          <td>
            <ul>
              {#each permit.attachments.filter((item) => item.type == "fee" && !item.contingent) as fee}
                <data class="fee" value={fee.id}>{fee.total.display}</data>
              {/each}
            </ul>
          </td>
          <td>
            <ul>
              {#each permit.attachments.filter((item) => item.type == "fee" && item.contingent) as fee}
                <data class="fee" value={fee.id}>{fee.total.display}</data>
              {/each}
            </ul>
          </td>
          <td>{get(permit, "valid.exempt", false) ? "Exempt" : "Counted"}</td>
          {#if get($user, "roles.admin", false)}
            <td>
              {#if !permit.cancelled && permit.valid.to && new Date() < new Date(permit.valid.from)}
                <button type="button" on:click={(e) => cancel(permit)}
                  >Cancel</button
                >{:else if !permit.cancelled && (!permit.valid.to || new Date() < new Date(permit.valid.to))}
                <button type="button" on:click={(e) => cancel(permit)}
                  >Revoke</button
                >{:else if !get(permit, "valid.exempt", false)}
                <button type="button" on:click={(e) => exempt(permit)}
                  >Credit Time</button
                >{/if}
            </td>
            <td>
              <button type="button" on:click={(e) => query("permit", permit.id)}
                >Details</button
              >
            </td>
          {/if}
        </tr>
      {/each}
    </tbody>
    <!-- <tfoot>
            <tr>
                <td colspan="20"></td>
            </tr>
        </tfoot> -->
  </table>
{:catch error}
  <p style="color: red">{error.message}</p>
{/await}
