<script>
  import PropertySearch from "./PropertySearch.svelte";
  import PropertySelectItem from "./PropertySelectItem.svelte";
  export let items = null;
  export let multiple = false;
  export let selected = [];
  let results = [];

  let selectedIds = {};

  function handleSelect(property) {
    if (multiple) {
      selectedIds = {
        ...selectedIds,
        [property.id]: property,
      };
    } else {
      selectedIds = { [property.id]: property };
    }
  }

  function handleRemove(property) {
    if (multiple) {
      delete selectedIds[property.id];
      selectedIds = selectedIds;
    } else {
      selectedIds = {};
    }
  }

  $: selected = multiple
    ? Object.values(selectedIds)
    : Object.values(selectedIds)[0];

  //$: selectedIds = selected.map(({ id }) => id);
  $: filteredResults = results.filter(({ id }) => !selectedIds[id]);
</script>

<PropertySearch properties={items} isDefaultEmpty={true} bind:results />
{#if multiple && results}
  <ul>
    {#each filteredResults as item}
      <li>
        <PropertySelectItem
          {item}
          selected={selectedIds[item.id]}
          on:change={(e) => handleSelect(item)}
        />
      </li>
    {/each}
    {#each selected as item}
      <li>
        <PropertySelectItem
          {item}
          selected={selectedIds[item.id]}
          on:change={(e) => handleRemove(item)}
        />
      </li>
    {/each}
  </ul>
{/if}
