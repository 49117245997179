<script>
  import { startOfMonth, subMonths } from "date-fns";
  import { utcToZonedTime } from "date-fns-tz";
  import { propertyTimezone } from "../../stores";
  import { getRevenueForMonth } from "../../util/metrics";
  import SparklineChart from "./SparklineChart.svelte";

  export let data;
  export let title;

  $: now = utcToZonedTime(new Date(), $propertyTimezone);
  $: lastMonth = utcToZonedTime(subMonths(now, 1), $propertyTimezone);
  $: lastMonthStart = utcToZonedTime(
    startOfMonth(lastMonth),
    $propertyTimezone
  );

  $: current =
    data?.values && getRevenueForMonth(data.values, now, $propertyTimezone);
  $: currentTotal =
    current &&
    Object.values(current).reduce((acc, value) => {
      acc += value;
      return acc;
    }, 0);
  $: previous =
    data?.values &&
    getRevenueForMonth(data.values, lastMonthStart, propertyTimezone);
  $: previousTotal =
    previous &&
    Object.values(previous).reduce((acc, value) => {
      acc += value;
      return acc;
    }, 0);
</script>

<tr class="metrics-table-row" on:click>
  <td class="title">{title}</td>
  <td class="this-month">
    {#if currentTotal}
      {`$${currentTotal}`}
    {:else}
      {"—"}
    {/if}
  </td>
  <td class="last-month">
    {#if previousTotal}
      {`$${previousTotal}`}
    {:else}
      {"—"}
    {/if}
  </td>
  <td>
    <SparklineChart
      data={{ current, previous }}
      displayXAxis={false}
      xAxisTitle="month"
    />
  </td>
</tr>
