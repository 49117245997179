<script lang="ts">
  import type { ExpandedProvider } from "@communityboss/api"
  import {
    faChevronDown,
    faChevronUp,
    faPencil,
    faPlus,
    faTrash,
  } from "@fortawesome/free-solid-svg-icons"
  import Fa from "svelte-fa"
  import { fade } from "svelte/transition"
  import CreateServiceForm from "./CreateServiceForm.svelte"
  import ServiceItem from "./ServiceItem.svelte"
  import { state } from "./state"

  export let provider: ExpandedProvider

  let hover = false
  let show_services = false
  let has_services = provider?.services?.length
  let show_create_service_form = false
  let show_edit_name_form = false
  let new_name = provider.name

  function change_name() {
    alert("change name to " + new_name)
    show_edit_name_form = false
    provider.name = new_name
  }
  function cancel_change_name() {
    show_edit_name_form = false
    new_name = provider.name
  }
</script>

<article
  class="py-3 border-b border-base-300"
  on:mouseenter={() => (hover = true)}
  on:mouseleave={() => (hover = false)}
>
  <div class="flex items-center">
    <header class="flex items-center gap-3">
      <button
        class="btn btn-ghost btn-sm btn-circle transition-all"
        class:opacity-0={!hover}
        class:opacity-100={hover}
        on:click={() => (show_services = !show_services)}
        disabled={!has_services}
      >
        <Fa icon={show_services ? faChevronUp : faChevronDown} size="lg" />
      </button>
      {#if show_edit_name_form}
        <form on:submit|preventDefault={change_name}>
          <input
            autofocus
            type="text"
            name="name"
            bind:value={new_name}
            class="input input-bordered mr-4 btn-sm w-64"
          />
          <button
            class="btn btn-primary btn-sm"
            disabled={!new_name || new_name === provider.name}>Save</button
          >
          <button class="btn btn-link btn-sm" on:click={cancel_change_name}
            >Cancel</button
          >
        </form>
      {:else}
        <h1 class="font-bold text-lg py-2">
          {provider.name}
        </h1>
      {/if}
      {#if hover && !show_edit_name_form}
        <button
          class="btn btn-sm btn-link no-animation gap-3"
          transition:fade={{ duration: 150 }}
          on:click={() => (show_edit_name_form = true)}
        >
          <Fa icon={faPencil} />
          Rename
        </button>
      {/if}
    </header>
    <footer class="ml-auto">
      {#if hover}
        <div class="dropdown dropdown-end" transition:fade={{ duration: 150 }}>
          <label tabindex="-1" class="btn btn-outline btn-sm m-1">Actions</label
          >
          <ul
            tabindex="-1"
            class="dropdown-content menu shadow bg-base-100 rounded-sm w-52"
          >
            <li>
              <button
                on:click={() => (show_create_service_form = true)}
                class="gap-3"
              >
                <Fa icon={faPlus} />
                Create service
              </button>
            </li>
            <li>
              <hr class="m-0 p-0" />
            </li>
            <li>
              <button
                class="text-error gap-3"
                on:click={() =>
                  state.send("REMOVE_PROVIDER", { id: provider.id })}
              >
                <Fa icon={faTrash} />
                Remove provider
              </button>
            </li>
          </ul>
        </div>
      {/if}
    </footer>
  </div>
  {#if show_services && has_services}
    <div class="my-4 bg-base-200 px-8 py-6 rounded-md">
      <div class="">
        {#each provider.services as service (service.id)}
          <ServiceItem {service} {provider} />
        {/each}
      </div>
      <p class="mt-6">
        <button
          on:click={() => (show_create_service_form = true)}
          class="btn btn-primary btn-sm gap-3"
        >
          <Fa icon={faPlus} />
          Service
        </button>
      </p>
    </div>
  {/if}

  <CreateServiceForm
    bind:open={show_create_service_form}
    provider_id={provider.id}
  />
</article>
