<script>
  import { fetchCreateNotify as update } from "../api";
  import { policies } from "../stores";

  export let property = null;

  let all = true;
  let type = "all";
  $: enforcement = "all" != type;
  $: permits = "all" != type;
  $: all = "all" == type;

  let submittable = false;
  let submitting = false;

  function change(e) {
    submittable = e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    await update(property, formData);

    submitting = submittable = false;
    all = true;
    type = "all";
    form.reset();
  }
</script>

{#if property}
  <form on:change={change} on:input={change} on:submit={submit}>
    <fieldset>
      <ul>
        <li>
          <label for="new-alert-email">Email</label>
          <input type="email" name="email" placeholder="required" required />
        </li>
        <li>
          <label for="new-alert-all">Trigger</label>
          <select id="new-alert-all" bind:value={type}>
            <option value="all">all events</option>
            <!-- {#each $policies || [] as policy}
              <option value={policy.policy}>{policy.title}…</option>
            {/each} -->
            <option value="">choose…</option>
            <!-- <option value="permit">permits…</option> -->
            <!-- <option value="false">specific events…</option>
          </select> -->
          </select>
          {#if all}
            <input name="all" type="hidden" value="true" />
          {/if}
        </li>
        {#if permits}
          <li>
            <label for="new-alert-permit-policy">Permit</label>
            <select id="new-alert-permit-policy" name="policy">
              <option value="">all policies</option>
              {#each $policies || [] as policy}
                <option value={policy.policy}>{policy.title}</option>
              {/each}
            </select>
            <!-- </li>
          <li> -->
            <!-- <label for="new-alert-permit">Permit</label> -->
            <ul>
              <li>
                <input
                  id="notify-permit"
                  type="checkbox"
                  name="permit"
                  value="true"
                /><label for="notify-permit">all</label>
              </li>
              <li>
                <input
                  id="notify-permit-issued"
                  type="checkbox"
                  name="permit.issued"
                  value="true"
                /><label for="notify-permit-issued">issued</label>
              </li>
              <li>
                <input
                  id="notify-permit-valid-end"
                  type="checkbox"
                  name="permit.valid.end"
                  value="true"
                /><label for="notify-permit-valid-end">expired or revoked</label
                >
              </li>
            </ul>
          </li>
        {/if}
        {#if enforcement}
          <li>
            <label for="new-alert-violations">Violations</label>
            <ul>
              <li>
                <input
                  id="notify-violation"
                  type="checkbox"
                  name="violation"
                  value="true"
                /><label for="notify-violation">all</label>
              </li>
              <li>
                <input
                  id="notify-violation-issued"
                  type="checkbox"
                  name="violation.issued"
                  value="true"
                /><label for="notify-violation-issued">recorded</label>
              </li>
              <li>
                <input
                  id="notify-violation-exception"
                  type="checkbox"
                  name="violation.exception"
                  value="true"
                /><label for="notify-violation-exception"
                  >threshold triggered</label
                >
              </li>
            </ul>
          </li>
        {/if}
        <!-- {#if !all}
          <li>
            <label for="new-alert-temp">Guest</label>
            <ul>
              <li>
                <input
                  id="notify-permit-temporary"
                  type="checkbox"
                  name="permit.temporary"
                  value="true"
                /><label for="notify-permit-temporary">all</label>
              </li>
              <li>
                <input
                  id="notify-permit-temporary-issued"
                  type="checkbox"
                  name="permit.temporary.issued"
                  value="true"
                /><label for="notify-permit-temporary-issued">issued</label>
              </li>
              <li>
                <input
                  id="notify-permit-temporary-valid-end"
                  type="checkbox"
                  name="permit.temporary.valid.end"
                  value="true"
                /><label for="notify-permit-temporary-valid-end"
                  >expired or revoked</label
                >
              </li>
            </ul>
          </li>
          <li>
            <label for="new-alert-exempt">Special</label>
            <ul>
              <li>
                <input
                  id="notify-permit-temporary-exempt"
                  type="checkbox"
                  name="permit.temporary.exempt"
                  value="true"
                /><label for="notify-permit-temporary-exempt">all</label>
              </li>
              <li>
                <input
                  id="notify-permit-temporary-exempt-isued"
                  type="checkbox"
                  name="permit.temporary.exempt.issued"
                  value="true"
                /><label for="notify-permit-temporary-exempt-isued"
                  >issued</label
                >
              </li>
              <li>
                <input
                  id="notify-permit-temporary-exempt-valid-end"
                  type="checkbox"
                  name="permit.temporary.exempt.valid.end"
                  value="true"
                /><label for="notify-permit-temporary-exempt-valid-end"
                  >expired or revoked</label
                >
              </li>
            </ul>
          </li>
          <li>
            <label for="new-alert-continuous">Assigned</label>
            <ul>
              <li>
                <input
                  id="notify-permit-continuous"
                  type="checkbox"
                  name="permit.continuous"
                  value="true"
                /><label for="notify-permit-continuous">all</label>
              </li>
              <li>
                <input
                  id="notify-permit-continuous-issued"
                  type="checkbox"
                  name="permit.continuous.issued"
                  value="true"
                /><label for="notify-permit-continuous-issued">issued</label>
              </li>
              <li>
                <input
                  id="notify-permit-continuous-valid-end"
                  type="checkbox"
                  name="permit.continuous.valid.end"
                  value="true"
                /><label for="notify-permit-continuous-valid-end"
                  >expired or revoked</label
                >
              </li>
            </ul>
          </li>
          <li>
            <label for="new-alert-violations">Violations</label>
            <ul>
              <li>
                <input
                  id="notify-violation"
                  type="checkbox"
                  name="violation"
                  value="true"
                /><label for="notify-violation">all</label>
              </li>
              <li>
                <input
                  id="notify-violation-issued"
                  type="checkbox"
                  name="violation.issued"
                  value="true"
                /><label for="notify-violation-issued">recorded</label>
              </li>
              <li>
                <input
                  id="notify-violation-exception"
                  type="checkbox"
                  name="violation.exception"
                  value="true"
                /><label for="notify-violation-exception"
                  >threshold triggered</label
                >
              </li>
            </ul>
          </li>
        {/if} -->
      </ul>
    </fieldset>

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Add Notifications"}</button
      >
    </footer>
  </form>
{/if}
