<script>
  import { media } from "./stores";
  import { fetchUpdatePolicy } from "./api";
  import {
    get,
    range,
    filter,
    map,
    size,
    each,
    clone,
    find,
    uniq,
  } from "lodash-es";

  export let policy = null;
  export let readonly = false;

  let submittable = false;
  let submitting = false;

  let editingPolicy = null;

  //$: editingPolicy = policy;
  $: if (!!policy && (!editingPolicy || !submittable))
    editingPolicy = { ...policy };

  $: selectedItems = map(get(editingPolicy, "media.colors", {}), (item) =>
    item.toLowerCase()
  );
  $: console.log(selectedItems);
  $: allItems = selectedItems.length === 0;

  $: colors = uniq(
    ["blue", "red", "purple", "green", "black", "yellow"]
      .concat(map($media || [], "color"))
      .filter((item) => !!item)
      .map((item) => item.toLowerCase())
  );

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (!editingPolicy || submitting || !submittable) return;

    submitting = true;

    const form = e.target;

    const formData = new FormData(form);

    await fetchUpdatePolicy(editingPolicy, formData);

    submitting = submittable = false;
    editingPolicy = null;
  }
</script>

<form on:change={change} on:input={change} on:submit={submit}>
  <header>
    <h1>{get(editingPolicy, "media.title", "Smart Decal")}s</h1>
  </header>

  <input type="hidden" name="permit.media.colors" value="" />

  <fieldset disabled={readonly && "disabled"}>
    <ul>
      <li>
        <label for="policy-field-media">Media</label>
        <select
          id="policy-field-media"
          name="permit.media"
          value={get(editingPolicy, "media.required")
            ? "true"
            : get(editingPolicy, "media.request")
            ? "false"
            : ""}
        >
          <option value="true">required</option>
          <option value="false">optional</option>
          <option value="">disabled</option>
        </select>
      </li>
      <li>
        <label for="policy-media-donotpermitblock">Banned</label>
        <select
          id="policy-media-donotpermitblock"
          name="permit.media.donotpermit.reject"
          value={get(editingPolicy, "media.donotpermit.reject", "true") + ""}
        >
          <option value="false">allow</option>
          <option value="true">block</option>
        </select>
      </li>
      <li>
        <label for="policy-media-repermit">Re-permit</label>
        <select
          id="policy-media-repermit"
          name="permit.media.repermit"
          value={get(editingPolicy, "media.repermit", false) + ""}
        >
          <option value="true">allow</option>
          <option value="false">not allowed</option>
        </select>
      </li>
      <li>
        <label for="policy-media-colors">Colors</label>
        <select
          id="policy-media-colors"
          on:blur={(e) => {
            allItems = e.target.value === "true";
          }}
          on:change={(e) => {
            allItems = e.target.value === "true";
          }}
          value={allItems + ""}
        >
          <option value="true">all colors</option>
          <option value="false">select colors…</option>
        </select>
      </li>
      {#if !allItems}
        <li>
          <label for="policy-media-colors-items">Selected</label>
          {#if !allItems}
            <ul>
              {#each colors as item}
                <li>
                  <input
                    id="media-options-{item}"
                    type="checkbox"
                    name="permit.media.colors"
                    checked={selectedItems.length === 0 ||
                      selectedItems.includes(item)}
                    value={item}
                  /> <label for="media-options-{item}">{item}</label>
                </li>
              {/each}
            </ul>
          {/if}
        </li>
      {/if}
    </ul>
  </fieldset>

  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Saving" : "Save"}</button
    >
  </footer>
</form>
