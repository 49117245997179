<script>
  import { fetchUpdateProperty } from "./api";
  import { get, clone } from "lodash-es";

  export let property = null;

  let editing = null;

  let submittable = false;
  let submitting = false;

  // save the first one we get
  $: if (!!property && (!submittable || !editing)) editing = { ...property };

  $: patrollers = !!get(editing, "users.patrollers.enabled");

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }

  async function submit(e) {
    e.preventDefault();

    if (submitting || !submittable || !editing) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    // disable?

    const newPolicy = await fetchUpdateProperty(editing, formData);

    submitting = submittable = false;
    editing = null;
  }
</script>

{#if editing}
  <form
    on:change={change}
    on:input={change}
    on:submit={submit}
    class="edit property"
  >
    <header>
      <h1>Enforcement</h1>
    </header>

    <fieldset>
      <ul>
        <li>
          <label for="property-patrollers">Patrollers</label>
          <select
            id="property-patrollers"
            name="users.patrollers.enabled"
            value={patrollers + ""}
            on:blur={(e) => (patrollers = e.target.value === "true")}
            on:change={(e) => (patrollers = e.target.value === "true")}
          >
            <option value="true">enabled</option>
            <option value="false">disabled</option>
          </select>
        </li>
        <li>
          <label for="property-vehicles-lpr">LPR</label>
          <select
            id="property-vehicles-lpr"
            name="vehicles.recognition.enabled"
            value={get(editing, "vehicles.recognition.enabled", false) + ""}
          >
            <option value="true">enabled</option>
            <option value="false">disabled</option>
          </select>
        </li>
        <li>
          <label for="property-enforcement-units">Unit Lookup</label>
          <select
            id="property-enforcement-units"
            name="enforcement.units"
            value={editing?.enforcement.units + ""}
          >
            <option value="true">enabled</option>
            <option value="false">disabled</option>
          </select>
        </li>
        <li>
          <label for="property-enforcement-checks">Checks/week</label>
          <input
            type="number"
            name="enforcement.target.minimum"
            min="0"
            value={editing?.enforcement.target.minimum}
          />
        </li>
      </ul>
    </fieldset>

    {#if patrollers}
      <fieldset>
        <p>Patrollers & permit contact info:</p>
        <ul>
          <li>
            <label for="property-patrollers-temporary">Temporary</label>
            <select
              id="property-patrollers-temporary"
              name="users.patrollers.temporary"
              value={get(editing, "users.patrollers.temporary") + ""}
            >
              <option value="true">enabled</option>
              <option value="false">disabled</option>
            </select>
          </li>
          <li>
            <label for="property-patrollers-assigned">Assigned</label>
            <select
              id="property-patrollers-assigned"
              name="users.patrollers.assigned"
              value={get(editing, "users.patrollers.continuous") + ""}
            >
              <option value="true">enabled</option>
              <option value="false">disabled</option>
            </select>
          </li>
          {#if get(editing, "tenants.enabled")}
            <li>
              <label for="property-patrollers-assigned">Units</label>
              <select
                id="property-patrollers-assigned"
                disabled
                value={get(editing, "users.patrollers.tenants") + ""}
              >
                <option value="true">enabled</option>
                <option value="false">disabled</option>
              </select>
            </li>
          {/if}
        </ul>
      </fieldset>
    {/if}

    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save"}</button
      >
    </footer>
  </form>
{/if}
