<script>
  import { fetchCreateUser } from "./api";
  import PropertySelect from "./components/PropertySelect.svelte";
  import { properties as allProperties } from "./stores";

  export let property = null;

  let submittable = false;
  let submitting = false;
  let properties = [];

  function change(e) {
    submittable =
      e.target.form &&
      e.target.form.checkValidity() &&
      (!!property || properties.length);
  }

  async function submit(e) {
    e.preventDefault();
    if (submitting || !submittable) return;

    const form = e.target;
    const formData = new FormData(form);
    submitting = true;

    await fetchCreateUser(
      form.elements["email"].value,
      form.elements["role"].value,
      property,
      ...properties
    );

    submitting = submittable = false;
    form.reset();
    properties = [];
  }
</script>

<form
  class="new-user-form"
  on:change={change}
  on:input={change}
  on:submit={submit}
>
  <fieldset>
    <!-- <p>User:</p> -->
    <ul>
      <li>
        <label for="new-user-email">Email</label><input
          id="new-user-email"
          name="email"
          type="email"
          placeholder="required"
          required
          multiple
        />
      </li>
      <li>
        <label for="new-user-role">Access</label><select
          id="new-user-role"
          name="role"
        >
          <option value="user">view only</option>
          <option value="patrol">enforcement</option>
          <option value="admin">full access</option>
        </select>
      </li>
    </ul>
  </fieldset>
  {#if !property}
    <fieldset>
      <p>Properties:</p>
      <PropertySelect
        items={$allProperties}
        bind:selected={properties}
        multiple={true}
      />
    </fieldset>
  {/if}
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Saving" : "Grant Access"}</button
    >
  </footer>
</form>
