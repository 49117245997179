<script>
  import { filter, get } from "lodash-es";
  import { fetchDeleteUser } from "./api";
  import { userId } from "./stores";

  export let authorized = null;
  export let property = null;

  let submittable = false;
  let submitting = false;

  let remove = {};
  $: if (filter(remove).length > 0) submittable = true;

  function change(e) {
    submittable = filter(remove).length > 0;
  }

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    await fetchDeleteUser(property.id, formData);

    submitting = submittable = false;
    remove = {};
  }
</script>

{#if property}
  <!-- <NewUser property={property} /> -->
  <form on:submit={submit} on:change={change}>
    <ul class="users" data-count={get(authorized, "length", "")}>
      {#if authorized}
        {#each authorized as auth}
          <li>
            {#if auth.id !== $userId}
              <input
                type="checkbox"
                name="email"
                value={auth.user.email}
                checked={!!remove[auth.user.email]}
                on:change={(e) => (remove[e.target.value] = e.target.checked)}
              />
            {/if}
            <data
              class="role"
              value={auth.roles.admin
                ? "admin"
                : auth.roles.patrol
                ? "patrol"
                : "user"}
              >{auth.roles.admin
                ? "Full Access"
                : auth.roles.patrol
                ? "Enforcement"
                : "View Only"}</data
            >
            <h1>
              <a
                href="https://my.parkingboss.com/users/{auth.user.id}/locations"
                target="_blank"
                rel="noreferrer"
                ><data class="user contact">{auth.user.display}</data></a
              >
            </h1>
            <a href="mailto:{auth.user.email}">{auth.user.email}</a>
          </li>
        {/each}
      {/if}
    </ul>
    {#if !!get(authorized, "length") && (submitting || submittable)}
      <footer>
        <button type="submit" disabled={!submittable || submitting}
          >{submitting ? "Saving" : "Revoke Access"}</button
        >
      </footer>
    {/if}
  </form>
{/if}
