<script lang="ts">
  import { policies } from "$stores";
  import debug from "debug";
  import CSVTable from "./CSVTable.svelte";
  import Errors from "./Errors.svelte";
  import UploadForm from "./UploadForm.svelte";
  import { UploadFormViewModel } from "./stores";

  const log = debug("boss:components:import-permits:ImportPermits");

  const view_model = new UploadFormViewModel();

  // Pass in all admin policies so we can associated them with the
  // newly created permits.
  $: {
    if ($policies?.length)
      view_model.policies = $policies.filter(
        (policy) => policy?.audience?.admin
      );
  }

  $: log("view_model state:", $view_model);
</script>

<section>
  <Errors {view_model} />
  <CSVTable {view_model} />
  <UploadForm {view_model} />
</section>
