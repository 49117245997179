<script>
  import {
    onMount,
    onDestroy,
    getContext,
    createEventDispatcher,
  } from "svelte";
  import { key, MapboxDraw } from "./mapbox";
  import DrawRectangle from "mapbox-gl-draw-rectangle-mode";

  const dispatch = createEventDispatcher();

  // assuming all MAPVIEW gl js/access/css is running

  export let geojson = null;
  export let selected = [];
  export let mode = null;
  export let deleted = null;
  export let changed = null;
  export let controls = false;
  export let geofence = false;

  $: deleteFeaturesEnabled = typeof deleted == "function";

  const { getMap } = getContext(key);
  const map = getMap();
  const container = map.getContainer();

  var draw = new MapboxDraw({
    displayControlsDefault: false,
    keybindings: false,
    modes: { ...MapboxDraw.modes, draw_rectangle: DrawRectangle },
    controls: {
      polygon: controls,
      trash: controls,
    },
  });

  // block deletion?

  map.addControl(draw, "bottom-right");

  let loaded = false;
  // let vertice = false;
  let points = [];

  if (map.loaded()) initDraw();
  else map.on("load", initDraw);

  function initDraw() {
    loaded = true;
  }

  $: if (loaded && geojson)
    draw.set({
      type: geojson.type,
      features: geojson.features,
    });

  $: if (loaded && mode && mode != draw.getMode()) draw.changeMode(mode);

  map.on("draw.selectionchange", function (data) {
    console.log("draw.selectionchange=", data);
    selected = data.features;
    points = data.points;
  });

  map.on("draw.update", function (data) {
    console.log("draw.update=", data);
    if (typeof changed == "function")
      changed(geofence ? draw.getAll().features : data.features);
    else dispatch("change", data.features);
    // selected = data.features;
    // points = data.points;
  });
  map.on("draw.create", function (data) {
    console.log("draw.create=", data);
    if (typeof changed == "function")
      changed(geofence ? draw.getAll().features : data.features);
    else dispatch("change", data.features);
    // selected = data.features;
    // points = data.points;
  });
  map.on("draw.delete", function (data) {
    console.log("draw.delete=", data);
    //dispatch("delete", data.features);
    if (typeof deleted == "function")
      deleted(geofence ? draw.getAll().features : data.features);
    else if (typeof changed == "function")
      changed(geofence ? draw.getAll().features : data.features);
    // selected = data.features;
    // points = data.points;
  });

  map.on("draw.modechange", function (data) {
    console.log("mode change=", data);
    if (data.mode && data.mode != mode) mode = data.mode;
    //vertice = (data.mode == "direct_select"); // just went into vertice mode
  });

  // 8 - Backspace
  // 46 - Delete
  function keydown(event) {
    if ((event.srcElement || event.target).classList[0] !== "mapboxgl-canvas")
      return; // we only handle events on the map

    if (event.keyCode === 8 || event.keyCode === 46) {
      event.preventDefault();

      // only trash in certain circumstances
      // if(!points || !points.length) return;
      // if(!selected || !selected.length) return;
      // if(selected[0].geometry.type == "Point") return;
      // if(selected[0].geometry.type == "Polygon" && selected[0].geometry.coordinates[0].length - points.length < 4) return; // there would be less than three points left (4th is the beginning)
      // if(selected[0].geometry.type == "LineString" && selected[0].geometry.coordinates[0].length - points.length < 2) return;

      console.log("starting delete");

      if (!selected || !selected.length) return; // nothing to delete

      // only allow deleting features if setup to accept
      if (!deleteFeaturesEnabled) {
        console.log("deleting features not enabled");
        if (mode == "simple_select") return;

        if (!points || !points.length) return; // no points to delete and we're not allowing deleting features

        console.log("checking delete points", points.length, points);

        for (const feature of selected) {
          if (feature.geometry.type == "Point") return; // can't delete point feature
          const pointsToCheck =
            feature.geometry.type == "Polygon"
              ? feature.geometry.coordinates[0].slice(0, -1)
              : feature.geometry.coordinates;
          const matchingPoints = pointsToCheck.reduce((count, pointA) => {
            for (const pointB of points) {
              if (
                pointA[0] == pointB.geometry.coordinates[0] &&
                pointA[1] == pointB.geometry.coordinates[1]
              )
                return count + 1;
            }

            return count;
          }, 0);

          console.log(
            "checking points of feature",
            feature,
            pointsToCheck.length,
            matchingPoints,
            pointsToCheck
          );

          if (
            pointsToCheck.length - matchingPoints <
            (feature.geometry.type == "Polygon" ? 3 : 2)
          )
            return; // too few points remaining
        }
      }

      draw.trash();
    }
  }

  // events.keyup = function(event) {
  //   if (isKeyModeValid(event.keyCode)) {
  //     currentMode.keyup(event);
  //   }
  // };

  onMount(async () => {
    const styleUrl = `https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-draw/v1.2.0/mapbox-gl-draw.css`;

    if (document.head.querySelector(`link[href='${styleUrl}']`)) {
    } else {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      //link.href = 'https://unpkg.com/mapbox-gl/dist/mapbox-gl.css';
      link.href = styleUrl;

      // link.onload = async () => {

      // };

      document.head.appendChild(link);
    }

    container.addEventListener("keydown", keydown);
  });

  onDestroy(function () {
    container.removeEventListener("keydown", keydown);
  });
</script>
