import { items } from "@parkingboss/svelte-utils";
import {
  addDays,
  addMonths,
  differenceInCalendarMonths,
  endOfDay,
  parseISO,
  startOfDay,
  startOfMonth,
} from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import debug from "debug";
import { find, get, has, map, merge, range } from "lodash-es";
import { derived, readable, writable } from "svelte/store";
import {
  api,
  fetchAndStoreAllProperties,
  fetchAndStoreMedia,
  fetchAndStoreNotify,
  fetchAndStorePermitsValid,
  fetchAndStorePrices,
  fetchAndStorePropertyPolicies,
  fetchAndStoreSpaces,
  fetchAndStoreUnits,
  fetchAndStoreUsers,
  fetchPaymentMetrics,
  fetchPermit,
  fetchPolicy,
  fetchUnitStatus,
  fetchUserValidForProperty,
  fetchViolation,
  resolveProperty,
} from "./api";
import { auth } from "./util/auth";
import { param, params } from "./util/params";
//import { client as mqtt } from "./mqtt";

const log = debug("boss:stores");

//log("apiBase=", api.settings.apiBase);

auth.subscribe((auth) => auth && fetchAndStoreAllProperties());

const comparer =
  !!window.Intl && !!window.Intl.Collator
    ? new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
        caseFirst: "lower",
      }).compare
    : (a, b) => (a < b ? -1 : a > b ? 1 : 0);

export const minuteNow = readable(new Date(), (set) => {
  set(new Date());
  const i = setInterval(() => set(new Date()), 60 * 1000);
  return () => clearInterval(i);
});
export const secondNow = readable(new Date(), (set) => {
  set(new Date());
  const i = setInterval(() => set(new Date()), 1000);
  return () => clearInterval(i);
});

export const timeStore = function (seconds) {
  return readable(new Date(), (set) => {
    set(new Date());
    const i = setInterval(() => set(new Date()), seconds * 1000);
    return () => clearInterval(i);
  });
};

export const userId = derived(auth, ($auth) => $auth && $auth.id);

export const state = items;

export const policyId = param("policy");
export const permitId = param("permit");
export const violationId = param("violation");

export const total = param("total");

// permitId.subscribe(async value => {

//     if(!value) return;

//     // permit id changed
//     await fetchAndStorePermits([ value ]);

// });

// conditional derive
export const propertyId = param("property", true);

export const vehicleId = param("vehicle");
export const spaceId = param("space");
export const tenantId = param("tenant");
export const mediaId = param("media");

export const view = param("view");
export const valid = param("valid");
export const search = param("search");

export const token = derived(api.user, ($user) => $user.token);

//user.subscribe($user => log("user=", $user));

export const validDefaultNow = (function () {
  let value = null;
  return derived([valid, minuteNow], ([valid, now], set) => {
    if (!valid)
      return set((value = `${now.toISOString()}/${now.toISOString()}`));
    if (valid != value) return set((value = valid));
  });
})();

export const validDefaultToday = (function () {
  let value = null;
  return derived([valid, minuteNow], ([valid, now], set) => {
    if (!valid)
      return set(
        (value = `${format(now, "yyyy-MM-dd")}T00:00:00/${format(
          now,
          "yyyy-MM-dd"
        )}T23:59:59`)
      );
    if (valid != value) return set((value = valid));
  });
})();

// background update watcher
export const updated = writable({});
let subscribedPropertyId = null;
//setData(root, "mqtt", "connecting");
const mqttConnection = null;
// const mqttConnection = mqtt("AKIAIUPPRVWKBYHY4UWQ", "GQQeZRDLfbR9JpVeIuAJzcAOgSlaJXABCRsqR3M8", {
//     message: json => {
//         log("mqtt.message=", json);
//         //updated.set(json);
//         updated.set({
//             scope:subscribedPropertyId,
//             type: Object.keys(json)[0],
//             updated: json[Object.keys(json)[0]],
//         });
//     },
//     connect: () => setData(root, "mqtt", "online"),
//     reconnect: () => setData(root, "mqtt", "connecting"),
//     disconnect: () => setData(root, "mqtt", "offline"),
//     offline: () => setData(root, "mqtt", "offline"),
//     close: () => setData(root, "mqtt", "offline"),

// });

propertyId.subscribe((propertyId) => {
  if (!propertyId) return;
  if (subscribedPropertyId == propertyId) return;

  // propertyId changed...

  // unsubscribe
  if (!!subscribedPropertyId && mqttConnection)
    mqttConnection.unsubscribe(`locations/${subscribedPropertyId}`);

  // subscribe
  if (mqttConnection)
    mqttConnection.subscribe(
      `locations/${(subscribedPropertyId = propertyId)}`
    );
});

// updated.subscribe(value => {
//     if(!value || !value.scope) return;
//     fetchAndStorePropertyPolicies(value.scope);
// });

// refresh permits on property change

async function updateProperty(property) {
  fetchAndStorePropertyPolicies(property);
  fetchAndStoreUnits(property, "*/*");
  fetchAndStoreSpaces(property);
  fetchAndStorePrices(property);
  fetchAndStoreMedia(property);
  fetchAndStoreNotify(property);
}

// once you're on a property, assumed until the actual value changes
// store previous value and use value equality to prevent frothy requests
let permitsRefresher = null;
let propertyRefresher = null;
let previousPropertyId = null;
propertyId.subscribe(async (value) => {
  //if(!!propertyRefresher) clearInterval(propertyRefresher); // alway stop the scheduler

  if (!value) return; // don't do anything, but keep previous value cached - leave refreshers active

  if (value === previousPropertyId) return; // the assignment changed, but not the actual value;

  // we have a new ID
  if (!!propertyRefresher) clearInterval(propertyRefresher); // stop the previous scheduler
  if (!!permitsRefresher) clearInterval(permitsRefresher); // stop the previous scheduler

  previousPropertyId = value;

  //log("propertyId changed=", value);

  updateProperty(value);
  fetchAndStorePermitsValid(value);

  propertyRefresher = setInterval(() => updateProperty(value), 10 * 60 * 1000);
  permitsRefresher = setInterval(
    () => fetchAndStorePermitsValid(value),
    5 * 60 * 1000
  );

  // log("writing propertyId to list", value);
  // propertyIds.update(prev => merge(prev, {
  //     [value]: new Date().toISOString(),
  // }));
});

// updated.subscribe(latest => {

// });

const _tenantsByUnit = writable({});

state.subscribe(($state) => {
  if (
    !$state ||
    !$state.tenants ||
    !$state.tenants.items ||
    !$state.units ||
    !$state.units.items
  )
    return; // no changes

  // run the update
  _tenantsByUnit.update(($value) => {
    return Object.values($state.tenants.items)
      .map((item) => $state[item] || item)
      .reduce(
        (map, item) => {
          if (!map[item.subject]) return map;

          (map[item.subject]["tenants"] = map[item.subject]["tenants"] || {})[
            item.id
          ] = item;

          return map;
        },
        Object.keys($state.units.items).reduce((map, id) => {
          if (!map[id] && $state[id])
            map[id] = {
              unit: $state[id],
            };
          return map;
        }, $value)
      );
  });
});

export { _tenantsByUnit as tenantsByUnit };

// export const tenantsByUnit = derived([propertyId, state ], async ([$propertyId, $state], set) => {
//     if(!$propertyId) return set(null);

//     if(!$state || !$state.tenants || !$state.tenants.items || !$state.units || !$state.units.items) return; // no changes

//     //const $state = await fetchUnits($propertyId, "*/*");

//     log("state.units", $state.units, "state.tenants", $state.tenants);

//     return set(Object.values($state.tenants.items).map(item => $state[item] || item).reduce((map, item) => {

//         if(!map[item.subject]) return map;

//         (map[item.subject]["tenants"] = map[item.subject]["tenants"] || []).push(item);

//         return map;

//     }, Object.values($state.units.items).reduce((map, item) => {

//         if(!map[item]) map[item] = {
//             unit: $state[item],
//         };
//         return map;

//     }, _tenantsByUnit)));

// });

export const properties = derived([state], ([$state]) => {
  if (!has($state, "allproperties")) return null;

  var items = map(get($state, "allproperties.items"), (item, id) => {
    item = resolveProperty(id, $state);
    if (item)
      if(item.rep) item.rep = $state[item.rep] || item.rep;
      item.revenue = Object.assign(
        {},
        item.revenue,
        get($state, ["billing", "items", item.id])
      );

    /*

        if($state.metrics && $state.metrics.items) {

            item.revenue = $state.metrics.items.reduce((latest, metric) => {

                if(metric.source != "payments" || metric.group != "property" || metric.property != item.id || metric.payments != "total" || metric.intervals != "datetimes") return latest;

                return metric;

                // {
                //     "generated": "2021-05-11T18:34:59.576Z",
                //     "interval": "2021-04-11T14:30:21.173-04:00/",
                //     "duration": "P2914168DT5H29M38.8269999S",
                //     "type": "metric",
                //     "metric": "cents",
                //     "currency": "usd",
                //     "count": "cumulative",
                //     "source": "payments",
                //     "payments": "total",
                //     "intervals": "datetimes",
                //     "group": "property",
                //     "property": "5phd1j1k1h76s72d08kbm2n1bc",
                //     "timezone": "America/New_York",
                //     "values": {
                //     "2021-04-11T14:30:21.173-04:00/": 190400
                //     }
                //     },

            }, null);

        }
        */

    return item;
  });

  return items.sort((a, b) => comparer(a.name, b.name));
});
//properties.subscribe($value => log("properties=", $value));

export const paymentmetrics = derived([valid], async ([$valid], set) => {
  set(null);

  if (!$valid) return set(null);

  // fetch the payment metrics

  var json = await fetchPaymentMetrics($valid, {
    property: "*",
    metrics: ["total", "policy", "property"],
    payments: ["total", "gateway", "net", "creditable", "service", "tax"],
  });

  log("paymentmetrics:", json);
  set(json);
});

export const propertiesPaymentMetrics = readable(null, (set) => {
  //set(null);

  const now = new Date();

  var start = "2021-01-01T00:00:00"; //format(
  //   addMonths(startOfMonth(now), -14),
  //   "yyyy-MM-dd'T'00:00:00"
  // )

  const interval = `${start}/${format(
    addMonths(startOfMonth(now), 1),
    "yyyy-MM-dd'T'00:00:00"
  )}`;

  const diff = differenceInCalendarMonths(parseISO(start), now);
  console.log("months=", diff);

  const months = range(diff, 1, 1)
    .map((i) => [
      addMonths(startOfMonth(now), i),
      addMonths(startOfMonth(now), i + 1),
    ])
    .map(
      ([min, max]) =>
        `${format(min, "yyyy-MM-dd'T'00:00:00")}/${format(
          max,
          "yyyy-MM-dd'T'00:00:00"
        )}`
    )
    .map((valid) =>
      fetchPaymentMetrics(valid, {
        property: "*",
        //datetimes: ["P1D", "P1M"],
        metrics: ["total", "property"],
        payments: ["total", "gateway", "net", "creditable", "service", "tax"],
        //payments: ["total", "net"],
      }).then((json) => {
        json.metrics.items.forEach((item) => {
          if (item.property)
            item.property = json.items[item.property] || item.property;
          item.datetimes = "P1M";
          item.interval = valid;
          return item;
        });
        return json;
      })
    );
  // const weeks = range(-12, 1, 1)
  // .map((i) => [
  //   addMonths(startOfWeek(new Date()), i),
  //   addMonths(startOfWeek(new Date()), i + 1),
  // ])
  // .map(
  //   ([min, max]) =>
  //     `${format(min, "yyyy-MM-dd'T'00:00:00")}/${format(
  //       max,
  //       "yyyy-MM-dd'T'00:00:00"
  //     )}`
  // )
  // .map((valid) =>
  //   fetchPaymentMetrics(valid, {
  //     property: "*",
  //     //datetimes: ["P1D", "P1M"],
  //     metrics: ["total", "policy", "property"],
  //     payments: ["total", "gateway", "net", "creditable", "service"]
  //     //payments: ["total", "net"],
  //   }).then(json => {
  //     json.metrics.items.forEach(item => item.datetimes = "P1W")
  //     return json;
  //   })
  // );
  // const days = range(-30, 1, 1)
  // .map((i) => [
  //   addMonths(startOfDay(new Date()), i),
  //   addMonths(startOfDay(new Date()), i + 1),
  // ])
  // .map(
  //   ([min, max]) =>
  //     `${format(min, "yyyy-MM-dd'T'00:00:00")}/${format(
  //       max,
  //       "yyyy-MM-dd'T'00:00:00"
  //     )}`
  // )
  // .map((valid) =>
  //   fetchPaymentMetrics(valid, {
  //     property: "*",
  //     //datetimes: ["P1D", "P1M"],
  //     metrics: ["total", "policy", "property"],
  //     payments: ["total", "gateway", "net", "creditable", "service"]
  //     //payments: ["total", "net"],
  //   }).then(json => {
  //     json.metrics.items.forEach(item => item.datetimes = "P1D")
  //     return json;
  //   })
  // );

  Promise.all([...months])
    .then((items) =>
      items.reduce((json, json1) => {
        return Object.assign(json, json1, {
          metrics: {
            interval,
            items: (json.metrics?.items || []).concat(
              json1.metrics.items || []
            ),
          },
        });
      }, {})
    )
    .then(set);

  //   const json = (await Promise.all(byInterval)).reduce((json, json1) => {
  //     return Object.assign(json, json1, {
  //       metrics: {
  //         items: (json.metrics?.items || []).concat(json1.metrics.items?.map(i => {
  //           i.datetimes = "P1M";
  //           return i;
  //         }) || []),
  //       },
  //     });
  //   }, {});

  // fetchPaymentMetrics(
  //   `${format(addMonths(now, -3), "yyyy-MM-01'T'00:00:00")}/${format(
  //     addDays(now, 1),
  //     "yyyy-MM-dd'T'00:00:00"
  //   )}`,
  //   {
  //     property: "*",
  //     datetimes: ["P1D", "P1W", "P1M"],
  //     metrics: ["total"],
  //     payments: ["total", "service", "net", "gateway"],
  //   }
  // ).then(set);

  return () => {};
});

export const property = derived([propertyId, state], ([id, items]) =>
  resolveProperty(items[id], items)
);

export const user = derived(
  [api.user, propertyId],
  async ([$user, $propertyId], set) => {
    if (!$user || !$propertyId) return; // no stuff
    var json = await fetchUserValidForProperty($propertyId);
    var user = get(json, ["items", get(json, "users.item")]);
    if (!!user) user.system = get(json, "authorizations.system", false);
    // not a system admin, need to check if user of this location
    if (!!user && !!$propertyId && !user.system) {
      const auth = find(
        map(
          get(json, "authorizations.items", []),
          (val) => get(json, ["items", val]) || val
        ),
        {
          subject: get(json, "authorizations.scopes[0]"),
          principal: user.id,
        }
      );
      if (!auth) return set(null); // no property user -- return?

      // add check for is valid
      user.roles = auth.roles;
    }
    if (!!user && !!user.system)
      user.roles = {
        admin: true,
        system: true,
      };
    set(user);
  }
);

function metered(item, state) {
  if (!item) return;
  const value =
    get(state, ["metered", "for", item.id]) ||
    get(state, ["metered", "for", item.subject]);
  if (!value) return value;

  Object.values(value.meters.items || value.meters).map(function (meter) {
    // assuming subjects is a { k, v }
    // meter.subjects = Object.values(meter.subjects).reduce((result, subject) => {
    //     if(typeof subject != "string") return result;
    //     //log(subject, result);
    //     if(!state[subject]) return result;
    //     result[subject] = state[subject]?.title || subject;
    //     return result;
    // }, meter.subjects);
    if (meter.subjects)
      for (const [k, v] of Object.entries(meter.subjects)) {
        meter.subjects[k] = state[v] || v;
      }
    if (meter.subject) meter.subject = state[meter.subject] || meter.subject;
    if (meter.principals)
      for (const [k, v] of Object.entries(meter.principals)) {
        meter.principals[k] = state[v] || v;
      }
    return meter;
  });

  // log("metered=", value);

  return value;
}

function pricing(item, state) {
  if (!item) return;
  const value =
    get(state, ["pricing", "for", item.id]) ||
    get(state, ["pricing", "for", item.subject]);
  if (!value) return value;

  Object.values(value.prices.items || value.prices).map(function (price) {
    // assuming subjects is a { k, v }
    // meter.subjects = Object.values(meter.subjects).reduce((result, subject) => {
    //     if(typeof subject != "string") return result;
    //     //log(subject, result);
    //     if(!state[subject]) return result;
    //     result[subject] = state[subject]?.title || subject;
    //     return result;
    // }, meter.subjects);
    if (price.subjects)
      for (const [k, v] of Object.entries(price.subjects)) {
        price.subjects[k] = state[v]?.title || v;
      }
    if (price.principals)
      for (const [k, v] of Object.entries(price.principals)) {
        price.principals[k] = state[v] || v;
      }
    return price;
  });

  // log("metered=", value);

  return value;
}

function hydratePolicy(item, state) {
  if (!item) return item;

  // log(
  //   "hydrate authcodes",
  //   state.authcodes,
  //   item.subject,
  //   get(state, ["authcodes", "for", item.subject])
  // );

  item.statistics =
    get(state, ["statistics", "for", item.id]) ||
    get(state, ["statistics", "for", item.subject]);
  // item.pricing =
  //   get(state, ["pricing", "for", item.id]) ||
  //   get(state, ["pricing", "for", item.subject]);
  item.metered = metered(item, state);
  item.pricing = pricing(item, state);
  item.versions =
    get(state, ["versions", "for", item.id]) ||
    get(state, ["versions", "for", item.subject]);
  item.authcodes =
    get(state, ["authcodes", "for", item.id]) ||
    get(state, ["authcodes", "for", item.subject]);
  //   for (let [id, v] of Object.entries(metersFromPolicy(item) || {})) {
  //     v = state[v] || v;
  //     if (!v || !v.principals) continue;
  //     for (const [id2, v2] of Object.entries(v.principals)) {
  //       v.principals[id2] = state[v2] || v2;
  //     }
  //   }

  item.property = resolveProperty(item.location, state);

  return item;
}

export const policies = derived([property, state], ([property, state]) => {
  if (!property) return null;
  if (!state["policies"]) return null;
  //log("policies=", state["policies"]);
  var policies = Object.entries(state["policies"])
    .filter(([policy, version]) => policy != "for")
    .map(([policy, version]) => state[policy] || state[version]);
  //log("policies=", policies);
  if (!policies.every((item) => !!item)) return null; // not done loading

  return policies
    .filter((item) => !!item && item.scope === property.id)
    .map((item) => hydratePolicy(item, state))
    .sort((a, b) => comparer(a.title, b.title));
});

export const policy = derived(
  [policyId, policies, state],
  ([id, policies, items]) => {
    var item =
      !!id &&
      (hydratePolicy(items[id], items) ||
        (!!policies &&
          policies.find((item) => item.id === id || item.subject === id)));
    return item;
    // return merge(item, {
    //   property: resolveProperty(item.location, items),
    // });
  }
);

// on any propertyId change update the users
propertyId.subscribe(
  ($propertyId) => $propertyId && fetchAndStoreUsers($propertyId)
);

export const authorized = derived([property, state], ([property, state]) => {
  if (!property) return null;

  if (!get(state, "authorizations.items")) return null;
  var items = map(state.authorizations.items, (item, id) =>
    item.id ? item : state[id] || state[item]
  );
  //log("authorizations=", items);
  if (!items.every((item) => !!item)) return null; // not done loading

  return (
    items
      //.filter(item => !!get(item, "roles.admin", false))
      .map((item) => {
        item.user = state[item.principal];
        return item;
      })
      .sort((a, b) => comparer(a.user.display, b.user.display))
  );
});

//authorized.subscribe($authorized => log("users=", $authorized));

export const permits = derived(
  [property, state, policies],
  ([property, items, policies]) => {
    if (!property) return null;

    if (!property) return null;
    if (!items["permits"]) return null;
    var values = map(items["permits"], (value, key) => items[key]);
    //log("permits=", values);
    if (!values.every((item) => !!item)) return null; // not done loading

    //if(!every(values, i => !!i)) return null;

    return values
      .filter((permit) => permit)
      .map((permit) =>
        !permit
          ? permit
          : merge(permit, {
              property: resolveProperty(
                items[permit.location] || permit.location,
                items
              ),
              address: items[permit.address] || permit.address,
              policy:
                items[permit.issued.policy] ||
                items[permit.issued.issuer] ||
                permit.issued.issuer,
              vehicle: items[permit.vehicle] || permit.vehicle,
              spaces: (permit.spaces || []).map((i) => items[i] || i),
              tenant: items[permit.tenant] || permit.tenant,
            })
      );

    // check for missing?

    return values;
  }
);

export const permit = derived([permitId], async ([permitId], set) => {
  if (!permitId) return set(null);

  set(null);

  var permits = await fetchPermit(permitId);

  if (permits.items && permits.items.length) set(permits.items[0]);
});

export const violation = derived([violationId], async ([violationId], set) => {
  if (!violationId) return set(null);

  set(null);

  var violations = await fetchViolation(violationId);

  if (violations.items && violations.items.length) set(violations.items[0]);
});

export const policyVersionId = derived(
  [policyId, param("version")],
  ([$policy, $version]) => $policy && $version
);

export const policyVersion = derived(
  [policyVersionId, state],
  async ([$version, $state], set) => {
    set(null);

    if (!$version) return;

    var json = await fetchPolicy($version);

    set(hydratePolicy(json.items[$version], $state));
  }
);

export const notify = derived([property, state], ([property, state]) => {
  if (!property) return null;
  return map(get(state, "notify.items"), (value, key) => state[key]).map(
    (item) => {
      if (item.user) item.user = state[item.user] || item.user;
      return item;
    }
  );
  //.filter(item => !!item && item.scope === property.id);
  //.sort((a, b) => comparer(a.email, b.display));
});

export const prices = derived([property, state], ([property, state]) => {
  if (!property) return null;
  if (!get(state, "prices.items")) return null;
  return Object.keys(get(state, "prices.items")).reduce((result, key) => {
    const item = state[key];
    if (!item) return result;

    if (item.scope !== property.id) return result;

    item.subject = state[item.subject] || item.subject;

    result.push(item);

    return result;
  }, []);
  return map(get(state, "prices.items"), (value, key) => state[key])
    .map((item) => {
      if (!item) return item;
      //log("tenant=", item);
      item.subject = state[item.subject] || item;
      return item;
    })
    .filter((item) => !!item && item.scope === property.id)
    .sort((a, b) => comparer(a.display, b.display));
});
//prices.subscribe($value => log("prices=", $value));

export const units = derived([property, state], ([property, state]) => {
  if (!property) return null;
  return map(get(state, "units.items"), (value, key) => state[key])
    .filter((item) => !!item && item.scope === property.id)
    .sort((a, b) => comparer(a.display, b.display));
});

export const tenants = derived([property, state], ([property, state]) => {
  if (!property) return null;
  if (!get(state, "tenants.items")) return null;
  return map(get(state, "tenants.items"), (value, key) => state[key])
    .map((item) => {
      if (!item) return item;
      item.authcode = get(
        state,
        ["authcodes", "items", item.id],
        item.authcode
      );
      item.subject = get(state, item.subject, item.subject);
      item.archived = item.archived || !!item.valid.utc.split("/")[1];
      item.current = !item.valid.utc.split("/")[1]; // recalc each time
      //log("tenant=", item);
      return item;
    })
    .filter((item) => !!item && item.scope === property.id)
    .sort((a, b) => comparer(a.display, b.display));
});

export const spaces = derived([property, state], ([property, state]) => {
  if (!property) return null;
  //log("spaces from store=", state.spaces);
  if (!get(state, "spaces.items")) return null;
  return map(
    get(state, "spaces.items"),
    (value, key) => state[value] || state[key] || value
  )
    .map((item) => {
      //log("space item =", item);
      if (!item) return item;
      //log("tenant=", item);
      return item;
    })
    .filter((item) => !!item && item.scope === property.id)
    .sort((a, b) => comparer(a.display, b.display));
});

export const media = derived([property, state], ([property, state]) => {
  if (!property) return null;
  if (!get(state, "media.items")) return null;
  return map(
    get(state, "media.items"),
    (value, key) => state[value] || state[key] || value
  )
    .map((item) => {
      if (!item) return item;
      //log("tenant=", item);
      return item;
    })
    .filter((item) => !!item && item.scope === property.id)
    .sort((a, b) => comparer(a.display, b.display));
});

export const documents = derived([property, state], ([property, state]) => {
  if (!property) return null;
  if (!get(state, "documents.items")) return null;
  return map(get(state, "documents.items"), (value, key) => state[key])
    .map((item) => {
      if (!item) return item;
      //log("tenant=", item);
      return item;
    })
    .filter((item) => !!item)
    .sort((a, b) => comparer(a.title, b.title));
});

// documents.subscribe(value => log("documents=", value));

export const space = derived([spaceId, state], ([id, items]) => items[id]);
export const tenant = derived([tenantId, state], ([id, items]) => items[id]);

// on permit update selected property
policy.subscribe((policy) => {
  if (!policy) return;
  params.update((prev) =>
    merge(prev, {
      property: policy.property.id || policy.property || policy.location,
    })
  );
});

let propertyTz = null;
export const propertyTimezone = derived(property, ($property, set) => {
  // only do if changed
  //if(!$property || !$property.timezone) set(property.timezone);
  if ($property && $property.timezone && $property.timezone != propertyTz) {
    set($property.timezone);
  }

  // no cleanup
});

export const propertyNow = derived(
  [propertyTimezone, minuteNow],
  ([$timezone, $now]) => {
    //log("propertyNow=", property,  property.timezone, now);

    if (!$timezone) return $now;
    return utcToZonedTime($now, $timezone);
  }
);

// export const validDefaultPropertyToday = (function() {

//     let value = null;
//     return derived([ valid, propertyNow], ([ $valid, $now ], set) => {
//         if(!$valid) return set(value = `${format($now, "yyyy-MM-dd")}T00:00:00/${format($now, "yyyy-MM-dd")}T23:59:59`);
//         if($valid != value) return set(value = $valid);
//     });
// }());

export const validDefaultPropertyToday = (function () {
  let value = null;
  return derived(
    [valid, propertyNow, propertyTimezone],
    ([$valid, $now, $tz], set) => {
      if (!$valid) {
        const min = startOfDay($now);
        const max = endOfDay($now);
        return set(
          (value = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", {
            timeZone: $tz,
          })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $tz })}`)
        );
      }
      if ($valid != value) {
        var [min, max] = $valid.split("/").map((i) => utcToZonedTime(i, $tz));
        return set(
          (value = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", {
            timeZone: $tz,
          })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $tz })}`)
        );
      }
    }
  );
})();

export const validDefaultPropertyWeek = (function () {
  let value = null;
  return derived(
    [valid, propertyNow, propertyTimezone],
    ([$valid, $now, $tz], set) => {
      if (!$valid) {
        const min = startOfDay(addDays($now, -6));
        const max = endOfDay($now);
        return set(
          (value = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", {
            timeZone: $tz,
          })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $tz })}`)
        );
      }
      if ($valid != value) {
        var [min, max] = $valid.split("/").map((i) => utcToZonedTime(i, $tz));
        return set(
          (value = `${format(min, "yyyy-MM-dd'T'HH:mm:ssXXX", {
            timeZone: $tz,
          })}/${format(max, "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: $tz })}`)
        );
      }
    }
  );
})();

// toast object:
// {
//     message: "toast message",
//     type: "success" | "error"
// }

function createToasts() {
  const { update, subscribe } = writable([]);

  const add = (msg, opts = {}) => {
    const newToast = { message: msg, ...opts };
    update((toasts) => [...toasts, newToast]);
  };

  const remove = (idx) => {
    update((toasts) => {
      let newToasts = [...toasts];
      newToasts.splice(idx, 1);
      return newToasts;
    });
  };

  return { add, remove, subscribe };
}

export const toasts = createToasts();

export const propertyPaymentMetrics = derived(
  propertyId,
  async ($propertyId, set) => {
    set(null);

    if (!$propertyId) return;

    const now = new Date();

    var byInterval = range(-25, 1, 1)
      .map((i) => [
        addMonths(startOfMonth(new Date()), i),
        addMonths(startOfMonth(new Date()), i + 1),
      ])
      .map(
        ([min, max]) =>
          `${format(min, "yyyy-MM-dd'T'00:00:00")}/${format(
            max,
            "yyyy-MM-dd'T'00:00:00"
          )}`
      )
      .map((valid) =>
        fetchPaymentMetrics(valid, {
          property: $propertyId,
          //datetimes: ["P1D", "P1M"],
          metrics: ["total", "policy", "property"],
          payments: ["total", "gateway", "net", "creditable", "service", "tax"],
          //payments: ["total", "net"],
        })
      );

    const json = (await Promise.all(byInterval)).reduce((json, json1) => {
      return Object.assign(json, json1, {
        metrics: {
          items: (json.metrics?.items || []).concat(
            json1.metrics.items?.map((i) => {
              i.datetimes = "P1M";
              return i;
            }) || []
          ),
        },
      });
    }, {});

    // var json = await fetchPaymentMetrics(
    //   `${format(addMonths(now, -25), "yyyy-MM-01'T'00:00:00")}/${format(
    //     addDays(now, 1),
    //     "yyyy-MM-dd'T'00:00:00"
    //   )}`,
    //   {
    //     property: $propertyId,
    //     datetimes: ["P1M"],
    //     metrics: ["total", "policy", "property"],
    //     payments: ["total", "gateway", "net", "creditable", "service"],
    //   }
    // );

    log("paymentmetrics=", json);

    set(json);
  }
);

let maxTenants;

export const unitStatus = derived(
  [propertyId, tenants],
  async ([$propertyId, $tenants], set) => {
    if (!$propertyId) return set(null);

    // find max updated...
    if (!$tenants) return;

    var max = $tenants.reduce((result, item) => {
      //log("item=", item);

      const [a] = item.valid.utc.split("/");

      if (null == result || a > result) return a;

      return result;
    }, maxTenants);

    if (null == maxTenants || max > maxTenants) {
      maxTenants = max; // update, fetch
      log("maxTenants=", maxTenants, max);
      set(await fetchUnitStatus($propertyId));
    }
  }
);

// let propertyNowUnsubscribe = null;
// export const propertyNow = derived(property, ($property, set) => {
//     //log("propertyNow=", property,  property.timezone, now);

//     // update the tz
//     // if(!$property) {
//     //     propertyNowTz = $property.timezone;
//     //     if(propertyNowUnsubscribe) propertyNowUnsubscribe();
//     // }
//     if($property && $property.timezone && $property.timezone != propertyNowTz) {
//         propertyNowTz = $property.timezone;
//         if(propertyNowUnsubscribe) propertyNowUnsubscribe();
//         propertyNowUnsubscribe = null;
//     }

//     // re-subscribe if not listenening
//     if(!propertyNowUnsubscribe && propertyNowTz) {
//         propertyNowUnsubscribe = timeLocal(minuteNow, propertyNowTz).subscribe(set); // create a new store for this tz
//     }

//     // cleanup
//     return () => {
//         if(propertyNowUnsubscribe) propertyNowUnsubscribe();
//         propertyNowUnsubscribe = null;
//     }
// });

// loggers
//permit.subscribe(value => log("permit.store=", value));
//properties.subscribe(value => log("properties.store=", value));
// permits.subscribe(value => log("permits.store=", value));
// view.subscribe(value => log("view.store=", value));
//policy.subscribe(value => log("policy.store=", value));
// policies.subscribe(value => log("policies.store=", value));
//units.subscribe(value => log("units.store=", value));
