<script>
  import { get } from "lodash-es";
  import { user } from "../stores";
  import Policy from "./Policy.svelte";

  export let policies = null;
</script>

{#if !!policies && !!policies.length}
  <nav>
    <ul class="policies" data-count={get(policies, "length", "")}>
      {#each policies as policy}
        <li>
          <header>
            <!-- <a href="/properties/{policy.scope}/amenities/{policy.subject}"> -->
            <Policy {policy} />
            <!-- </a> -->
          </header>
          {#if get($user, "roles.admin", false)}
            <a href="/properties/{policy.scope}/policies/{policy.subject}/edit">
              Edit
            </a>
          {/if}
          {#if !!get(policy, "disabled", false)}
            <p class="disabled">{get(policy, "disabled.reason")}</p>
          {:else}
            <ul>
              {#if get(policy, "alert.text")}
                <li>
                  <p>{policy.alert.text}</p>
                </li>
              {/if}

              {#if get(policy, "info.text")}
                <li>
                  <p>{policy.info.text}</p>
                </li>
              {/if}
            </ul>
          {/if}
        </li>
      {/each}
    </ul>
  </nav>
{/if}
