<script context="module">
  import { scaleTime, scaleLinear } from "d3-scale";
  import { addMonths, isWithinInterval, startOfMonth, isEqual } from "date-fns";
  import { utcToZonedTime, format, toDate as toUtcDate } from "date-fns-tz";
  import { time as timestore } from "../util/timestores";
  import SVGTimeNow from "./svg/SVGTimeNow.svelte";
  function reduceToInterval(interval, date, i) {
    if (i === 0 && date) interval.start = date;
    else if (i === 1 && date) interval.end = date;
    return interval;
  }
  // todate will utc time if tz info, get utc as if string is local based on timezone
  // this is why utc to zoned time is necessary
  function stringToDateInterval(input, timezone) {
    if (!input) return input;
    return input
      .split("/")
      .map(
        (str) =>
          str &&
          utcToZonedTime(toUtcDate(str, { timeZone: timezone }), timezone)
      )
      .reduce(reduceToInterval, {
        start: utcToZonedTime(new Date(), timezone),
        end: utcToZonedTime(new Date(), timezone),
      });
  }
  const calendar = {
    P1D: "day",
    P1W: "week",
    P1M: "month",
    P1Y: "year",
  };
  const nowutc = timestore({ minutes: 60 });
</script>

<script>
  export let width = "135";
  export let scale = 24;

  export let item;

  //$: console.log("minichart item=", item);

  $: interval = stringToDateInterval(item?.interval, item.timezone);

  $: nowlocal = utcToZonedTime($nowutc, item?.timezone);

  $: month = {
    start: startOfMonth(nowlocal),
    end: startOfMonth(addMonths(nowlocal, 1)),
  };
  $: monthcomplete =
    (month.end - month.start) / (month && nowlocal - month.start);

  //$: console.log("month=", month, monthcomplete);

  //$: console.log("month chart item=", item);

  $: intervals = Object.entries(item?.values || {})
    .map(([k, count]) => ({
      interval: k,
      count,
      per: item.datetimes,
      ...stringToDateInterval(k, item.timezone),
    }))
    .map((v) => {
      if (isEqual(v.start, month?.start))
        v.projection = v.count * monthcomplete;
      return v;
    });

  //$: console.log("intervals=", interval, intervals);

  $: max = Math.max(...intervals.map((item) => item.projection || item.count));

  $: timescale = scaleTime()
    .domain([interval.start, interval.end])
    .range([0, 100]);
  $: numscale = scaleLinear()
    .domain([max, 0])
    .range([0, scale - 0]);
</script>

<time
  datetime="{format(interval.start, "yyyy-MM-dd'T'HH:mm:ssxxx", {
    timeZone: item.timezone,
  })}/{format(interval.end, "yyyy-MM-dd'T'HH:mm:ssxxx", {
    timeZone: item.timezone,
  })}"
>
  <svg {width} height={scale}>
    {#each intervals as { start, end, per, count, projection }}
      {#if count > 0}
        {#if projection}
          <rect
            title={projection}
            rx="1"
            ry="1"
            class="projection {item.type} {item.metric} {calendar[per]} {per}"
            x="{timescale(start)}%"
            y={numscale(projection)}
            height="200%"
            width="{timescale(end) - timescale(start)}%"
          />
        {/if}
        <rect
          title={count}
          rx="1"
          ry="1"
          class="{item.type} {item.metric} {calendar[per]} {per}"
          x="{timescale(start)}%"
          y={numscale(count)}
          height="200%"
          width="{timescale(end) - timescale(start)}%"
          datetime="{format(start, "yyyy-MM-dd'T'HH:mm:ssxxx", {
            timeZone: item.timezone,
          })}/{format(end, "yyyy-MM-dd'T'HH:mm:ssxxx", {
            timeZone: item.timezone,
          })}"
        />
      {/if}
    {/each}
    {#if isWithinInterval(nowlocal, interval)}
      <SVGTimeNow x="{timescale(nowlocal)}%" />
    {/if}
  </svg>
</time>
