<script>
  import { fetchSearchMedia as search } from "../api";

  let submittable = false;
  let submitting = false;

  async function submit(e) {
    e.preventDefault();

    const form = e.target;

    if (submitting || !submittable) return;

    submitting = true;

    var item = await search(form.elements["serial"].value);

    //console.log("res=", res);
    submitting = false;

    if (!item) alert("No Smart Decal found");
    else if (!item.scope) alert("Smart Decal not assigned to property");
    else {
      //form.reset();
      window.location.href = `https://manager.communityboss.app/properties/${item.scope}/media/${item.id}`;
    }

    submittable = false;
  }

  function change(e) {
    submittable = e.target.form && e.target.form.checkValidity();
  }
</script>

<form on:change={change} on:input={change} on:submit={submit}>
  <header>
    <h1>Find Smart Decal</h1>
  </header>
  <fieldset>
    <ul>
      <li>
        <label for="media-number">Number</label>
        <input
          id="media-number"
          type="number"
          name="serial"
          placeholder="required"
          min="100000"
          required
        />
      </li>
    </ul>
  </fieldset>
  <footer>
    <button type="submit" disabled={!submittable || submitting}
      >{submitting ? "Searching" : "Search"}</button
    >
  </footer>
</form>
