<script>
  import { downloadHandler } from "../util/exportTable";
  import {
    startOfMonth,
    addMonths,
    addYears,
    startOfYear,
    parseISO,
  } from "date-fns";
  import { format, utcToZonedTime } from "date-fns-tz";
  import {
    paymentmetrics,
    valid,
    total,
    propertiesPaymentMetrics,
  } from "../stores";
  import { query } from "../util/router";
  import { sortBy } from "../util/sort";
  import PaymentsMetricsOverview from "./PaymentsMetricsOverview.svelte";
  import PropertiesPaymentsComparison from "./PropertiesPaymentsComparison.svelte";

  $: data = processData($paymentmetrics);
  $: console.log($propertiesPaymentMetrics);

  $: totals =
    data &&
    data.reduce((totals, item) => {
      if (!totals.length) totals.push({});
      const total = totals[0];

      console.log("item=", item);

      for (const [key, value] of Object.entries(item)) {
        if (!value.values) continue;
        if (!total[key]) total[key] = 0;
        total[key] += Object.values(value.values)[0];
      }

      console.log("total=", total);

      return totals;
    }, []);

  let valids = getValidIntervals();

  //$: if(!$valid) query("valid", valids[0][1]);

  let table;

  var currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 0,
  }).format;

  function asUtcString(date) {
    return format(date, "yyyy-MM-dd'T'00:00:00'Z'");
  }

  function asLocalString(date) {
    return format(date, "yyyy-MM-dd'T'00:00:00");
  }

  function getValidIntervals() {
    var maxPast = new Date(2020, 0);

    let start = new Date();

    var items = [
      [
        format(start, "yyyy"),
        [startOfYear(start), addYears(startOfYear(start), 1)]
          .map((i) => asLocalString(i))
          .join("/"),
      ],
    ];

    while (start >= maxPast) {
      if (start.getMonth() == 11)
        items.push([
          format(start, "yyyy"),
          [startOfYear(start), addYears(startOfYear(start), 1)]
            .map((i) => asLocalString(i))
            .join("/"),
        ]);

      items.push([
        format(start, "MMM yyyy"),
        [startOfMonth(start), addMonths(startOfMonth(start), 1)]
          .map((i) => asLocalString(i))
          .join("/"),
      ]);

      if (start.getMonth() % 3 == 0)
        items.push([
          format(start, "qqq yyyy"),
          [startOfMonth(start), addMonths(startOfMonth(start), 3)]
            .map((i) => asLocalString(i))
            .join("/"),
        ]);

      if (start.getMonth() % 6 == 0)
        items.push([
          format(start, `'H'${Math.floor(start.getMonth() / 6) + 1} yyyy`),
          [startOfMonth(start), addMonths(startOfMonth(start), 6)]
            .map((i) => asLocalString(i))
            .join("/"),
        ]);

      start = addMonths(start, -1);
    }

    return items;
  }

  function processData(json) {
    if (!json || !json.metrics || !json.metrics.items) return null;

    return sortBy(
      Object.values(
        json.metrics.items.reduce((properties, metric) => {
          if (!metric.property || metric.source != "payments")
            return properties;

          const item =
            properties[metric.property] ||
            (properties[metric.property] = {
              property: json.items[metric.property],
              timezone: metric.timezone,
              interval: metric.interval,
            });

          if (metric.group == "property") item[metric.payments] = metric;
          else if (metric.group == "property+policy") {
            item.policies = item.policies || {};
            const policyItem =
              item.policies[metric.policy] ||
              (item.policies[metric.policy] = {
                property: json.items[metric.property],
                policy: json.items[metric.policy],
                timezone: metric.timezone,
                interval: metric.interval,
              });
            policyItem[metric.payments] = metric;
          }

          return properties;
        }, {})
      ),
      "property.name"
    );
  }

  $: showTotalFor = $total + "";

  //formatter.format(2500); /* $2,500.00 */
</script>

<main>
  <PaymentsMetricsOverview data={$propertiesPaymentMetrics} />
  <PropertiesPaymentsComparison data={$propertiesPaymentMetrics} />
  <section>
    <header><h1>Properties By Range</h1></header>
    <form>
      <fieldset>
        <ul on:change={({ target }) => query(target.name, target.value)}>
          <li>
            <label for="payments-valid">Range</label>
            <select id="payments-valid" name="valid" value={$valid}>
              <option value="">--</option>
              {#each valids as [label, interval]}
                <option value={interval}>{label}</option>
              {/each}
            </select>
          </li>
          <li>
            <label for="payments-total">Totals by</label>
            <select id="payments-total" name="total" value={showTotalFor}>
              <option value="">property</option>
              <option value="policy">policy</option>
            </select>
          </li>
        </ul>
      </fieldset>
    </form>
    <a
      href="#"
      type="text/csv"
      download="Properties {format(new Date(), 'yyyy-MM-dd')}.csv"
      on:click={(e) => downloadHandler(e, table)}>Download Spreadsheet</a
    >
  </section>
  <table bind:this={table}>
    <thead>
      <tr>
        <th scope="column" />
        <th scope="column row">Property</th>
        <th scope="column">Type</th>
        <th scope="column">Management</th>
        <th scope="column">Group</th>
        <th scope="column">Total Charges</th>
        <th scope="column">Property App Fee</th>
        <th scope="column">Property Stripe Fee</th>
        <th scope="column">Property Net</th>
        <th scope="column">Property Credit</th>
        <th scope="column">Tax To Remit</th>
        <th scope="column">Stripe Fee</th>
        <th scope="column">App Net</th>
        <th scope="column">From</th>
        <th scope="column">To</th>
      </tr>
    </thead>
    <tbody>
      {#if data}
        {#each data.map( (v, i) => [i, v] ) as [i, { property, total, net, service, creditable, tax, gateway, interval, timezone, policies }]}
          {#if showTotalFor != "policy"}
            <tr>
              <td>{i + 1}</td>
              <th scope="row"
                ><a
                  href="/properties/{property.id}"
                  on:click|preventDefault={({ currentTarget: target }) =>
                    (window.location.href = target.href)}>{property.name}</a
                ></th
              >
              <td>{property.format}</td>
              <td>{property.management}</td>
              <td>Total</td>
              <td
                >{(total && currency(Object.values(total.values)[0] / 100)) ||
                  ""}</td
              >
              <td
                >{(service &&
                  currency(Object.values(service.values)[0] / 100)) ||
                  ""}</td
              >
              <td
                >{(total &&
                  currency(
                    (Object.values(total.values)[0] -
                      Object.values(net.values)[0] -
                      Object.values(service.values)[0]) /
                      100
                  )) ||
                  ""}</td
              >
              <td
                >{(net && currency(Object.values(net.values)[0] / 100)) ||
                  ""}</td
              >
              <td
                >{(creditable &&
                  currency(Object.values(creditable.values)[0] / 100)) ||
                  ""}</td
              >
              <td
                >{(creditable &&
                  currency(Object.values(tax.values)[0] / 100)) ||
                  ""}</td
              >
              <td
                >{(gateway &&
                  currency(Object.values(gateway.values)[0] / 100)) ||
                  ""}</td
              >
              <td
                >{(total &&
                  currency(
                    (Object.values(total.values)[0] -
                      Object.values(net.values)[0] -
                      Object.values(gateway.values)[0]) /
                      100
                  )) ||
                  ""}</td
              >

              <td
                ><time datetime={interval && interval.split("/")[0]}
                  >{interval &&
                    format(
                      utcToZonedTime(interval.split("/")[0], timezone),
                      "EEE MMM d yyyy h:mm a zzz",
                      { timeZone: timezone }
                    )}</time
                ></td
              >
              <td
                ><time datetime={interval && interval.split("/")[1]}
                  >{interval &&
                    format(
                      utcToZonedTime(interval.split("/")[1], timezone),
                      "EEE MMM d yyyy h:mm a zzz",
                      { timeZone: timezone }
                    )}</time
                ></td
              >
            </tr>
          {:else}
            {#each Object.values(policies || []) as { property, policy, total, net, service, creditable, gateway, interval, timezone }}
              <tr>
                <!-- <th scope="row"></th> -->
                <td scope="row">--</td>
                <th scope="row"
                  ><a
                    href="/properties/{property.id}"
                    on:click|preventDefault={({ currentTarget: target }) =>
                      (window.location.href = target.href)}>{property.name}</a
                  ></th
                >
                <td>{property.format}</td>
                <td>{property.management}</td>
                <td>{policy.title}</td>
                <td
                  >{(total && currency(Object.values(total.values)[0] / 100)) ||
                    ""}</td
                >
                <td
                  >{(service &&
                    currency(Object.values(service.values)[0] / 100)) ||
                    ""}</td
                >
                <td
                  >{(total &&
                    currency(
                      (Object.values(total.values)[0] -
                        Object.values(net.values)[0] -
                        Object.values(service.values)[0]) /
                        100
                    )) ||
                    ""}</td
                >
                <td
                  >{(net && currency(Object.values(net.values)[0] / 100)) ||
                    ""}</td
                >
                <td
                  >{(creditable &&
                    currency(Object.values(creditable.values)[0] / 100)) ||
                    ""}</td
                >
                <td
                  >{(gateway &&
                    currency(Object.values(gateway.values)[0] / 100)) ||
                    ""}</td
                >
                <td
                  >{(total &&
                    currency(
                      (Object.values(total.values)[0] -
                        Object.values(net.values)[0] -
                        Object.values(gateway.values)[0]) /
                        100
                    )) ||
                    ""}</td
                >
                <td
                  ><time datetime={interval && interval.split("/")[0]}
                    >{interval &&
                      format(
                        utcToZonedTime(interval.split("/")[0], timezone),
                        "EEE MMM d yyyy h:mm a zzz",
                        { timeZone: timezone }
                      )}</time
                  ></td
                >
                <td
                  ><time datetime={interval && interval.split("/")[1]}
                    >{interval &&
                      format(
                        utcToZonedTime(interval.split("/")[1], timezone),
                        "EEE MMM d yyyy h:mm a zzz",
                        { timeZone: timezone }
                      )}</time
                  ></td
                >
              </tr>
            {/each}
          {/if}
        {/each}
      {/if}
    </tbody>
    <tfoot>
      {#if totals && showTotalFor != "policy"}
        {#each totals as { total, net, service, creditable, tax, gateway }}
          <tr>
            <th scope="column" />
            <th scope="column row">All Properties</th>
            <th scope="column">All Types</th>
            <th scope="column">All Management</th>
            <th scope="column">All Policies</th>
            <th scope="column">{(total && currency(total / 100)) || ""}</th>
            <th scope="column">{(service && currency(service / 100)) || ""}</th>
            <th scope="column"
              >{(total && currency((total - net - service) / 100)) || ""}</th
            >
            <th scope="column">{(net && currency(net / 100)) || ""}</th>
            <th scope="column"
              >{(creditable && currency(creditable / 100)) || ""}</th
            >
            <th scope="column">{(creditable && currency(tax / 100)) || ""}</th>
            <th scope="column">{(gateway && currency(gateway / 100)) || ""}</th>
            <th scope="column"
              >{(total && currency((total - net - gateway) / 100)) || ""}</th
            >
            <th scope="column"
              ><time datetime={$valid && $valid.split("/")[0]}
                >{$valid &&
                  format(
                    parseISO($valid.split("/")[0]),
                    "EEE MMM d yyyy h:mm a"
                  )}</time
              ></th
            >
            <th scope="column"
              ><time datetime={$valid && $valid.split("/")[1]}
                >{$valid &&
                  format(
                    parseISO($valid.split("/")[1]),
                    "EEE MMM d yyyy h:mm a"
                  )}</time
              ></th
            >
          </tr>
        {/each}
      {/if}
    </tfoot>
  </table>
</main>
