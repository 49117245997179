<script lang="ts">
  import type { UploadFormViewModel } from "./stores";
  export let view_model: UploadFormViewModel;
</script>

{#if $view_model.errors?.length}
  <ul class="errors">
    {#each $view_model.errors as error}
      <li><strong>[{error.status}]</strong>: {error.message}</li>
    {/each}
  </ul>
{/if}

<style lang="scss">
  @import "../../../styles/parkingboss/colors/_index.scss";

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      color: $red;
    }
  }
</style>
