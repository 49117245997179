import bbox_from_geometry from "@turf/bbox";
import { circle } from "@turf/turf";
import debug from "debug";
import type { FitBoundsOptions } from "mapbox-gl";
import { z } from "zod";
import { RADIUS } from "../constants";

const log = debug("boss:providers:stores:map");

const DEFAULT_PADDING = 50;
const DEFAULT_BOUNDS_OPTIONS: FitBoundsOptions = {
  padding: DEFAULT_PADDING,
  animate: false,
};

// export type BBox = [number, number, number, number];

const BBoxEnforcer = z.tuple([z.number(), z.number(), z.number(), z.number()]);

export const utils = {
  bbox: {
    set(
      instance: mapboxgl.Map,
      bbox: mapboxgl.LngLatBoundsLike,
      opts: FitBoundsOptions = DEFAULT_BOUNDS_OPTIONS
    ) {
      log("set_bbox", bbox);
      log("options:", opts);

      // bbox = BBoxEnforcer.parse(bbox);

      instance.fitBounds(bbox, opts);

      return bbox;
    },
    set_from_geometry(
      instance: mapboxgl.Map,
      geometry: GeoJSON.GeoJSON,
      opts: FitBoundsOptions = DEFAULT_BOUNDS_OPTIONS
    ) {
      log("set bbox from geometry:", geometry);
      log("options:", opts);

      let bbox = utils.bbox.get_from_geometry(geometry);

      bbox = BBoxEnforcer.parse(bbox);

      utils.bbox.set(instance, bbox, opts);

      return bbox;
    },
    get_from_geometry(geometry: mapboxgl.GeoJSONSource) {
      return bbox_from_geometry(geometry);
    },
  },
  radius_cicle: {
    render({
      instance,
      center,
      radius_meters = 65 * 1000, // 65 km
      name = RADIUS,
    }: {
      instance: mapboxgl.Map;
      center: [number, number];
      radius_meters: number;
      name?: string;
    }) {
      if (!instance) throw new Error("no map instance to render radius on");
      if (!center) throw new Error("cannot create circle without center");
      if (!radius_meters)
        throw new Error("cannot create circle without radius");

      log("rendering radius circle...");

      const feature = circle(center, radius_meters, {
        units: "meters",
        steps: 128,
      });

      log("circle:", feature);

      const source = instance.getSource(name) as mapboxgl.GeoJSONSource;

      if (source) {
        log("updating radius source...");
        source.setData(feature);
      } else {
        log("adding radius soruce...");
        instance.addSource(name, {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [feature],
          },
        });
        instance.addLayer({
          id: name,
          type: "fill",
          source: name,
          layout: {},
          paint: { "fill-color": "blue", "fill-opacity": 0.6 },
        });
      }

      return { source, feature };
    },
  },
  boundary: {
    render({
      instance,
      features,
      name = "boundary",
    }: {
      instance: mapboxgl.Map;
      features: GeoJSON.FeatureCollection;
      name?: string;
    }) {
      log("adding boundary source:", features);

      const source = instance.getSource(name) as mapboxgl.GeoJSONSource;

      if (source) {
        log("updating boundary source");
        source.setData(features);
      } else {
        const source = instance.addSource(name, {
          type: "geojson",
          data: features,
        });
        instance.addLayer({
          id: name,
          type: "fill",
          source: name,
          layout: {},
          paint: {
            "fill-color": "blue",
            "fill-opacity": 0.6,
          },
        });
      }

      return { source, features };
    },
  },
};
