<script>
  import { onMount, onDestroy, getContext } from "svelte";
  import { mapbox, key } from "./mapbox.js";
  import { debounce } from "lodash-es";

  const { getMap } = getContext(key);
  const map = getMap();

  let pitch = map.getPitch();

  function updatePitch() {
    requestAnimationFrame(() => (pitch = map.getPitch()));
  }

  map.on("pitchend", updatePitch);
  map.on("pitch", updatePitch);
  map.on("pitchstart", updatePitch);

  function setPitch(pitch) {
    map.easeTo(
      {
        pitch: pitch,
      },
      {
        duration: 400,
      }
    );
  }
</script>

<figure class="pitch" on:click={(e) => setPitch(pitch > 0 ? 0 : 60)}>
  <button type="button">{pitch > 0 ? "2D" : "3D"}</button>
</figure>
