<script lang="ts">
  import { faTimes } from "@fortawesome/free-solid-svg-icons"
  import { createEventDispatcher } from "svelte"
  import Fa from "svelte-fa"
  import { fade } from "svelte/transition"

  const dispatch = createEventDispatcher<{ close: void }>()

  export let open = false
  export let title: string
  export let width: "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" =
    "md"

  function close() {
    open = false
    dispatch("close")
  }
</script>

{#if open}
  <div
    class="modal modal-open backdrop-blur-sm"
    transition:fade={{ duration: 150 }}
    on:click|self={close}
    on:keydown={(e) => {
      if (e.key === "Escape") close()
    }}
  >
    <div
      class="modal-box w-11/12"
      class:max-w-sm={width === "sm"}
      class:max-w-md={width === "md"}
      class:max-w-lg={width === "lg"}
      class:max-w-xl={width === "xl"}
      class:max-w-2xl={width === "2xl"}
      class:max-w-3xl={width === "3xl"}
      class:max-w-4xl={width === "4xl"}
      class:max-w-5xl={width === "5xl"}
    >
      <header class="flex mb-6">
        {#if title}
          <h1 class="text-xl font-bold">{title}</h1>
        {/if}

        <button
          class="btn btn-ghost btn-sm btn-circle ml-auto"
          on:click={close}
        >
          <Fa icon={faTimes} size="lg" />
        </button>
      </header>
      <slot />
    </div>
  </div>
{/if}
