<script lang="ts">
  export let item: any;
</script>

<article class="{item.type} {item.format} {item.result}">
  <h1>{item.title}</h1>
  <h2>{item.description}</h2>
  {#each Object.values(item.meters.items ?? {}) as meter}
    <data class={meter.type} value={meter.id}>{meter.display}</data>
  {/each}
  {#each Object.values(item.prices.items ?? {}) as price}
    <data class={price.type} value={price.id}>{price.description}</data>
  {/each}
  <slot />
</article>
