<script>
  import { query } from "$utils/router";
  import { format, utcToZonedTime } from "date-fns-tz";
  import { capitalize, get } from "lodash-es";
  import Attachments from "./Attachments.svelte";
  export let permit;

  console.log(permit);
</script>

<aside class="details">
  <header>
    <h1>Permit Details</h1>
    <button type="button" on:click={(e) => query("permit", null)}>close</button>
  </header>
  {#if permit}
    <table class="permit-details">
      <tbody>
        <tr>
          <th>Title</th>
          <td>{permit.title}</td>
        </tr>
        <tr>
          <th>Serial</th>
          <td>{permit.serial}</td>
        </tr>
        <tr>
          <th>Public</th>
          <td
            ><a href={permit.url} target="_blank" rel="noreferrer"
              >open in new tab</a
            ></td
          >
        </tr>
        {#if permit.policy}
          <tr>
            <th>Policy</th>
            <td>
              <data
                class="{permit.policy.type} id {[
                  permit.policy.format,
                  permit.policy.size,
                ]
                  .filter((i) => !!i)
                  .join(' ')}"
                value={permit.policy.id}
                >{permit.policy.display ||
                  permit.policy.title ||
                  permit.policy.name}</data
              >
            </td>
          </tr>
        {/if}
        {#each permit.subjects as subject}
          <tr>
            <th>{capitalize(subject.type)}</th>
            <td>
              <data
                class="{subject.type} id {[subject.format, subject.size]
                  .filter((i) => !!i)
                  .join(' ')}"
                value={subject.id}
                >{subject.display || subject.title || subject.name}</data
              >
            </td>
          </tr>
        {/each}
        {#if permit.description}
          <tr>
            <th>Notes</th>
            <td>
              {permit.description}
            </td>
          </tr>
        {/if}
        <Attachments
          attachments={permit.attachments}
          timezone={permit.timezone}
          permitUuid={permit.uuid}
        />
        <tr>
          <th>Timezone</th>
          <td>{permit.timezone}</td>
        </tr>
        <tr>
          <th>Created</th>
          <td
            ><time datetime={permit.created}
              >{format(
                utcToZonedTime(get(permit, "created"), permit.timezone),
                "h:mm a EE MMM d yyyy zzz",
                { timeZone: permit.timezone }
              )}</time
            ></td
          >
        </tr>
        <tr>
          <th>Issued</th>
          <td
            ><time datetime={permit.issued.datetime || permit.issued}
              >{format(
                utcToZonedTime(
                  permit.issued.datetime || permit.issued,
                  permit.timezone
                ),
                "h:mm a EE MMM d yyyy zzz",
                { timeZone: permit.timezone }
              )}</time
            ></td
          >
        </tr>
        {#if permit.valid.from}
          <tr>
            <th>Valid From</th>
            <td
              ><time datetime={permit.valid.from}
                >{format(
                  utcToZonedTime(get(permit, "valid.from"), permit.timezone),
                  "h:mm a EE MMM d yyyy zzz",
                  { timeZone: permit.timezone }
                )}</time
              ></td
            >
          </tr>
        {/if}
        {#if permit.valid.to}
          <tr>
            <th>Valid To</th>
            <td
              ><time datetime={permit.valid.to}
                >{format(
                  utcToZonedTime(get(permit, "valid.to"), permit.timezone),
                  "h:mm a EE MMM d yyyy zzz",
                  { timeZone: permit.timezone }
                )}</time
              ></td
            >
          </tr>
        {/if}
        {#if permit.validations.length}
          <tr>
            <th>Validations</th>
            <td>
              <ul>
                {#each permit.validations as validation}
                  <li>
                    {validation.meter.display}
                    {#if validation.meter.principal}
                      per
                    {:else if validation.meter.group}
                      per {validation.meter.group}
                    {/if}
                    <data
                      value={validation.evaluated.time ||
                        validation.evaluated.count}
                      >Used {validation.evaluated.time ||
                        validation.evaluated.count} in
                      <time datetime={validation.evaluated.interval}
                        >{validation.evaluated.interval}</time
                      ></data
                    >
                  </li>
                {/each}
              </ul>
            </td>
          </tr>
        {/if}
      </tbody>
    </table>
  {:else}
    <figure>Loading&hellip;</figure>
  {/if}
</aside>
