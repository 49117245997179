<script>
  import PropertySearch from "$components/PropertySearch.svelte";
  import { downloadHandler } from "$utils/exportTable";
  import { format, parseISO } from "date-fns";

  export let properties;

  let results;
  let table;

  var currency = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0,
    //maximumFractionDigits: 0,
  }).format;

  function onSearch(searchResults) {
    results = searchResults;
  }

  //formatter.format(2500); /* $2,500.00 */

  // $: index = properties && new Searcher(Object.values(properties), {
  //     threshold:0.8,
  //     keySelector:(item) => [

  //     ].filter(i => !!i)
  // });
</script>

<main>
  <section>
    <PropertySearch {properties} bind:results />
    <a
      href="#"
      type="text/csv"
      download="Properties {format(new Date(), 'yyyy-MM-dd')}.csv"
      on:click={(e) => downloadHandler(e, table)}>Download Spreadsheet</a
    >
  </section>
  <table bind:this={table}>
    <thead>
      <tr>
        <th scope="column">{(results || properties)?.length || 0}</th>
        <th scope="column row">Name</th>
        <th scope="column">Street</th>
        <th scope="column">City</th>
        <th scope="column">State</th>
        <th scope="column">Type</th>
        <th scope="column">Management</th>
        <th scope="column">Manager UI</th>
        <th scope="column">Products</th>
        <th scope="column" />
        <th scope="column" />
        <th scope="column" />
        <th scope="column">Units</th>
        <th scope="column">ARR</th>
        <th scope="column">MRR</th>
        <th scope="column">Freqency Mo</th>
        <th scope="column">Permit Fee</th>
        <!-- <th scope="column">QuickBooks</th> -->
        <th scope="column">Suspend</th>
        <th scope="column">ALN</th>
        <th scope="column">PM Integration</th>
        <th scope="column">Rep</th>
        <th scope="column">Created</th>
      </tr>
    </thead>
    <tbody>
      {#if properties}
        {#each Object.entries(results || properties) as [i, item]}
          <tr>
            <td>{parseInt(i) + 1}</td>
            <th scope="row"
              ><a
                href="/properties/{item.id}"
                on:click|preventDefault={(e) =>
                  (window.location.href = e.target.href)}>{item.name}</a
              ></th
            >
            <td>{item.address.street}</td>
            <td>{item.address.city}</td>
            <td>{item.address.state}</td>
            <td>{item.format}</td>
            <td>{item.management}</td>
            <td>
              <a href={item.manager.url} target="_blank">{item.manager.title}</a
              >
            </td>
            <td
              >{#if item.amenities?.enabled}<a
                  href="https://manager.communityboss.app/properties/{item.id}/amenities"
                  target="_blank"
                  rel="noreferrer">Amenities</a
                >{/if}</td
            >
            <td
              >{#if item.parking?.enabled}<a
                  href="https://manager.communityboss.app/properties/{item.id}/parking"
                  target="_blank"
                  rel="noreferrer">Parking</a
                >{/if}</td
            >
            <td
              >{#if item.map}<a
                  href="https://maps.communityboss.app/properties/{item.id}"
                  target="_blank"
                  rel="noreferrer">Smart Map</a
                >{/if}</td
            >
            <td>{item.vehicles.recognition.enabled ? "LPR" : ""}</td>
            <td>{item.units?.count || 0}</td>
            <td>{currency(item.revenue.arr)}</td>
            <td>{currency(item.revenue.mrr)}</td>
            <td>{item.revenue.annual === true ? 12 : 1}</td>
            <td
              >{item.revenue.permitfee
                ? currency(item.revenue.permitfee)
                : ""}</td
            >
            <td
              >{(item.suspend?.datetime &&
                format(parseISO(item.suspend.datetime), "yyyy-MM-dd")) ||
                ""}</td
            >
            <!-- <td>{item.billing?.external ?? ""}</td> -->
            <td>{item.info?.external ?? ""}</td>
            <td>{item.crm?.name ?? ""}</td>
            <td>{item.rep?.name || "--"}</td>
            <td>{format(parseISO(item.created), "yyyy-MM-dd")}</td>
          </tr>
        {/each}
      {/if}
    </tbody>
    <tfoot />
  </table>
</main>
