<script>
  import SvgQr from "svgqr.js";

  export let property;
  export let tenants;

  function encodeSVG(data) {
    data = data.replace(/"/g, "'");
    data = data.replace(/>\s{1,}</g, "><");
    data = data.replace(/\s{2,}/g, " ");
    return data.replace(/[\r\n%#()<>?\[\\\]^`{|}]/g, encodeURIComponent);
  }

  let tenant;

  $: id = id || (property && property.id);
  $: number = number || (property && property.number);

  $: url =
    property && `https://scanme.io/1/${id}/${number || 0}/DIR`.toUpperCase();

  $: svg =
    url &&
    SvgQr(url, {
      correction: "M",
      mode: "Alphanumeric",
    });

  let generating = false;

  function pdf(e) {
    if (!!e) e.preventDefault();
    if (generating) return;
    generating = true;
    //const svg = e.target.closest("figure").querySelector("svg");
    //${new XMLSerializer().serializeToString(svg).replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "")}
    fetch("https://v2.api2pdf.com/chrome/pdf/html", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "c550eb1b-888b-4377-ac99-096487c3ee13",
      },
      body: JSON.stringify({
        html: `<!DOCTYPE html><html><style>body { padding:0; margin:0; }</style><body>${svg}</body></html>`,
        inline: false,
        inlinePdf: false, // should download on nav
        fileName: e.target.getAttribute("download"),
        options: {
          //"landscape":"true",
          width: "3in",
          height: "3in",
          marginTop: "0in",
          marginBottom: "0in",
          marginLeft: "0in",
          marginRight: "0in",
          pageRanges: "1",
        },
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        generating = false;
        window.location.href = json.FileUrl;
      });
  }
</script>

{#if property}
  <!-- <figure class="preview">
    <iframe title="mobile app" src="https://my.communityhq.app/?property={property.id}"></iframe>
    <figcaption>
        <h1><a href="https://my.communityhq.app?property={property.id}" target="_blank" rel="noreferrer">directory link</a></h1>
    </figcaption>
</figure> -->
  <figure class="preview">
    {#if tenant}
      <iframe
        title="mobile app"
        src="https://my.communityhq.app/?account={tenant}"
      />
    {:else}
      <iframe
        title="mobile app"
        src="https://my.communityhq.app/?property={property.id}"
      />
    {/if}

    <figcaption>
      <form>
        <fieldset>
          <ul>
            <li>
              <label for="community-account">View</label>
              <select id="community-account" bind:value={tenant}>
                <option value="">directory</option>
                {#each tenants || [] as item}
                  <option value={item.id}>{item.format} {item.display}</option>
                {/each}
              </select>
              {#if tenant}
                <a
                  href="https://my.communityhq.app?account={tenant}"
                  target="_blank"
                  rel="noreferrer">link</a
                >
              {:else}
                <a
                  href="https://my.communityhq.app?property={property.id}"
                  target="_blank"
                  rel="noreferrer">link</a
                >
              {/if}
            </li>
          </ul>
        </fieldset>
      </form>
      <a
        href="http://api.qrserver.com/v1/create-qr-code/?ecc=M&format=eps&data={encodeURIComponent(
          url
        )}"
        on:click={pdf}
        download="{property.name.replace(/[^a-z0-9]+/gi, '-')}-directory-qr.pdf"
        >{generating ? "Generating…" : "Download QR PDF"}</a
      >
    </figcaption>
  </figure>
{/if}
