<script>
  import { fetchResetTenantsCodes as update } from "../api";
  import { fetchCSV } from "../api";

  export let property = null;
  export let tenants = null;

  let file = null;

  let submitting = false;
  let submittable = false;

  let edited = {};

  $: if (file && tenants) processCsvFile(tenants, file);

  function edit(record, value) {
    edited[record.id || record] = value;
  }

  function checkSubmittable() {
    submittable = Object.keys(edited).length > 0;
    console.log("edited=", edited);
  }

  $: if (edited) checkSubmittable();

  async function submit(e) {
    e.preventDefault();

    if (!property || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    const res = await update(property, formData);

    //console.log("res=", res);
    submitting = false;

    if (res && res.message) return alert(res.message);

    // do the updating
    edited = {};

    //submittable = false;
  }

  function normalize(str) {
    return !str || (str + "").replace(/[^a-zA-Z0-9]/gi, "").toUpperCase();
  }

  async function processCsvFile(records, file) {
    if (!file) return;

    let input = file;

    console.log("process=", file);

    const recordsByKey = records.reduce((result, record) => {
      result[record.key] = record;
      return result;
    }, {});

    //console.log("spacesByKey=", spacesByKey);

    if (file.type !== "text/csv" && file.name.indexOf(".csv") < 0)
      input = await fetchCSV(file);

    const numeric = /[$.]/gi;

    Papa.parse(input, {
      header: false,
      complete: function (results) {
        console.log(results);

        for (const item of results.data) {
          let recordFieldNameOrKey = null;
          let record = null;

          //check for explicit space field
          let itemField;
          if (!!item.unit) {
            recordFieldNameOrKey = "unit";
            record = recordsByKey[normalize(item.unit)];
          }

          // find the first field that matches a unit
          for (const [key, value] of Object.entries(item)) {
            if (!!record || null != recordFieldNameOrKey) break;

            if (numeric.test(key + "")) continue; // money or numeric

            const normalized = normalize(value);

            record =
              recordsByKey[normalized] ||
              recordsByKey["0" + normalized] ||
              (normalized &&
                normalized[0] === "0" &&
                recordsByKey[normalized.substring(1)]);

            if (!!record) recordFieldNameOrKey = key;

            //console.log("identified unit field?", unitFieldNameOrKey, tenant);
          }

          if (!record) continue;

          let value = null;

          for (const [k, v] of Object.entries(item)) {
            if (k === recordFieldNameOrKey) continue; // skip the one we already matched
            if (!v) continue;

            value = v;
            if (value && value.replace) value = value.replace(/[^0-9.]/gi, "");
            break;
          }

          if (record && value) edit(record, value);
        }

        //console.log(results);
      },
    });

    edited = edited;
  }

  function files(e) {
    //console.log(e);

    var items =
      (e.target && e.target.files) ||
      (e.dataTransfer && e.dataTransfer.files) ||
      [];
    //console.log("files=", items);
    if (!items.length) return;

    for (let item of items) {
      if (
        item.type !== "text/csv" &&
        item.name.indexOf(".csv") < 0 &&
        item.name.indexOf(".xlsx") < 0 &&
        item.name.indexOf(".xls") < 0
      )
        continue;
      file = item;
    }

    if (e.target) e.target.value = "";

    e.preventDefault();
  }
</script>

{#if property && tenants}
  <form
    on:input={checkSubmittable}
    on:change={checkSubmittable}
    on:submit={submit}
  >
    <header><h1>Import Passcodes</h1></header>
    {#each Object.entries(edited) as [id, code]}
      <input type="hidden" name={id} value={code} />
    {/each}
    <fieldset>
      <ul>
        <li>
          <input
            id="codes-file"
            type="file"
            on:change={files}
            accept="text/csv,.csv,.xlsx,.xls"
          />
          <label for="codes-file">Import from CSV</label>
        </li>
        {#each tenants as item}
          <li>
            <!-- <label> -->
            <data class="{item.type} {item.format || ''} id" value={item.id}
              >{item.display}</data
            >
            <!-- </label> -->
            <input
              on:input={(e) => edit(item.id, e.target.value)}
              on:change={(e) => edit(item.id, e.target.value)}
              type="number"
              value={edited[item.id] || ""}
              placeholder="keep"
            />
          </li>
        {/each}
      </ul>
    </fieldset>
    <footer>
      <button type="submit" disabled={!submittable || submitting}
        >{submitting ? "Saving" : "Save All"}</button
      >
    </footer>
  </form>
{/if}
