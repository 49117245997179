<script>
  export let content;
  export let isOpen = true;
  export let onCloseModal;
  $: showModal = isOpen;

  function handleCloseModal() {
    showModal = false;
    onCloseModal && onCloseModal();
  }
</script>

{#if showModal}
  <div class="modal-backdrop" />
  <div class="modal-container">
    <div class="modal-content">
      {content}
    </div>
    <div class="modal-controls">
      <div class="modal-button modal-cancel-button" on:click={handleCloseModal}>
        Cancel
      </div>
    </div>
  </div>
{/if}
