<script>
  import { fetchDeletePolicyPrices as update } from "./api";
  import { get } from "lodash-es";
  import { pricesFromPolicy } from "./util/policy";
  import { sortBy } from "./util/sort";
  import { format, utcToZonedTime } from "date-fns-tz";
  import { parseISO } from "date-fns";
  import { normalize, parse as parsems } from "duration-fns";
  import DayTimesWeekChart from "$components/daytimes/DayTimesWeekChart.svelte";

  export let policy = null;
  export let readonly = false;

  let submittable = false;
  let submitting = false;

  let remove = {};
  $: if (Object.values(remove).length > 0) submittable = true;

  $: items = policy && pricesFromPolicy(policy);

  function change(e) {
    submittable = Object.values(remove).length > 0;
  }

  async function submit(e) {
    e.preventDefault();

    if (!policy || submitting || !submittable) return;

    const form = e.target;

    const formData = new FormData(form);

    submitting = true;

    await update(policy, formData);

    submitting = submittable = false;
    remove = {};
  }
  function formatTime(ms) {
    const parsed = normalize(parsems(ms));
    console.log("parsed=", parsed);
    if (parsed.days > 3) {
      return `${parsed.days} days, ${parsed.hours}:${parsed.minutes
        .toString()
        .padStart(2, "0")}`;
    }
    return `${parsed.days * 24 + parsed.hours}:${parsed.minutes
      .toString()
      .padStart(2, "0")}`;
  }
</script>

{#if policy}
  <form on:submit={submit} on:change={change}>
    <fieldset disabled={readonly && "disabled"}>
      <ul class="remove" data-count={get(items, "length")}>
        {#each items as item}
          <li>
            <input
              type="checkbox"
              name="price"
              value={item.id}
              checked={!!remove[item.id]}
              on:change={(e) => (remove[e.target.value] = e.target.checked)}
            />
            <dl>
              <dt>Type</dt>
              <dd>{item.contingent ? "deposit" : "fee"}</dd>
              <dt>Amount</dt>
              <dd>
                {item.description}

                {[
                  item.match,
                  item.group && `per ${item.group.replace("tenant", "unit")}`,
                  // item.principals &&
                  //   `for ${Object.values(item.principals)
                  //     .map((p) => p.display || p)
                  //     .join(",")}`,
                ]
                  .filter((i) => !!i)
                  .join(" ")}
              </dd>

              <dt>Applies To</dt>
              {#each sortBy(Object.values(item.principals || {}), "display") as item}
                <dd>
                  <data
                    class="id {[item.format, item.type].join(' ')}"
                    value={item.id}>{item.display}</data
                  >
                </dd>
              {:else}
                <dd>all/any</dd>
              {/each}
              <dt>Policies</dt>
              {#each Object.entries(item.subjects).filter(([id, title]) => true || id != policy.policy) as [id, title]}
                <dd><data value={id}>{title}</data></dd>
              {/each}
              <dt>Valid</dt>
              <dd>
                {#if !item.valid.interval.endsWith("/")}
                  {item.valid.interval
                    .split("/")
                    .map((str) =>
                      format(
                        utcToZonedTime(str, policy.timezone),
                        " EE MMM d yyyy h:mm a"
                      )
                    )
                    .join(" - ")}
                {:else if parseISO(item.valid.interval.split("/")[0]).getTime() > Date.now()}
                  starting {format(
                    utcToZonedTime(
                      item.valid.interval.split("/")[0],
                      policy.timezone
                    ),
                    " EE MMM d yyyy h:mm a"
                  )}
                {:else}
                  until removed
                {/if}
              </dd>
              <dt>Duration</dt>
              <dd>
                {#if item.duration}
                  <time datetime={item.duration.min}
                    >{formatTime(item.duration.min)}</time
                  >
                  -
                  <time datetime={item.duration.max}
                    >{formatTime(item.duration.max)}</time
                  >
                {:else}
                  all/any
                {/if}
              </dd>
              <dt>Days & Times</dt>
              <dd>
                {#if item.valid.display}
                  {item.valid.display}
                {:else}
                  all/any
                {/if}
              </dd>
              <dd>
                <DayTimesWeekChart
                  values={item.valid.daytime.length
                    ? item.valid.daytime
                    : ["7T00:00/7T00:00"]}
                />
              </dd>
            </dl>

            <!-- {#each Object.entries(item.subjects).filter(([id, title]) => id != policy.policy) as [id, title]}
              <data value={id}>{title}</data>
            {/each} -->
          </li>
        {/each}
      </ul>
    </fieldset>
    {#if !!get(items, "length") && (submitting || submittable)}
      <footer>
        <button type="submit" disabled={!submittable || submitting}
          >{submitting ? "Saving" : "Remove Prices"}</button
        >
      </footer>
    {/if}
  </form>
{/if}
